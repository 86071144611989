import { Trans } from '@lingui/macro'
import { ButtonGray, ButtonPrimary, ButtonPrimary2 } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { SwapPoolTabs } from 'components/NavigationTabs'
import PositionList from 'components/PositionList'
import { RowFixed } from 'components/Row'
import { useV3Positions } from 'hooks/useV3Positions'
import { useActiveWeb3React } from 'hooks/web3'
import { useContext, useEffect, useState } from 'react'
import { ChevronDown, Inbox, PieChart } from 'react-feather'
import { Link, RouteComponentProps } from 'react-router-dom'
import { useWalletModalToggle } from 'state/application/hooks'
import { useUserHideClosedPositions } from 'state/user/hooks'
import styled, { ThemeContext } from 'styled-components/macro'
import { TYPE } from 'theme'
import { PositionDetails } from 'types/position'
import { LoadingRows } from './styleds'
// ============= LC ================
import { Link as HistoryLink } from 'react-router-dom'
import Modal from 'components/Modal'

import { useCubeInfoContract } from 'hooks/useContract'
import LeftArrow from '../../components/NavigationTabs/left-arrow.png'
// import { CubeContext } from '../App'
import { CurrencySearch } from 'components/SearchModal/CurrencySearch'
import { useCubeInfoByAddresses } from 'hooks/useCubeInfo'
import { VersionContext } from 'pages/App'
import { useCubeWhitelistAPI } from 'hooks/useCubeInfoFromAPI'

const StyledLeftArrow = styled.img`
  margin-bottom: 20px;
  margin-left: 10px;
  margin-top: 20px;
  height: 30px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 0;
    height: 25px;
  `};
`

const PageWrapper = styled(AutoColumn)`
  max-width: 870px;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 10px 23px;
  `};
`
const ButtonRow = styled(RowFixed)`
  margin-left: 60%;
  margin-bottom: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-top: 5%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    //flex-direction: row-reverse;
  `};
`
const MoreOptionsButton = styled(ButtonGray)`
  border-radius: 12px;
  flex: 1 1 auto;
  padding: 6px 8px;
  width: 160px;
  background-color: ${({ theme }) => theme.bg0};
  margin-right: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    // justify-content: start;
    width: 100%;
    //display: none;
  `};
`

const NoLiquidity = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 25vh;
`
const ResponsiveButtonPrimary = styled(ButtonPrimary2)`
  border-radius: 22px;
  padding: 8px 12px;
  width: 143px;
  height: 37px;
  white-space: nowrap;
  //justify-content: flex-end;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    //flex: 1 1 auto;
    width: 80%;
    //display: none;
    height: 37px;
    margin-right: 5px;
  `};
`

const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.bg0};
  padding: 8px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
`

const ShowInactiveToggle = styled.div`
  display: flex;
  align-items: center;
  justify-items: end;
  grid-column-gap: 4px;
  padding: 0 8px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin: 5px 0;
    //margin-bottom: 5px;
  `};
`

const ResponsiveRow = styled(RowFixed)`
  justify-content: space-between;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column-reverse;
  `};
`

export default function Pool({
  match: {
    params: { cubeAddress: cubeAddressFromUrl, version: versionFromURL },
  },
}: RouteComponentProps<{
  cubeAddress?: string
  version: string
}>) {
  const { account } = useActiveWeb3React()
  const { switchVersion } = useContext(VersionContext)

  useEffect(() => {
    switchVersion(versionFromURL ? versionFromURL?.replace('v', '').replace('-', '.') : '1.2')
  }, [switchVersion, versionFromURL])

  const toggleWalletModal = useWalletModalToggle()
  const [balanceModalOpen, setBalanceModalOpen] = useState<boolean>(false)

  const { cubes: cubes, loading: cubeLoading } = useCubeInfoByAddresses(
    [cubeAddressFromUrl as string],
    undefined,
    undefined,
    versionFromURL
  )
  //const cubeInfo = cubes[0]
  //const cubeInfo = allCubes?.filter((item) => item.cubeAddress === cubeAddressFromUrl)[0]
  const theme = useContext(ThemeContext)
  const [userHideClosedPositions, setUserHideClosedPositions] = useUserHideClosedPositions()

  const { positions, loading: positionsLoading } = useV3Positions(cubeAddressFromUrl ?? undefined)

  const [openPositions, closedPositions] = positions?.reduce<[PositionDetails[], PositionDetails[]]>(
    (acc, p) => {
      acc[p.liquidity?.isZero() ? 1 : 0].push(p)
      return acc
    },
    [[], []]
  ) ?? [[], []]

  const filteredPositions = [...openPositions, ...(userHideClosedPositions ? [] : closedPositions)]
  const showConnectAWallet = Boolean(!account)
  const { data: tokenList } = useCubeWhitelistAPI(cubeAddressFromUrl as string)

  //const [, setTokenList] = useState<string[]>([])

  // async function getCube() {
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   const tokens = await useCubeInfoContract(cubeAddressFromUrl as string)?.getTokenWhitelist()
  //   if (tokens) {
  //     setTokenList(tokens)
  //   }
  // }
  // getCube().then()

  const defaultCurrency = !cubeLoading && cubes && cubes[0] ? cubes[0].baseCurrency : 'ETH'

  return (
    <>
      <PageWrapper>
        {balanceModalOpen && (
          <Modal isOpen={balanceModalOpen} onDismiss={() => setBalanceModalOpen(false)} maxHeight={80} minHeight={80}>
            <CurrencySearch
              cubeWhitelist={tokenList}
              cubeAddr={cubeAddressFromUrl}
              isWallet={false}
              isOpen={balanceModalOpen}
              onDismiss={() => setBalanceModalOpen(false)}
              onCurrencySelect={() => console.log('')}
              selectedCurrency={null}
              otherSelectedCurrency={null}
              showCommonBases={false}
              showCurrencyAmount={true}
              disableNonToken={false}
              showImportView={() => console.log('')}
              setImportToken={() => console.log('')}
              showManageView={() => console.log('')}
              isPool={true}
            />
          </Modal>
        )}
        <AutoColumn justify="start">
          <HistoryLink to={'/trader/' + versionFromURL + '/' + cubeAddressFromUrl}>
            <StyledLeftArrow src={LeftArrow} />
          </HistoryLink>

          <TYPE.body fontSize={'22px'} fontWeight={'500'} margin={'auto'} marginBottom={'20px'}>
            <Trans>Stake</Trans>
          </TYPE.body>
          {cubeAddressFromUrl && (
            <SwapPoolTabs
              active={'pool'}
              version={versionFromURL ? versionFromURL : 'v1-1'}
              traderAddress={cubeAddressFromUrl}
            />
          )}
        </AutoColumn>

        <MainContentWrapper>
          <div>
            <ButtonRow>
              {cubeAddressFromUrl && (
                <MoreOptionsButton onClick={() => setBalanceModalOpen(true)}>
                  <TYPE.body style={{ alignItems: 'center', display: 'flex' }}>
                    <PieChart opacity={0.6} size={16} style={{ marginRight: '5px' }} />
                    <Trans>Cube Balance</Trans>
                    <ChevronDown size={15} />
                  </TYPE.body>
                </MoreOptionsButton>
              )}

              <ResponsiveButtonPrimary
                id="join-pool-button"
                as={Link}
                to={
                  defaultCurrency
                    ? '/trader/' + versionFromURL + '/' + cubeAddressFromUrl + '/add/' + defaultCurrency
                    : '/trader/' + versionFromURL + '/' + cubeAddressFromUrl + '/add/'
                }
              >
                + <Trans>New Position</Trans>
              </ResponsiveButtonPrimary>
            </ButtonRow>
          </div>
          {closedPositions.length > 0 ? (
            <ShowInactiveToggle>
              <input
                type="checkbox"
                onClick={() => setUserHideClosedPositions(!userHideClosedPositions)}
                checked={!userHideClosedPositions}
              />
              <label>
                <TYPE.body onClick={() => setUserHideClosedPositions(!userHideClosedPositions)}>
                  <Trans>Show closed positions</Trans>
                </TYPE.body>
              </label>
            </ShowInactiveToggle>
          ) : null}
          {positionsLoading ? (
            <LoadingRows>
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
            </LoadingRows>
          ) : filteredPositions && filteredPositions.length > 0 ? (
            <PositionList
              cubeAddr={cubeAddressFromUrl as string}
              positions={filteredPositions}
              version={versionFromURL}
            />
          ) : (
            <NoLiquidity>
              <TYPE.body color={theme.text3} textAlign="center">
                <Inbox size={48} strokeWidth={1} style={{ marginBottom: '.5rem' }} />
                <div>
                  {/* <Trans>Your V3 liquidity positions will appear here.</Trans> */}
                  <Trans>Add a new position.</Trans>
                </div>
              </TYPE.body>
              {showConnectAWallet && (
                <ButtonPrimary style={{ marginTop: '2em', padding: '8px 16px' }} onClick={toggleWalletModal}>
                  <Trans>Connect a wallet</Trans>
                </ButtonPrimary>
              )}
            </NoLiquidity>
          )}
        </MainContentWrapper>

        <ResponsiveRow />
      </PageWrapper>
    </>
  )
}
