import { Trans } from '@lingui/macro'
import { Currency, Token } from '@uniswap/sdk-core'
import { CSSProperties, MutableRefObject, useCallback, useMemo } from 'react'
import { FixedSizeList } from 'react-window'
import { Text } from 'rebass'
import styled from 'styled-components/macro'
import { useCombinedActiveList } from '../../state/lists/hooks'
import { WrappedTokenInfo } from '../../state/lists/wrappedTokenInfo'
import { TYPE } from '../../theme'
import { useIsUserAddedToken } from '../../hooks/Tokens'
import Column from '../Column'
import { RowFixed, RowBetween } from '../Row'
import CurrencyLogo from '../CurrencyLogo'
import { MenuItem } from './styleds'
import { isTokenOnList } from '../../utils'
import ImportRow from './ImportRow'
import { LightGreyCard } from 'components/Card'
import TokenListLogo from '../../assets/svg/tokenlist.svg'
import QuestionHelper from 'components/QuestionHelper'
import useTheme from 'hooks/useTheme'

import { Square, CheckSquare } from 'react-feather'
import TokenTags from './TokenTags'

function currencyKey(currency: Currency): string {
  return currency.isToken ? currency.address : 'ETHER'
}

const FixedContentRow = styled.div`
  padding: 4px 20px;
  height: 56px;
  display: grid;
  grid-gap: 16px;
  align-items: center;
`

const TokenListLogoWrapper = styled.img`
  height: 20px;
`

function CurrencyRow({
  //isWallet,
  currency,
  onSelect,
  isSelected,
  otherSelected,
  style,
}: //showCurrencyAmount,
{
  isWallet?: boolean
  currency: Currency
  onSelect: () => void
  isSelected: boolean
  otherSelected: boolean
  style: CSSProperties
  showCurrencyAmount?: boolean
}) {
  const key = currencyKey(currency)
  const selectedTokenList = useCombinedActiveList()
  const isOnSelectedList = isTokenOnList(selectedTokenList, currency.isToken ? currency : undefined)
  const customAdded = useIsUserAddedToken(currency)

  return (
    <MenuItem
      style={style}
      className={`token-item-${key}`}
      onClick={() => onSelect()}
      disabled={otherSelected}
      selected={otherSelected}
    >
      <CurrencyLogo currency={currency} size={'24px'} />
      <Column>
        <Text title={currency.name} fontWeight={500}>
          {currency.symbol}
        </Text>
        <TYPE.darkGray ml="0px" fontSize={'12px'} fontWeight={300}>
          {!currency.isNative && !isOnSelectedList && customAdded ? (
            <Trans>{currency.name} • Added by user</Trans>
          ) : (
            currency.name
          )}
        </TYPE.darkGray>
      </Column>
      <TokenTags currency={currency} />
      <RowFixed style={{ justifySelf: 'flex-end' }}>
        {isSelected ? <CheckSquare size={'20px'} /> : <Square size={'20px'} />}
      </RowFixed>
    </MenuItem>
  )
}

const BREAK_LINE = 'BREAK'
type BreakLine = typeof BREAK_LINE
function isBreakLine(x: unknown): x is BreakLine {
  return x === BREAK_LINE
}

function BreakLineComponent({ style }: { style: CSSProperties }) {
  const theme = useTheme()
  return (
    <FixedContentRow style={style}>
      <LightGreyCard padding="8px 12px" $borderRadius="8px">
        <RowBetween>
          <RowFixed>
            <TokenListLogoWrapper src={TokenListLogo} />
            <TYPE.main ml="6px" fontSize="12px" color={theme.text1}>
              <Trans>Expanded results from inactive Token Lists</Trans>
            </TYPE.main>
          </RowFixed>
          <QuestionHelper
            text={
              <Trans>
                Tokens from inactive lists. Import specific tokens below or click Manage to activate more lists.
              </Trans>
            }
          />
        </RowBetween>
      </LightGreyCard>
    </FixedContentRow>
  )
}

export default function MultipleCurrencyList({
  isWallet,
  height,
  currencies,
  otherListTokens,
  selectedCurrency,
  onCurrencySelect,
  otherCurrency,
  fixedListRef,
  showImportView,
  setImportToken,
  showCurrencyAmount,
}: {
  isWallet?: boolean
  height: number
  currencies: Currency[]
  otherListTokens?: WrappedTokenInfo[]
  selectedCurrency?: Currency[] | null
  onCurrencySelect: (currency: Currency[]) => void
  otherCurrency?: Currency | null
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
  showImportView: () => void
  setImportToken: (token: Token) => void
  showCurrencyAmount?: boolean
}) {
  const itemData: (Currency | BreakLine)[] = useMemo(() => {
    if (otherListTokens && otherListTokens?.length > 0) {
      return [...currencies, BREAK_LINE, ...otherListTokens]
    }
    return currencies
  }, [currencies, otherListTokens])

  const Row = useCallback(
    function TokenRow({ data, index, style }) {
      const row: Currency | BreakLine = data[index]

      if (isBreakLine(row)) {
        return <BreakLineComponent style={style} />
      }

      const currency = row
      //console.log('currency: ', currency)
      // console.log('list', selectedCurrency)

      const hasTokenAddress = selectedCurrency
        ? selectedCurrency.filter((token) => token.isToken && currency.isToken && token.address === currency.address)
            .length !== 0
        : false

      const isSelected = Boolean(
        currency && selectedCurrency && (selectedCurrency.includes(currency) || hasTokenAddress)
      )

      const otherSelected = Boolean(currency && otherCurrency && otherCurrency.equals(currency))
      const handleSelect = () => {
        currency && selectedCurrency
          ? !isSelected
            ? onCurrencySelect([...selectedCurrency, currency])
            : onCurrencySelect(
                selectedCurrency
                  ?.filter((el) => el !== currency)
                  .filter((el) => {
                    if (el.isToken && currency.isToken) {
                      return el.address !== currency.address
                    }
                    return true
                  })
              )
          : !isSelected && onCurrencySelect([currency])
      }

      const token = currency?.wrapped

      const showImport = index > currencies.length

      if (showImport && token) {
        return (
          <ImportRow style={style} token={token} showImportView={showImportView} setImportToken={setImportToken} dim />
        )
      } else if (currency) {
        return (
          <>
            <CurrencyRow
              isWallet={isWallet}
              style={style}
              currency={currency}
              isSelected={isSelected}
              onSelect={handleSelect}
              otherSelected={otherSelected}
              showCurrencyAmount={showCurrencyAmount}
            />
          </>
        )
      } else {
        return null
      }
    },
    [
      selectedCurrency,
      otherCurrency,
      currencies.length,
      onCurrencySelect,
      showImportView,
      setImportToken,
      isWallet,
      showCurrencyAmount,
    ]
  )

  const itemKey = useCallback((index: number, data: typeof itemData) => {
    const currency = data[index]
    if (isBreakLine(currency)) return BREAK_LINE
    return currencyKey(currency)
  }, [])

  return (
    <FixedSizeList
      height={height}
      ref={fixedListRef as any}
      width="100%"
      itemData={itemData}
      itemCount={itemData.length}
      itemSize={56}
      itemKey={itemKey}
    >
      {Row}
    </FixedSizeList>
  )
}
