// ============== LC ===============
// import LIQUID_CUBE_ABI from 'abis/liquid_cube.json'
// import { Erc20, ArgentWalletDetector, EnsPublicResolver, EnsRegistrar, Weth, LiquidCube } from '../abis/types'
// import { LIQUID_CUBE_ADDRESS } from 'constants/addresses'

// export function useLCCreatePosition() {
// }
// import { Percent } from '@uniswap/sdk-core'
import { toHex } from '@uniswap/v3-sdk'
import { Interface } from '@ethersproject/abi'
// import { BigNumber, ethers } from 'ethers'
// import { BaseCurrency } from '@uniswap/sdk-core/dist/entities/baseCurrency'
import { utils } from 'ethers'
// import erc20abi from '../abis/erc20.json'
// import { Erc20Interface } from 'abis/types/Erc20'
// import { useSingleCallResult } from 'state/multicall/hooks'
// import { useToken } from './Tokens'

// type AddressMap = { [chainId: number]: string }

export function useLCCreateCube(
  creator: string,
  INTERFACE: Interface,
  cubeName: string,
  baseCurrency: string,
  //LIQUID_CUBE_ADDRESS: AddressMap,
  numberOfShares: string,
  pricePerShare: string,
  traderCut: string,
  tradingPeriod: string,
  //deadline: BigNumber,
  tokenWhitelist: string[],
  startDeadline: string
): string[] {
  const calldata: string[] = []

  // adjust for slippage
  //const minimumAmounts = position.mintAmountsWithSlippage(allowedSlippage)
  //const amount0Min = toHex(minimumAmounts.amount0)
  //const amount1Min = toHex(minimumAmounts.amount1)
  //const tokenContract = new ethers.getContract(baseCurrency, ERC20abi);
  //async function
  //10/14 -------
  //const baseTokenContract = new ethers.Contract(baseCurrency, erc20abi)
  // baseTokenContract.decimals().then((rep: any) => console.log(rep))
  // console.log('@@@@', baseTokenContract)
  //const ERC20Interface = new Interface(erc20abi) as Erc20Interface
  //const result = useSingleCallResult(baseTokenContract, 'decimals', [])
  //console.log('rsult', result)
  //const BaseDecimal = useToken(baseCurrency)?.decimals
  //console.log('BaseDecimal', BaseDecimal)

  calldata.push(
    INTERFACE.encodeFunctionData('createSudoCubeRelay', [
      [
        //recipient: LIQUID_CUBE_ADDRESS[chainId],
        //deadline,
        toHex(creator),
        utils.formatBytes32String(cubeName),
        baseCurrency,
        toHex(numberOfShares),
        //TODO: if 18 parseEther, if not, ->ether.big number(pricePerShare).mul(baseCurrency.decimals)
        pricePerShare,
        //toHex(pricePerShare),
        toHex(traderCut),
        //toHex((86400 * Number(tradingPeriod)).toFixed(0)),
        toHex(tradingPeriod),
        tokenWhitelist,
        toHex(startDeadline),
      ],
    ])
  )
  //console.log(`call data: ${calldata[0]}`)

  return calldata
}
