import { Percent } from '@uniswap/sdk-core'
import { Position } from '@uniswap/v3-sdk'
import { toHex } from '@uniswap/v3-sdk'
import { Interface } from '@ethersproject/abi'
import { BigNumber } from 'ethers'
import { ZERO_ADDRESS } from 'constants/misc'

export function useLCCollectFees(
  account: string,
  cubeAddress: string,
  tokenId: BigNumber,
  //position: Position,
  INTERFACE: Interface,
  token0: string,
  token1: string
  // fee0Value: string,
  // fee1Value: string
) {
  //const { account } = useActiveWeb3React()
  const calldatas: string[] = []

  // console.log(`tokenId: ${tokenId}`)
  // console.log(position)

  // collect
  calldatas.push(
    INTERFACE.encodeFunctionData('collectAllFees', [
      token0,
      token1,
      // cubeAddr: toHex(cubeAddress),
      // traderAddr: toHex(account),
      // selectedPlatform: toHex(0),
      {
        cubeAddr: toHex(cubeAddress),
        traderAddr: toHex(account),
        selectedPlatform: toHex(0),
        collectParams: {
          tokenId: toHex(tokenId.toString()),
          recipient: toHex(cubeAddress),
        },
        // tokenId: toHex(tokenId.toString()),
        // recipient: toHex(cubeAddress),
      },
    ])
  )

  //const calldataRe: string[] = []
  //calldataRe.push(INTERFACE.encodeFunctionData('multicall', [calldatas]))
  return calldatas
}
