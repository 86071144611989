import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from '@uniswap/v2-sdk'
import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from '@uniswap/v3-sdk'
import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

export const UNI_ADDRESS: AddressMap = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984')
export const MULTICALL_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x1F98415757620B543A52E61c46B32eB19261F984', [SupportedChainId.OPTIMISTIC_KOVAN]),
  [SupportedChainId.OPTIMISM]: '0x90f872b3d8f33f305e0250db6A2761B354f7710A',
  [SupportedChainId.ARBITRUM_ONE]: '0xadF885960B47eA2CD9B55E6DAc6B42b7Cb2806dB',
  [SupportedChainId.ARBITRUM_RINKEBY]: '0xa501c031958F579dB7676fF1CE78AD305794d579',
}
export const V2_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap(V2_FACTORY_ADDRESS)
export const V2_ROUTER_ADDRESS: AddressMap = constructSameAddressMap('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D')

/**
 * The older V0 governance account
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = constructSameAddressMap(
  '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'
)
/**
 * The latest governor alpha that is currently admin of timelock
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6',
}

export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC')

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}
export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap(V3_FACTORY_ADDRESS, [
  SupportedChainId.OPTIMISM,
  SupportedChainId.OPTIMISTIC_KOVAN,
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.ARBITRUM_RINKEBY,
])
export const QUOTER_ADDRESSES: AddressMap = constructSameAddressMap('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', [
  SupportedChainId.OPTIMISM,
  SupportedChainId.OPTIMISTIC_KOVAN,
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.ARBITRUM_RINKEBY,
])
export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = constructSameAddressMap(
  '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
  [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
  ]
)
export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.ROPSTEN]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.GOERLI]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.RINKEBY]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}
export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x65770b5283117639760beA3F867b69b3697a91dd',
}
export const SWAP_ROUTER_ADDRESSES: AddressMap = constructSameAddressMap('0xE592427A0AEce92De3Edee1F18E0157C05861564', [
  SupportedChainId.OPTIMISM,
  SupportedChainId.OPTIMISTIC_KOVAN,
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.ARBITRUM_RINKEBY,
])
export const V3_MIGRATOR_ADDRESSES: AddressMap = constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34', [
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.ARBITRUM_RINKEBY,
])

// ================ LC ================

export const LC_CUBESWAPPING_LOGIC: AddressMap = {
  [SupportedChainId.MAINNET]: '0x910514E69a9d73014e773f7ae64Ce7039C705fd7',
  [SupportedChainId.ROPSTEN]: '0xe4F8eB8adEeeA3ABafbbeac878f059bAD28de06f',
  [SupportedChainId.RINKEBY]: '0x06FD09D0ba98713075c7D2eADE23dF09787E51fE',
}

export const LC_CUBESTAKING_LOGIC: AddressMap = {
  [SupportedChainId.MAINNET]: '0xEe66249edd09C2d714D803b98aeC60a67eF53D1c',
  [SupportedChainId.ROPSTEN]: '0x4b93dBb76a1bC87fCb26bF3cB4cd3a13D035D89f',
  [SupportedChainId.RINKEBY]: '0xCcbf1CBE498Ee7f520822F7B2f0b7df2eb1c2A21',
}

export const LC_FACTORY_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x26946AEa345c7d4E2eC6635e02E4b110482F4A98',
  [SupportedChainId.ROPSTEN]: '0x870d4D8611A4AF5dFB22C0292b36187a975cb3c2',
  [SupportedChainId.RINKEBY]: '0x4Cd4744bD7270fe5dc4Dc56aa8761E09Dc85Db0F',
}

export const LC_MANAGER_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x96b92D2a81E0B88154d12164c2ce6a5E50dA456A',
  [SupportedChainId.ROPSTEN]: '0x4e8848f6d1F3aFEd99bD75ab3d3076B44a5eC068',
  [SupportedChainId.RINKEBY]: '0x70dbA811fDD64E3135A441CD638c32E5CF7a536E',
}

export const LC_PERIPHERY_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xbBd6E70d939Aa1275b1f3DD37eD7ad6ebE5aE0E8',
  [SupportedChainId.ROPSTEN]: '0xCdc0C0a5c1127c57F2E5E2C7E744553c1e422F2A',
  [SupportedChainId.RINKEBY]: '0xb16AcE6a2790348bf1D525E738bCD468B16ba993',
}

export const MULTICALL_DETACHED_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x8D5d9bC29dEB9EaD0c015011ef220e2782267bB0',
  [SupportedChainId.ROPSTEN]: '0xc6a4EC29ceA274a003b12e1d7F1432696bedeA5D',
}

// ================ LC v1.1 ===================

export const LC_CUBESWAPPING_LOGIC_V1_1: AddressMap = {
  [SupportedChainId.MAINNET]: '0x5d5C3a5016C1A219cd6A81345F530c6A152B46D2',
  [SupportedChainId.ROPSTEN]: '0x93d208BCEF7E64acAa66f462C39eb5f8C5f2A951',
  [SupportedChainId.RINKEBY]: '0xa42a358524add03920547Dd58B09082c6F843720',
}

export const LC_CUBESTAKING_LOGIC_V1_1: AddressMap = {
  [SupportedChainId.MAINNET]: '0xAa4Fc9CBC398f3D32b7898707964657346cbDe5C',
  [SupportedChainId.ROPSTEN]: '0x0e145eA308b8d49aE44eb487D52BA63A288E96aC',
  [SupportedChainId.RINKEBY]: '0x836606935eA91b72ae76ce2563037db103F5fDD2',
}

export const LC_FACTORY_ADDRESS_V1_1: AddressMap = {
  [SupportedChainId.MAINNET]: '0x3493A20E0CE653eB61Dbe0613adbfC62E8eA8E72',
  [SupportedChainId.ROPSTEN]: '0x6de02D8a0b83692efB41c7A0e1dD5fBF5E95fCc2',
  [SupportedChainId.RINKEBY]: '0xc1Cb0e0FC46DDDaD80BC14CaCF8ee3613e1E65C7',
}

export const LC_MANAGER_ADDRESS_V1_1: AddressMap = {
  [SupportedChainId.MAINNET]: '0xaaaf3f81Ee6cf01Bb8761e752d33CAe912697928',
  [SupportedChainId.ROPSTEN]: '0xCddc7180F11a7C77c6ea7fa01D8D8f6eB88159A2',
  [SupportedChainId.RINKEBY]: '0xADD67faEFF6369c7cAc4E97a219cBdA3bde93C58',
}

export const LC_PERIPHERY_ADDRESS_V1_1: AddressMap = {
  [SupportedChainId.MAINNET]: '0x680C84bC2991c847091aF3FC6eD7513a1BF6a748',
  [SupportedChainId.ROPSTEN]: '0xb3a0525c91a189829A80F3fEcb4EDcd379F5e611',
  [SupportedChainId.RINKEBY]: '0xA75223C416AC56B9aa2335655Adf1731b535e267',
}

// ================ LC v1.2 ===================

export const LC_CUBESWAPPING_LOGIC_V1_2: AddressMap = {
  [SupportedChainId.MAINNET]: '0x0Dc2A5D1E97C0d6eD3085bA94d9d6Ebd99AE4efD',
  //[SupportedChainId.ROPSTEN]: '0x93d208BCEF7E64acAa66f462C39eb5f8C5f2A951',
  [SupportedChainId.RINKEBY]: '0x38cB74788fe421409fa8758fd2A1acE2eCFFbC2f',
  [SupportedChainId.ARBITRUM_RINKEBY]: '0x36268eE6b36bC0FA7664B55259FE4F7DF706391C',
  [SupportedChainId.ARBITRUM_ONE]: '0x0Dc2A5D1E97C0d6eD3085bA94d9d6Ebd99AE4efD',
}

export const LC_CUBESTAKING_LOGIC_V1_2: AddressMap = {
  [SupportedChainId.MAINNET]: '0xA38cCE30CECe81e5F1431870D803F0d3233aFC17',
  //[SupportedChainId.ROPSTEN]: '0x0e145eA308b8d49aE44eb487D52BA63A288E96aC',
  [SupportedChainId.RINKEBY]: '0x41eE0EC536ac92FC0662985E4Bb894C0506881c5',
  [SupportedChainId.ARBITRUM_RINKEBY]: '0x3353AB3E1BAA7a56c285a710abDf0F843B2Cf595',
  [SupportedChainId.ARBITRUM_ONE]: '0xA38cCE30CECe81e5F1431870D803F0d3233aFC17',
}

export const LC_FACTORY_ADDRESS_V1_2: AddressMap = {
  [SupportedChainId.MAINNET]: '0x7c8Ac1a7A4eD2144e2Bf5A22584b69f154499205',
  //[SupportedChainId.ROPSTEN]: '0x6de02D8a0b83692efB41c7A0e1dD5fBF5E95fCc2',
  [SupportedChainId.RINKEBY]: '0x8Fd24514482C5Da686Ba4822E775E03Fbee422d0',
  [SupportedChainId.ARBITRUM_RINKEBY]: '0xC26c43BA40baBEcBF9872dEeDFE84588F7a0c017',
  [SupportedChainId.ARBITRUM_ONE]: '0x7c8Ac1a7A4eD2144e2Bf5A22584b69f154499205',
}

export const LC_MANAGER_ADDRESS_V1_2: AddressMap = {
  [SupportedChainId.MAINNET]: '0xE3eD833C831dCe682C125098bd701AFEd4722b75',
  //[SupportedChainId.ROPSTEN]: '0xCddc7180F11a7C77c6ea7fa01D8D8f6eB88159A2',
  [SupportedChainId.RINKEBY]: '0x468509f946126c6ec28dA50ab9bf416876e8B08c',
  [SupportedChainId.ARBITRUM_RINKEBY]: '0xb4a07b2065743d18684D30C4361a61aBcF1FFc57',
  [SupportedChainId.ARBITRUM_ONE]: '0xE3eD833C831dCe682C125098bd701AFEd4722b75',
}

export const LC_PERIPHERY_ADDRESS_V1_2: AddressMap = {
  [SupportedChainId.MAINNET]: '0x2c99d23b0f0cE507FCd9d47D91D9b2cD438cFC4A',
  //[SupportedChainId.ROPSTEN]: '0xb3a0525c91a189829A80F3fEcb4EDcd379F5e611',
  [SupportedChainId.RINKEBY]: '0x220a8Ad4cAfe5020FD251A7610BCB9d7b50aee55',
  [SupportedChainId.ARBITRUM_RINKEBY]: '0xe1Abc060D660CEFADFD5c5482D67C0F1dfD381aD',
  [SupportedChainId.ARBITRUM_ONE]: '0x2c99d23b0f0cE507FCd9d47D91D9b2cD438cFC4A',
}
