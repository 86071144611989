import styled from 'styled-components/macro'

const GeneralLabel = styled.span`
  font-size: 13px;
  font-weight: 700;
  margin-top: -5px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: left;
  border-radius: 11px;
  width: 145px;
  height: 31px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 20px;
    font-size: 11px;
    margin: 0;
    margin-top: 2px;
    line-height: 8px;
    border-radius: 7px;
    margin-left: auto;
`};
`

const GreenLabel = styled(GeneralLabel)`
  color: #4fbb26;
  border: 2px solid #4fbb26;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 120px;
    border: 1px solid #4fbb26;
`};
`

const RedLabel = styled(GeneralLabel)`
  color: #d64b4b;
  border: 2px solid #d64b4b;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 86px;
    border: 1px solid #d64b4b;
`};
`

const BlueLabel = styled(GeneralLabel)`
  color: #0d6ce8;
  border: 2px solid #0d6ce8;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    border: 1px solid #0D6CE8;
    width: 59px;
    margin-top: 3px;
`};
`

const YellowLabel = styled(GeneralLabel)`
  color: #efa116;
  border: 2px solid #efa116;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 107px;
    margin-top: 3px;
    line-height: 10px;
    border: 1px solid #EFA116;
`};
`

const BlackLabel = styled(GeneralLabel)`
  color: #000000;
  border: 2px solid #000000;
  align-items: flex-end;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    border: 1px solid #000000;
    width: 104px;
    margin-top: 3px;
    line-height: 8px;
`};
`

export function CubeStatusLabel(props: { color: string }) {
  return props.color === 'green' ? (
    <GreenLabel>Trading in Progress</GreenLabel>
  ) : props.color === 'red' ? (
    <RedLabel>Trade Ended</RedLabel>
  ) : props.color === 'black' ? (
    <BlackLabel>Funding Expired</BlackLabel>
  ) : props.color === 'yellow' ? (
    <YellowLabel>Waiting to Trade</YellowLabel>
  ) : props.color === 'blue' ? (
    <BlueLabel>Funding</BlueLabel>
  ) : null
}
