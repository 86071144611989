import { t } from '@lingui/macro'
import React, { useEffect, useRef, useState, useCallback } from 'react'
//import { Globe, ChevronLeft, Check } from 'react-feather'
import { Link, useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
//import { Trans } from '@lingui/macro'
import { ExternalLink } from '../../theme'
// import { ButtonPrimary } from '../Button'
//import { useDarkModeManager } from 'state/user/hooks'

// import { L2_CHAIN_IDS, CHAIN_INFO, SupportedChainId } from 'constants/chains'
//import { LOCALE_LABEL, SupportedLocale, SUPPORTED_LOCALES } from 'constants/locales'
// import { useLocationLinkProps } from 'hooks/useLocationLinkProps'
// import { useActiveLocale } from 'hooks/useActiveLocale'
//========LC=========
//import { VersionContext } from 'pages/App'
import Modal from 'components/Modal'
import SwapPopup from 'components/SwapPopup'
import { SupportedChainId } from 'constants/chains'
import { useActiveWeb3React } from 'hooks/web3'
// import { BigNumber } from 'ethers'

export enum FlyoutAlignment {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

const StyledMenuIcon = styled(MenuIcon)`
  path {
    stroke: ${({ theme }) => theme.text1};
  }
`

const StyledMenuButton = styled.button`
  width: 100%;
  margin: 0;
  height: 38px;
  background-color: ${({ theme }) => theme.bg0};
  border: 1px solid ${({ theme }) => theme.bg2};
  font-size: 15px;
  font-weight: 700;
  color: black;

  padding: 0.15rem 0.5rem;
  border-radius: 12px;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    border: 1px solid ${({ theme }) => theme.primary1};
  }

  svg {
    margin-top: 2px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 13.33px;
    height: 35.5px;
  `};
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
  //   ${({ theme }) => theme.mediaWidth.upToSmall`
//   display: none;
// `};
`

export const MenuFlyout = styled.span<{ flyoutAlignment?: FlyoutAlignment }>`
  min-width: 196px;
  max-height: 350px;
  overflow: auto;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border: 1px solid ${({ theme }) => theme.bg0};
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: absolute;
  top: 3rem;
  z-index: 100;

  ${({ flyoutAlignment = FlyoutAlignment.RIGHT }) =>
    flyoutAlignment === FlyoutAlignment.RIGHT
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    bottom: unset;
    right: 0;
    left: unset;
  `};
`

const MenuItem = styled(ExternalLink)`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
`

const InternalMenuItem = styled(Link)`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

const InternalLinkMenuItem = styled(InternalMenuItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
`

export const ToggleMenuItem = styled.button`
  background-color: transparent;
  margin: 0;
  border: none;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
`

// const CODE_LINK = 'https://github.com/Uniswap/uniswap-interface'

// function LanguageMenuItem({ locale, active, key }: { locale: SupportedLocale; active: boolean; key: string }) {
//   const { to, onClick } = useLocationLinkProps(locale)

//   if (!to) return null

//   return (
//     <InternalLinkMenuItem onClick={onClick} key={key} to={to}>
//       <div>{LOCALE_LABEL[locale]}</div>
//       {active && <Check opacity={0.6} size={16} />}
//     </InternalLinkMenuItem>
//   )
// }

// function LanguageMenu({ close }: { close: () => void }) {
//   const activeLocale = useActiveLocale()

//   return (
//     <MenuFlyout>
//       <ToggleMenuItem onClick={close}>
//         <ChevronLeft size={16} />
//       </ToggleMenuItem>
//       {SUPPORTED_LOCALES.map((locale) => (
//         <LanguageMenuItem locale={locale} active={activeLocale === locale} key={locale} />
//       ))}
//     </MenuFlyout>
//   )
// }

// export default function Menu() {
//   //const { chainId } = useActiveWeb3React()
//   const { currentVersion, toggleVersion } = useContext(VersionContext)

//   const node = useRef<HTMLDivElement>()
//   const open = useModalOpen(ApplicationModal.MENU)
//   const toggle = useToggleModal(ApplicationModal.MENU)
//   useOnClickOutside(node, open ? toggle : undefined)
//   //const openClaimModal = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
//   //const showUNIClaimOption = Boolean(!!account && !!chainId && !L2_CHAIN_IDS.includes(chainId))
//   //const { infoLink } = CHAIN_INFO[chainId ? chainId : SupportedChainId.MAINNET]

//   //const [darkMode, toggleDarkMode] = useDarkModeManager()

//   const [menu, setMenu] = useState<'main' | 'lang'>('main')
//   const [showSwap, setShowSwap] = useState<boolean>(false)
//   //====================================================

//   useEffect(() => {
//     setMenu('main')
//   }, [open])

//   return (
//     <StyledMenu ref={node as any}>
//       <StyledMenuButton onClick={toggle} aria-label={t`Menu`}>
//         {/* <StyledMenuIcon /> */}v{currentVersion}
//       </StyledMenuButton>

//       {open &&
//         (() => {
//           switch (menu) {
//             case 'lang':
//               return <LanguageMenu close={() => setMenu('main')} />
//             case 'main':
//             default:
//               return (
//                 <MenuFlyout>
//                   {/* <ToggleMenuItem onClick={() => setMenu('lang')}>
//                     <div>
//                       <Trans>Language</Trans>
//                     </div>
//                     <Globe opacity={0.6} size={16} />
//                   </ToggleMenuItem> */}
//                   <ToggleMenuItem onClick={() => toggleVersion('1.2')}>
//                     <div>{currentVersion}</div>
//                     {/* {isVersionOne ? <Moon opacity={0.6} size={16} /> : <Sun opacity={0.6} size={16} />} */}
//                     <Globe opacity={0.6} size={16} />
//                   </ToggleMenuItem>
//                 </MenuFlyout>
//               )
//           }
//         })()}
//     </StyledMenu>
//   )
// }

export default function Menu() {
  const { chainId, account } = useActiveWeb3React()
  //const { currentVersion, toggleVersion } = useContext(VersionContext)

  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.MENU)
  const toggle = useToggleModal(ApplicationModal.MENU)
  const history = useHistory()
  useOnClickOutside(node, open ? toggle : undefined)
  //const openClaimModal = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  //const showUNIClaimOption = Boolean(!!account && !!chainId && !L2_CHAIN_IDS.includes(chainId))
  //const { infoLink } = CHAIN_INFO[chainId ? chainId : SupportedChainId.MAINNET]

  //const [darkMode, toggleDarkMode] = useDarkModeManager()

  const [menu, setMenu] = useState<'main' | 'lang'>('main')
  const [showSwap, setShowSwap] = useState<boolean>(false)
  //====================================================

  useEffect(() => {
    setMenu('main')
  }, [open])

  const handleDismissConfirmation = useCallback(() => {
    setShowSwap(false)
  }, [])

  return (
    <StyledMenu ref={node as any}>
      {account && (
        <StyledMenuButton onClick={() => setShowSwap(true)} aria-label={t`Menu`}>
          {/* <StyledMenuIcon /> */}Swap
        </StyledMenuButton>
      )}

      {chainId === SupportedChainId.MAINNET && account && (
        <StyledMenuButton
          onClick={() => history.push('/profile/investor')}
          aria-label={t`Menu`}
          style={{ marginLeft: '5px' }}
        >
          {/* <StyledMenuIcon /> */}Profile
        </StyledMenuButton>
      )}

      <Modal isOpen={showSwap} onDismiss={handleDismissConfirmation}>
        {/* <SwapWidget
              provider={library}
              jsonRpcEndpoint={chainId === 1 ? NETWORK_URLS[1] : undefined}
              tokenList={chainId === 1 ? COINGECKO_LIST : COMPOUND_LIST}
              theme={widgetTheme}
              defaultOutputTokenAddress={base?.isToken ? base.address : undefined}
              defaultOutputAmount={base?.isToken ? parseFloat((Number(pricePerCubelet) * inputNumber).toFixed(12)) : 0}
              //defaultOutputAmount={100}
            /> */}
        <SwapPopup
        // defaultOutputToken={base?.isToken ? base.address : undefined}
        // defaultOutputToken={base}
        // defaultOutputAmount={parseFloat((Number(pricePerCubelet) * inputNumber).toFixed(12))}
        />
      </Modal>
    </StyledMenu>
  )
}

interface NewMenuProps {
  flyoutAlignment?: FlyoutAlignment
  ToggleUI?: React.FunctionComponent
  menuItems: {
    content: any
    link: string
    external: boolean
  }[]
}

const NewMenuFlyout = styled(MenuFlyout)`
  top: 3rem !important;
`
const NewMenuItem = styled(InternalMenuItem)`
  width: max-content;
  text-decoration: none;
`

const ExternalMenuItem = styled(MenuItem)`
  width: max-content;
  text-decoration: none;
`

export const NewMenu = ({ flyoutAlignment = FlyoutAlignment.RIGHT, ToggleUI, menuItems, ...rest }: NewMenuProps) => {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.POOL_OVERVIEW_OPTIONS)
  const toggle = useToggleModal(ApplicationModal.POOL_OVERVIEW_OPTIONS)
  useOnClickOutside(node, open ? toggle : undefined)
  const ToggleElement = ToggleUI || StyledMenuIcon
  return (
    <StyledMenu ref={node as any} {...rest}>
      <ToggleElement onClick={toggle} />
      {open && (
        <NewMenuFlyout flyoutAlignment={flyoutAlignment}>
          {menuItems.map(({ content, link, external }, i) =>
            external ? (
              <ExternalMenuItem href={link} key={i}>
                {content}
              </ExternalMenuItem>
            ) : (
              <NewMenuItem to={link} key={i}>
                {content}
              </NewMenuItem>
            )
          )}
        </NewMenuFlyout>
      )}
    </StyledMenu>
  )
}
