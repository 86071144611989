import { Percent } from '@uniswap/sdk-core'
import { Position } from '@uniswap/v3-sdk'
import { toHex } from '@uniswap/v3-sdk'
import { Interface } from '@ethersproject/abi'
import { BigNumber } from 'ethers'
// import { useContext } from 'react'
// import { VersionContext } from 'pages/App'

export function useLCreateV3Position(
  cubeAddress: string,
  traderAddr: string,
  position: Position,
  INTERFACE: Interface,
  allowedSlippage: Percent,
  chainId: number,
  deadline: BigNumber,
  isVersionOne: boolean
): string[] {
  const calldatas: string[] = []

  // get amounts
  const { amount0: amount0Desired, amount1: amount1Desired } = position.mintAmounts

  // adjust for slippage
  const minimumAmounts = position.mintAmountsWithSlippage(allowedSlippage)
  const amount0Min = toHex(minimumAmounts.amount0)
  const amount1Min = toHex(minimumAmounts.amount1)

  calldatas.push(
    INTERFACE.encodeFunctionData('createPosition', [
      {
        cubeAddr: toHex(cubeAddress),
        traderAddr: toHex(traderAddr),
        selectedPlatform: toHex(0),
        mintParams: {
          token0: position.pool.token0.address,
          token1: position.pool.token1.address,
          fee: position.pool.fee,
          tickLower: position.tickLower,
          tickUpper: position.tickUpper,
          amount0Desired: toHex(amount0Desired),
          amount1Desired: toHex(amount1Desired),
          // amount0Min,
          // amount1Min,
          amount0Min: isVersionOne ? toHex(amount0Desired) : amount0Min,
          amount1Min: isVersionOne ? toHex(amount1Desired) : amount1Min,
          recipient: toHex(cubeAddress),
          deadline,
        },
      },
    ])
  )
  return calldatas
}

export function useLCAddV3Liquidity(
  cubeAddress: string,
  traderAddr: string,
  position: Position,
  tokenId: string,
  INTERFACE: Interface,
  allowedSlippage: Percent,
  deadline: BigNumber,
  isVersionOne: boolean
): string[] {
  const calldatas: string[] = []
  const { amount0: amount0Desired, amount1: amount1Desired } = position.mintAmounts

  // adjust for slippage
  const minimumAmounts = position.mintAmountsWithSlippage(allowedSlippage)
  const amount0Min = toHex(minimumAmounts.amount0)
  const amount1Min = toHex(minimumAmounts.amount1)

  //   uint256 tokenId;
  //         uint256 amount0Desired;
  //         uint256 amount1Desired;
  //         uint256 amount0Min;
  //         uint256 amount1Min;
  //         uint256 deadline;
  console.log(
    `${cubeAddress}\n${tokenId}\n${amount0Desired}\n${amount1Desired}\n${amount0Min}\n${amount1Min}\n${deadline}`
  )

  calldatas.push(
    INTERFACE.encodeFunctionData('increaseLiquidity', [
      {
        cubeAddr: toHex(cubeAddress),
        traderAddr: toHex(traderAddr),
        token0: position.pool.token0.address,
        token1: position.pool.token1.address,
        selectedPlatform: toHex(0),
        increaseParams: {
          tokenId: toHex(tokenId),
          amount0Desired: toHex(amount0Desired),
          amount1Desired: toHex(amount1Desired),
          // amount0Min,
          // amount1Min,
          amount0Min: isVersionOne ? toHex(amount0Desired) : amount0Min,
          amount1Min: isVersionOne ? toHex(amount1Desired) : amount1Min,
          deadline,
        },
        // increaseV2Params: {
        //   token0: ZERO_ADDRESS,
        //   token1: ZERO_ADDRESS,
        //   fee: '0',
        //   tickLower: '0',
        //   tickUpper: '0',
        //   amount0Desired: '0',
        //   amount1Desired: '0',
        //   amount0Min: '0',
        //   amount1Min: '0',
        //   recipient: ZERO_ADDRESS,
        //   deadline,
        // },
      },
    ])
  )
  return calldatas
}
