import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
//import { DesktopHide, MobileHide } from 'theme'

const ContentBody = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 25px;
  padding-left: 45px;
  //margin-top: 20px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    padding: 0 15%;
    margin-top: 70px;
`};
`

const StoryContainer = styled.div`
  //margin-bottom: -90px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    height: 100px;
    flex-direction: row;
`};
`

const Block = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
  margin-top: 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
    margin: 0;
    height: 100%;
    width: 80px;
    flex-direction: column;
`};
`
const Round = styled.div`
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #0c1e70;
  z-index: 2;
  margin: 0 auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 10px;
    height: 10px;
`};
`

const Line = styled.div`
  position: absolute;
  height: 1px;
  width: 100%;
  background: #0c1e70;
  left: 0;
  top: 7px;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 1px;
    position: relative;
    margin: auto;
    top: -40px;
    height: 100px;
    margin-bottom: -25px;
`};
`
const Content = styled.div`
  color: #0a043c;
  letter-spacing: 0;
  text-align: left;
  padding-left: 25px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0 10px;
    margin-top: -35px;
`};
`
const Date = styled.div`
  margin-top: 14px;
  font-size: 24px;
  color: #0c1e70;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 16px;
    margin-top: 0;

`};
`
const Text = styled.div`
  margin-top: 14px;
  font-size: 17px;
  font-weight: 400;
  text-align: left;
  max-lines: 3;
  min-width: 105px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 12px;
    width: 120px;
    margin-top: 7px;
    //text-align: justify;
`};
`

const MobileHide = styled.span`
  ${({ theme }) => theme.mediaWidth.upToMedium`
  display: none;
`};
`

const DesktopHide = styled.span`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  display: inline;
`};
`

const dateList = ['Q2 2021', 'Q3 2021', 'Q4 2021', 'Jan 2022', 'Feb 2022', 'Q2 2022', 'Q4 2022']

const contentList = [
  'Project initiated',
  'Contracts completed',
  'Contracts optimized and audit completed',
  'Public-Beta release (Uniswap V3)',
  'Mainnet Deploy',
  'Multi-platform support release',
  'LiquidCube V2 release (introducing AI-trading)',
]

export function Timeline() {
  const divWidth = 100 / dateList.length
  const [isMobile, setIsMobile] = useState(true)

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 961) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize)
  })

  return (
    <ContentBody>
      <DesktopHide>
        <Date style={{ marginBottom: '30px', marginTop: '-40px' }}>2021</Date>
      </DesktopHide>

      {dateList.map((date, index) => (
        <div key={index} style={{ width: isMobile ? '100%' : `${divWidth}%` }}>
          {/* Desktop Roadmap */}
          <MobileHide>
            <StoryContainer>
              <Content style={{ visibility: index % 2 === 0 ? 'hidden' : 'visible' }}>
                <Text>{contentList[index]}</Text>
                <Date>{date}</Date>
              </Content>
              <Block>
                <Round />
                <Line />
              </Block>
              <Content style={{ visibility: index % 2 === 0 ? 'visible' : 'hidden' }}>
                <Date>{date}</Date>
                <Text>{contentList[index]}</Text>
              </Content>
            </StoryContainer>
          </MobileHide>

          {/* Mobile Roadmap */}
          <DesktopHide>
            <StoryContainer>
              <Content style={{ visibility: index % 2 === 0 ? 'hidden' : 'visible', textAlign: 'right' }}>
                <Date>{date}</Date>
                <Text>{contentList[index]}</Text>
              </Content>
              <Block>
                <Round />
                <Line />
              </Block>
              <Content style={{ visibility: index % 2 === 0 ? 'visible' : 'hidden', textAlign: 'left' }}>
                <Date>{date}</Date>
                <Text>{contentList[index]}</Text>
              </Content>
            </StoryContainer>
          </DesktopHide>
        </div>
      ))}

      <DesktopHide>
        <Date>2022</Date>
      </DesktopHide>
    </ContentBody>
  )
}
