import { useSingleCallResult } from '../state/multicall/hooks'
import { useMemo } from 'react'
import { useCubeInfoContract } from './useContract'

interface useStateResults {
  loading: boolean
  state: number
}

interface useEndtimeResults {
  loading: boolean
  endTime: string
}

export function useCubeState(cubeAddress: string): useStateResults {
  const useCube = useCubeInfoContract(cubeAddress)
  const results = useSingleCallResult(useCube, 'state')

  const loading = useMemo(() => results.loading, [results])
  const error = useMemo(() => results.error, [results])

  const state = useMemo(() => {
    if (!loading && !error && cubeAddress && results.result) {
      return results.result[0]
    }
    return undefined
  }, [loading, error, cubeAddress, results.result])

  return {
    loading,
    state: state,
  }
}

export function useCubeEndTime(cubeAddress: string): useEndtimeResults {
  const useCube = useCubeInfoContract(cubeAddress)
  const results = useSingleCallResult(useCube, 'endTime')

  const loading = useMemo(() => results.loading, [results])
  const error = useMemo(() => results.error, [results])

  const endTime = useMemo(() => {
    if (!loading && !error && cubeAddress && results.result) {
      return results.result[0]
    }
    return undefined
  }, [loading, error, cubeAddress, results.result])

  return {
    loading,
    endTime: endTime ? endTime.toString() : '0',
  }
}
