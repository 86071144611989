import { useSingleCallResult, useSingleContractMultipleData } from 'state/multicall/hooks'
import { useMemo } from 'react'
import { CubeDetails } from 'types/cube'
import { CubeletInfo } from 'types/cubelet'
import { useLCManagerContract, useLCManagerContractV1_1, useLCManagerContractV1_2 } from './useContract'
import { utils } from 'ethers'
import { useAllTokens, useCurrency } from './Tokens'
import { ZERO_ADDRESS } from 'constants/misc'
//import { LcCubeManager } from '../abis/types'
import useFetchCubeData from './useCubeInfoFromAPI'

interface useCubeInfoResults {
  loading: boolean
  cubes: CubeDetails[] | undefined
}

export function useCubeAddressByCubeId(cubeId: string, version: string) {
  const manager = useLCManagerContract()
  const manager2 = useLCManagerContractV1_1()
  const manager3 = useLCManagerContractV1_2()

  const callRes = useSingleCallResult(
    version === '1-0' ? manager : version === '1-1' ? manager2 : manager3,
    'sudoCubeToCube',
    [cubeId]
  )
  const loading = useMemo(() => callRes.loading, [callRes])

  return {
    loading: loading,
    cubeAddress: callRes && callRes.result ? callRes.result[0].toString() : undefined,
  }
}

export function useSudoIdByCubeAddress(cubeAddress: string, version: string) {
  const manager = useLCManagerContract()
  const manager2 = useLCManagerContractV1_1()
  const manager3 = useLCManagerContractV1_2()

  const callRes = useSingleCallResult(
    version === '1-0' ? manager : version === '1-1' ? manager2 : manager3,
    'cubeToSudoCube',
    [cubeAddress]
  )
  const loading = useMemo(() => callRes.loading, [callRes])

  return {
    loading: loading,
    cubeAddress: callRes && callRes.result ? callRes.result[0].toString() : undefined,
  }
}
export function useCubeInfoByAddresses(
  addresses: string[],
  addresses2?: string[],
  addresses3?: string[],
  version?: string
): useCubeInfoResults {
  const manager = useLCManagerContract()
  const manager2 = useLCManagerContractV1_1()
  const manager3 = useLCManagerContractV1_2()

  const { data: dataApi, loading: APILoading } = useFetchCubeData()
  //console.log(dataApi)

  if (version && version === 'v1-1') {
    addresses2 = addresses
    addresses = []
  }

  if (version && version === 'v1-2') {
    addresses3 = addresses
    addresses = []
  }

  const tokens = useAllTokens()
  const defaultCurrency = useCurrency('ETH')
  const calls = addresses.map((addr) => {
    return [addr]
  })

  const calls2 = addresses2
    ? addresses2.map((addr) => {
        return [addr]
      })
    : []

  const calls3 = addresses3
    ? addresses3.map((addr) => {
        return [addr]
      })
    : []

  const cubesResults = useSingleContractMultipleData(manager, 'getCubeByAddress', calls)
  const cubesResults2 = useSingleContractMultipleData(manager2, 'getCubeByAddress', calls2)
  const cubesResults3 = useSingleContractMultipleData(manager3, 'getCubeByAddress', calls3)

  const loading = useMemo(() => cubesResults.some(({ loading }) => loading), [cubesResults])
  const loading2 = useMemo(() => cubesResults2.some(({ loading }) => loading), [cubesResults2])
  const loading3 = useMemo(() => cubesResults3.some(({ loading }) => loading), [cubesResults3])

  const cubes: CubeDetails[] | undefined = useMemo(() => {
    if (!loading) {
      return cubesResults
        .filter((item) => !item.error && item.result && item.result[0])
        .map((item: any) => {
          const cubeRaw = item.result[0]
          const cubeFromAPI = dataApi?.find((data) => data.cube_address === cubeRaw.cubeAddress)
          //console.log('@@@', utils.parseBytes32String(cubeRaw.cubeName), cubeFromAPI?.profit)
          const currencyList = cubeRaw.tokenWhitelist?.map((item: string) =>
            tokens[item]?.symbol === 'WETH' ? defaultCurrency : tokens[item]
          )
          const cubeletsInfo = item.result[1].map((cubelet: CubeletInfo) => {
            return {
              cubeletId: Number(cubelet.cubeletId.toString()),
              ownerAddr: cubelet.ownerAddr,
              tokenId: Number(cubelet.tokenId.toString()),
            }
          })
          return {
            cubeAddress: cubeRaw.cubeAddress,
            traderCut: cubeRaw.traderCut.toString(),
            startingTime: cubeRaw.startingTime.toString(),
            tradingPeriodLength: cubeRaw.tradingPeriodLength.toString(),
            traderAddr: cubeRaw.traderAddr,
            liquidatedAmount: cubeRaw.liquidatedAmount.toString(),
            investorLiquidatedAmount: cubeRaw.investorLiquidatedAmount.toString(),
            traderLiquidatedAmount: cubeRaw.traderLiquidatedAmount.toString(),
            state: cubeFromAPI?.cube_state ? cubeFromAPI?.cube_state : cubeRaw.state,
            cubeName: utils.parseBytes32String(cubeRaw.cubeName),
            baseCurrency: cubeRaw.baseCurrency === ZERO_ADDRESS ? 'ETH' : cubeRaw.baseCurrency,
            numberOfCubelets: cubeRaw.numberOfCubelets.toString(),
            pricePerCubelet: cubeRaw.pricePerCubelet,
            tokenWhitelist: cubeRaw.tokenWhitelist,
            currencyList: currencyList,
            // the below code causes crashing
            cubeletsInfo: cubeletsInfo,
            version: '1-0',
            profit: Number(cubeFromAPI?.profit ? cubeFromAPI?.profit : '-100'),
            assetsToEth: cubeFromAPI?.assets_in_eth,
          }
        })
    }
    return undefined
  }, [loading, cubesResults, defaultCurrency, tokens, dataApi])

  const cubes2: CubeDetails[] | undefined = useMemo(() => {
    if (!loading2) {
      return cubesResults2
        .filter((item) => !item.error && item.result && item.result[0])
        .map((item: any) => {
          const cubeRaw = item.result[0]
          const cubeFromAPI = dataApi?.find((data) => data.cube_address === cubeRaw.cubeAddress)
          const currencyList = cubeRaw.tokenWhitelist?.map((item: string) =>
            tokens[item]?.symbol === 'WETH' ? defaultCurrency : tokens[item]
          )
          const cubeletsInfo = item.result[1].map((cubelet: CubeletInfo) => {
            return {
              cubeletId: Number(cubelet.cubeletId.toString()),
              ownerAddr: cubelet.ownerAddr,
              tokenId: Number(cubelet.tokenId.toString()),
            }
          })
          return {
            cubeAddress: cubeRaw.cubeAddress,
            traderCut: cubeRaw.traderCut.toString(),
            startingTime: cubeRaw.startingTime.toString(),
            tradingPeriodLength: cubeRaw.tradingPeriodLength.toString(),
            traderAddr: cubeRaw.traderAddr,
            liquidatedAmount: cubeRaw.liquidatedAmount.toString(),
            investorLiquidatedAmount: cubeRaw.investorLiquidatedAmount.toString(),
            traderLiquidatedAmount: cubeRaw.traderLiquidatedAmount.toString(),
            state: cubeFromAPI?.cube_state ? cubeFromAPI?.cube_state : cubeRaw.state,
            cubeName: utils.parseBytes32String(cubeRaw.cubeName),
            baseCurrency: cubeRaw.baseCurrency === ZERO_ADDRESS ? 'ETH' : cubeRaw.baseCurrency,
            numberOfCubelets: cubeRaw.numberOfCubelets.toString(),
            pricePerCubelet: cubeRaw.pricePerCubelet,
            tokenWhitelist: cubeRaw.tokenWhitelist,
            currencyList: currencyList,
            // the below code causes crashing
            cubeletsInfo: cubeletsInfo,
            version: '1-1',
            profit: Number(cubeFromAPI?.profit ? cubeFromAPI?.profit : '-100'),
            assetsToEth: cubeFromAPI?.assets_in_eth,
          }
        })
    }
    return undefined
  }, [loading2, cubesResults2, dataApi, tokens, defaultCurrency])

  const cubes3: CubeDetails[] | undefined = useMemo(() => {
    if (!loading3) {
      return cubesResults3
        .filter((item) => !item.error && item.result && item.result[0])
        .map((item: any) => {
          const cubeRaw = item.result[0]
          const cubeFromAPI = dataApi?.find((data) => data.cube_address === cubeRaw.cubeAddress)
          const currencyList = cubeRaw.tokenWhitelist?.map((item: string) =>
            tokens[item]?.symbol === 'WETH' ? defaultCurrency : tokens[item]
          )
          const cubeletsInfo = item.result[1].map((cubelet: CubeletInfo) => {
            return {
              cubeletId: Number(cubelet.cubeletId.toString()),
              ownerAddr: cubelet.ownerAddr,
              tokenId: Number(cubelet.tokenId.toString()),
            }
          })
          return {
            cubeAddress: cubeRaw.cubeAddress,
            traderCut: cubeRaw.traderCut.toString(),
            startingTime: cubeRaw.startingTime.toString(),
            tradingPeriodLength: cubeRaw.tradingPeriodLength.toString(),
            traderAddr: cubeRaw.traderAddr,
            liquidatedAmount: cubeRaw.liquidatedAmount.toString(),
            investorLiquidatedAmount: cubeRaw.investorLiquidatedAmount.toString(),
            traderLiquidatedAmount: cubeRaw.traderLiquidatedAmount.toString(),
            state: cubeFromAPI?.cube_state ? cubeFromAPI?.cube_state : cubeRaw.state,
            cubeName: utils.parseBytes32String(cubeRaw.cubeName),
            baseCurrency: cubeRaw.baseCurrency === ZERO_ADDRESS ? 'ETH' : cubeRaw.baseCurrency,
            numberOfCubelets: cubeRaw.numberOfCubelets.toString(),
            pricePerCubelet: cubeRaw.pricePerCubelet,
            tokenWhitelist: cubeRaw.tokenWhitelist,
            currencyList: currencyList,
            // the below code causes crashing
            cubeletsInfo: cubeletsInfo,
            version: '1-2',
            profit: Number(cubeFromAPI?.profit ? cubeFromAPI?.profit : '-100'),
            assetsToEth: cubeFromAPI?.assets_in_eth,
          }
        })
    }
    return undefined
  }, [loading3, cubesResults3, dataApi, tokens, defaultCurrency])

  return {
    loading: loading || loading2 || loading3 || APILoading,
    cubes:
      cubes && cubes2 && cubes3
        ? cubes.concat(cubes2).concat(cubes3)
        : cubes
        ? cubes
        : cubes2
        ? cubes2
        : cubes3
        ? cubes3
        : undefined,
  }
}

export function useAllCubeInfo(): useCubeInfoResults {
  const manager = useLCManagerContract()
  const manager2 = useLCManagerContractV1_1()
  const manager3 = useLCManagerContractV1_2()

  const cubeLengthResult = useSingleCallResult(manager, 'getCubeAddressLength')
  const cube2LengthResult = useSingleCallResult(manager2, 'getCubeAddressLength')
  const cube3LengthResult = useSingleCallResult(manager3, 'getCubeAddressLength')

  const calls = useMemo(() => {
    const inputList: any[] = []
    if (!cubeLengthResult.loading && cubeLengthResult.result) {
      for (let i = 0; i < cubeLengthResult.result[0].toNumber(); i++) {
        inputList.push([i])
      }
    }
    return inputList
  }, [cubeLengthResult])

  const calls2 = useMemo(() => {
    const inputList: any[] = []
    if (!cube2LengthResult.loading && cube2LengthResult.result) {
      for (let i = 0; i < cube2LengthResult.result[0].toNumber(); i++) {
        inputList.push([i])
      }
    }
    return inputList
  }, [cube2LengthResult])

  const calls3 = useMemo(() => {
    const inputList: any[] = []
    if (!cube3LengthResult.loading && cube3LengthResult.result) {
      for (let i = 0; i < cube3LengthResult.result[0].toNumber(); i++) {
        inputList.push([i])
      }
    }
    return inputList
  }, [cube3LengthResult])

  const addressesResult = useSingleContractMultipleData(manager, 'cubeAddresses', calls)
  const addressesResult2 = useSingleContractMultipleData(manager2, 'cubeAddresses', calls2)
  const addressesResult3 = useSingleContractMultipleData(manager3, 'cubeAddresses', calls3)

  const loading = useMemo(() => addressesResult.some(({ loading }) => loading), [addressesResult])
  const loading2 = useMemo(() => addressesResult2.some(({ loading }) => loading), [addressesResult2])
  const loading3 = useMemo(() => addressesResult3.some(({ loading }) => loading), [addressesResult3])

  const addresses = useMemo(() => {
    if (!loading && addressesResult) {
      const result = addressesResult.map((item: any) => {
        return item.result[0]
      })
      return result
    }
    return []
  }, [addressesResult, loading])

  const addresses2 = useMemo(() => {
    if (!loading2 && addressesResult2) {
      const result = addressesResult2.map((item: any) => {
        return item.result[0]
      })
      return result
    }
    return []
  }, [addressesResult2, loading2])

  const addresses3 = useMemo(() => {
    if (!loading3 && addressesResult3) {
      const result = addressesResult3.map((item: any) => {
        return item.result[0]
      })
      return result
    }
    return []
  }, [addressesResult3, loading3])

  return useCubeInfoByAddresses(addresses, addresses2, addresses3)
}

export function useTraderCubeInfo(traderAddr: string): useCubeInfoResults {
  const { loading, cubes } = useAllCubeInfo()
  const filteredCubes = useMemo(() => {
    if (!loading && cubes) {
      return cubes.filter((cube) => cube.traderAddr === traderAddr)
    }
    return undefined
  }, [loading, cubes, traderAddr])
  return {
    loading: loading,
    cubes: filteredCubes?.filter((cube) => cube.startingTime !== '0'),
  }
}
