import { Trans } from '@lingui/macro'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { RowBetween } from 'components/Row'
import { useActiveWeb3React } from 'hooks/web3'
import { useContext, useEffect, useState } from 'react'
import { Inbox } from 'react-feather'
import { useWalletModalToggle } from 'state/application/hooks'
import styled, { ThemeContext } from 'styled-components/macro'
import { MobileHide, DesktopHide, TYPE } from 'theme'
import { LoadingRows } from './styleds'
// ============= LC ================
import { MEDIA_WIDTHS } from 'theme'
import { SudoCubeDetails } from 'types/sudoCube'
import SudoCubeListItem from 'components/SudoCubeListItem'
import CubeListItem from 'components/CubeListItem'
import SearchIcon from 'assets/images/searchIcon.png'
import LotsCubes from 'assets/images/lots_cubes.png'

import { CubeDetails } from 'types/cube'
import Select, { StylesConfig } from 'react-select'
import { useAllSudoCubeInfo } from 'hooks/useSudoCubeInfo'
import { useAllCubeInfo } from 'hooks/useCubeInfo'
//import useFetchCubeData from 'hooks/useCubeInfoFromAPI'
//import debounce from 'lodash.debounce'

import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'

const PageWrapper = styled(AutoColumn)`
  max-width: 1085px;
  width: 100%;
  padding-bottom: 100px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 0;
  `};
`

const ListTitle = styled.div`
  font-size: 16px;
  color: #424242;
  font-weight: 600;
  align-items: center;
  text-align: center;
`

const StyledRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    //display: none;
  `};
`

const Announcement = styled.div`
  font-size: 26px;
  color: #242424;
  font-weight: 700;
  width: 50%;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 5px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 24px;
    display: none;
  `};
`

const LotsCube = styled.img`
  top: 100px;
  margin-left: auto;
  max-width: 340px;
  margin-top: -15px;
  margin-bottom: -10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: auto;
    height: auto;
    display: block;
    width: 283.52px;
    margin-top: -50px;
    margin-bottom: -20px;
  `};
`

const NoLiquidity = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 25vh;
  padding-top: 100px;
`

const EmptyWarning = styled.div`
  font-size: 18px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 16px;
  `};
`

const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.bg0};
  padding: 0 24px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-bottom: 20px;
  `};
`

const GridContainer = styled.div`
  display: grid;
  margin-left: 4%;
  grid-template-columns: 1.8fr 1fr 0.5fr 0.6fr 0.4fr;
  margin-bottom: 10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const DesktopHeader = styled.div`
  font-size: 18px;
  font-weight: 500;
  display: none;
  padding-left: 0px;

  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    & > div:last-child {
      text-align: right;
      margin-right: 12px;
    }
  }
`

const MobileHideFilter = styled(MobileHide)`
  display: flex;
  flex-direction: row;
  margin-bottom: -5px;
`

const MobileHeader = styled.div`
  font-weight: medium;
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
  padding-bottom: 0;
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  // border-bottom: ${({ theme }) => '1px solid ' + theme.bg3};
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    display: none;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding-left: 0;
  align-items: left;
  text-align: left;
`};
`
const ToggleWrapper = styled.button<{ width?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 39px;
  background: ${({ theme }) => theme.white};
  border: none;

  cursor: pointer;
  outline: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-left: 0;
    align-items: left;
    text-align: left;
  `};
`

const ToggleOption = styled.div<{ active?: boolean }>`
  width: 100%;
  max-width: 100px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin-bottom: -0.5%;
  font-size: 17px;
  white-space: nowrap;
  //border-bottom: ${({ theme }) => '1px solid ' + theme.bg3};

  color: ${({ theme, active }) => (active ? theme.primary1 : theme.bg4)};
  user-select: none;
  //border-bottom: ${({ theme, active }) => (active ? '2px solid ' + theme.primary1 : '2px solid transparent')};
  border-bottom: ${({ theme, active }) => (active ? '2px solid ' + theme.primary1 : '1px solid' + theme.bg3)};
  padding-bottom: ${({ active }) => (active ? '9px' : '10px')};
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }

  ${({ theme, active }) => theme.mediaWidth.upToSmall`
    border-bottom: ${({ theme }) => (active ? '2px solid ' + theme.primary1 : '1px solid' + theme.bg3)};
    font-size: 16px;
    white-space: nowrap;
    margin-bottom: -1%;
    width: 100%;
    padding-left: 0;
    margin-left: 0;
    text-aligns: center;
    //padding-bottom: 10px;
    padding-bottom: ${({}) => (active ? '8px' : '9px')};
    :hover {
      opacity: 1;
    }
  `};
`

const SearchInput = styled.input`
  position: relative;
  overflow: hidden;
  align-items: center;
  text-indent: 40px;
  width: 160px;
  height: 35px;
  white-space: nowrap;
  background: none;
  outline: none;
  border-radius: 100px;
  color: ${({ theme }) => theme.text1};
  border: 1px solid rgba(0, 0, 0, 0.23);
  -webkit-appearance: none;
  font-size: 16px;
  margin-right: 8px;

  ::placeholder {
    color: ${({ theme }) => theme.text3};
  }
  transition: border 100ms;
  :focus {
    border: 2px solid ${({ theme }) => theme.primary1};
    outline: none;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  //position: relative;
    width: 113px;
    height: 29px;
    margin-bottom: 0;
    margin-left: 0px;
    margin-right: 0px;
    ::placeholder {
      font-size: 14px;
    }
    padding: 0;
    text-indent: 25px;
  `};
`

const ConnectWalletButton = styled(ButtonPrimary)`
  margin-top: 2em;
  padding: 8px 16px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 200px;
`};
`

const CustomInputLabel = styled(InputLabel)({
  top: '-8px !important',
  '&.MuiInputLabel-shrink': {
    top: '0px !important',
  },
})

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
    sx: {
      '& .MuiMenuItem-root': {
        padding: 0,
        //fontSize: '14px !important',
      },
      '& .MuiTypography-root': {
        fontSize: '14px !important',
      },
    },
  },
}

const filters = ['Funding', 'Trading', 'Ended']

export default function InvestorPage() {
  // Mui Select
  const [filter, setFilter] = useState<string[]>([])

  const handleChange = (event: SelectChangeEvent<typeof filter>) => {
    const {
      target: { value },
    } = event
    setFilter(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }

  const { account } = useActiveWeb3React()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { cubes: allSudoCubes, loading: allSudoCubesLoading } = useAllSudoCubeInfo()
  const { cubes: allCubes, loading: allCubesLoading } = useAllCubeInfo()

  const combinedAllCubesLoading = allSudoCubesLoading || allCubesLoading
  const combinedAllCubes =
    allSudoCubes && allCubes
      ? [...allSudoCubes, ...allCubes].sort(
          (a, b) => Number(b.startingTime.toString()) - Number(a.startingTime.toString())
        )
      : []

  const combinedInvestorCubes = combinedAllCubes?.filter((cube) =>
    cube.cubeletsInfo.some((cubelet) => cubelet.ownerAddr === account)
  )
  // dropdown menu filter options
  type OptionType = {
    value: string
    label: string
    fn?: any
  }

  const options: OptionType[] = [
    { value: 'all', label: 'All' },
    { value: 'funding', label: 'Funding' },
    { value: 'started', label: 'Started' },
  ]

  const sortOptions: OptionType[] = [
    {
      value: 'profit_high',
      label: 'Profit: Highest',
      fn: (b: any, a: any) => a.profit - b.profit,
    },
    {
      value: 'time_up',
      label: 'Time: Latest',
      fn: (a: any, b: any) => Number(b.startingTime.toString()) - Number(a.startingTime.toString()),
    },
    {
      value: 'time_down',
      label: 'Time: Oldest',
      fn: (b: any, a: any) => Number(b.startingTime.toString()) - Number(a.startingTime.toString()),
    },
    // {
    //   value: 'profit_low',
    //   label: 'Profit Split: Lowest',
    //   fn: (a: any, b: any) => b.traderCut - a.traderCut,
    // },
    {
      value: 'supported_token_up',
      label: 'Trading Tokens: Most',
      fn: (a: any, b: any) => b.tokenWhitelist.length - a.tokenWhitelist.length,
    },
    {
      value: 'supported_token_down',
      label: 'Trading Tokens: Least',
      fn: (b: any, a: any) => b.tokenWhitelist.length - a.tokenWhitelist.length,
    },
  ]

  const [filterOption, setFilterOption] = useState<OptionType | null>(options[0]) // set all as default option
  const [sortOption, setSortOption] = useState<OptionType | null>(sortOptions[0])

  const dropdownStyles: StylesConfig<OptionType, false> = {
    container: (provided) => ({
      ...provided,
      width: '150px',
      fontSize: '14px',
    }),
    control: (provided) => ({
      ...provided,
      paddingTop: 0,
      background: '#fff',
      borderColor: 'rgba(0,0,0,0.23)',
      height: 34,
      minHeight: 34,
      width: '150px',
      borderRadius: '20px',
      fontSize: '14px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingTop: '0',
    }),
  }

  const sortDropdownStyles: StylesConfig<OptionType, false> = {
    container: (provided) => ({
      ...provided,
      width: '190px',
      fontSize: '14px',
    }),
    control: (provided) => ({
      ...provided,
      marginLeft: '8px',
      background: '#fff',
      borderColor: 'rgba(0,0,0,0.23)',
      height: 35,
      minHeight: 35,
      width: '160px',
      borderRadius: '20px',
      fontSize: '14px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingTop: '0',
    }),
  }

  const toggleWalletModal = useWalletModalToggle()

  const theme = useContext(ThemeContext)
  // 1-> trading, 2-> waiting, 3-> completed
  const [switchTab, setSwitchTab] = useState<number>(1)

  // new search
  const [searchField, setSearchField] = useState<string>('')
  const [searchShow, setSearchShow] = useState<boolean>(false)

  const filteredAllCubes = combinedAllCubes.filter((cube) => {
    return (
      // cube.traderAddr.toLowerCase().includes(searchField.toLowerCase()) ||
      cube.cubeName.toLowerCase().includes(searchField.toLowerCase())
    )
  })

  const handleSearch = (e: any) => {
    setSearchField(e.target.value)
    //filteredCubes(e.target.value)
    if (e.target.value === '') {
      setSearchShow(false)
    } else {
      setSearchShow(true)
    }
  }

  //const debouncedEventHandler = useMemo(() => debounce(handleSearch, 300), [])

  const showConnectAWallet = Boolean(!account)

  function AllCubeList() {
    return (
      <>
        <GridContainer style={{ paddingTop: '35px' }}>
          <ListTitle style={{ textAlign: 'left', marginLeft: '22px' }}>Cube Name</ListTitle>
          <ListTitle>Fund Cap</ListTitle>
          <ListTitle>Cube Version</ListTitle>
          {/* <ListTitle style={{ marginTop: '-10px' }}>
            Profit Split <div>(investor)</div>
          </ListTitle> */}
          <ListTitle>Platform</ListTitle>
          <ListTitle>Profit</ListTitle>
        </GridContainer>
        {combinedAllCubesLoading ? (
          <LoadingRows>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </LoadingRows>
        ) : searchShow ? (
          filteredAllCubes.map((cube) => {
            if (cube.state < 2) {
              return (
                (filter.length === 0 || filter.includes('Funding')) && (
                  <SudoCubeListItem key={cube.startingTime.toString()} cubeDetails={cube as SudoCubeDetails} />
                )
              )
            } else if (cube.state === 2 || cube.state === 3) {
              return (
                (filter.length === 0 || filter.includes('Trading')) && (
                  <CubeListItem key={cube.startingTime.toString()} cubeDetails={cube as CubeDetails} />
                )
              )
            } else {
              return (
                (filter.length === 0 || filter.includes('Ended')) && (
                  <CubeListItem key={cube.startingTime.toString()} cubeDetails={cube as CubeDetails} />
                )
              )
            }
          })
        ) : allSudoCubes || allCubes ? ( // display all cubes by default
          <>
            {combinedAllCubes.sort(sortOption?.fn).map((cube) => {
              if (cube.state < 2) {
                return (
                  (filter.length === 0 || filter.includes('Funding')) && (
                    <SudoCubeListItem key={cube.startingTime.toString()} cubeDetails={cube as SudoCubeDetails} />
                  )
                )
              } else if (cube.state === 2 || cube.state === 3) {
                return (
                  (filter.length === 0 || filter.includes('Trading')) && (
                    <CubeListItem key={cube.startingTime.toString()} cubeDetails={cube as CubeDetails} />
                  )
                )
              } else {
                return (
                  (filter.length === 0 || filter.includes('Ended')) && (
                    <CubeListItem key={cube.startingTime.toString()} cubeDetails={cube as CubeDetails} />
                  )
                )
              }
            })}
          </>
        ) : (
          <NoLiquidity>
            <TYPE.body color={theme.text3} textAlign="center">
              <Inbox size={48} strokeWidth={1} style={{ marginBottom: '.5rem' }} />
              <EmptyWarning>
                <Trans>Find cubes to invest by typing the trader address</Trans>
              </EmptyWarning>
            </TYPE.body>
            {showConnectAWallet && (
              <ConnectWalletButton onClick={toggleWalletModal}>
                <Trans>Connect a wallet</Trans>
              </ConnectWalletButton>
            )}
          </NoLiquidity>
        )}
      </>
    )
  }

  function MyCubeList() {
    return (
      <>
        <GridContainer style={{ paddingTop: '35px' }}>
          <ListTitle style={{ textAlign: 'left', marginLeft: '22px' }}>Cube Name</ListTitle>
          <ListTitle>Fund Cap</ListTitle>
          <ListTitle>Cube Version</ListTitle>
          {/* <ListTitle style={{ marginTop: '-10px' }}>
            Profit Split <div>(investor)</div>
          </ListTitle> */}
          <ListTitle>Platform</ListTitle>
          <ListTitle>Profit</ListTitle>
        </GridContainer>

        {combinedInvestorCubes.sort(sortOption?.fn).map((cube) => {
          if (cube.state < 2) {
            return (
              (filter.length === 0 || filter.includes('Funding')) && (
                <SudoCubeListItem key={cube.startingTime.toString()} cubeDetails={cube as SudoCubeDetails} />
              )
            )
          } else if (cube.state === 2 || cube.state === 3) {
            return (
              (filter.length === 0 || filter.includes('Trading')) && (
                <CubeListItem key={cube.startingTime.toString()} cubeDetails={cube as CubeDetails} />
              )
            )
          } else {
            return (
              (filter.length === 0 || filter.includes('Ended')) && (
                <CubeListItem key={cube.startingTime.toString()} cubeDetails={cube as CubeDetails} />
              )
            )
          }
        })}
      </>
    )
  }

  return (
    <>
      <PageWrapper>
        {/* <SwapPoolTabs active={'pool'} /> */}
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <MainContentWrapper>
              <StyledRow>
                <Announcement>A DeFi Investment Opportunity Marketplace</Announcement>
                <LotsCube src={LotsCubes} alt="cubes" />
              </StyledRow>

              <DesktopHeader>
                <ToggleWrapper>
                  <ToggleOption onClick={() => setSwitchTab(1)} active={switchTab === 1}>
                    All Cubes
                  </ToggleOption>
                  <ToggleOption onClick={() => setSwitchTab(2)} active={switchTab === 2}>
                    {' '}
                    My Cubes
                  </ToggleOption>
                </ToggleWrapper>

                <MobileHideFilter>
                  {switchTab === 1 && (
                    <SearchInput
                      type="text"
                      style={{
                        backgroundImage: `url(${SearchIcon})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPositionX: '5%',
                        backgroundPositionY: 'center',
                        backgroundSize: '24px 24px',
                      }}
                      spellCheck={false}
                      autoComplete="off"
                      //ref={inputRef as RefObject<HTMLInputElement>}
                      value={searchField}
                      onChange={handleSearch}
                      //onChange={(event) => filteredCubes(event.target.value)}
                      //onChange={debouncedEventHandler}
                      //onKeyDown={handleSearch}
                    />
                  )}
                  {/* <Select
                    options={options}
                    //defaultValue={options[0]}
                    styles={dropdownStyles}
                    isSearchable={false}
                    placeholder={'Filter'}
                    onChange={(option) => setFilterOption(option)}
                  /> */}
                  <div>
                    <FormControl
                      sx={{
                        m: 0,
                        width: 150,
                        fontSize: '14px',
                        padding: 0,
                        height: 34,
                      }}
                    >
                      <CustomInputLabel
                        id="multiple-checkbox-label"
                        style={{
                          fontSize: '14px',
                          padding: 0,
                        }}
                      >
                        Filter
                      </CustomInputLabel>
                      <MuiSelect
                        labelId="multiple-checkbox-label"
                        id="multiple-checkbox"
                        className="inputRounded"
                        multiple
                        value={filter}
                        SelectDisplayProps={{
                          style: {
                            padding: 0,
                            fontSize: '14x',
                            height: 35,
                            verticalAlign: 'middle',
                            lineHeight: '35px',
                          },
                        }}
                        onChange={handleChange}
                        input={
                          <OutlinedInput
                            style={{
                              borderRadius: '20px',
                              //border: '1px solid #9e9e9e',
                              fontSize: '14px',
                              textIndent: '10px',
                            }}
                            label="Tag"
                          />
                        }
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        {filters.map((item) => (
                          <MenuItem key={item} value={item}>
                            <Checkbox checked={filter.indexOf(item) > -1} />
                            <ListItemText primary={item} />
                          </MenuItem>
                        ))}
                      </MuiSelect>
                    </FormControl>
                  </div>
                  <Select
                    options={sortOptions}
                    //defaultValue={sortOptions[0]}
                    styles={sortDropdownStyles}
                    isSearchable={false}
                    placeholder={'Sort'}
                    onChange={(option) => setSortOption(option)}
                  />
                </MobileHideFilter>
              </DesktopHeader>
              <MobileHeader>
                <ToggleWrapper>
                  <ToggleOption
                    style={{ paddingRight: '10px' }}
                    onClick={() => setSwitchTab(1)}
                    active={switchTab === 1}
                  >
                    All Cubes
                  </ToggleOption>
                  <ToggleOption style={{ paddingLeft: '5px' }} onClick={() => setSwitchTab(2)} active={switchTab === 2}>
                    My Cubes
                  </ToggleOption>
                </ToggleWrapper>
                {switchTab === 1 && (
                  <DesktopHide style={{ marginLeft: 'auto', marginTop: '10px' }}>
                    <SearchInput
                      type="text"
                      id="token-search-input"
                      //placeholder={` Enter trader address...`}
                      style={{
                        float: 'right',
                        marginBottom: '-10px',
                        backgroundImage: `url(${SearchIcon})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPositionX: '5%',
                        backgroundPositionY: 'center',
                        backgroundSize: '20px 20px',
                      }}
                      spellCheck={false}
                      //placeholder={<img src={SearchIcon} />}
                      autoComplete="off"
                      //ref={inputRef as RefObject<HTMLInputElement>}
                      value={searchField}
                      //onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleSearchInput(e.target.value)}
                      onChange={handleSearch}
                      //onKeyDown={() => setResult(searchAddr)}
                    />
                  </DesktopHide>
                )}
              </MobileHeader>

              {combinedAllCubesLoading ? (
                <LoadingRows>
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </LoadingRows>
              ) : switchTab === 1 && !showConnectAWallet ? (
                <>
                  <AllCubeList />
                </>
              ) : combinedInvestorCubes &&
                combinedInvestorCubes.length > 0 &&
                switchTab === 2 &&
                !showConnectAWallet ? (
                <MyCubeList />
              ) : (
                <NoLiquidity>
                  <TYPE.body color={theme.text3} textAlign="center">
                    <Inbox size={48} strokeWidth={1} style={{ marginBottom: '.5rem' }} />
                    <EmptyWarning>
                      <Trans>Find a new cube to invest</Trans>
                    </EmptyWarning>
                  </TYPE.body>
                  {showConnectAWallet && (
                    <ConnectWalletButton onClick={toggleWalletModal}>
                      <Trans>Connect a wallet</Trans>
                    </ConnectWalletButton>
                  )}
                </NoLiquidity>
              )}
            </MainContentWrapper>
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
      {/* <SwitchLocaleLink /> */}
    </>
  )
}
