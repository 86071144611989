import { useSingleCallResult } from '../state/multicall/hooks'
import { useMemo } from 'react'
import { useCubeInfoContract } from './useContract'

interface useStateResults {
  loading: boolean
  whitelist: string[]
}

export function useCubeWhitelist(cubeAddress: string): useStateResults {
  //const positionManager = useV3NFTPositionManagerContract()
  //const periphery = useLcPeripheryContract()
  const useCube = useCubeInfoContract(cubeAddress)
  //const inputs = useMemo(() => (tokenIds ? tokenIds.map((tokenId) => [BigNumber.from(tokenId)]) : []), [tokenIds])
  const results = useSingleCallResult(useCube, 'getTokenWhitelist')

  //console.log(results)
  const loading = useMemo(() => results.loading, [results])
  const error = useMemo(() => results.error, [results])

  const whitelist = useMemo(() => {
    if (!loading && !error && cubeAddress && results.result) {
      return results.result[0]
    }
    return undefined
  }, [loading, error, cubeAddress, results.result])

  return {
    loading,
    whitelist: whitelist,
    //positions: cubes?.map((position, i) => ({ ...position, tokenId: inputs[i][0] })),
  }
}
