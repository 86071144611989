import { Trans } from '@lingui/macro'
import useScrollPosition from '@react-hook/window-scroll'
import Menu from 'components/Menu'
import { SupportedChainId } from 'constants/chains'
import { VersionContext } from 'pages/App'
// import { darken } from 'polished'
import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Text } from 'rebass'
// import { useShowClaimPopup, useToggleSelfClaimModal } from 'state/application/hooks'
// import { useUserHasAvailableClaim } from 'state/claim/hooks'
// import { useUserHasSubmittedClaim } from 'state/transactions/hooks'
import { useDarkModeManager } from 'state/user/hooks'
import { useETHBalances } from 'state/wallet/hooks'
import styled from 'styled-components/macro'
//import LogoNew from '../../assets/svg/logo_new.png'
//import LogoOld from '../../assets/images/LiquidCubeIcon.png'
import LogoNew from '../../assets/svg/logo-final.svg'
import { useActiveWeb3React } from '../../hooks/web3'
// import { ExternalLink, TYPE } from '../../theme'
// import ClaimModal from '../claim/ClaimModal'
// import { CardNoise } from '../earn/styled'
// import Menu from '../Menu'
// import Modal from '../Modal'
// import Row from '../Row'
// import { Dots } from '../swap/styleds'
import Web3Status from '../Web3Status'
import NetworkCard from './NetworkCard'
// import Toggle, { ToggleTrader } from 'components/Toggle'
// import { AutoColumn } from 'components/Column'
// import { ButtonPrimary2 } from 'components/Button'

const HeaderFrame = styled.div<{ showBackground: boolean; isRolePage: boolean }>`
  display: grid;
  //grid-template-columns: 60px minmax(700px, 980px) 10px;
  grid-template-columns: 10px minmax(700px, 1050px) 0px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 80px;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 21;
  /* Background slide effect on scroll. */
  background-image: ${({ theme, isRolePage }) =>
    isRolePage ? 'rgba(228,233,249)' : `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  box-shadow: 0 5px 5px ${({ showBackground }) => (showBackground ? 'rgba(141,141,141, 0.5)' : 'transparent;')};
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;
  background-color: ${({ isRolePage }) => (isRolePage ? 'rgba(228,233,249)' : 'transparent')};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: 60px minmax(700px, 1007px) 10px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns:  1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-left: 1rem;
    height: 60px;
    grid-template-columns:  1fr 1fr;
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  padding-right: 10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`

// const HeaderLinks = styled(Row)`
//   justify-self: center;
//   // background-color: ${({ theme }) => theme.bg0};
//   width: fit-content;
//   padding: 4px;
//   border-radius: 16px;
//   display: grid;
//   grid-auto-flow: column;
//   grid-gap: 10px;
//   overflow: auto;
//   align-items: center;
//   ${({ theme }) => theme.mediaWidth.upToLarge`
//     justify-self: start;
//     `};
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     justify-self: center;
//   `};
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     flex-direction: row;
//     justify-content: space-between;
//     justify-self: center;
//     z-index: 99;
//     position: fixed;
//     bottom: 0; right: 50%;
//     transform: translate(50%,-50%);
//     margin: 0 auto;
//     background-color: ${({ theme }) => theme.bg0};
//     border: 1px solid ${({ theme }) => theme.bg2};
//     box-shadow: 0px 6px 10px rgb(0 0 0 / 2%);
//   `};
// `

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg1)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`

// const UNIAmount = styled(AccountElement)`
//   color: white;
//   padding: 4px 8px;
//   height: 36px;
//   font-weight: 500;
//   background-color: ${({ theme }) => theme.bg3};
//   background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
// `

// const UNIWrapper = styled.span`
//   width: fit-content;
//   position: relative;
//   cursor: pointer;

//   :hover {
//     opacity: 0.8;
//   }

//   :active {
//     opacity: 0.9;
//   }
// `

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  //padding-left: 85px;
  text-decoration: none;
  font-weight: 800;
  font-size: 31px;
  color: #424242;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  //margin-right: 590px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    justify-self: left;
    padding-left: 25px;
   `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: left;
    padding-left: 25px;
    //padding-top: 30px;
`};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-left: 0;
    justify-self: left;
    //margin-right: 50%;
    //padding-top: 15px;
  `};
  :hover {
    cursor: pointer;
  }
  p {
    @media only screen and (max-width: 475px) {
      //display: none;
    }
  }
  .LandingTitle {
    ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
  `};
  }
`

const TitleText = styled.p`
  display: flex;
  padding-left: 35px;
  text-decoration: none;
  font-weight: 800;
  color: #424242;
  font-size: 21px;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-left: -30px;
  .version {
    font-size: 16px;
    margin: 0 0 -3px 5px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
    .title {
      display: none;
    }
    .version {
      margin: 0 0 -3px 0;
      display: flex;
    }
`};
`

const LandingTitleText = styled.p`
  display: flex;
  padding-left: 35px;
  text-decoration: none;
  font-weight: 800;
  font-size: 25px;
  color: #424242;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-left: -30px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
`};
`

const UniIcon = styled.img`
  width: 45px;
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-15deg);
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 35px;
  `};
`

// const activeClassName = 'ACTIVE'

// const StyledNavLink = styled(NavLink).attrs({
//   activeClassName,
// })`
//   ${({ theme }) => theme.flexRowNoWrap}
//   align-items: left;
//   border-radius: 3rem;
//   outline: none;
//   cursor: pointer;
//   text-decoration: none;
//   color: ${({ theme }) => theme.text2};
//   font-size: 1rem;
//   font-weight: 500;
//   padding: 8px 12px;
//   word-break: break-word;
//   overflow: hidden;
//   white-space: nowrap;
//   &.${activeClassName} {
//     border-radius: 12px;
//     font-weight: 600;
//     justify-content: center;
//     color: ${({ theme }) => theme.text1};
//     background-color: ${({ theme }) => theme.bg2};
//   }

//   :hover,
//   :focus {
//     color: ${({ theme }) => darken(0.1, theme.text1)};
//   }
// `

// const StyledExternalLink = styled(ExternalLink).attrs({
//   activeClassName,
// })<{ isActive?: boolean }>`
//   ${({ theme }) => theme.flexRowNoWrap}
//   align-items: left;
//   border-radius: 3rem;
//   outline: none;
//   cursor: pointer;
//   text-decoration: none;
//   color: ${({ theme }) => theme.text2};
//   font-size: 1rem;
//   width: fit-content;
//   margin: 0 12px;
//   font-weight: 500;

//   &.${activeClassName} {
//     border-radius: 12px;
//     font-weight: 600;
//     color: ${({ theme }) => theme.text1};
//   }

//   :hover,
//   :focus {
//     color: ${({ theme }) => darken(0.1, theme.text1)};
//     text-decoration: none;
//   }
// `

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  //const history = useHistory()
  const location = useLocation()
  const networkSupported = chainId === SupportedChainId.ROPSTEN || chainId === SupportedChainId.MAINNET

  const { currentVersion } = useContext(VersionContext)

  //==================LC=====================
  // const [isTrader, setIsTrader] = useState<boolean>(false)
  // const [traderAdr, setTraderAdr] = useState<string>('')

  const [darkMode, toggleDarkMode] = useDarkModeManager()

  useEffect(() => {
    if (darkMode) {
      toggleDarkMode()
    }
  }, [darkMode, toggleDarkMode])

  //=====change role based on the link=======
  // useEffect(() => {
  //   if (account) {
  //     if (
  //       location.pathname === '/investor' ||
  //       // location.pathname.startsWith('/remove') ||
  //       // location.pathname.startsWith('/increase') ||
  //       location.pathname === '/find'
  //     ) {
  //       setIsTrader(false)
  //     } else {
  //       setIsTrader(true)
  //     }
  //   }
  // }, [account, location])

  const isRolePage = false

  // if (location.pathname.endsWith('/role')) {
  //   isRolePage = true
  // }
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']

  //const toggleClaimModal = useToggleSelfClaimModal()

  //const availableClaim: boolean = useUserHasAvailableClaim(account)

  //const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  //const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  //const showClaimPopup = useShowClaimPopup()

  const scrollY = useScrollPosition()

  //const { infoLink } = CHAIN_INFO[chainId ? chainId : SupportedChainId.MAINNET]

  return (
    <HeaderFrame showBackground={scrollY > 45} isRolePage={isRolePage}>
      {/* <ClaimModal />
      <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal> */}

      {/* <Title href="/#/role"> */}
      {!location.pathname.endsWith('/landing') ? (
        <>
          {' '}
          <Title href="/#/home">
            <UniIcon style={{ marginRight: '5px' }} src={LogoNew} alt="logo" />
            <TitleText>
              {' '}
              <span className="title">LiquidCube</span>
              {/* <span className="version">{currentVersion}</span> */}
            </TitleText>
          </Title>
          {/* <AutoColumn> */}
          {/* <HeaderLinks> */}
          {/* {isTrader && (
            <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
              <Trans>Swap</Trans>
            </StyledNavLink>
          )}

          {isTrader && (
            <StyledNavLink
              id={`pool-nav-link`}
              to={'/pool'}
              isActive={(match, { pathname }) =>
                Boolean(match) ||
                pathname.startsWith('/add') ||
                pathname.startsWith('/remove') ||
                pathname.startsWith('/increase') ||
                pathname.startsWith('/find')
              }
            >
              <Trans>Pool</Trans>
            </StyledNavLink>
          )} */}
          {/* {chainId && chainId === SupportedChainId.MAINNET && (
            <StyledNavLink id={`vote-nav-link`} to={'/vote'}>
              <Trans>Vote</Trans>
            </StyledNavLink>
          )} */}
          {/* <StyledExternalLink id={`charts-nav-link`} href={infoLink}>
          <Trans>Charts</Trans>
          <sup>↗</sup>
        </StyledExternalLink> */}
          {/* {!isTrader && (
            <StyledNavLink id={`swap-nav-link`} to={'/transfer'}>
              <Trans>Transfer</Trans>
            </StyledNavLink>
          )} */}
          {/* </HeaderLinks>
          </AutoColumn> */}
          <HeaderControls>
            <NetworkCard />
            <HeaderElement>
              {/* {availableClaim && !showClaimPopup && (
            <UNIWrapper onClick={toggleClaimModal}>
              <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                <TYPE.white padding="0 2px">
                  {claimTxn && !claimTxn?.receipt ? (
                    <Dots>
                      <Trans>Claiming UNI</Trans>
                    </Dots>
                  ) : (
                    <Trans>Claim UNI</Trans>
                  )}
                </TYPE.white>
              </UNIAmount>
              <CardNoise />
            </UNIWrapper>
          )} */}
              <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
                {/* {account && !location.pathname.endsWith('/role') && userEthBalance ? (
              <ToggleTrader
                id={'toggle-trader'}
                isActive={isTrader}
                // toggle={() => console.log('unable to switch role')}
                toggle={() => {
                  setIsTrader(!isTrader)
                  {
                    !isTrader ? history.push('/') : history.push('/transfer')
                  }
                }}
              />
            ) : null} */}
                {account && userEthBalance && networkSupported ? (
                  <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                    <Trans>{userEthBalance?.toSignificant(3)} ETH</Trans>
                  </BalanceText>
                ) : null}
                <Web3Status />
              </AccountElement>
              <Menu />
            </HeaderElement>
          </HeaderControls>
        </>
      ) : (
        <>
          <Title href="/#/home">
            <UniIcon width={'66px'} style={{ marginRight: '5px' }} src={LogoNew} alt="logo" />
            <LandingTitleText> LiquidCube</LandingTitleText>
          </Title>
          <HeaderControls>
            {/* <ButtonPrimary2
              style={{ color: '#132073', fontWeight: 500, borderRadius: '16px', height: '40px' }}
              onClick={() => history.push('/role')}
            >
              Launch App
            </ButtonPrimary2> */}
          </HeaderControls>
        </>
      )}
    </HeaderFrame>
  )
}
