import CurrencyLogo from 'components/CurrencyLogo'
import styled from 'styled-components/macro'
import { Currency, Token } from '@uniswap/sdk-core'
import { AutoColumn } from 'components/Column'
import { DesktopHide, MobileHide } from 'theme/components'
import { MouseoverTooltipContent } from 'components/Tooltip'
import { Info } from 'react-feather'
import Row from 'components/Row'
import CopyHelper from 'components/AccountDetails/Copy'
import { useCurrency } from 'hooks/Tokens'
import { useHistory } from 'react-router'
import { formatFloat } from 'components/SudoCubeListItem'
import { useContext } from 'react'
import { VersionContext } from 'pages/App'
import idCard from '../../assets/images/id-card.png'
import numeral from 'numeral'

const FirstSectionContainer = styled(AutoColumn)`
  gap: 18px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  gap: 15px;
  margin-top: 0;
  margin-bottom: 0;
`};
`

const WhiteListMobile = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
display: flex;
`};
  .whitelistIcon {
    margin-left: 61px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 130px;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`};
  }
`

const MobileOnlyText = styled.div`
  color: #424242;
  margin-right: 4px;
  display: none;
  font-weight: 600;
  flex-direction: row;
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    //padding-top: 5px;
    font-size: 14px;
  `};
  text-align: left;
`

const InfoGridContainer = styled.div`
  display: grid;
  text-align: left;
  grid-template-columns: 1.1fr 1.2fr 1fr 1.5fr;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1.7fr 1.2fr;
`};
`

const StyledAutoColumn = styled(AutoColumn)`
  gap: 18px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  gap: 15px;
  margin-bottom: 15px
  `};
`

const InfoTextTitle = styled.div`
  font-size: 16px;
  color: #424242;
  font-weight: 400;
  text-align: left;
  //margin-bottom: auto;
  width: 200.06px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: auto;
    font-size: 14px;
    color: #424242;
    letter-spacing: 0;
    font-weight: 600;
  `};
`

const StyledInfo = styled(Info)`
  opacity: 0.4;
  color: black;
  height: 18px;
  width: 18px;
  :hover {
    opacity: 0.8;
  }
  margin-bottom: -3px;
`

const InputWrapper = styled.div`
  font-size: 16px;
  color: #424242;
  letter-spacing: 0;
  font-weight: 700;
  text-align: left;
  align-items: left;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    color: #000000;
  `};
`
const ReducedFontSize = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 12px;
`};
`

const MobileHideGridContainer = styled.div`
  display: grid;
  text-align: left;
  grid-template-columns: 1.1fr 3.7fr;
  //padding: 0 42px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    flex-direction: column;
    gap: 10px;
    //padding: 0 10px;
    display: none;
`};
`

const ShowInvestorButton = styled.span`
  color: #1d5398;
  border: 1px solid #1d5398;
  font-size: 11px;
  padding: 0.2rem;
  border-radius: 11px;
  margin-left: 10px;
  cursor: pointer;
  font-weight: 700;
  // position: relative;
  // margin-left: auto;
  // float: right;
  :hover {
    background-color: #1d5398;
    color: white;
  }
`
interface CubeInfoType {
  showInvestors?: () => void
  hasStarted?: boolean
  currencyList: Token[]
  base: Currency
  totalFund: number | string
  traderCut: string | undefined
  traderAddr: string | undefined
  date: Date
  endDate: Date
  tradingPeriodLength: string | undefined
  numberCubelets?: number
  singleCubeletValue?: number | string
}
export default function CubeInfoDetail({
  showInvestors,
  hasStarted,
  currencyList,
  base,
  totalFund,
  traderCut,
  traderAddr,
  date,
  endDate,
  tradingPeriodLength,
  numberCubelets,
  singleCubeletValue,
}: CubeInfoType) {
  const defaultCurrency = useCurrency('ETH')
  const history = useHistory()
  const { currentVersion } = useContext(VersionContext)

  const isVersionOne = currentVersion === '1.0'

  const isTrader = history.location.pathname.includes('trader')
  const traderShare = formatFloat(Number(traderCut) * (isVersionOne ? 0.98 : 1) - (isVersionOne ? 0 : 2), 2)
  const investorShare = formatFloat((100 - Number(traderCut)) * (isVersionOne ? 0.98 : 1), 2)

  function truncate(fullStr: string | undefined, strLen: number) {
    if (!fullStr) return '...'
    if (fullStr.length <= strLen) return fullStr
    const separator = '...'
    const sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2)
    return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars)
  }

  return (
    <FirstSectionContainer>
      <WhiteListMobile>
        <MobileOnlyText>Trading Token Whitelist: </MobileOnlyText>{' '}
        <MobileOnlyText className="whitelistIcon" style={{ marginTop: '-3px' }}>
          {currencyList.map((item, i) =>
            item?.symbol === 'WETH' ? (
              <span key={`${item?.name}${i}`} style={{ marginRight: '3px' }}>
                <CurrencyLogo currency={defaultCurrency as Currency} size={'24px'} />
              </span>
            ) : (
              <span key={`${item?.name}${i}`} style={{ marginRight: '3px' }}>
                <CurrencyLogo currency={item as Currency} size={'24px'} />
              </span>
            )
          )}
        </MobileOnlyText>
      </WhiteListMobile>
      <InfoGridContainer>
        <StyledAutoColumn>
          <InfoTextTitle>Total Fund {hasStarted ? 'Raised' : 'to Raise'}:</InfoTextTitle>
          {hasStarted && <InfoTextTitle>Single Cubelet Value:</InfoTextTitle>}
          <InfoTextTitle>Platform Allowed:</InfoTextTitle>
          <InfoTextTitle>
            Base Token:
            <MobileHide>
              <MouseoverTooltipContent content={<span>The accepted investment token.</span>}>
                <StyledInfo />
              </MouseoverTooltipContent>
            </MobileHide>
          </InfoTextTitle>
        </StyledAutoColumn>
        <StyledAutoColumn>
          <InputWrapper>
            {totalFund} {base?.symbol}
          </InputWrapper>
          {hasStarted && (
            <InputWrapper>
              {numeral(parseFloat(Number(singleCubeletValue).toFixed(12))).format('0,0')} {base?.symbol}
            </InputWrapper>
          )}
          <InputWrapper>Uniswap V3</InputWrapper>
          <InputWrapper>
            <MobileHide>
              <CurrencyLogo
                currency={base as Currency}
                size={'24px'}
                style={{ marginBottom: '-5px', marginRight: '8px' }}
              />
            </MobileHide>
            <DesktopHide>
              <CurrencyLogo
                currency={base as Currency}
                size={'24px'}
                style={{ marginBottom: '-5px', marginRight: '5px' }}
              />
            </DesktopHide>
            {base?.symbol}
          </InputWrapper>
        </StyledAutoColumn>

        <StyledAutoColumn>
          <InfoTextTitle style={{ whiteSpace: 'nowrap', marginTop: '-3px' }}>
            Profit Split <ReducedFontSize>{isTrader ? '(Trader)' : '(Investor)'}</ReducedFontSize>:
            <MobileHide>
              <MouseoverTooltipContent
                content={
                  <span>
                    {isVersionOne
                      ? 'Platform will share 2% of the total profit'
                      : "Platform will share 2% of the total profit. The platform share will be deducted from trader's profit share. This applies when there's profit in cube"}
                  </span>
                }
              >
                <StyledInfo />
              </MouseoverTooltipContent>
            </MobileHide>
          </InfoTextTitle>
          {hasStarted && <InfoTextTitle style={{ marginTop: '-2px' }}>Cubelets Number:</InfoTextTitle>}
          <InfoTextTitle>Trader Address:</InfoTextTitle>
          <InfoTextTitle>Trading Period:</InfoTextTitle>
        </StyledAutoColumn>
        <StyledAutoColumn>
          <InputWrapper>
            {!isTrader ? investorShare : traderShare}%{' '}
            <MobileHide>
              <ReducedFontSize>(Gas Fee on Trader) </ReducedFontSize>
            </MobileHide>
          </InputWrapper>
          {hasStarted && (
            <InputWrapper style={{ paddingTop: '0px' }}>
              {numberCubelets}{' '}
              {/* {showInvestors && <ShowInvestorButton onClick={showInvestors}>View Investors</ShowInvestorButton>} */}
            </InputWrapper>
          )}
          <InputWrapper>
            <Row style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
              {' '}
              <img
                src={idCard}
                alt="id"
                style={{ width: '25px', marginRight: '10px' }}
                onClick={() => window.open(`/#/profile/trader/${traderAddr}`, '_blank')}
              />
              <span onClick={() => window.open(`/#/profile/trader/${traderAddr}`, '_blank')}>
                {truncate(traderAddr, 16)}
              </span>{' '}
              <CopyHelper toCopy={traderAddr as string} />
            </Row>
            {/* <Row style={{ whiteSpace: 'nowrap' }}>
              {' '}
              <img src={idCard} alt="id" style={{ width: '25px', marginRight: '10px' }} />
              <span>{truncate(traderAddr, 16)}</span> <CopyHelper toCopy={traderAddr as string} />
            </Row> */}
          </InputWrapper>
          <InputWrapper style={{ whiteSpace: 'nowrap' }}>
            {date.toLocaleDateString('en-US')} - {endDate.toLocaleDateString('en-US')}{' '}
            <MobileHide>({parseFloat((Number(tradingPeriodLength) / 86400).toFixed(4))} Days)</MobileHide>
          </InputWrapper>
        </StyledAutoColumn>
      </InfoGridContainer>
      <MobileHideGridContainer>
        <InfoTextTitle style={{ whiteSpace: 'nowrap' }}>Token Whitelist:</InfoTextTitle>
        <InputWrapper style={{ lineHeight: '30px', marginTop: '-3px' }}>
          {currencyList.map((item, i) =>
            item?.symbol === 'WETH' ? (
              <span key={`${item?.name}${i}`} style={{ marginRight: '8px', display: 'inline-block' }}>
                <CurrencyLogo
                  currency={defaultCurrency as Currency}
                  size={'24px'}
                  style={{ marginBottom: '-5px', marginRight: '8px' }}
                />
                <span>ETH </span>
              </span>
            ) : (
              <span key={`${item?.name}${i}`} style={{ marginRight: '8px', display: 'inline-block' }}>
                <CurrencyLogo
                  currency={item as Currency}
                  size={'24px'}
                  style={{ marginBottom: '-5px', marginRight: '8px' }}
                />
                <span>{item?.symbol} </span>
              </span>
            )
          )}
        </InputWrapper>
      </MobileHideGridContainer>
    </FirstSectionContainer>
  )
}
