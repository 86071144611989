//import { useEffect, useMemo, useState } from 'react'
import { useCurrency } from 'hooks/Tokens'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { MEDIA_WIDTHS } from 'theme'
import { Currency } from '@uniswap/sdk-core'
import CurrencyLogo from 'components/CurrencyLogo'
import { useActiveWeb3React } from 'hooks/web3'
import { utils } from 'ethers'
import { CubeDetails } from 'types/cube'
import LCLogo from '../../assets/images/uniswap.png'
//import CubeYellow from '../../assets/images/cube_yellow.png'
// import { useBestV3TradeExactIn } from 'hooks/useBestV3Trade'
// import { useCurrencyBalances } from 'state/wallet/hooks'
// import { useV3Positions } from 'hooks/useV3Positions'
// import { FeeAmount, Position } from '@uniswap/v3-sdk'
// import { usePools } from 'hooks/usePools'
// import { tryParseAmount } from 'state/swap/hooks'
//import { useV3PositionFees } from 'hooks/useV3PositionFees'
import CubeRed from '../../assets/images/cube_red.png'
import CubeGreen from '../../assets/images/cube_green.png'
import { CubeStatusLabel } from 'components/CubeStatusLabel'
import numeral from 'numeral'
import { useCubeState } from 'hooks/useCubeState'
import { SupportedChainId } from 'constants/chains'
//import { formatFloat } from 'components/SudoCubeListItem'

const InsideWrapper = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  //border-radius: 25px;
  //margin-bottom: 20px;
  padding: 20px 0 0 0;
  :hover {
    background-color: ${({ theme }) => theme.bg1};
  }
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    /* flex-direction: row; */
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
  `};
`

const Wrapper = styled.div`
  //align-items: flex-start;
  user-select: none;
  justify-content: space-between;
  border-bottom: ${({ theme }) => '1px solid' + theme.bg2};
  color: ${({ theme }) => theme.text1};
  margin: 8px 0;
  padding-bottom: 12px;
  text-decoration: none;
  font-weight: 500;

  &:last-of-type {
    margin: 8px 0 0 0;
  }
  & > div:not(:first-child) {
    text-align: left;
  }

  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    /* flex-direction: row; */
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 5px 0 10px 0;
  `};
`

const SmallCube = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 8px;
  margin-top: -7px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 23px;
    height: 23px;
    margin: 0;
    margin-right: 5px;
  `};
`

const CubeName = styled.div`
  font-size: 19px;
  color: #1d5398;
  margin-top: -5px;
  font-weight: 700;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .versionTag {
    //position: absolute;
    font-size: 15px;
    line-height: 20px;
    margin: 0 0 3px 5px;
    padding-bottom: 15px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 140px;
    //white-space: nowrap;
    font-size: 16px;
    margin-top: 0;
    .versionTag {
      font-size: 13px;
    }
  `};
`

const GridContainer = styled.div`
  display: grid;
  margin-left: 2%;
  grid-template-columns: 1.2fr 1fr 1fr 0.5fr 0.5fr 0.3fr;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr 1fr;
    margin: 0;
  //display: flex;
  //justify-content: space-between;
  `};
`
const SecondRowGrid = styled.div`
  display: grid;
  margin-left: 6.5%;
  // grid-template-columns: 1.5fr 5fr;
  grid-template-columns: 3fr 2.7fr;
  margin-bottom: 10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: -8px 0 6px 8%;
    display: block;
    text-align: left;
  `};
`

const ThirdRowGrid = styled.div`
  display: flex;
  margin-left: 6.5%;
  //grid-template-columns: 4fr 6fr;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    margin-top: -13px;
    margin-left: 8%;
   margin-bottom: 10px;
  `};
`

const ExtentsText = styled.div`
  color: #424242;
  font-size: 15px;
  margin-right: 4px;
  display: flex;
  font-weight: 400;
  flex-direction: row;
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    //padding-top: 5px;
    font-size: 14px;
  `};
  text-align: left;
`

const MobileOnlyText = styled.div`
  color: #424242;
  margin-right: 4px;
  display: none;
  font-weight: 600;
  flex-direction: row;
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    //padding-top: 5px;
    font-size: 13px;
  `};
  text-align: left;
`

const TitleText = styled.div`
  color: #424242;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  //padding-bottom: 17px;
  white-space: nowrap;
  font-weight: 400;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 13px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 1fr 2fr;
  `};
  text-align: left;
`
const WhiteListDesktop = styled.div`
  display: flex;
  //margin-top: 5px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};
  .whitelistIcon {
    margin-left: 54px;
    //width: 100%;
    max-width: 600px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -5px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 150px;
      margin-left: 10px;
      
  `};
  }
`

const DesktopHidedText = styled.div`
  color: #424242;
  display: none;
  flex-direction: row;
  //padding-bottom: 17px;
  white-space: nowrap;
  font-weight: 600;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    font-size: 13px;
    display: grid;
    grid-template-columns: 1fr 2fr;
  `};
  text-align: left;
`

const WhiteListMobile = styled.div`
  display: none;
  margin-left: 8%;
  margin-top: -9%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: flex;
`};
  .whitelistIcon {
    margin-left: 61px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 145px;
      margin-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  `};
  }
`

const DataText = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 700;
  font-size: 22px;
  padding-bottom: 10px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
    //justify-content: space-between;
    padding: 0;
  `};
`

const CubeletsContainer = styled.div`
  // display: flex;
  // flex-direction: row;
  display: inline-block;
  min-width: 300px;
  max-width: 600px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -3px;
  // white-space: nowrap;
  // overflow: hidden;
`
const OwnedCubelet = styled.div`
  width: 29px;
  height: 100%;
  display: inline-block;
  background-color: #8ad76d;
  font-size: 18px;
  color: white;
  font-weight: 400;
  min-width: 29px;
  min-height: 29px;
  text-align: center;
  vertical-align: middle;
  line-height: 29px;
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`

interface CubeListItemProps {
  cubeDetails: CubeDetails
}

export default function CubeListItem({ cubeDetails }: CubeListItemProps) {
  const {
    cubeAddress,
    //traderCut,
    startingTime,
    tradingPeriodLength,
    cubeName,
    baseCurrency,
    numberOfCubelets,
    pricePerCubelet,
    tokenWhitelist,
    cubeletsInfo,
    version,
    profit,
  } = cubeDetails
  const { account, chainId } = useActiveWeb3React()
  const { state: cubeState, loading: stateLoading } = useCubeState(cubeAddress)

  const history = useHistory()

  const ownedTokenIds = cubeletsInfo
    ?.filter((item) => item?.ownerAddr?.toLowerCase() === account?.toLowerCase())
    .sort((item1, item2) => Number(item1.cubeletId) - Number(item2.cubeletId))

  const defaultCurrency = useCurrency('ETH')
  //const [cubeState, setCubeState] = useState<number>()

  const base = useCurrency(baseCurrency)
  const singleCubeletPrice = base
    ? base.decimals === 18
      ? utils.formatEther(pricePerCubelet)
      : //: pricePerCubelet.div(10 ** 6)
        Number(pricePerCubelet.toString()) / 10 ** 6
    : undefined

  const totalFund = parseFloat((Number(numberOfCubelets) * Number(singleCubeletPrice)).toFixed(12))
  const formattedTotalFund = numeral(totalFund).format('0,0')

  const currencyList = tokenWhitelist.map((item) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const currency = useCurrency(item)
    if (currency?.symbol === 'WETH') {
      return defaultCurrency
    } else {
      return currency
    }
  })

  const today = new Date()
  const endTime = new Date((Number(startingTime) + Number(tradingPeriodLength)) * 1000)
  //const timerComponents = CountdownTimer(endTime)

  // // ==== getting cube final total from contract after liquidating ======
  //const { total: finalTotal, loading: loadingTotal } = useCubeFinalTotal(cubeAddress)

  // const profitToDisplay =
  //   profit === -100 || chainId !== SupportedChainId.MAINNET
  //     ? 'NA'
  //     : profit === -1
  //     ? !loadingTotal
  //       ? (
  //           ((Number(utils.formatEther(finalTotal)) * (base?.decimals === 18 ? 1 : 10 ** 12)) / totalFund - 1) *
  //           100
  //         ).toFixed(1) + '%'
  //       : 'NA'
  //     : (Number(profit) * 100).toFixed(1) + '%'
  const profitToDisplay =
    profit === -100 || chainId !== SupportedChainId.MAINNET ? 'NA' : (Number(profit) * 100).toFixed(1) + '%'

  return (
    <InsideWrapper
      to={
        history.location.pathname.includes('trader')
          ? '/trader/v' + version + '/' + cubeAddress
          : '/investor/v' + version + '/' + cubeAddress
      }
    >
      <Wrapper>
        <GridContainer>
          <DataText>
            <SmallCube
              // src={!stateLoading && (cubeState == 4 || today > endTime) ? CubeRed : CubeGreen}
              src={
                stateLoading
                  ? today > endTime
                    ? CubeRed
                    : CubeGreen
                  : cubeState === 4 || today > endTime
                  ? CubeRed
                  : CubeGreen
              }
              alt="littleCube"
            />
            <CubeName>
              {' ' + cubeName}
              {/* <span className="versionTag">v{version.replace('-', '.')}</span> */}
            </CubeName>
          </DataText>

          {stateLoading ? (
            today > endTime ? (
              <CubeStatusLabel color="red" />
            ) : (
              <CubeStatusLabel color="green" />
            )
          ) : cubeState === 4 || today > endTime ? (
            <CubeStatusLabel color="red" />
          ) : (
            <CubeStatusLabel color="green" />
          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <ExtentsText style={{ fontWeight: 700 }}>
              <CurrencyLogo
                currency={base as Currency}
                size={'25px'}
                style={{ marginTop: '-2px', marginRight: '10px' }}
              />
              {formattedTotalFund} {base?.symbol}
            </ExtentsText>
            <span
              style={{
                color: '#70B954',
                whiteSpace: 'nowrap',
                marginTop: '10px',
              }}
            >
              <br />
            </span>
          </div>
          <ExtentsText style={{ fontWeight: 700 }}>{version.replace('-', '.')}</ExtentsText>
          {/* <ExtentsText style={{ fontWeight: 700 }}>{formatFloat((100 - Number(traderCut)) * 0.98, 2)} %</ExtentsText> */}
          <ExtentsText>
            <img src={LCLogo} alt="uni-v3" style={{ marginTop: '-6px' }} width={'30px'} height={'30px'} />
          </ExtentsText>
          <ExtentsText style={{ fontWeight: 700 }}>{profitToDisplay}</ExtentsText>
        </GridContainer>
        <WhiteListMobile>
          <MobileOnlyText>Trading Token Whitelist: </MobileOnlyText>{' '}
          <MobileOnlyText className="whitelistIcon" style={{ marginTop: '-3px' }}>
            {currencyList.map((item, i) =>
              item?.symbol === 'WETH' ? (
                <span key={`${item?.name}${i}`} style={{ marginRight: '3px', marginLeft: i === 0 ? '0' : '-10px' }}>
                  <CurrencyLogo currency={defaultCurrency as Currency} size={'23px'} />
                </span>
              ) : (
                <span key={`${item?.name}${i}`} style={{ marginRight: '3px', marginLeft: i === 0 ? '0' : '-10px' }}>
                  <CurrencyLogo currency={item as Currency} size={'23px'} />
                </span>
              )
            )}
          </MobileOnlyText>
        </WhiteListMobile>
        <SecondRowGrid>
          <DesktopHidedText>
            <span> Fund Cap: </span>
            <span
              style={{
                color: 'black',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {' '}
              <CurrencyLogo
                currency={base as Currency}
                size={'22px'}
                style={{ marginTop: '-3px', marginRight: '8px' }}
              />
              {formattedTotalFund} {base?.symbol}
            </span>
          </DesktopHidedText>{' '}
          <WhiteListDesktop>
            <ExtentsText>Trading Token Whitelist: </ExtentsText>{' '}
            <ExtentsText className="whitelistIcon" style={{ display: 'block' }}>
              {currencyList.map((item, i) =>
                item?.symbol === 'WETH' ? (
                  <span key={`${item?.name}${i}`} style={{ marginRight: '8px' }}>
                    <CurrencyLogo
                      currency={defaultCurrency as Currency}
                      size={'25px'}
                      style={{ marginRight: '11px' }}
                    />
                  </span>
                ) : (
                  <span key={`${item?.name}${i}`} style={{ marginRight: '8px' }}>
                    <CurrencyLogo currency={item as Currency} size={'25px'} style={{ marginRight: '3px' }} />
                  </span>
                )
              )}
            </ExtentsText>
          </WhiteListDesktop>
          {/* <MobileHide style={{ display: 'flex', flexDirection: 'row' }}>
            <ExtentsText>Version: </ExtentsText>
            <ExtentsText style={{ paddingLeft: '10px', fontWeight: 700 }}>
              v{version.replace('-', '.')}{' '}
            </ExtentsText>{' '}
          </MobileHide> */}
        </SecondRowGrid>
        {ownedTokenIds &&
          ownedTokenIds &&
          history.location.pathname.includes('investor') &&
          ownedTokenIds.length !== 0 && (
            <ThirdRowGrid>
              <TitleText style={{ width: '40%' }}>
                <span style={{ marginRight: '16px' }}> You Invested: </span>
                <span style={{ color: 'black' }}>
                  {' '}
                  {ownedTokenIds.length} Cubelets (
                  {parseFloat((ownedTokenIds.length * Number(singleCubeletPrice)).toFixed(12))} {base?.symbol})
                </span>
              </TitleText>{' '}
              <ExtentsText style={{ width: '40%' }}>
                <span style={{ marginRight: '16px' }}> Your Cubelets:</span>
                <CubeletsContainer>
                  {ownedTokenIds
                    ?.sort((itemA, itemB) => itemA.cubeletId - itemB.cubeletId)
                    .map((item, i) => (
                      <OwnedCubelet key={i} style={{ margin: '0 5px' }}>
                        {Number(item.cubeletId) > 99
                          ? ('0' + (Number(item.cubeletId) + 1)).slice(-2)
                          : Number(item.cubeletId) + 1}
                      </OwnedCubelet>
                    ))}
                </CubeletsContainer>
              </ExtentsText>{' '}
            </ThirdRowGrid>
          )}
        {/* {pools.map((item, i) => {
          return <PositionListMap key={`${i}`} pool={item} index={i} />
        })}
        {currencyList.map((item, i) => {
          return (
            <TokenListMap
              key={i}
              token={item as Currency}
              // the following solves the problem that tryParseAmount returns undefined sometimes if the currency amount has too many digits
              amount={
                tryParseAmount(balancesWithPool[i][1].toString(), item as Currency)
                  ? tryParseAmount(balancesWithPool[i][1].toString(), item as Currency)
                  : tryParseAmount(balancesWithPool[i][1].toPrecision(12), item as Currency)
              }
              index={i}
            />
          )
        })} */}
      </Wrapper>
    </InsideWrapper>
  )
}
