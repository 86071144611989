import { Currency, Token, WETH9 } from '@uniswap/sdk-core'
import { KeyboardEvent, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactGA from 'react-ga'
import { t, Trans } from '@lingui/macro'
import { FixedSizeList } from 'react-window'
import { Text } from 'rebass'
import { ExtendedEther } from '../../constants/tokens'
import { useActiveWeb3React } from '../../hooks/web3'
import { useAllTokens, useToken, useIsUserAddedToken, useSearchInactiveTokenLists } from '../../hooks/Tokens'
import { CloseIcon, TYPE, ButtonText, IconWrapper } from '../../theme'
import { isAddress } from '../../utils'
import Column from '../Column'
import Row, { RowBetween, RowFixed } from '../Row'
import CommonBases from './CommonBases'
import CurrencyList from './CurrencyList'
import { filterTokens, useSortedTokensByQuery } from './filtering'
import { useTokenComparator } from './sorting'
import { PaddedColumn, SearchInput, Separator } from './styleds'
import AutoSizer from 'react-virtualized-auto-sizer'
import styled from 'styled-components/macro'
import useToggle from 'hooks/useToggle'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useTheme from 'hooks/useTheme'
import ImportRow from './ImportRow'
import { Edit } from 'react-feather'
import useDebounce from 'hooks/useDebounce'
import { useCubeInfoContract } from 'hooks/useContract'
//import { LcCubeInfo } from 'abis/types/LcCubeInfo'
import { SupportedChainId } from 'constants/chains'
import Loader from '../Loader'
import { useCubeWhitelist } from 'hooks/useCubeWhitelist'
import { useCubeWhitelistAPI } from 'hooks/useCubeInfoFromAPI'

const ContentWrapper = styled(Column)`
  width: 100%;
  flex: 1 1;
  position: relative;
`

const Footer = styled.div`
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: ${({ theme }) => theme.bg1};
  border-top: 1px solid ${({ theme }) => theme.bg2};
`

interface CurrencySearchProps {
  cubeWhitelist?: string[]
  cubeAddr?: string
  isWallet?: boolean
  isOpen: boolean
  onDismiss: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherSelectedCurrency?: Currency | null
  showCommonBases?: boolean
  showCurrencyAmount?: boolean
  disableNonToken?: boolean
  showManageView: () => void
  showImportView: () => void
  setImportToken: (token: Token) => void
  isPool?: boolean
}

export function CurrencySearch({
  cubeWhitelist,
  cubeAddr,
  isWallet,
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  showCommonBases,
  showCurrencyAmount,
  //disableNonToken,
  onDismiss,
  isOpen,
  showManageView,
  showImportView,
  setImportToken,
  isPool,
}: CurrencySearchProps) {
  const { chainId } = useActiveWeb3React()
  const theme = useTheme()

  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>()

  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedQuery = useDebounce(searchQuery, 200)

  const [invertSearchOrder] = useState<boolean>(false)

  const allTokens = useAllTokens()

  //======== LC V0 ===========

  // const cubeInfo = useCubeInfoContract()
  // //console.log(cubeInfo?.cubes(cubeAddr))
  // cubeInfo?.cubes(cubeAddr as string).then((re) => console.log(re))

  // async function getCubeInfo() {
  //   const cube = await cubeInfo?.cubes(cubeAddr as string)
  //   console.log(cube)
  // }

  // if they input an address, use it
  const isAddressSearch = isAddress(debouncedQuery)

  const searchToken = useToken(debouncedQuery)

  const searchTokenIsAdded = useIsUserAddedToken(searchToken)

  useEffect(() => {
    if (isAddressSearch) {
      ReactGA.event({
        category: 'Currency Select',
        action: 'Search by address',
        label: isAddressSearch,
      })
    }
  }, [isAddressSearch])

  const tokenComparator = useTokenComparator(invertSearchOrder)

  //const [tokenList, setTokenList] = useState<string[]>([])

  //useCubeInfoContract(cubeAddr ? cubeAddr : undefined)
  // async function getCube() {
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   const tokens = await useCubeInfoContract(cubeAddr as string)?.getTokenWhitelist()
  //   if (tokens) {
  //     setTokenList(tokens)
  //   }
  // }

  // getCube()

  const { whitelist: tokenListTestnet, loading: tokenLoading } = useCubeWhitelist(cubeAddr as string)
  //console.log(tokenListTestnet)

  //const tokenLists = cubeWhitelist ? cubeWhitelist : []

  const tokenLists = cubeAddr
    ? chainId === 1
      ? cubeWhitelist
        ? cubeWhitelist
        : []
      : tokenListTestnet
      ? tokenListTestnet
      : []
    : []
  // console.log(tokenList)

  const hasETH = cubeAddr
    ? tokenLists
      ? tokenLists.includes(WETH9[chainId ? chainId : SupportedChainId.MAINNET].address)
      : false
    : true

  //console.log('hasETH', hasETH)

  const filteredTokens: Token[] = useMemo(() => {
    return filterTokens(Object.values(allTokens), debouncedQuery)
  }, [allTokens, debouncedQuery])

  const sortedTokens: Token[] = useMemo(() => {
    return filteredTokens.sort(tokenComparator)
  }, [filteredTokens, tokenComparator])

  const defaultFilteredSortedTokens = useSortedTokensByQuery(sortedTokens, debouncedQuery)
  const filteredSortedTokens = cubeAddr
    ? defaultFilteredSortedTokens.filter(
        (tokenDefualt) =>
          tokenDefualt.symbol !== 'WETH' &&
          tokenLists.filter((token) => token.toLowerCase() === tokenDefualt.address.toLowerCase()).length > 0
      )
    : defaultFilteredSortedTokens.filter((tokenDefault) => tokenDefault.symbol !== 'WETH')

  //const [supportedList, setSupportedList] = useState<Token[]>()
  //const supportList: Token[] = []

  //newInfo.then((item) => setTokenList(item))

  //const tokenListList: Token[] | undefined = tokenList?.map((token) => allTokens[token])

  // eslint-disable-next-line react-hooks/rules-of-hooks

  //const cubeInfo = useCubeInfoContract(cubeAddr as string)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  //const supportList = tokenList ? tokenList.map((item) => useToken(item)) : undefined
  //console.log('this is great!', supportList)
  //console.log('this is great!', tokenList)

  // if (tokenList) {
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   const Supportedlist = tokenList?.map((token) => useCurrency(token))
  //   console.log('this is great!', Supportedlist)
  // }

  const ether = useMemo(() => chainId && ExtendedEther.onChain(chainId), [chainId])

  const filteredSortedTokensWithETH: Currency[] = useMemo(() => {
    const s = debouncedQuery.toLowerCase().trim()
    if (s === '' || s === 'e' || s === 'et' || s === 'eth') {
      return ether ? [ether, ...filteredSortedTokens] : filteredSortedTokens
    }
    return filteredSortedTokens
  }, [debouncedQuery, ether, filteredSortedTokens])

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency)
      onDismiss()
    },
    [onDismiss, onCurrencySelect]
  )

  // clear the input on open
  useEffect(() => {
    if (isOpen) setSearchQuery('')
  }, [isOpen])

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>()
  const handleInput = useCallback((event) => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
    //fixedList.current?.scrollTo(0)
  }, [])

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const s = debouncedQuery.toLowerCase().trim()
        if (s === 'eth' && ether) {
          handleCurrencySelect(ether)
        } else if (filteredSortedTokensWithETH.length > 0) {
          if (
            filteredSortedTokensWithETH[0].symbol?.toLowerCase() === debouncedQuery.trim().toLowerCase() ||
            filteredSortedTokensWithETH.length === 1
          ) {
            handleCurrencySelect(filteredSortedTokensWithETH[0])
          }
        }
      }
    },
    [debouncedQuery, ether, filteredSortedTokensWithETH, handleCurrencySelect]
  )

  // menu ui
  const [open, toggle] = useToggle(false)
  const node = useRef<HTMLDivElement>()
  useOnClickOutside(node, open ? toggle : undefined)

  // if no results on main list, show option to expand into inactive
  const filteredInactiveTokens = useSearchInactiveTokenLists(
    filteredTokens.length === 0 || (debouncedQuery.length > 2 && !isAddressSearch) ? debouncedQuery : undefined
  )

  return (
    <ContentWrapper>
      <PaddedColumn gap="16px">
        <RowBetween>
          <Text fontWeight={500} fontSize={16}>
            <Trans>{isPool ? 'Cube Balance' : 'Select a token'}</Trans>
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        {!cubeAddr && showCommonBases && (
          <>
            <Row>
              <SearchInput
                type="text"
                id="token-search-input"
                placeholder={t`Search name or paste address`}
                autoComplete="off"
                value={searchQuery}
                ref={inputRef as RefObject<HTMLInputElement>}
                onChange={handleInput}
                onKeyDown={handleEnter}
              />
            </Row>
            <CommonBases chainId={chainId} onSelect={handleCurrencySelect} selectedCurrency={selectedCurrency} />
          </>
        )}
      </PaddedColumn>
      <Separator />
      {searchToken && !searchTokenIsAdded ? (
        <Column style={{ padding: '20px 0', height: '100%' }}>
          <ImportRow token={searchToken} showImportView={showImportView} setImportToken={setImportToken} />
        </Column>
      ) : filteredSortedTokens?.length > 0 || filteredInactiveTokens?.length > 0 ? (
        <div style={{ flex: '1' }}>
          <AutoSizer disableWidth>
            {({ height }) => (
              <CurrencyList
                cubeAddr={cubeAddr}
                isWallet={isWallet}
                height={height}
                currencies={hasETH ? filteredSortedTokensWithETH : filteredSortedTokens}
                otherListTokens={filteredInactiveTokens}
                onCurrencySelect={handleCurrencySelect}
                otherCurrency={otherSelectedCurrency}
                selectedCurrency={selectedCurrency}
                fixedListRef={fixedList}
                showImportView={showImportView}
                setImportToken={setImportToken}
                showCurrencyAmount={showCurrencyAmount}
              />
            )}
          </AutoSizer>
        </div>
      ) : (
        <Column style={{ padding: '20px', height: '100%' }}>
          <TYPE.main color={theme.text3} textAlign="center" mb="20px">
            {/* {isPool ? <Loader /> : <Trans>No results found.</Trans>} */}
            <Loader />
          </TYPE.main>
        </Column>
      )}
      <Footer>
        {!cubeAddr && (
          <Row justify="center">
            <ButtonText onClick={showManageView} color={theme.primary1} className="list-token-manage-button">
              <RowFixed>
                <IconWrapper size="16px" marginRight="6px" stroke={theme.primaryText1}>
                  <Edit />
                </IconWrapper>
                <TYPE.main color={theme.primaryText1}>
                  <Trans>Manage Token Lists</Trans>
                </TYPE.main>
              </RowFixed>
            </ButtonText>
          </Row>
        )}
      </Footer>
    </ContentWrapper>
  )
}
