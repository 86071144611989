import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components/macro'
import AddressClaimModal from '../components/claim/AddressClaimModal'
import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { ApplicationModal } from '../state/application/actions'
import { useModalOpen, useToggleModal } from '../state/application/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import { RedirectDuplicateTokenIds } from './AddLiquidity/redirects'
//import { RedirectDuplicateTokenIdsV2 } from './AddLiquidityV2/redirects'
import CreateCube from './CreateCube'
import HomePage from './Homepage'
import InvestorPage from './InvestorPage'
import Pool from './Pool'
import { PositionPage } from './Pool/PositionPage'
//import RemoveLiquidity from './RemoveLiquidity'
import RemoveLiquidityV3 from './RemoveLiquidity/V3'
import Swap from './Swap'
import SudoCubeDetail from './SudoCubeDetail'
import CubeDetail from './CubeDetail'
import { RedirectPathToLanding } from './LandingPage/redirects'
import TraderPage from './TraderPage'
import { createContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import TraderProfile from './TraderProfile'
import UserProfile from './UserProfile'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  font-family: 'Open Sans', serif;
  padding-bottom: 20px;
  // @media only screen and (max-width: 475px) {
  //   padding: 0px 0px 0px 0px;
  //   width: 100%;
  // }
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //padding: 120px 16px 0px 16px;
  padding: 80px 0 0 0;
  align-items: center;
  //flex: 1;
  z-index: 1;
  //box-size: border-size;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 80px 0 0 0;
`};
`

const HeaderWrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`

function TopLevelModals() {
  const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM)
  const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  return <AddressClaimModal isOpen={open} onDismiss={toggle} />
}

export const VersionContext = createContext({
  currentVersion: '1.2',
  toggleVersion: (version: string) => {
    console.log(version)
  },
  switchVersion: (version: string) => {
    console.log(version)
  },
})

export default function App() {
  const history = useHistory()

  // const [isVersionOne, setVersion] = useState<boolean>(false)

  // useEffect(() => {
  //   setVersion(window.localStorage.getItem('version') === '1' ? true : false)
  // }, [])

  // useEffect(() => {
  //   window.localStorage.setItem('version', isVersionOne ? '1' : '0')
  // }, [isVersionOne])

  // function toggleVersion() {
  //   setVersion(!isVersionOne)
  //   if (history.location.pathname.includes('investor')) {
  //     history.push('/investor')
  //   } else if (history.location.pathname.includes('trader/')) {
  //     history.push('/trader')
  //   } else {
  //     history.push('/')
  //   }
  // }

  // function switchVersion(version: boolean) {
  //   if (version !== isVersionOne) {
  //     setVersion(version)
  //   }
  // }

  const [currentVersion, setVersion] = useState<string>('1.2')

  useEffect(() => {
    setVersion(window.localStorage.getItem('version') || '1.2')
  }, [])

  useEffect(() => {
    window.localStorage.setItem('version', currentVersion)
  }, [currentVersion])

  function toggleVersion(version: string) {
    setVersion(version)
    if (history.location.pathname.includes('investor')) {
      history.push('/investor')
    } else if (history.location.pathname.includes('trader/')) {
      history.push('/trader')
    } else {
      history.push('/')
    }
  }

  function switchVersion(version: string) {
    if (version !== currentVersion) {
      setVersion(version)
    }
  }

  return (
    <ErrorBoundary>
      {/* <Route component={GoogleAnalyticsReporter} /> */}
      <Route component={DarkModeQueryParamReader} />
      <Route component={ApeModeQueryParamReader} />
      <Web3ReactManager>
        <VersionContext.Provider value={{ currentVersion, toggleVersion, switchVersion }}>
          <AppWrapper>
            <HeaderWrapper>
              <Header />
            </HeaderWrapper>
            <BodyWrapper>
              <Popups />
              <Polling />
              <TopLevelModals />
              <Switch>
                {/* <Route exact strict path="/landing" component={LandingPage} /> */}
                <Route exact strict path="/home" component={HomePage} />
                <Route exact strict path="/investor" component={InvestorPage} />
                <Route exact strict path="/trader" component={TraderPage} />
                <Route exact strict path="/create-cube" component={CreateCube} />
                <Route exact strict path="/profile/trader/:address" component={TraderProfile} />
                <Route exact strict path="/profile/investor/:address?" component={UserProfile} />
                {/* <Route exact strict path="/vote" component={Vote} /> */}
                {/* <Route exact strict path="/vote/:governorIndex/:id" component={VotePage} /> */}
                {/* <Route exact strict path="/claim" component={OpenClaimAddressModalAndRedirectToSwap} /> */}
                {/* <Route exact strict path="/uni" component={Earn} />
                <Route exact strict path="/uni/:currencyIdA/:currencyIdB" component={Manage} /> */}
                {/* <Route exact strict path="/send" component={RedirectPathToLanding} /> */}
                {/* <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                <Route exact strict path="/swap" component={Swap} /> */}
                {/* <Route exact strict path="/transfer" component={Deposit} /> */}

                {/* =========pool v2============*/}
                {/* <Route exact strict path="/pool/v2/find" component={PoolFinder} />
                <Route exact strict path="/pool/v2" component={PoolV2} /> */}
                {/* <Route exact strict path="/pool" component={Pool} /> */}
                {/* <Route exact strict path="/migrate/v2" component={MigrateV2} />
                <Route exact strict path="/migrate/v2/:address" component={MigrateV2Pair} /> */}
                {/* <Route exact strict path="/pool/:tokenId" component={PositionPage} /> */}

                {/* ========== LC =========== */}
                <Route exact strict path="/investor/:version/:cubeAddress" component={CubeDetail} />
                <Route exact strict path="/trader/:version/:cubeAddress" component={CubeDetail} />
                {/* <Route exact strict path="/trader/:cubeAddress" component={Pool} /> */}
                <Route exact strict path="/trader/:version/:cubeAddress/pool" component={Pool} />
                <Route exact strict path="/trader/:version/:cubeAddress/pool/:tokenId" component={PositionPage} />
                <Route exact strict path="/trader/:version/:cubeAddress/swap" component={Swap} />
                <Route
                  exact
                  strict
                  path="/trader/:version/:cubeAddress/add/:currencyIdA?/:currencyIdB?/:feeAmount?"
                  component={RedirectDuplicateTokenIds}
                />
                {/* <Route
                  exact
                  strict
                  path="/add/v2/:currencyIdA?/:currencyIdB?"
                  component={RedirectDuplicateTokenIdsV2}
                /> */}
                <Route
                  exact
                  strict
                  path="/trader/:version/:cubeAddress/add/:currencyIdA?/:currencyIdB?/:feeAmount?"
                  component={RedirectDuplicateTokenIds}
                />
                <Route
                  exact
                  strict
                  path="/increase/:version/:cubeAddress/:currencyIdA?/:currencyIdB?/:feeAmount?/:tokenId?"
                  component={AddLiquidity}
                />
                {/* <Route
                exact
                strict
                path="/increase/:currencyIdA?/:currencyIdB?/:feeAmount?/:tokenId?"
                component={AddLiquidity}
              /> */}
                {/* 
              <Route
                exact
                strict
                path="/increase/:currencyIdA?/:currencyIdB?/:feeAmount?/:6509"
                component={AddLiquidity}
              /> */}
                {/* <Route exact strict path="/remove/v2/:currencyIdA/:currencyIdB" component={RemoveLiquidity} /> */}
                {/* <Route exact strict path="/remove/:tokenId" component={RemoveLiquidityV3} /> */}
                <Route exact strict path="/remove/:version/:cubeAddress/:tokenId" component={RemoveLiquidityV3} />

                {/* <Route exact strict path="/create-proposal" component={CreateProposal} /> */}
                {/* <Route exact strict path="/cube/:cubeAddress?" component={SudoCubeDetail} /> */}
                <Route exact strict path="/trader/cube/:version/:cubeAddress" component={SudoCubeDetail} />
                <Route exact strict path="/investor/cube/:version/:cubeAddress" component={SudoCubeDetail} />
                <Route component={RedirectPathToLanding} />
                {/* <Route exact path="/">
                <Redirect to="/#/role" />
              </Route> */}
              </Switch>
              {/* <Marginer /> */}
            </BodyWrapper>
            {/* <Footer /> */}
          </AppWrapper>
        </VersionContext.Provider>
      </Web3ReactManager>
    </ErrorBoundary>
  )
}
