import PositionListItem from 'components/PositionListItem'
import React from 'react'
import { PositionDetails } from 'types/position'

type PositionListProps = React.PropsWithChildren<{
  positions: PositionDetails[]
  cubeAddr: string
  version: string
}>

export default function PositionList({ positions, cubeAddr, version }: PositionListProps) {
  return (
    <>
      {positions.map((p) => {
        return <PositionListItem key={p.tokenId.toString()} cubeAddr={cubeAddr} positionDetails={p} version={version} />
      })}
    </>
  )
}
