import styled from 'styled-components/macro'
import { darken } from 'polished'
import { Trans } from '@lingui/macro'
import { NavLink, Link as HistoryLink, useLocation } from 'react-router-dom'
import { Percent } from '@uniswap/sdk-core'

import { ArrowLeft } from 'react-feather'
import Row, { RowBetween } from '../Row'
import SettingsTab from '../Settings'

import { useAppDispatch } from 'state/hooks'
import { resetMintState } from 'state/mint/actions'
import { resetMintState as resetMintV3State } from 'state/mint/v3/actions'
import { TYPE } from 'theme'
import useTheme from 'hooks/useTheme'
import { ReactNode } from 'react'
import { Box } from 'rebass'
import LeftArrow from './left-arrow.png'

const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: 3rem;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
`

const ToggleTabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  height: 39px;
  width: 167px;
  align-items: center;
  border-radius: 19.5px;
  justify-content: space-evenly;
  margin: auto;
  border: 1px solid ${({ theme }) => theme.bg3};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 137px;
  `};
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  height: 38px;
  width: 184px;
  align-items: center;
  justify-content: center;
  border-radius: 19.5px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 16px;
  padding: 0 10px;

  &.${activeClassName} {
    border-radius: 3rem;
    background-color: ${({ theme }) => theme.primary1};
    font-weight: 500;
    color: ${({ theme }) => theme.bg1};
  }

  :hover,
  :focus {
    //color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

const StyledHistoryLink = styled(HistoryLink)<{ flex: string | undefined }>`
  flex: ${({ flex }) => flex ?? 'none'};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex: none;
    margin-right: 10px;
  `};
`

const ActiveText = styled.div`
  font-size: 20px;
  color: #242424;
  letter-spacing: 0;
  font-weight: 700;
`

const CreateCubeTitle = styled.div`
  font-size: 21px;
  color: #242424;
  letter-spacing: 0;
  font-weight: 700;
  margin: auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 19px;
  `};
`

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.text1};
`

const PageNumber = styled.div`
  margin: auto;
  font-size: 21px;
  color: #868686;
  font-weight: 600;
  padding-right: 10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 18px;
  `};
`

const StyledCreateCubeHistoryLink = styled(HistoryLink)`
  text-decoration: none;
  color: #868686;
  font-weight: bolder;
  font-size: 24px;
  position: absolute;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`
export function SwapPoolTabs({
  active,
  traderAddress,
  version,
}: {
  active: 'swap' | 'pool'
  traderAddress?: string
  version: string
}) {
  return (
    <ToggleTabs>
      <StyledNavLink
        id={`swap-nav-link`}
        to={'/trader/' + version + '/' + traderAddress + '/swap'}
        isActive={() => active === 'swap'}
      >
        <Trans>Swap</Trans>
      </StyledNavLink>
      <StyledNavLink
        id={`pool-nav-link`}
        to={'/trader/' + version + '/' + traderAddress + '/pool'}
        isActive={() => active === 'pool'}
      >
        <Trans>Stake</Trans>
      </StyledNavLink>
    </ToggleTabs>
  )
}

export function FindPoolTabs({ origin }: { origin: string }) {
  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem 1rem 0 1rem', position: 'relative' }}>
        <HistoryLink to={origin}>
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
          <Trans>Import V2 Pool</Trans>
        </ActiveText>
      </RowBetween>
    </Tabs>
  )
}

export function AddRemoveTabs({
  cubeAddress,
  version,
  adding,
  creating,
  defaultSlippage,
  positionID,
  children,
}: {
  cubeAddress: string
  version: string
  adding: boolean
  creating: boolean
  defaultSlippage: Percent
  positionID?: string | undefined
  showBackLink?: boolean
  children?: ReactNode | undefined
}) {
  const theme = useTheme()
  // reset states on back
  const dispatch = useAppDispatch()
  const location = useLocation()

  // detect if back should redirect to v3 or v2 pool page
  const poolLink = location.pathname.includes('add/v2')
    ? '/trader/' + version + '/' + cubeAddress + '/pool/v2'
    : '/trader/' + version + '/' + cubeAddress + '/pool' + (!!positionID ? `/${positionID.toString()}` : '')

  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem 1rem 0 1rem' }}>
        <StyledHistoryLink
          to={poolLink}
          onClick={() => {
            if (adding) {
              // not 100% sure both of these are needed
              dispatch(resetMintState())
              dispatch(resetMintV3State())
            }
          }}
          flex={children ? '1' : undefined}
        >
          <StyledArrowLeft stroke={theme.text2} />
        </StyledHistoryLink>
        <TYPE.mediumHeader
          fontWeight={500}
          fontSize={20}
          style={{ flex: '1', margin: 'auto', textAlign: children ? 'start' : 'center' }}
        >
          {creating ? (
            <Trans>Create a pair</Trans>
          ) : adding ? (
            <Trans>Add Liquidity</Trans>
          ) : (
            <Trans>Remove Liquidity</Trans>
          )}
        </TYPE.mediumHeader>
        <Box style={{ marginRight: '.5rem' }}>{children}</Box>
        <SettingsTab placeholderSlippage={defaultSlippage} />
      </RowBetween>
    </Tabs>
  )
}

export function CreateProposalTabs() {
  return (
    <Tabs>
      <Row style={{ padding: '1rem 1rem 0 1rem' }}>
        <HistoryLink to="/vote">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText style={{ marginLeft: 'auto', marginRight: 'auto' }}>Create Proposal</ActiveText>
      </Row>
    </Tabs>
  )
}
export function CreateCubeTabs({ page }: { page: number }) {
  return (
    <Tabs>
      <Row style={{ padding: '0rem 1rem 0 1rem' }}>
        <StyledCreateCubeHistoryLink to="/trader">
          <img src={LeftArrow} height="24px" />
        </StyledCreateCubeHistoryLink>
        <CreateCubeTitle>Create New Cube</CreateCubeTitle>
      </Row>
      <PageNumber>{page}/3</PageNumber>
      {/* <Row justifyContent="flex-end" style={{ width: 'fit-content', minWidth: 'fit-content' }}>
        <MediumOnly>
          <ButtonText onClick={clearAll} margin="0 15px 0 0">
            <TYPE.blue fontSize="12px">
              <Trans>Clear All</Trans>
            </TYPE.blue>
          </ButtonText>
        </MediumOnly>
      </Row> */}
    </Tabs>
  )
}

export function SudoCubeDetailTabs({ cubeName }: { cubeName: string }) {
  return (
    <Tabs>
      <Row style={{ padding: '0rem 1rem 1rem 1rem' }}>
        <HistoryLink
          to="/investor"
          style={{
            paddingBottom: '50px',
            textDecoration: 'none',
            color: '#989898',
            fontWeight: 600,
            height: '58px',
            fontSize: '21px',
            marginRight: '8px',
          }}
        >
          New Cubes
        </HistoryLink>
        <span
          style={{
            paddingBottom: '50px',
            textDecoration: 'none',
            color: '#989898',
            fontWeight: 600,
            height: '58px',
            fontSize: '21px',
          }}
        >
          {' '}
          {'>'} {cubeName}
        </span>
      </Row>
    </Tabs>
  )
}
