import { useEffect, useState } from 'react'
import axios from 'axios'
import { useAllTokens } from 'hooks/Tokens'
import { ZERO_ADDRESS } from 'constants/misc'
import { isAddress } from 'utils'

export interface ProfileApiType {
  detail: any[]
  token_value_locked_eth: [string, number][]
  total_value_locked_eth: number
  averageProfit: number
  totalProfitRate: number
  totalProfit: number
}

export const LC_ENDPOINT = 'https://www.liquidcube.io/api/v1'

const useFetchProfileData = (address: string) => {
  const [data, setData] = useState<ProfileApiType>()
  const [loading, setLoading] = useState(false)
  const tokens = useAllTokens()

  //const ENDPOINT = 'http://64.71.142.215:8006/api/v1/profit'
  //const testInvestor = '0xB790f3858606c824D2F89531A39962c2404993de'
  //const ENDPOINT = `http://64.71.142.215:8006/api/v1/assets?address=${address}`
  const checksumAddress = isAddress(address)
  const ENDPOINT = LC_ENDPOINT + `/assets?address=${checksumAddress ? checksumAddress : address}`

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const { data: response } = await axios.get(ENDPOINT)
        //console.log(response.data)
        setData({
          token_value_locked_eth: Object.entries(response.data.token_value_locked_eth).map((token) => [
            token[0] === ZERO_ADDRESS
              ? 'ETH'
              : tokens[token[0]].symbol
              ? (tokens[token[0]].symbol as string)
              : 'unknown',
            Number(token[1]),
          ]),
          total_value_locked_eth: Number(response.data.total_value_locked_eth),
          detail: Object.entries(response.data.detail),
          averageProfit: response.data.average_profit,
          totalProfitRate: response.data.total_profit,
          totalProfit: response.data.total_earning_eth,
        })
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
    }
    if (checksumAddress) {
      fetchData()
    }
  }, [ENDPOINT, address])

  return {
    data,
    loading,
  }
}

export function useFetchTraderProfileData(address: string) {
  const [data, setData] = useState<ProfileApiType>()
  const [loading, setLoading] = useState(false)
  const tokens = useAllTokens()

  //const ENDPOINT = 'http://64.71.142.215:8006/api/v1/profit'
  //const testInvestor = '0xB790f3858606c824D2F89531A39962c2404993de'
  //const ENDPOINT = `http://64.71.142.215:8006/api/v1/assets?address=${address}`
  const checksumAddress = isAddress(address)
  const ENDPOINT = LC_ENDPOINT + `/assets?address=${checksumAddress ? checksumAddress : address}&is_trader=True`
  //console.log(ENDPOINT)
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const { data: response } = await axios.get(ENDPOINT)
        //console.log(rescaponse.data)
        setData({
          token_value_locked_eth: Object.entries(response.data.token_value_locked_eth).map((token) => [
            token[0] === ZERO_ADDRESS
              ? 'ETH'
              : tokens[token[0]].symbol
              ? (tokens[token[0]].symbol as string)
              : 'unknown',
            Number(token[1]),
          ]),
          total_value_locked_eth: Number(response.data.total_value_locked_eth),
          detail: Object.entries(response.data.detail),
          averageProfit: response.data.average_profit,
          totalProfitRate: response.data.total_profit,
          totalProfit: response.data.total_earning_eth,
        })
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
    }

    fetchData()
  }, [ENDPOINT, address])

  return {
    data,
    loading,
  }
}

export const useInvestorsData = (cubeAddress: string) => {
  const [data, setData] = useState<string[]>()
  const [loading, setLoading] = useState(false)

  const ENDPOINT = LC_ENDPOINT + `/cube?show_investors=True&address=${cubeAddress}`

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const { data: response } = await axios.get(ENDPOINT)
        //console.log(response.data[0].investors)
        setData(response.data[0].investors)
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
    }
    if (cubeAddress) {
      fetchData()
    }
  }, [ENDPOINT, cubeAddress])

  return {
    data,
    loading,
  }
}

export interface TVLApiType {
  address: string
  sudocube_id: string
  block_timestamp: number
  tvl: number
}

export const useInvestorTVL = (address: string) => {
  const [data, setData] = useState<TVLApiType[]>()
  const [loading, setLoading] = useState(false)

  const lowerCaseAddress = address.toLowerCase()
  const ENDPOINT = LC_ENDPOINT + `/tvl?address=${lowerCaseAddress}&cumulative=True`

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const { data: response } = await axios.get(ENDPOINT)
        //console.log(response.data)
        const saveData = response.data.map((item: TVLApiType) => {
          return { address: item.address, block_timestamp: item.block_timestamp, tvl: item.tvl }
        })
        setData(saveData)
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
    }
    if (lowerCaseAddress) {
      fetchData()
    }
  }, [ENDPOINT, address])

  return {
    data,
    loading,
  }
}

export const useTraderTVL = (address: string) => {
  const [data, setData] = useState<TVLApiType[]>()
  const [loading, setLoading] = useState(false)

  const lowerCaseAddress = address.toLowerCase()
  const ENDPOINT = LC_ENDPOINT + `/tvl?address=${lowerCaseAddress}&is_trader=True`

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const { data: response } = await axios.get(ENDPOINT)
        //console.log(response.data)
        const saveData = response.data.map((item: TVLApiType) => {
          return { address: item.address, block_timestamp: item.block_timestamp, tvl: item.tvl }
        })
        setData(saveData)
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
    }
    if (lowerCaseAddress) {
      fetchData()
    }
  }, [ENDPOINT, address])

  return {
    data,
    loading,
  }
}

export const useTVL = (cumulative: boolean) => {
  const [data, setData] = useState<number>()
  const [loading, setLoading] = useState(false)

  //const lowerCaseAddress = address.toLowerCase()
  const ENDPOINT = LC_ENDPOINT + `/tvl?cumulative=${cumulative ? 'True' : 'False'}`

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const { data: response } = await axios.get(ENDPOINT)
        //console.log(response.data)
        const tvl = response.data[response.data.length - 1].tvl
        setData(tvl)
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
    }

    fetchData()
  }, [ENDPOINT, cumulative])

  return {
    data,
    loading,
  }
}

export const useCubeNumber = () => {
  const [data, setData] = useState<number>()
  const [loading, setLoading] = useState(false)

  //const lowerCaseAddress = address.toLowerCase()
  const ENDPOINT = LC_ENDPOINT + `/events?event_type=CubeCreated`

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const { data: response } = await axios.get(ENDPOINT)
        //console.log(response.data)
        //const tvl = response.data[response.data.length - 1].tvl
        setData(response.data.length)
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
    }

    fetchData()
  }, [ENDPOINT])

  return {
    data,
    loading,
  }
}

export const useWithdrawStatus = (cubeAddress: string, investorAddress: string | undefined | null) => {
  const [data, setData] = useState<number>()
  const [loading, setLoading] = useState(false)

  //const lowerCaseAddress = address.toLowerCase()
  const ENDPOINT =
    LC_ENDPOINT +
    `/events?event_type=InvestorLiquidated&investor_address=${investorAddress}&cube_address=${cubeAddress}`

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const { data: response } = await axios.get(ENDPOINT)
        //const tvl = response.data[response.data.length - 1].tvl
        setData(response.data.length)
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
    }
    if (investorAddress) {
      fetchData()
    }
  }, [ENDPOINT, investorAddress])

  return {
    data,
    loading,
  }
}

export default useFetchProfileData
