//import { Bound, Field } from '../../state/mint/v3/actions'
import AutoColumn from 'components/Column'
import styled from 'styled-components/macro'
import { Currency } from '@uniswap/sdk-core'
// import { Position } from '@uniswap/v3-sdk'
// import { PositionPreview } from 'components/PositionPreview'
import { TYPE } from 'theme'
import { RowFixed } from 'components/Row'
import CurrencyLogo from 'components/CurrencyLogo'
import { Trans } from '@lingui/macro'

const Wrapper = styled.div`
  padding: 12px 5% 0 5%;
  font-size: 16px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 15px;
`};
`

const WhiteListText = styled.div`
  color: #424242;
  font-weight: 600;
  flex-direction: row;
  text-align: right;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    font-size: 14px;
    max-width: 130px;
    //margin-left: 10px;
    //white-space: nowrap;
    //overflow: hidden;
    //text-overflow: ellipsis;
  `};
`
const SameRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
`

export function Preview({
  base,
  tokens,
  totalNumber,
  single,
  profit,
}: {
  base: Currency
  tokens: Currency[] | undefined
  totalNumber: string
  single: string
  profit: string
  period: string
}) {
  return (
    <Wrapper>
      <AutoColumn style={{ marginTop: '0.5rem' }}>
        <SameRow>
          <TYPE.label>
            <Trans> Base Currency:</Trans>
          </TYPE.label>
          <RowFixed>
            <CurrencyLogo size={'22px'} currency={base} />
            &nbsp;{base.symbol}
          </RowFixed>
        </SameRow>
        {/* Second */}
        <SameRow>
          <TYPE.label>
            <Trans>Trading Token Whitelist:</Trans>
          </TYPE.label>
          <RowFixed>
            <AutoColumn>
              <WhiteListText className="whitelistIcon" style={{ marginTop: '-3px' }}>
                {tokens?.map((item, i) => (
                  <span key={`${item?.name}${i}`} style={{ marginRight: '3px', marginLeft: i === 0 ? '0' : '-10px' }}>
                    <CurrencyLogo currency={item as Currency} size={'25px'} />
                  </span>
                ))}
              </WhiteListText>
            </AutoColumn>
          </RowFixed>
        </SameRow>
        {/* third */}
        <SameRow>
          <TYPE.label>
            <Trans>Total Number of Cubelets:</Trans>
          </TYPE.label>
          <RowFixed>{totalNumber}</RowFixed>
        </SameRow>
        <SameRow>
          <TYPE.label>
            <Trans>Single Cubelet Value:</Trans>
          </TYPE.label>
          <RowFixed>
            <CurrencyLogo size={'22px'} currency={base} />
            &nbsp;
            {single}
          </RowFixed>
        </SameRow>
        <SameRow>
          <TYPE.label>
            <Trans>Total Fund To Be Raised:</Trans>
          </TYPE.label>
          <RowFixed>
            <CurrencyLogo size={'22px'} currency={base} />
            &nbsp;
            {parseFloat((Number(single) * Number(totalNumber)).toFixed(12))}
          </RowFixed>
        </SameRow>
        <SameRow>
          <TYPE.label>
            <Trans>Trader Profit Sharing:</Trans>
          </TYPE.label>
          <RowFixed>{profit} %</RowFixed>
        </SameRow>
        {/* <RowBetween style={{ marginBottom: '1.5rem' }}>
          <TYPE.label>
            <Trans>Trading Period:</Trans>
          </TYPE.label>
          <RowFixed>
            <TYPE.label ml="10px" fontSize="18px">
              {period} Days
            </TYPE.label>
          </RowFixed>
        </RowBetween> */}
      </AutoColumn>
    </Wrapper>
  )
}
