import { useEffect, useState } from 'react'

export default function CountdownTimer(endDate: Date) {
  const calculateTimeLeft = () => {
    //const year = new Date().getFullYear()
    const difference = +endDate - +new Date()
    //console.log(difference)
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        D: Math.floor(difference / (1000 * 60 * 60 * 24)),
        H: Math.floor((difference / (1000 * 60 * 60)) % 24),
        M: Math.floor((difference / 1000 / 60) % 60),
        S: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer)
  })

  const timerComponents: JSX.Element[] = []

  Object.keys(timeLeft).forEach((interval) => {
    if (!(timeLeft as any)[interval]) {
      return
    }

    timerComponents.push(
      <span style={{ width: '30px', textAlign: 'left' }}>
        <span style={{ width: '20px' }}>
          <strong>{('0' + (timeLeft as any)[interval]).slice(-2)}</strong>
        </span>
        {''} {interval}
        {interval !== 'S' && ': '}
      </span>
    )
  })

  return timerComponents
}
