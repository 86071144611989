import React from 'react'
import styled from 'styled-components/macro'
import useCopyClipboard from '../../hooks/useCopyClipboard'

import { LinkStyledButton } from '../../theme'
import { CheckCircle, CheckSquare, ExternalLink, Copy } from 'react-feather'
import { Trans } from '@lingui/macro'

const CopyIcon = styled(LinkStyledButton)`
  color: ${({ theme }) => theme.text3};
  flex-shrink: 0;
  display: flex;
  text-decoration: none;
  font-size: 0.825rem;
  :hover,
  :active,
  :focus {
    text-decoration: none;
    color: ${({ theme }) => theme.text2};
  }
`
const TransactionStatusText = styled.span`
  margin-left: 0.25rem;
  font-size: 0.825rem;
  ${({ theme }) => theme.flexRowNoWrap};
  align-items: center;
`

const MobileTransactionStatusText = styled.span`
  margin-left: 0.25rem;
  font-size: 0.825rem;
  ${({ theme }) => theme.flexRowNoWrap};
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};
`

export default function CopyHelper(props: { toCopy: string; children?: React.ReactNode; style?: number }) {
  const [isCopied, setCopied] = useCopyClipboard()

  return (
    <CopyIcon onClick={() => setCopied(props.toCopy)}>
      {isCopied ? (
        <TransactionStatusText>
          {props.style === 1 ? <CheckSquare size={'20'} /> : <CheckCircle size={'20'} />}

          <MobileTransactionStatusText>
            <Trans>{props.style === 1 && 'URL'} Copied</Trans>
          </MobileTransactionStatusText>
        </TransactionStatusText>
      ) : (
        <TransactionStatusText>
          {props.style === 1 ? <ExternalLink size={'22'} /> : <Copy size={'20'} />}
        </TransactionStatusText>
      )}
      {isCopied ? '' : props.children}
    </CopyIcon>
  )
}
