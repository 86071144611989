import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { darken } from 'polished'
import styled from 'styled-components/macro'
import { utils } from 'ethers'
import { TYPE } from 'theme'
import { Currency, CurrencyAmount, Token, WETH9 } from '@uniswap/sdk-core'
import { CreateCubeTabs } from '../../components/NavigationTabs'
import { ButtonPrimary } from 'components/Button'
import { useActiveWeb3React } from 'hooks/web3'
import { Trans } from '@lingui/macro'
import Row from 'components/Row'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { Input as NumericalInput } from '../../components/InputPanel'
import MultipleCurrencyInputPanel from 'components/MultipleCurrencyInputPanel'
import { currencyId } from 'utils/currencyId'
import { AlertCircle, Info } from 'react-feather'
import { LightCard } from '../../components/Card'
// ============== LC ===============
import { Interface } from '@ethersproject/abi'
import LC_PERI_ABI from 'abis/lc-periphery.json'
import { useLCCreateCube } from 'hooks/useLCCreateCube'
import { LC_PERIPHERY_ADDRESS_V1_2 } from 'constants/addresses'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import ReactGA from 'react-ga'
import TransactionConfirmationModal, {
  ConfirmationCreateModalContent,
  TransactionErrorContent,
} from 'components/TransactionConfirmationModal'
import { toHex } from '@uniswap/v3-sdk'
import CurrencyLogo, { getTokenLogoURL } from 'components/CurrencyLogo'
import { useHistory } from 'react-router-dom'
import { Text } from 'rebass'
import { Preview } from './Preview'
import { useCurrency } from 'hooks/Tokens'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import BigCube from 'pages/CreateCube/images/big_cube.svg'
import Calendar from 'pages/CreateCube/images/calendar.svg'
import LeftArrow from 'pages/CreateCube/images/left_arrow.png'
import { ZERO_ADDRESS } from 'constants/misc'
import { useLCFactoryContract } from 'hooks/useContract'
import { useSingleCallResult } from 'state/multicall/hooks'
import { VersionContext } from 'pages/App'
import { MouseoverTooltipContent } from 'components/Tooltip'
import { WrappedTokenInfo } from 'state/lists/wrappedTokenInfo'
import axios from 'axios'
import { LIQUID_CUBE_LIST } from 'constants/lists'
import useHttpLocations from 'hooks/useHttpLocations'
import { SupportedChainId } from 'constants/chains'
// ========= LC ==============
//const LC_INTERFACE: Interface = new Interface(LIQUID_CUBE_ABI)
const LC_PERI_INTERFACE: Interface = new Interface(LC_PERI_ABI)

const OutWrapper = styled.div`
  position: relative;
  padding: 30px 77px 110px;
  max-width: 1257px;
  width: 100%;
  background-color: #ffffff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 35px 40px;
    padding-bottom: 0;
  `};
`
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 26%;
  padding-right: 24%;
  margin: auto;
  max-width: 1050px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 8px 0;
    margin-top: -10px;
  `};
`

const GoBackButton = styled.img`
  text-decoration: none;
  color: #868686;
  font-weight: bolder;
  height: 24px;
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 10px;
    display: block;
    position: absolute;
  `};
`
const StyledRow = styled(Row)`
  justify-content: space-between;
  margin-bottom: 32px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 50px;
  `};
`
const Marketplace = styled.div`
  font-size: 30px;
  font-weight: 700;
  color: #242424;
  margin: -20px auto 0 120px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`
const Input = styled.input`
  max-width: 551px;
  width: 100%;
  height: 50px;
  border: 1px solid #979797;
  border-radius: 11px;
  font-size: 21px;
  text-align: left;
  text-indent: 10px;
  color: black;
  margin-right: 35px;
  font-weight: 600;
  :focus,
  :hover {
  }
  :disabled {
    opacity: 1;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
    height: 45px;
`};
`

const DateInput = styled.button`
  max-width: 551px;
  width: 100%;
  height: 50px;
  border: 1px solid #979797;
  border-radius: 11px;
  font-size: 21px;
  text-align: left;
  text-indent: 10px;
  color: black;
  margin-right: 35px;
  font-weight: 600;
  background-color: white;
  :focus,
  :hover {
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
    height: 45px;
`};
`
const TraderInput = styled.input`
  max-width: 71px;
  width: 100%;
  height: 50px;
  border: 1px solid #979797;
  border-radius: 11px;
  font-size: 21px;
  text-align: center;
  text-indent: 5px;
  color: black;
  margin-right: 11px;
  margin-left: 23px;
  font-weight: 600;
  :focus,
  :hover {
  }
  :disabled {
    opacity: 1;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    width: 58px;
    height: 37px;
`};
`

const BigCubeImg = styled.img`
  margin: auto;
  max-height: 220px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  max-width: 180px;
  //margin-top: -10px;
  margin-bottom: -20px;
`};
`
const StyledInput = styled.input<{ error?: boolean; fontSize?: string; isInvestor?: boolean }>`
  color: ${({ error, theme }) => (error ? theme.red1 : theme.text1)};
  position: relative;
  font-weight: 600;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.bg0};
  font-size: ${({ fontSize }) => fontSize ?? '19px'};
  border: ${({ isInvestor, theme }) => (isInvestor ? 'none' : '1px solid' + theme.bg4)};
  border-radius: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: textfield;
  text-align: left;
  max-width: 547px;
  height: 50px;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.text4};
  }
  :focus,
  :hover {
    border: 1px solid ${({ theme }) => theme.bg3};
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 45px;
    font-size: 18px;
`};
`

const PlatformButton = styled(ButtonPrimary)`
  width: 102px;
  height: 44px;
  border-radius: 9px;
  font-size: 18px;
  font-weight: 600;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 112px;
    height: 40px;
    font-size: 16px;
  `};
`

const MobileHide = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};
`

const DesktopHide = styled.span`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: block;
`};
`

const TitleText = styled.div`
  font-size: 18px;
  color: #000000;
  letter-spacing: 0;
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    //display: none;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 10px;
  `};
`

const Suffix = styled.div`
  position: absolute;
  top: 14px;
  right: 13%;
  font-size: 18px;
  font-weight: 600;
  color: #898888;
  pointer-events: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    //display: none;
    top: 11px;
    right: 10%;
    font-size: 16px;
  `};
`
const DatePickerStyled = styled(DatePicker)`
  font-size: 21px;
  width: 100%;
  max-width: 551px;
  height: 50px;
  border-radius: 11px;
  text-indent: 15px;
  font-weight: 600;
  border: ${({ theme }) => '1px solid' + theme.bg4};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
    white-space: nowrap;
  `};
`

const NoteText = styled.div`
  font-size: 15px;
  margin-top: 10px;
  color: #4d4d4d;
  max-width: 551px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
`

const CreateButton = styled.button`
  background-color: #facc66;
  height: 52px;
  width: 170px;
  border-radius: 23px;
  border: none;
  font-size: 21px;
  font-weight: 600;
  cursor: pointer;
  margin-left: auto;
  color: black;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary6)};
    background-color: ${({ theme }) => darken(0.05, theme.primary6)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.primary6)};
  }
  &:disabled {
    background-color: ${({ theme, disabled }) => (disabled ? theme.bg2 : '#facc66')};
    color: ${({ theme }) => theme.text2};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    width: 110px;
    height: 43px;
    font-size: 16px;
    border-radius: 23px;
  `};
`

const NextButton = styled.button`
  background-color: #facc66;
  height: 52px;
  width: 170px;

  border-radius: 23px;
  border: none;
  font-size: 21px;
  font-weight: 700;
  cursor: pointer;
  margin: 70px auto 100px auto;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary6)};
    background-color: ${({ theme }) => darken(0.05, theme.primary6)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.primary6)};
  }
  color: black;
  &:disabled {
    background-color: ${({ theme, disabled }) => (disabled ? theme.bg2 : '#facc66')};
    color: ${({ theme }) => theme.text2};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 110px;
    height: 43px;
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 10px;
`};
`

const BackButton = styled.button`
  background-color: white;
  height: 52px;
  width: 170px;
  border-radius: 23px;
  border: 2px solid #facc66;
  font-size: 21px;
  font-weight: 700;
  cursor: pointer;
  margin-right: auto;
  color: black;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary6)};
    background-color: ${({ theme }) => darken(0.05, theme.primary6)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.primary6)};
  }
  &:disabled {
    background-color: ${({ theme, disabled }) => (disabled ? theme.bg2 : '#facc66')};
    color: ${({ theme }) => theme.text2};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 110px;
    height: 43px;
    font-size: 16px;
  `};
`

const StyledCurrencyLogo = styled(CurrencyLogo)`
  position: absolute;
  left: 13px;
  bottom: 10px;
  z-index: 90000;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  left: 13px;
  bottom: 8px;
  `};
`

const StyledInfo = styled(Info)`
  opacity: 0.4;
  color: black;
  height: 18px;
  width: 18px;
  :hover {
    opacity: 0.8;
  }
  margin-bottom: -3px;
`

interface TokenType {
  chainId: number
  decimals: number
  address: string
  name: string
  symbol: string
  logoURI?: string
}

const CreateCubeButton = ({
  isFormInvalid,
  handleCreateCube,
}: {
  proposalThreshold?: CurrencyAmount<Token>

  isFormInvalid: boolean
  handleCreateCube: () => void
}) => {
  return (
    <CreateButton disabled={isFormInvalid} onClick={handleCreateCube}>
      <Trans>Create</Trans>
    </CreateButton>
  )
}

export default function CreateCube() {
  const { account, chainId, library } = useActiveWeb3React()
  const history = useHistory()
  const { currentVersion, switchVersion } = useContext(VersionContext)

  useEffect(() => {
    switchVersion('1.2')
  }, [currentVersion, switchVersion])

  const defaultCurrency = useCurrency('ETH')
  const [hash, setHash] = useState<string | undefined>()
  const [currencyValue, setCurrencyValue] = useState<Currency>(defaultCurrency as Currency)
  const [amountValue, setAmountValue] = useState('')
  const [minute, setMinute] = useState('')

  //=============LC====================
  const [numOfNFT, setNumOfNFT] = useState('')
  const [singleValue, setSingleValue] = useState('')
  const [tradingPeriod, setTradingPeriod] = useState('')
  const [supportedTokens, setSupportTokens] = useState<Currency[]>([defaultCurrency as Currency])
  const [cubeName, setCubeName] = useState<string>('')
  const [traderProfit, setTraderProfit] = useState('')
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  const [startDate, setStartDate] = useState(tomorrow)
  const [isDesktopFirstPage, setIsDesktopFirstPage] = useState<boolean>(true)
  const [pageNumber, setPageNumber] = useState<number>(1)

  const [traderSplitCap, setTraderSplitCap] = useState(30)
  const factory = useLCFactoryContract()
  const traderCutFromContract = Number(useSingleCallResult(factory, 'MAX_TRADER_CUT').result?.toString())

  useEffect(() => {
    if (traderCutFromContract) {
      setTraderSplitCap(traderCutFromContract)
    }
  }, [traderCutFromContract])

  const handleColor = (time: Date) => {
    return time.getHours() > 12 ? 'text-success' : 'text-error'
  }

  const ReadonlyInput = ({ value, onClick }: any) => <DateInput onClick={onClick}>{value}</DateInput>
  //===============Error===================
  const [showError, setShowError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>()
  const [showNumOfNFTWarning, setShowNumOfNFTWarning] = useState<boolean>(false)
  const [showTradingPeriodWarning, setShowTradingPeriodWarning] = useState<boolean>(false)

  const addTransaction = useTransactionAdder()

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      setCurrencyValue(currency)
    },
    [setCurrencyValue]
  )

  function getLogoURI(currency: Currency) {
    const uriLocations = currency instanceof WrappedTokenInfo ? currency.logoURI : undefined
    const defaultUrls = currency.chainId === 1 && currency.isToken ? getTokenLogoURL(currency.address) : ''
    //console.log(uriLocations, defaultUrls)

    // if (currency.isToken) {
    //   const defaultUrls = currency.chainId === 1 ? [getTokenLogoURL(currency.address)] : []
    //   if (currency instanceof WrappedTokenInfo) {
    //     return [...uriLocations, ...defaultUrls]
    //   }
    //   return defaultUrls
    // }
    // return []
    return uriLocations ? uriLocations : defaultUrls
  }
  // console.log(getLogoURI(currencyValue))
  //getLogoURI(currencyValue)
  //console.log(currencyValue instanceof WrappedTokenInfo ? currencyValue.logoURI : 'no')

  const handleCurrenciesSelect = useCallback(
    (currency: Currency[]) => {
      setSupportTokens(currency)
    },
    [setSupportTokens]
  )

  const handleProfitInput = useCallback(
    (number: string) => {
      setTraderProfit(number)
    },
    [setTraderProfit]
  )

  const handleAmountInput = useCallback(
    (amount: string) => {
      setAmountValue(amount)
    },
    [setAmountValue]
  )

  const handleNumOfNFTInput = useCallback(
    (number: string) => {
      setNumOfNFT(number)
    },
    [setNumOfNFT]
  )

  const handleSingleValueInput = useCallback(
    (number: string) => {
      setSingleValue(number)
    },
    [setSingleValue]
  )

  const handleTradingPeriodInput = useCallback(
    (number: string) => {
      setTradingPeriod(number)
    },
    [setTradingPeriod]
  )

  const handleMinutePeriodInput = useCallback(
    (number: string) => {
      setMinute(number)
    },
    [setMinute]
  )

  const handleCubeNameInput = useCallback(
    (name: string) => {
      setCubeName(name)
    },
    [setCubeName]
  )

  useEffect(() => {
    if (Number(traderProfit) > 100) {
      setTraderProfit('100')
    } else if (Number(traderProfit) > traderSplitCap) {
      setTraderProfit(traderSplitCap.toString())
    }
  }, [traderProfit, traderSplitCap])

  useEffect(() => {
    if (Number(numOfNFT) > 100) {
      setShowNumOfNFTWarning(true)
      setNumOfNFT('100')
    }
    setTimeout(function () {
      setShowNumOfNFTWarning(false)
    }, 3000)
  }, [numOfNFT])

  useEffect(() => {
    if (86400 * Number(tradingPeriod) + 60 * Number(minute) > 180 * 86400) {
      setShowTradingPeriodWarning(true)
      setMinute('0')
      setTradingPeriod('180')
    }
    setTimeout(function () {
      setShowTradingPeriodWarning(false)
    }, 3000)
  }, [tradingPeriod, minute])

  useEffect(() => {
    setSupportTokens([currencyValue])
  }, [currencyValue, handleCurrencySelect])

  const isFormInvalid = useMemo(
    () =>
      Boolean(
        singleValue === '' ||
          singleValue === '0' ||
          numOfNFT === '' ||
          numOfNFT === '0' ||
          traderProfit === '' ||
          traderProfit === '0' ||
          (tradingPeriod === '' && minute === '') ||
          cubeName === '' ||
          supportedTokens?.length < 2 ||
          !supportedTokens
      ),
    [singleValue, numOfNFT, traderProfit, tradingPeriod, minute, cubeName, supportedTokens]
  )

  const firstPageInvalid = useMemo(
    () => Boolean(cubeName === '' || new Blob([cubeName]).size > 31 || supportedTokens?.length < 2 || !supportedTokens),
    [cubeName, supportedTokens]
  )

  const secondPageInvalid = useMemo(
    () =>
      Boolean(
        singleValue === '' ||
          singleValue === '0' ||
          numOfNFT === '' ||
          Number(singleValue) < 0.01 ||
          numOfNFT === '0' ||
          traderProfit === '' ||
          traderProfit === '0'
      ),
    [numOfNFT, singleValue, traderProfit]
  )

  useEffect(() => {
    setPageNumber(1)
    setSingleValue('')
    setNumOfNFT('')
    setTradingPeriod('')
    setSupportTokens([currencyValue])
    setTraderProfit('')
    //setCubeName('')
  }, [chainId, currencyValue])

  const body: TokenType[] = supportedTokens
    .filter((token) => !token.isNative)
    .map((token) => {
      return {
        chainId: token.chainId,
        address: token.isNative ? '' : token.address,
        name: token.name ? token.name : 'Unknown Name',
        symbol: token.symbol ? token.symbol : 'Unknown Symbol',
        decimals: token.decimals,
        logoURI: getLogoURI(token),
      }
    })

  function postToken() {
    axios
      .post(LIQUID_CUBE_LIST, body)
      .then((response) => console.log('Tokens posted', response))
      .catch((err) => console.log('Post failed', err))
  }

  async function onCreate() {
    if (!chainId || !library) return
    if (chainId === 1 && account) {
      postToken()
    }

    const tokenAddresses = supportedTokens
      ? supportedTokens.map((token) => (currencyId(token) === 'ETH' ? currencyId(WETH9[chainId]) : currencyId(token)))
      : []

    //console.log(tokenAddresses.map((item) => toHex(item)))

    if (account) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const calldata: string[] = useLCCreateCube(
        account,
        LC_PERI_INTERFACE,
        cubeName,
        currencyId(currencyValue) === 'ETH' ? ZERO_ADDRESS : toHex(currencyId(currencyValue)),
        numOfNFT,
        currencyValue.decimals === 18
          ? utils.parseEther(singleValue)._hex
          : (Number(singleValue) * 10 ** currencyValue.decimals).toString(),
        traderProfit,
        (86400 * Number(tradingPeriod) + 60 * Number(minute)).toFixed(0),
        tokenAddresses,
        parseInt((startDate.getTime() / 1000).toFixed(0)).toString()
      )

      console.log(`call data: ${calldata[0]}`)

      setAttemptingTxn(true)

      const txn: { to: string; data: string; value: string } = {
        to: LC_PERIPHERY_ADDRESS_V1_2[chainId],
        data: calldata[0],
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              // debug
              //console.log(`response: ${response}`)
              setAttemptingTxn(false)
              addTransaction(response, {
                summary: 'Create a cube with the base of ' + currencyValue?.symbol,
              })
              setHash(response.hash)
              ReactGA.event({
                category: 'Cube',
                action: 'Create',
                label: currencyValue.symbol ?? '',
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          setAttemptingTxn(false)
          // we only care if the error is something _other_ than the user rejected the tx
          //console.error(error.message)
          setShowError(true)
          setErrorMsg(
            chainId === SupportedChainId.ARBITRUM_ONE || SupportedChainId.ARBITRUM_RINKEBY
              ? error.data.message
              : error.message
          )
        })
    } else {
      return
    }
  }

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    //setAttemptingTxn(false)
    // if there was a tx hash, we want to clear the input
    if (hash) {
      setSingleValue('')
      setNumOfNFT('')
      setTradingPeriod('')
      setSupportTokens([])
      setTraderProfit('')
      history.goBack()
    }
    setHash(undefined)
  }, [hash, history])

  return (
    <OutWrapper>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={hash}
        content={() =>
          showError ? (
            <TransactionErrorContent
              onDismiss={() => {
                setShowError(false)
                handleDismissConfirmation()
              }}
              message={errorMsg}
            />
          ) : (
            <ConfirmationCreateModalContent
              title={`Creating a cube`}
              onDismiss={handleDismissConfirmation}
              topContent={() => (
                <Preview
                  base={currencyValue}
                  tokens={supportedTokens}
                  totalNumber={numOfNFT}
                  single={singleValue}
                  profit={traderProfit}
                  period={tradingPeriod}
                />
              )}
              bottomContent={() => (
                <>
                  <TYPE.italic fontSize={12} textAlign="left" padding={'2px 0 0 1rem'}>
                    {`Gas fee will apply to trader.`}
                  </TYPE.italic>
                  <ButtonPrimary style={{ marginTop: '0.5rem' }} onClick={onCreate}>
                    <Text fontWeight={500} fontSize={19}>
                      <Trans>Confirm Create</Trans>
                    </Text>
                  </ButtonPrimary>
                </>
              )}
            />
          )
        }
        pendingText={''}
      />
      <StyledRow>
        <Marketplace>
          A DeFi Investment <br />
          Opportunity Marketplace
        </Marketplace>
        <GoBackButton
          src={LeftArrow}
          onClick={() => (isDesktopFirstPage ? history.push('/trader') : setIsDesktopFirstPage(true))}
        />
        <BigCubeImg src={BigCube} alt={'big-cube'} />
      </StyledRow>
      <CreateCubeTabs page={pageNumber} />
      {pageNumber === 1 ? (
        <BodyWrapper>
          <TitleText>Cube Name</TitleText>
          <StyledInput value={cubeName} onChange={(e) => handleCubeNameInput(e.target.value)} />
          {new Blob([cubeName]).size > 31 && (
            <span style={{ fontSize: '15px', color: '#c25742' }}>Cube name is too long!</span>
          )}
          <TitleText>Platform</TitleText>
          <PlatformButton>Uniswap</PlatformButton>
          <TitleText>Base Currency</TitleText>
          <CurrencyInputPanel
            isCreateCube={true}
            value={amountValue}
            currency={currencyValue}
            onUserInput={(amountValue: string) => handleAmountInput(amountValue)}
            onCurrencySelect={(currency: Currency) => handleCurrencySelect(currency)}
            showMaxButton={false}
            showCommonBases={true}
            showCurrencyAmount={false}
            disableNonToken={false}
            hideBalance={true}
            hideInput={true}
            id="currency-input"
          />
          <TitleText>Trading Token Whitelist</TitleText>
          <MultipleCurrencyInputPanel
            currencies={supportedTokens}
            onCurrencySelect={(currency: Currency[]) => handleCurrenciesSelect(currency)}
            otherCurrency={currencyValue}
            showCommonBases={true}
            showCurrencyAmount={false}
            disableNonToken={false}
            hideInput={true}
            id="currency-input"
          />
          {supportedTokens.length < 2 && (
            <span style={{ fontSize: '15px', color: '#c25742' }}>Please select two or more tokens</span>
          )}

          <NextButton disabled={firstPageInvalid} onClick={() => setPageNumber(2)}>
            Next
          </NextButton>
        </BodyWrapper>
      ) : // Page 2
      pageNumber === 2 ? (
        <BodyWrapper>
          <LightCard padding="10px" marginTop="10px">
            <div
              style={{
                fontSize: '14px',
                fontWeight: 500,
                maxWidth: '800px',
                color: '#565A69',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <MobileHide>
                <AlertCircle style={{ marginRight: '12px', width: '20px' }} />
              </MobileHide>

              <div>
                <strong>Note: </strong>10 is the suggested cubelet number. Higher number will result in paying more gas
                fee as investors when purchasing cubelets
              </div>
            </div>
          </LightCard>
          <TitleText>Total Number of Cubelets </TitleText>

          <NumericalInput
            className="NFT-number-input"
            value={numOfNFT}
            align={'left'}
            placeholder={'0'}
            onUserInput={(val) => {
              ;/^\d+$/.test(val) ? handleNumOfNFTInput(val) : handleNumOfNFTInput('')
            }}
          />
          {showNumOfNFTWarning && (
            <span style={{ fontSize: '15px', color: '#c25742' }}>
              You can only create a cube with a maximum of 100 cubelets.
            </span>
          )}

          {/* <span style={{ fontSize: '15px', color: '#c25742' }}>
            Note: 10 is the suggested cubelet number. Higher number will result in paying more gas fee as investors when
            purchasing cubelets
          </span> */}

          <TitleText>Single Cubelet value</TitleText>
          <div style={{ display: 'inline-block', position: 'relative' }}>
            <StyledCurrencyLogo currency={currencyValue} size={'30px'} />

            <NumericalInput
              className="single-value"
              value={singleValue}
              style={{ textIndent: '45px' }}
              align={'left'}
              onUserInput={(val) => {
                handleSingleValueInput(val)
              }}
            />
          </div>
          {Number(singleValue) !== 0 && Number(singleValue) < 0.01 && (
            <NoteText style={{ color: '#c25742', marginTop: '0' }}>
              The single cubelet value needs to be larger than 0.01.
            </NoteText>
          )}

          <TitleText>Total Fund To Be Raised</TitleText>
          <div style={{ display: 'inline-block', position: 'relative' }}>
            <StyledCurrencyLogo currency={currencyValue} size={'30px'} />
            <Input
              className="total-value-input"
              style={{ textIndent: '45px' }}
              value={singleValue && numOfNFT ? parseFloat((Number(singleValue) * Number(numOfNFT)).toFixed(12)) : ''}
              disabled={true}
              placeholder={'0.0'}
            />
          </div>
          <Row style={{ marginTop: '50px', marginBottom: '20px', maxWidth: '551px' }} justify="space-between">
            <TitleText style={{ whiteSpace: 'nowrap', margin: '0' }}>
              Trading Profit Sharing
              <MouseoverTooltipContent
                content={
                  <span>
                    {currentVersion === '1.0'
                      ? 'Platform will share 2% of the total profit'
                      : "Platform will share 2% of the total profit. The platform share will be deducted from trader's profit share. This applies when there's profit in cube"}
                  </span>
                }
              >
                <StyledInfo />
              </MouseoverTooltipContent>
            </TitleText>
            <MobileHide>
              Trader
              <TraderInput
                className="total-value-input"
                value={traderProfit}
                type="number"
                onWheel={(e) => (e.target as HTMLElement).blur()}
                onChange={(val) => {
                  Number(val.target.value) < 0 ? (val.target.value = '') : handleProfitInput(val.target.value)
                }}
                placeholder={'0.0'}
              />
              %
            </MobileHide>
          </Row>
          <MobileHide>
            <Row style={{ maxWidth: '551px' }} justify="space-between">
              <TitleText style={{ whiteSpace: 'nowrap', margin: '0' }} />
              <div style={{ marginLeft: 'auto', maxWidth: '440px' }}>
                Investor
                <TraderInput
                  disabled={true}
                  style={{ border: 'none', backgroundColor: 'white' }}
                  className="total-value-input"
                  value={traderProfit ? 100 - Number(traderProfit) : ''}
                  placeholder={''}
                />
                %
              </div>
            </Row>
          </MobileHide>
          <DesktopHide style={{ marginBottom: '10px' }}>
            <Row>
              <div style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
                Trader
                <TraderInput
                  className="total-value-input"
                  value={traderProfit}
                  type="number"
                  onWheel={(e) => (e.target as HTMLElement).blur()}
                  inputMode="decimal"
                  onChange={(val) => {
                    Number(val.target.value) < 0 ? (val.target.value = '') : handleProfitInput(val.target.value)
                  }}
                  placeholder={''}
                />
                % <span style={{ color: '#979797' }}>|</span>
              </div>
              <div style={{ whiteSpace: 'nowrap' }}>
                Investor
                <TraderInput
                  disabled={true}
                  style={{ border: 'none', width: '40px', backgroundColor: 'white' }}
                  className="total-value-input"
                  value={traderProfit ? 100 - Number(traderProfit) : ''}
                  placeholder={''}
                />
                %
              </div>
            </Row>
          </DesktopHide>
          {traderProfit === '0' && (
            <NoteText style={{ color: '#c25742', marginTop: '10px' }}>
              Trader&apos;s profit sharing must be greater than 0
            </NoteText>
          )}
          <TYPE.italic fontSize={14} textAlign="left" padding={'2px 0 0 0'}>
            {`Gas fee will apply to trader.`}
          </TYPE.italic>
          <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '551px', marginTop: '5%' }}>
            <BackButton onClick={() => setPageNumber(1)}>Back</BackButton>
            <NextButton disabled={secondPageInvalid} style={{ margin: '0' }} onClick={() => setPageNumber(3)}>
              Next
            </NextButton>
          </div>
        </BodyWrapper>
      ) : (
        <BodyWrapper>
          <TitleText>Trading Period</TitleText>
          <div style={{ display: 'inline-block', position: 'relative' }}>
            <NumericalInput
              className="trading-days"
              value={tradingPeriod}
              style={{ marginBottom: '10px' }}
              align={'left'}
              placeholder={' '}
              onUserInput={(val) => {
                ;/^\d+$/.test(val) ? handleTradingPeriodInput(val) : handleTradingPeriodInput('')
              }}
            />
            <Suffix>Days</Suffix>
          </div>
          <div style={{ display: 'inline-block', position: 'relative' }}>
            <NumericalInput
              className="trading-minutes"
              value={minute}
              align={'left'}
              placeholder={' '}
              onUserInput={(val) => {
                ;/^\d+$/.test(val) ? handleMinutePeriodInput(val) : handleMinutePeriodInput('')
              }}
            />
            <Suffix>Minutes</Suffix>
          </div>
          {showTradingPeriodWarning && (
            <span style={{ fontSize: '15px', color: '#c25742' }}>The max trading period is 180 days.</span>
          )}

          <TitleText>Trading Starts: *</TitleText>
          <div style={{ display: 'inline-block', position: 'relative' }}>
            <DatePickerStyled
              selected={startDate}
              onChange={(date) => setStartDate(date as Date)}
              timeClassName={handleColor}
              minDate={new Date()}
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy h:mm a"
              showTimeInput
              customInput={<ReadonlyInput />}
            />
            <div style={{ marginTop: '3px' }}>
              Time Zone:
              {startDate.toLocaleDateString('en-US', { timeZoneName: 'short' }).split(',')[1]} (
              {Intl.DateTimeFormat().resolvedOptions().timeZone} Time)
            </div>
            <div></div>

            <Suffix>
              <img src={Calendar} alt="little-calendar" />
            </Suffix>
          </div>
          <NoteText>
            *This time is an estimation. If the cube has not raised the target amount of fund by this time, the cube
            will be invalidated and the fund raised will be withdrawable for investors.
          </NoteText>
          <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '551px', marginTop: '10%' }}>
            <BackButton onClick={() => setPageNumber(2)}>Back</BackButton>
            <CreateCubeButton isFormInvalid={isFormInvalid} handleCreateCube={() => setShowConfirm(true)} />
          </div>
        </BodyWrapper>
      )}
    </OutWrapper>
  )
}
