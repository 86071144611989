import React, { useContext, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components/macro'
import { utils } from 'ethers'
import Modal from 'components/Modal'
import { Currency, WETH9 } from '@uniswap/sdk-core'
import { AutoColumn } from 'components/Column'
import { useActiveWeb3React } from 'hooks/web3'
import Row from 'components/Row'
// ============== LC ===============
import { Interface } from '@ethersproject/abi'
import LC_PERI_ABI from 'abis/lc-periphery.json'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import ReactGA from 'react-ga'
import { ConfirmationModalContent, TransactionErrorContent } from 'components/TransactionConfirmationModal'
import { BigNumber } from '@ethersproject/bignumber'
import { RouteComponentProps } from 'react-router-dom'
import { useAllTokens, useCurrency } from 'hooks/Tokens'
import { MouseoverTooltipContent } from 'components/Tooltip'
import { AlertCircle } from 'react-feather'
import CubeRed from '../../assets/images/cube_red.png'
import CubeGreen from '../../assets/images/cube_green.png'
import { encodeRouteToPath, FeeAmount, Position, Route, toHex } from '@uniswap/v3-sdk'
import { ButtonPrimary } from 'components/Button'
import { useCubeEndTime, useCubeState } from 'hooks/useCubeState'
import {
  LC_CUBESWAPPING_LOGIC,
  LC_CUBESWAPPING_LOGIC_V1_1,
  LC_CUBESWAPPING_LOGIC_V1_2,
  LC_PERIPHERY_ADDRESS,
  LC_PERIPHERY_ADDRESS_V1_1,
  LC_PERIPHERY_ADDRESS_V1_2,
  SWAP_ROUTER_ADDRESSES,
} from 'constants/addresses'
import { useCurrencyBalances } from 'state/wallet/hooks'
import Chart from 'react-google-charts'
import { useBestV3TradeExactIn } from 'hooks/useBestV3Trade'
import { tryParseAmount } from 'state/swap/hooks'
import { useV3Positions } from 'hooks/useV3Positions'
import { Dots } from '../Pool/styleds'
import { usePools } from 'hooks/usePools'
import LeftArrow from '../../components/NavigationTabs/left-arrow.png'
import CountdownTimer from 'components/CountdownTimer'
import { SupportedChainId } from 'constants/chains'
import { DesktopHide, MobileHide } from 'theme'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { useV3PositionFees } from 'hooks/useV3PositionFees'
import CubeInfoDetail from 'components/CubeInfoDetail'
import { useCubeInfoByAddresses } from 'hooks/useCubeInfo'
import { useApproveCallback } from 'hooks/useCubeApproveCallback'
import { MaxUint256 } from '@ethersproject/constants'
import useUSDCPrice from 'hooks/useUSDCPrice'
import { VersionContext } from 'pages/App'
import CopyHelper from 'components/AccountDetails/Copy'
import numeral from 'numeral'
import EarlyLiquidate from 'components/ShowEarlyLiquidate'
import { useInvestorsData, useWithdrawStatus } from 'hooks/useProfileFromAPI'
import { shortenAddress } from 'utils'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'
// import { IconButton } from '@mui/material'

const LC_PERI_INTERFACE: Interface = new Interface(LC_PERI_ABI)

const OutWrapper = styled.div`
  padding: 50px 50px 150px 50px;
  max-width: 1057px;
  width: 100%;
  background-color: #ffffff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 25px;
  `};
`

const TitleRow = styled(Row)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin: auto;
  flex-direction: column;
  `};
`

const StyledAutoColumn = styled(AutoColumn)`
  gap: 18px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  gap: 15px;
  margin-top: 0;
  margin-bottom: 0;
  `};
`
const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: 3rem;
  justify-content: space-evenly;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`
const TextTitle = styled.div`
  font-size: 16px;
  color: #424242;
  font-weight: 400;
  text-align: left;
  width: 200.06px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    font-size: 14px;
    color: #424242;
    letter-spacing: 0;
    font-weight: 600;
  `};
`

const CubeName = styled.div`
  font-size: 23px;
  color: #1d5398;
  font-weight: 700;
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  .versionTag {
    font-size: 17px;
    //line-height: 20px;
    margin: 7px 0 0 5px;
    //padding-bottom: 15px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-left: 24px;
    font-size: 22px;
    margin: auto;
    max-width: 80%;
    .versionTag {
      margin: 5px 0 0 5px;
    }
  `};
`

const CubeAddress = styled.div`
  font-size: 15px;
  color: #424242;
  font-weight: 400;
  //margin-left: 13px;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 15px;
    margin: 10px auto 10px auto;
    color: #424242;
    letter-spacing: 0;
    font-weight: 400;
  `};
`
const SameRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0 10px 0;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 5px;
    flex-direction: column;
  `};
`
const InfoTitle = styled.div`
  font-size: 19px;
  font-weight: 700;
  color: #424242;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    justify-content: initial;
    flex-direction: row;
    font-size: 16px;
    color: #1D5398;
    gap: 16%;
  `};
`

const CubeInfoDesktopWrapper = styled.div`
  padding: 0 42px;
  display: flex;
  width: 100%;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    //display: none;
    padding-left: 10px;
    padding-right: 10px;
  `};
`

const NavButton = styled.span`
  padding-bottom: 20px;
  text-decoration: none;
  color: #989898;
  font-weight: 600;
  font-size: 17px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
`};
`
const MobileNav = styled.img`
  position: absolute;
  height: 25px;
  width: 25px;
  display: none;
  margin-top: 3px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: inline;
`};
`

const BorderLine = styled.div`
  padding: 10px 60px;
  width: 100%;
  margin: auto;
  border-bottom: 1px solid #979797;
  margin-bottom: 40px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 20px;
    padding-top: 0;
`};
`

const GreenLabel = styled.div`
  font-size: 13px;
  color: #4fbb26;
  font-weight: 700;
  border: 2px solid #4fbb26;
  margin-top: 5px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  width: 140px;
  height: 32px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 120px;
    border: 1px solid #4fbb26;
    height: 20px;
    font-size: 11px;
    margin: 0;
    margin-top: 2px;
    line-height: 8px;
    border-radius: 7px;
`};
`

const RedLabel = styled(GreenLabel)`
  color: #d64b4b;
  border: 2px solid #d64b4b;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 99px;
    border: 1px solid #d64b4b;
    margin-top: 3px;
`};
`

const CubeIcon = styled.img`
  height: 40px;
  //margin-top: 5px 7px 0 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  height: 40px;
  display: none;
`};
`

const RangeLineItem = styled.div`
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  font-size: 14px;
  color: #868686;
  gap: 5px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-radius: 12px;
    gap: 8px;
    align-items: left;
    margin: auto;
    //padding: 8px 0;
`};
`

const CubletContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  max-width: 73%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    float: right;
    width: 52%;
    margin-top: -20px;
    align-items: left;
`};
`

const OwnedCubelet = styled.div`
  width: 33px;
  height: 33px;
  background-color: #8ad76d;
  font-size: 17px;
  border: 1px solid white;
  color: white;
  font-weight: 400;

  text-align: center;
  vertical-align: middle;
  line-height: 33px;
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0 2.5px;
    width: 25px;
    height: 25px;
    font-size: 14px;
    line-height: 25px;
`};
`

const StyledButton = styled(ButtonPrimary)`
  font-weight: 700;
  font-size: 20px;
  margin: -110px auto 0 auto;
  width: 16%;
  height: 50px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width:30%;
    margin-top: 20px;
    height: 45px;
    font-size: 16px;
    margin-bottom: 10px;
`};
`

const TradeButton = styled(StyledButton)`
  margin: 0;
  width: 110px;
  height: 40px;
  margin-top: 3px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 28%;
    margin: auto;
    margin: 20px auto 10px auto;
    height: 35px;
    font-size: 16px;
`};
`

const PieChartCenterText = styled.div`
  position: absolute;
  z-index: 9999;
  width: 330px;
  bottom: 290px;
  top: 120px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  font-style: italic;
  color: #424242;
  .numberClass {
    font-size: 34px;
    color: black;
    font-style: normal;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 14px;
  width: 350px;
  top: 50px;
  left: -34px;
  bottom: 0;
  .numberClass {
    font-size: 22px;
    color: black;
    font-wight: 700;
    font-style: normal;
  }
`};
`

const EstimateEarning = styled.div`
  margin-top: 45px;
  margin-left: -200px;
  z-index: 1;
  color: #424242;
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  right: 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    font-size: 15px;
    margin: auto;
    margin-left: -50px;
    position: relative;
    text-align: center;
    z-index: 99999;
    margin-top: -40px;
`};
`

const FinalProfit = styled.div`
  margin: auto;
  color: #424242;
  font-weight: 400;
  margin-top: 20px;
  font-size: 17px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
`};
`

const WaitingToLiquidateText = styled.div`
  margin: auto;
  position: relative;
  color: #c25742;
  font-size: 16px;
  text-align: center;
  margin-top: -110px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 10px;
    font-size: 14px;
`};
`

const Alert = styled(AlertCircle)`
  width: 80%;
  color: #c25742;
  margin-bottom: -6px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 70%;
`};
`

const loadingAnimation = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

const LoadingRows = styled.div`
  display: grid;
  max-width: 80px;
  //margin-top: 10px;
  grid-column-gap: 0.5em;
  grid-row-gap: 0.8em;
  //grid-template-columns: repeat(3, 1fr);
  margin: auto;
  & > span {
    animation: ${loadingAnimation} 1.5s infinite;
    animation-fill-mode: both;
    background: linear-gradient(
      to left,
      ${({ theme }) => theme.bg1} 25%,
      ${({ theme }) => theme.bg2} 50%,
      ${({ theme }) => theme.bg1} 75%
    );
    background-size: 400%;
    border-radius: 12px;
    height: 2.4em;
    will-change: background-position;
  }
  & > div:nth-child(4n + 1) {
    grid-column: 1 / 3;
  }
  & > div:nth-child(4n) {
    grid-column: 3 / 4;
    margin-bottom: 2em;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-row-gap: 0.1em;
    //min-width: 150;
    //margin-top: -40px;
    //max-height: 20px;
`};
`

const PopupTitle = styled.div`
  font-weight: 700;
  height: 2rem;
  padding-left: 0.5rem;
  //border-bottom: 1px solid #868686;
`

const InvestorWrapper = styled.div`
  border-top: 1px solid #979797;
  margin: 0.5rem;
  padding-top: 1rem;
`

const InvestorItem = styled.div`
  padding: 1rem 0 1rem 0;
  font-size: 16px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 14px;
`};
  // :hover {
  //   cursor: pointer;
  // }
`

const ShowInvestorButton = styled.span`
  color: #1d5398;
  border: 1px solid #1d5398;
  font-size: 14px;
  padding: 0.3rem;
  border-radius: 9px;
  margin-left: 10px;
  cursor: pointer;
  font-weight: 700;
  position: relative;
  margin-top: -0.15rem;
  float: right;
  :hover {
    background-color: #1d5398;
    color: white;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-left: 0;
  font-size: 13px;
  padding: 0.3rem;
  margin-top: -0.15rem;
`};
`

const TitleRowWithInvestor = styled.span`
  display: flex;
  flex-direction: row;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  gap: 15px;
`};
`

export default function CubeDetail({
  match: {
    params: { version: versionFromURL, cubeAddress },
  },
  history,
}: RouteComponentProps<{
  version: string
  cubeAddress: string
}>) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  //const { data: historyData, loading } = useEtherscanTransaction({ startBlock: 14738955, address: cubeAddress })

  const { account, chainId, library } = useActiveWeb3React()
  const { currentVersion, switchVersion } = useContext(VersionContext)

  const { state: cubeState, loading: stateLoading } = useCubeState(cubeAddress)
  const { endTime: earlyEndTime } = useCubeEndTime(cubeAddress)

  const { cubes: cubeInfoArray } = useCubeInfoByAddresses([cubeAddress], undefined, undefined, versionFromURL)
  const cubeInfo = cubeInfoArray ? cubeInfoArray[0] : undefined

  const { data: investorList } = useInvestorsData(cubeAddress)
  const { data: withdrawn } = useWithdrawStatus(cubeAddress, account)

  useEffect(() => {
    switchVersion(versionFromURL ? versionFromURL?.replace('v', '').replace('-', '.') : '1.2')
  }, [switchVersion, versionFromURL])

  const cubeletsInfo = cubeInfo?.cubeletsInfo

  const [, setHash] = useState<string | undefined>()

  //const [openDropDown, setOpenDropDown] = useState<boolean>(false)
  const [showEarlyButton, setShowEarlyButton] = useState<boolean>(false)

  const isTrader = history.location.pathname.includes('trader')

  const [, setAttemptingTxn] = useState<boolean>(false) // clicked confirm
  const [showInvestors, setShowInvestors] = useState<boolean>(false)
  //console.log(showInvestors)

  function toggleInvestorList() {
    setShowInvestors(!showInvestors)
  }
  //===============Error===================
  const [showError, setShowError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>()

  const addTransaction = useTransactionAdder()

  const base = useCurrency(cubeInfo?.baseCurrency)
  const numberShares = Number(cubeInfo?.numberOfCubelets)
  const pricePerCubelet = base
    ? base.decimals === 18
      ? utils.formatEther(cubeInfo?.pricePerCubelet as BigNumber)
      : Number(cubeInfo?.pricePerCubelet.toString()) / 10 ** 6
    : undefined
  const totalFund = parseFloat((numberShares * Number(pricePerCubelet)).toFixed(12))
  const formattedTotalFund = numeral(totalFund).format('0,0')
  //============ getting cube balance=================
  const tokens = useAllTokens()
  const currencyList =
    cubeInfo && cubeInfo.tokenWhitelist !== [] ? cubeInfo.tokenWhitelist.map((item: string) => tokens[item]) : []

  const balance = useCurrencyBalances(cubeAddress, cubeInfo?.currencyList as Currency[])
  const balances = balance.map((token) => [token?.currency.symbol, Number(token?.toExact())])

  const tokenAddressToSymbol = currencyList
    .filter((token) => token)
    .map((item) => {
      //console.log(item.address, item.symbol)
      return [item.address, item.symbol]
    })
  //console.log(cubeInfo?.assetsToEth)
  const toETHList = cubeInfo?.assetsToEth ? cubeInfo.assetsToEth : [] //0 address 1 value
  const pieChartData = tokenAddressToSymbol.map((token) => {
    const tokenName = toETHList.find((item) => item[0] === token[0])
    const noWETHname = token[1] === 'WETH' ? 'ETH' : token[1]
    if (tokenName) {
      return [noWETHname, tokenName[1]]
    } else {
      return [noWETHname, 0]
    }
  })

  const cubeProfit = cubeInfo?.profit

  // const profitToDisplay =
  //   cubeProfit !== undefined // need improvement
  //     ? cubeProfit === -1
  //       ? !loadingTotal
  //         ? (
  //             ((Number(utils.formatEther(finalTotal)) * (base?.decimals === 18 ? 1 : 10 ** 12)) / totalFund - 1) *
  //             100
  //           ).toFixed(2) + '%'
  //         : 'NA'
  //       : cubeProfit === 0
  //       ? '0%'
  //       : (Number(cubeProfit) * 100).toFixed(2) + '%'
  //     : 'NA'
  const profitToDisplay =
    cubeProfit === undefined || cubeProfit === -100
      ? 'NA'
      : cubeProfit === 0
      ? '0%'
      : (Number(cubeProfit) * 100).toFixed(2) + '%'

  // console.log(currencyList.map((item) => [item.address, item.symbol]))

  //========== getting token amount from pools ========
  const { positions, loading: positionsLoading } = useV3Positions(cubeAddress ?? undefined)
  //console.log('>>>>>>', positions)
  const positionArray: [Currency | undefined, Currency | undefined, FeeAmount | undefined][] =
    positions && !positionsLoading
      ? positions.map((item) => {
          const token0 = tokens[item.token0]
          const token1 = tokens[item.token1]

          return [
            token0 ? (token0 as Currency) : undefined,
            token1 ? (token1 as Currency) : undefined,
            item.fee ? (item.fee as FeeAmount) : undefined,
          ]
        })
      : []

  const pools = usePools(positionArray)

  // console.log('positions', positions)
  // console.log('positionArray', positionArray)

  const positionList = positions
    ? pools
        .map((pool, i) => {
          if (pool[1]) {
            return new Position({
              pool: pool[1],
              liquidity: positions[i].liquidity.toString(),
              tickLower: positions[i].tickLower,
              tickUpper: positions[i].tickUpper,
            })
          }
          return undefined
        })
        .filter((item) => item?.amount0.toExact() !== '0' || item?.amount1.toExact() !== '0')
    : []

  /*
   ** check if cube has unclosed pool positions
   */
  const hasOpenPosition: boolean = positionList.length !== 0

  const poolAmounts = positionList.map((item) => [
    [item?.pool.token0.symbol === 'WETH' ? 'ETH' : item?.pool.token0.symbol, Number(item?.amount0.toExact())],
    [item?.pool.token1.symbol === 'WETH' ? 'ETH' : item?.pool.token1.symbol, Number(item?.amount1.toExact())],
  ])

  poolAmounts.push(balances)

  //===== claimable fee from positions========

  const [claimableFeePairs] = useState<any[]>(Array(positionList.length).fill(0))

  function PositionListMap(position: any) {
    const [feeValue0, feeValue1] = useV3PositionFees(
      positionsLoading ? undefined : position.pool[1],
      positions ? positions[position.index].tokenId : undefined,
      false
    )
    //console.log(feeValue0?.toSignificant(2), feeValue1?.toSignificant(2))
    useEffect(() => {
      if (feeValue0 || feeValue1) {
        claimableFeePairs[position.index * 2] = position.pool[1]
          ? [
              position?.pool[1].token0?.symbol === 'WETH' ? 'ETH' : position?.pool[1].token0?.symbol,
              isNaN(Number(feeValue0?.toSignificant(12))) ? 0 : Number(feeValue0?.toSignificant(12)),
            ]
          : [base?.symbol, 0]
        claimableFeePairs[position.index * 2 + 1] = position.pool[1]
          ? [
              position?.pool[1].token1?.symbol === 'WETH' ? 'ETH' : position?.pool[1].token1?.symbol,
              isNaN(Number(feeValue1?.toSignificant(12))) ? 0 : Number(feeValue1?.toSignificant(12)),
            ]
          : [base?.symbol, 0]
      }
    }, [feeValue0, feeValue1, position])

    return null
  }

  // ====== combine all balances ========

  const result = poolAmounts.flatMap((item) => item).concat(claimableFeePairs)

  const balancesWithPool: any[] = []
  for (let i = 0; i < balances.length; i++) {
    let sum = 0
    result.map((item) => {
      if (item[0] === balances[i][0]) {
        sum += Number(item[1])
      }
    })
    balancesWithPool.push([balances[i][0], sum])
  }

  //console.log(balancesWithPool)

  //===== getting trade info from each token =========

  //const [toBaseList] = useState<number[]>(Array(cubeInfo?.currencyList.length).fill(0))
  const [feeList] = useState<number[]>(Array(cubeInfo?.currencyList.length).fill(0))
  const [pathList] = useState<string[]>(Array(cubeInfo?.currencyList.length).fill(''))
  const [singHopBoolean] = useState<boolean[]>(Array(cubeInfo?.currencyList.length).fill(false))
  const [stateList] = useState<number[]>(Array(cubeInfo?.currencyList.length).fill(0))
  const [approvalList] = useState<string[]>(Array(cubeInfo?.currencyList.length).fill(''))
  //const [colorList] = useState<string[]>(Array(cubeInfo?.currencyList.length).fill(''))

  function TokenListMap(token: any) {
    //colorList[token.index] = useListColor(token.token.list ? token.token.list.logoURI : undefined)
    const { trade: getV3Trade, state: state } = useBestV3TradeExactIn(token.amount, base as Currency)
    const [approvalState] = useApproveCallback(
      token.amount,
      chainId ? SWAP_ROUTER_ADDRESSES[chainId] : undefined,
      cubeAddress,
      'swap'
    )

    // const tokenRateToBase = useMemo(() => {
    //   return token.token.symbol === base?.symbol
    //     ? 1
    //     : isNaN(Number(getV3Trade?.executionPrice.toSignificant(8)))
    //     ? 0
    //     : Number(getV3Trade?.executionPrice.toSignificant(8))
    // }, [getV3Trade, token])
    //const toBase = tokenRateToBase * (balancesWithPool[token.index][1] as number)

    const path: string =
      state === 3 ? encodeRouteToPath(getV3Trade?.swaps[0].route as Route<Currency, Currency>, false) : ''

    useEffect(() => {
      if (state !== 0) {
        //toBaseList[token.index] = toBase
        feeList[token.index] = getV3Trade?.swaps[0].route.pools[0].fee ?? 0
        pathList[token.index] = path
        singHopBoolean[token.index] = getV3Trade?.swaps[0].route.pools.length === 1
        approvalList[token.index] = approvalState
      }
      stateList[token.index] = state
    }, [state, getV3Trade, path, token, approvalState])

    return null
  }

  //console.log('COLOR', colorList)
  const needToApprove = approvalList.includes('NOT_APPROVED')
  const approvePending = approvalList.includes('PENDING')

  const approveData = cubeInfo?.currencyList
    .map((token, i) => {
      if (approvalList[i] === 'NOT_APPROVED') {
        return [
          cubeAddress,
          chainId
            ? currentVersion === '1.0'
              ? LC_CUBESWAPPING_LOGIC[chainId]
              : currentVersion === '1.1'
              ? LC_CUBESWAPPING_LOGIC_V1_1[chainId]
              : currentVersion === '1.2'
              ? LC_CUBESWAPPING_LOGIC_V1_2[chainId]
              : undefined
            : undefined,
          token.address,
          chainId ? SWAP_ROUTER_ADDRESSES[chainId] : undefined,
          MaxUint256,
        ]
      } else {
        return undefined
      }
    })
    .filter((n) => n)

  function formatFloat(src: number, pos: number) {
    return Math.round(src * Math.pow(10, pos)) / Math.pow(10, pos)
  }

  function truncate(fullStr: string | undefined, strLen: number) {
    if (!fullStr) return '...'
    if (fullStr.length <= strLen) return fullStr
    const separator = '...'
    const sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2)

    return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars)
  }

  const liquidateData = cubeInfo
    ? feeList
        .map((fee, i) => [
          singHopBoolean[i],
          cubeInfo?.currencyList[i]?.symbol === 'ETH'
            ? WETH9[chainId ? chainId : SupportedChainId.MAINNET].address
            : cubeInfo?.currencyList[i]
            ? cubeInfo?.currencyList[i].address
            : '',
          toHex(fee),
          pathList[i],
        ])
        .filter((token) => token[2] !== toHex(0))
    : []

  //const liquidateData = [1, 2, 3]

  /**
   * CountDown Timer
   */

  const date = new Date(Number(cubeInfo?.startingTime) * 1000)
  const normalEndDate = new Date((Number(cubeInfo?.startingTime) + Number(cubeInfo?.tradingPeriodLength)) * 1000)
  const endDate =
    earlyEndTime &&
    earlyEndTime !== '0' &&
    // the cube ends earlier than normal end date
    Number(cubeInfo?.startingTime) + Number(cubeInfo?.tradingPeriodLength) > Number(earlyEndTime)
      ? new Date(Number(earlyEndTime) * 1000)
      : normalEndDate

  const liquidDate = new Date()
  liquidDate.setDate(endDate.getDate() + 7) // investor can liquidate 7 days after the trading ends
  const today = new Date()

  const timerComponents = CountdownTimer(endDate)
  //console.log(timerComponents.length)

  const ownedTokenIds = cubeletsInfo?.filter((item) => item.ownerAddr === account).map((item) => toHex(item.tokenId))

  const cubeletsArray = cubeletsInfo
    ?.filter((item) => item?.ownerAddr?.toLowerCase() === account?.toLowerCase())
    .sort((item1, item2) => Number(item1.cubeletId) - Number(item2.cubeletId))

  //const investedCubelets = cubeletsArray.filter((item) => item?.toLowerCase() === account?.toLowerCase())

  const unstakeTokenIds = positions?.map((position) => position.tokenId)
  //const liquidities = positions?.map((position) => position.liquidity)

  async function onUnstake() {
    if (!chainId || !library || !account) return
    const txn: { to: string; data: string; value: string } = { to: '', data: '', value: '' }
    if (cubeInfo && unstakeTokenIds) {
      //const calldata: string = LC_PERI_INTERFACE.encodeFunctionData('depositETH')
      const calldata: string = LC_PERI_INTERFACE.encodeFunctionData('removeAllPositionsRelay', [
        toHex(cubeAddress),
        unstakeTokenIds,
      ])
      //console.log(`>>>>> calldata from liquidate: ${calldata}`)

      setAttemptingTxn(true)

      txn.to =
        currentVersion === '1.0'
          ? LC_PERIPHERY_ADDRESS[chainId]
          : currentVersion === '1.1'
          ? LC_PERIPHERY_ADDRESS_V1_1[chainId]
          : LC_PERIPHERY_ADDRESS_V1_2[chainId]
      txn.data = calldata
      txn.value = '0x0'
    }

    library
      .getSigner()
      .estimateGas(txn)
      .then((estimate) => {
        const newTxn = {
          ...txn,
          gasLimit: calculateGasMargin(chainId, estimate),
        }

        return library
          .getSigner()
          .sendTransaction(newTxn)
          .then((response: TransactionResponse) => {
            // debug
            console.log(`response: ${response}`)
            setAttemptingTxn(false)
            addTransaction(response, {
              summary: 'Unstaking all positions in ' + cubeInfo?.cubeName,
            })
            setHash(response.hash)
            ReactGA.event({
              category: 'Liquidate',
              action: 'Unstake',
              label: cubeInfo?.cubeName ?? '',
            })
          })
      })
      .catch((error) => {
        console.error('Failed to send transaction', error)
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
          setShowError(true)
          if (error.message.includes('LCC:WrongState')) {
            setErrorMsg(
              'Wrong state. Unstake will be available around one minute after trading ends, please come back later.'
            )
          } else if (
            error.message.includes(
              'cannot estimate gas; transaction may fail or may require manual gas limit (error={"code":-32603,"message":"execution reverted: ERC721: owner query for nonexistent token'
            )
          ) {
            setErrorMsg('Already withdrawn.')
          } else {
            setErrorMsg(error.message)
          }
        }
      })
  }

  async function onApprove() {
    if (!chainId || !library || !account) return
    const txn: { to: string; data: string; value: string } = { to: '', data: '', value: '' }
    if (cubeInfo) {
      //const calldata: string = LC_PERI_INTERFACE.encodeFunctionData('depositETH')
      //console.log([toHex(cubeAddress), approveData])
      //const calldata: string = LC_PERI_INTERFACE.encodeFunctionData('approveRelay', approveData)
      const calldatas = approveData?.map((call) => LC_PERI_INTERFACE.encodeFunctionData('approveRelay', call))
      const calldata: string = LC_PERI_INTERFACE.encodeFunctionData('multicall', [calldatas])

      //console.log(`>>>>> calldata from liquidate: ${calldata}`)

      setAttemptingTxn(true)

      txn.to =
        currentVersion === '1.0'
          ? LC_PERIPHERY_ADDRESS[chainId]
          : currentVersion === '1.1'
          ? LC_PERIPHERY_ADDRESS_V1_1[chainId]
          : LC_PERIPHERY_ADDRESS_V1_2[chainId]
      txn.data = calldata
      txn.value = '0x0'
    }

    //console.log(`>>>>>>>>>> tx: ${JSON.stringify(txn, null, ' ')}`)

    library
      .getSigner()
      .estimateGas(txn)
      .then((estimate) => {
        const newTxn = {
          ...txn,
          gasLimit: calculateGasMargin(chainId, estimate),
        }

        return library
          .getSigner()
          .sendTransaction(newTxn)
          .then((response: TransactionResponse) => {
            // debug
            console.log(`response: ${response}`)
            setAttemptingTxn(false)
            addTransaction(response, {
              summary: 'Approved all tokens in ' + cubeInfo?.cubeName,
            })
            setHash(response.hash)
            ReactGA.event({
              category: 'Trader',
              action: 'Approve',
              label: cubeInfo?.cubeName ?? '',
            })
          })
      })
      .catch((error) => {
        console.error('Failed to send transaction', error)
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
          setShowError(true)
          if (error.message.includes('LCC:BaseCurrencyRequired')) {
            setErrorMsg('Trader needs to swap all the tokens with the base currency to liquidate')
          } else if (error.message.includes('LCC:WrongState')) {
            setErrorMsg(
              'Wrong state. Liquidate will be available one minute after trading ends, Please come back later.'
            )
          } else {
            setErrorMsg(error.message)
          }
        }
      })
  }

  async function onLiquidate() {
    if (!chainId || !library || !account) return
    const txn: { to: string; data: string; value: string } = { to: '', data: '', value: '' }
    if (cubeInfo) {
      //const calldata: string = LC_PERI_INTERFACE.encodeFunctionData('depositETH')
      //console.log(liquidateData)
      const calldata: string = LC_PERI_INTERFACE.encodeFunctionData('liquidateCubeRelay', [
        toHex(cubeAddress),
        // unstakeTokenIds,
        // liquidities,
        liquidateData,
      ])
      //console.log(`>>>>> calldata from liquidate: ${calldata}`)

      setAttemptingTxn(true)

      txn.to =
        currentVersion === '1.0'
          ? LC_PERIPHERY_ADDRESS[chainId]
          : currentVersion === '1.1'
          ? LC_PERIPHERY_ADDRESS_V1_1[chainId]
          : LC_PERIPHERY_ADDRESS_V1_2[chainId]
      txn.data = calldata
      txn.value = '0x0'
    }

    //console.log(`>>>>>>>>>> tx: ${JSON.stringify(txn, null, ' ')}`)

    library
      .getSigner()
      .estimateGas(txn)
      .then((estimate) => {
        const newTxn = {
          ...txn,
          gasLimit: calculateGasMargin(chainId, estimate),
        }

        return library
          .getSigner()
          .sendTransaction(newTxn)
          .then((response: TransactionResponse) => {
            // debug
            console.log(`response: ${response}`)
            setAttemptingTxn(false)
            addTransaction(response, {
              summary: 'Liquidated the cube ' + cubeInfo?.cubeName,
            })
            setHash(response.hash)
            ReactGA.event({
              category: 'Trader',
              action: 'Liquidate',
              label: cubeInfo?.cubeName ?? '',
            })
          })
      })
      .catch((error) => {
        console.error('Failed to send transaction', error)
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
          setShowError(true)
          if (error.message.includes('LCC:BaseCurrencyRequired')) {
            setErrorMsg('Trader needs to swap all the tokens with the base currency to liquidate')
          } else if (error.message.includes('LCC:WrongState')) {
            setErrorMsg(
              'Wrong state. Liquidate will be available one minute after trading ends, Please come back later.'
            )
          } else {
            setErrorMsg(error.message)
          }
        }
      })
  }

  async function onTraderLiquidate() {
    if (!chainId || !library || !account) return

    const txn: { to: string; data: string; value: string } = { to: '', data: '', value: '' }
    if (cubeInfo) {
      //const calldata: string = LC_PERI_INTERFACE.encodeFunctionData('depositETH')
      const calldata: string = LC_PERI_INTERFACE.encodeFunctionData('traderLiquidateRelay', [toHex(cubeAddress)])
      //console.log(`>>>>> calldata from trader withdraw: ${calldata}`)

      setAttemptingTxn(true)

      txn.to =
        currentVersion === '1.0'
          ? LC_PERIPHERY_ADDRESS[chainId]
          : currentVersion === '1.1'
          ? LC_PERIPHERY_ADDRESS_V1_1[chainId]
          : LC_PERIPHERY_ADDRESS_V1_2[chainId]
      txn.data = calldata
      txn.value = '0x0'
    }

    //console.log(`>>>>>>>>>> tx: ${JSON.stringify(txn, null, ' ')}`)

    library
      .getSigner()
      .estimateGas(txn)
      .then((estimate) => {
        const newTxn = {
          ...txn,
          gasLimit: calculateGasMargin(chainId, estimate),
        }

        return library
          .getSigner()
          .sendTransaction(newTxn)
          .then((response: TransactionResponse) => {
            // debug
            console.log(`response: ${response}`)
            setAttemptingTxn(false)
            addTransaction(response, {
              summary: 'Claimed trader profit from the cube ' + cubeInfo?.cubeName,
            })
            setHash(response.hash)
            history.push('/trader')
            ReactGA.event({
              category: 'Trader',
              action: 'Withdraw',
              label: cubeInfo?.cubeName ?? '',
            })
          })
      })
      .catch((error) => {
        console.error('Failed to send transaction', error)
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
          setShowError(true)
          if (error.message.includes('LCF:WrongState')) {
            setErrorMsg('The cube needs to be liquidated before claiming.')
          } else if (error.message.includes('LCM:NoProfitForTrader')) {
            setErrorMsg('No profit available.')
          } else {
            setErrorMsg(error.message)
          }
        }
      })
  }

  async function onInvestorLiquidate() {
    if (!chainId || !library || !account) return

    const txn: { to: string; data: string; value: string } = { to: '', data: '', value: '' }
    if (account && ownedTokenIds) {
      //const calldata: string = LC_PERI_INTERFACE.encodeFunctionData('depositETH')
      const calldata: string = LC_PERI_INTERFACE.encodeFunctionData('investorLiquidateRelay', [
        [toHex(cubeAddress), toHex(account), ownedTokenIds],
      ])
      //console.log(`>>>>> calldata from investor withdraw: ${calldata}`)

      setAttemptingTxn(true)

      txn.to =
        currentVersion === '1.0'
          ? LC_PERIPHERY_ADDRESS[chainId]
          : currentVersion === '1.1'
          ? LC_PERIPHERY_ADDRESS_V1_1[chainId]
          : LC_PERIPHERY_ADDRESS_V1_2[chainId]
      txn.data = calldata
      txn.value = '0x0'
    }

    //console.log(`>>>>>>>>>> tx: ${JSON.stringify(txn, null, ' ')}`)

    library
      .getSigner()
      .estimateGas(txn)
      .then((estimate) => {
        const newTxn = {
          ...txn,
          gasLimit: calculateGasMargin(chainId, estimate),
        }

        return library
          .getSigner()
          .sendTransaction(newTxn)
          .then((response: TransactionResponse) => {
            console.log(`response: ${response}`)
            setAttemptingTxn(false)
            addTransaction(response, {
              summary: 'Withdrawn from the cube ' + cubeInfo?.cubeName + 'as Investor',
            })
            setHash(response.hash)
            history.push('/investor')
            ReactGA.event({
              category: 'Trader',
              action: 'Liquidate',
              label: cubeInfo?.cubeName ?? '',
            })
          })
      })
      .catch((error) => {
        console.error('Failed to send transaction', error)
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
          setShowError(true)
          if (error.message.includes('LCF:WrongState')) {
            setErrorMsg('The cube needs to be liquidated by the trader before withdrawing.')
          } else if (
            error.message.startsWith(
              'cannot estimate gas; transaction may fail or may require manual gas limit (error={"code":-32603,"message":"execution reverted: ERC721: owner query for nonexistent token'
            )
          ) {
            setErrorMsg('Withdraw completed.')
          } else {
            setErrorMsg(error.message)
          }
        }
      })
  }

  const etherscanURL = getExplorerLink(chainId ?? 1, cubeAddress, ExplorerDataType.ADDRESS)

  // total earning
  const totalEarning = cubeProfit !== undefined ? formatFloat(totalFund * cubeProfit, 8) : 0
  //profit !== 'N/A' ? formatFloat(toBaseSum - totalFund, 8) : 0
  const traderEarning =
    totalEarning > 0
      ? formatFloat(
          (totalEarning * Number(cubeInfo?.traderCut) * (currentVersion === '1.0' ? 0.98 : 1)) / 100 -
            (currentVersion === '1.0' ? 0 : totalEarning * 0.02),
          8
        )
      : 0

  const baseToUSDC = useUSDCPrice(base as Currency)
  const totalEarningInUSD = baseToUSDC ? (Number(baseToUSDC.toSignificant(8)) * totalEarning).toFixed(3) : 0
  const yourEarning =
    cubeProfit !== undefined
      ? cubeletsArray
        ? formatFloat(
            cubeProfit *
              cubeletsArray.length *
              Number(pricePerCubelet) *
              (currentVersion === '1.0' ? 0.98 : 1) *
              // apply split percentage when profit is positive
              (cubeProfit > 0 ? (100 - Number(cubeInfo?.traderCut)) / 100 : 1),
            8
          )
        : 0
      : 0

  const yourEarningInUSD = baseToUSDC ? (Number(baseToUSDC.toSignificant(8)) * yourEarning).toFixed(3) : 0
  const traderEarningInUSD = baseToUSDC ? (Number(baseToUSDC.toSignificant(8)) * traderEarning).toFixed(3) : 0

  // APY calculation
  const dateDiff = Math.floor((Number(new Date()) - Number(date)) / 1000)
  const tradingEndedDiff = Math.floor((Number(endDate) - Number(date)) / 1000)

  const apy =
    cubeProfit !== undefined && cubeProfit !== -100
      ? Math.max((cubeProfit / (cubeState === 4 ? tradingEndedDiff : dateDiff)) * 365 * 86400, 0)
      : -1

  //console.log(Number(new Date()) / 1000)

  // const ethtoken = useCurrency('ETH')
  // console.log(Number(useUSDCPrice(ethtoken as Currency)?.toSignificant(8)).toFixed(3))
  //console.log(cubeInfo)
  return (
    <OutWrapper>
      <Modal isOpen={showError} onDismiss={() => setShowError(false)}>
        <TransactionErrorContent
          onDismiss={() => {
            setShowError(false)
          }}
          message={errorMsg}
        />
      </Modal>
      <Modal isOpen={showInvestors} onDismiss={() => setShowInvestors(false)}>
        <ConfirmationModalContent
          title={<PopupTitle>Investor List</PopupTitle>}
          topContent={() => (
            <InvestorWrapper>
              {investorList?.map((investor) => (
                <InvestorItem key={investor}>
                  <MobileHide>{shortenAddress(investor, 10)}</MobileHide>
                  <DesktopHide>{shortenAddress(investor, 8)}</DesktopHide>
                  <ShowInvestorButton onClick={() => window.open(`/#/profile/investor/${investor}`, '_blank')}>
                    View Profile
                  </ShowInvestorButton>
                </InvestorItem>
              ))}
            </InvestorWrapper>
          )}
          onDismiss={() => setShowInvestors(false)}
        />
      </Modal>

      <Tabs>
        <Row style={{ padding: '0' }}>
          <NavButton
            onClick={() => (isTrader ? history.push('/trader') : history.push('/investor'))}
            style={{ marginRight: '8px', cursor: 'pointer' }}
          >
            {isTrader ? 'Cube List' : 'Investor Cube List'}
          </NavButton>
          <NavButton>
            {' '}
            {'>'} {cubeInfo?.cubeName}
          </NavButton>
        </Row>
      </Tabs>
      <MobileNav
        src={LeftArrow}
        alt="left-arrow"
        onClick={() => (isTrader ? history.push('/trader') : history.push('/investor'))}
      />

      <TitleRow>
        <CubeIcon
          src={cubeInfo && !stateLoading && (today > endDate || cubeState === 4) ? CubeRed : CubeGreen}
          alt="blueYellow"
        />
        <CubeName>
          {cubeInfo?.cubeName} <span className="versionTag">v{cubeInfo?.version.replace('-', '.')}</span> {'  '}
          <DesktopHide style={{ margin: 'auto' }}>
            <CopyHelper toCopy={window.location.href.replace('trader', 'investor')} style={1} />
          </DesktopHide>
        </CubeName>
        <MobileHide>
          <CopyHelper toCopy={window.location.href.replace('trader', 'investor')} style={1} />
        </MobileHide>
        <MobileHide style={{ marginLeft: 'auto', paddingRight: '42px' }}>
          <div style={{ fontSize: '23px' }}>
            <span style={{ fontSize: '14px' }}>APY:</span> {apy <= 0 ? 'NA' : `${(apy * 100).toFixed(2)}%`}
          </div>
        </MobileHide>
        <DesktopHide>
          <CubeAddress onClick={() => window.open(etherscanURL)}>
            {truncate(cubeAddress, 22)}
            {/* <CopyHelper toCopy={cubeAddress} style={1} /> */}
          </CubeAddress>
          <div style={{ margin: 'auto', textAlign: 'center', marginTop: '15px', fontWeight: 600 }}>APY </div>
          <div style={{ margin: 'auto', textAlign: 'center', marginBottom: '10px', fontSize: '22px', fontWeight: 700 }}>
            {apy === 0 ? '0%' : apy <= 0 ? 'NA' : `${(apy * 100).toFixed(2)}%`}
          </div>
        </DesktopHide>

        {/* <TextTitle>{cubeInfo?.sudoCubeId}</TextTitle> */}
      </TitleRow>

      <CubeInfoDesktopWrapper>
        <MobileHide style={{ margin: '5px 0 20px 0' }}>
          <CubeAddress onClick={() => window.open(etherscanURL)}>
            {cubeAddress}
            {/* <CopyHelper toCopy={cubeAddress} style={1} /> */}
          </CubeAddress>
        </MobileHide>
        <StyledAutoColumn>
          <SameRow>
            <MobileHide>
              <div style={{ display: 'flex', flexDirection: 'row', verticalAlign: 'middle' }}>
                {cubeInfo && !stateLoading && cubeState !== 4 && today < endDate && (
                  <RangeLineItem>
                    <span style={{ display: 'flex', flexDirection: 'row' }}>
                      <span style={{ minWidth: '150px' }}>Trading Period Ends: </span>
                      <span style={{ fontSize: '15px', color: 'black', whiteSpace: 'nowrap' }}>
                        {cubeInfo && timerComponents}{' '}
                      </span>
                    </span>
                    <span style={{ display: 'flex', flexDirection: 'row' }}>
                      <span style={{ minWidth: '150px' }}>Trading Start Time: </span>
                      <span style={{ fontSize: '15px', color: 'black', whiteSpace: 'nowrap' }}>
                        {date.toLocaleString('en-US')},
                        {date.toLocaleDateString('en-US', { timeZoneName: 'short' }).split(',')[1]}
                      </span>
                    </span>
                  </RangeLineItem>
                )}

                {cubeInfo &&
                  !stateLoading &&
                  (today < endDate && cubeState !== 4 ? (
                    <GreenLabel>Trading in Progress</GreenLabel>
                  ) : (
                    <RedLabel>Trading Ended</RedLabel>
                  ))}
              </div>
            </MobileHide>
            <DesktopHide>
              <RangeLineItem>
                {!stateLoading && cubeState !== 4 && (
                  <span style={{ display: 'flex', flexDirection: 'row' }}>
                    <span style={{ minWidth: '140px' }}>Trading Period Ends: </span>
                    <span style={{ fontSize: '14px', color: 'black', whiteSpace: 'nowrap', marginLeft: 'auto' }}>
                      {cubeInfo && timerComponents}{' '}
                    </span>
                  </span>
                )}

                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <span style={{ minWidth: '140px' }}>Trading Start Time: </span>
                  <span style={{ fontSize: '14px', color: 'black', whiteSpace: 'nowrap', marginLeft: 'auto' }}>
                    {date.toLocaleString('en-US')},
                    {date.toLocaleDateString('en-US', { timeZoneName: 'short' }).split(',')[1]}
                  </span>
                </span>
              </RangeLineItem>
            </DesktopHide>

            {isTrader && cubeState === 2 && timerComponents.length ? (
              <TradeButton
                //style={{ margin: '0', height: '40px', width: '110px' }}
                onClick={() => history.push('/trader/' + versionFromURL + '/' + cubeAddress + '/swap')}
              >
                Trade
              </TradeButton>
            ) : null}
          </SameRow>

          <InfoTitle>
            <TitleRowWithInvestor>
              Cube Info <ShowInvestorButton onClick={toggleInvestorList}>View Investors</ShowInvestorButton>
            </TitleRowWithInvestor>

            <DesktopHide>
              {cubeInfo &&
                !stateLoading &&
                (today < endDate && cubeState !== 4 ? (
                  <GreenLabel>Trading in Progress</GreenLabel>
                ) : (
                  <RedLabel>Trading Ended</RedLabel>
                ))}
            </DesktopHide>
          </InfoTitle>

          <CubeInfoDetail
            showInvestors={toggleInvestorList}
            hasStarted={true}
            currencyList={currencyList}
            base={base as Currency}
            totalFund={formattedTotalFund}
            traderCut={cubeInfo?.traderCut}
            traderAddr={cubeInfo?.traderAddr}
            date={date}
            endDate={endDate}
            numberCubelets={numberShares}
            singleCubeletValue={pricePerCubelet}
            tradingPeriodLength={
              earlyEndTime &&
              earlyEndTime !== '0' &&
              Number(cubeInfo?.startingTime) + Number(cubeInfo?.tradingPeriodLength) > Number(earlyEndTime)
                ? (Number(earlyEndTime) - Number(cubeInfo?.startingTime)).toString()
                : cubeInfo?.tradingPeriodLength
            }
          />
          <MobileHide style={{ marginTop: '-5px' }}>
            {cubeletsArray && cubeletsArray.length > 0 && !isTrader && (
              <Row justify={'start'} marginTop="0px">
                <TextTitle>My Invested Cubelets:</TextTitle>
                <CubletContainer>
                  {cubeletsArray.map((item, i) => (
                    <OwnedCubelet key={`OwnedCubelet${i}${item}`} style={{ margin: '0 5px' }}>
                      {Number(item.cubeletId) > 99
                        ? ('0' + (Number(item.cubeletId) + 1)).slice(-2)
                        : Number(item.cubeletId) + 1}
                    </OwnedCubelet>
                  ))}
                </CubletContainer>
              </Row>
            )}
          </MobileHide>
        </StyledAutoColumn>

        <BorderLine />
        <InfoTitle>
          Trading Info
          {cubeState !== 4 && isTrader && today < endDate && (
            <div style={{ marginLeft: 'auto' }}>
              <EarlyLiquidate show={showEarlyButton} toggles={() => setShowEarlyButton(!showEarlyButton)} />
            </div>
          )}
        </InfoTitle>
        {cubeState !== 4 && (
          <MobileHide style={{ position: 'relative', margin: 'auto', marginLeft: '60px' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <>
                <PieChartCenterText>
                  Current Profit: <br />
                  {!cubeInfo?.assetsToEth ? (
                    <LoadingRows>
                      <span />
                    </LoadingRows>
                  ) : (
                    <span className="numberClass">{profitToDisplay}</span>
                  )}
                </PieChartCenterText>
                <Chart
                  width={'680px'}
                  height={'490px'}
                  chartType="PieChart"
                  loader={
                    <LoadingRows>
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                    </LoadingRows>
                  }
                  //pieChartData
                  // data={[['Currency', 'Balance'], ...currencyBalance]}
                  data={[['Currency', 'Balance'], ...pieChartData]}
                  options={{
                    //colors: colorList,
                    pieHole: 0.9,
                    pieSliceText: 'none',
                    tooltip: { showColorCode: true, text: 'none', trigger: 'selection' },
                    chartArea: { top: 0, left: 30 },
                    enableInteractivity: true,
                    legend: {
                      //maxLines: 5,
                      position: 'right', // right
                      alignment: 'end', // center
                      textStyle: {
                        fontSize: 16,
                        color: '#424242',
                        fontWeight: 600,
                        fontFamily: 'Open Sans',
                      },
                    },
                    pieSliceTextStyle: {
                      color: 'black',
                    },
                  }}
                  rootProps={{ 'data-testid': '3' }}
                />
              </>

              <EstimateEarning>
                <div style={{ whiteSpace: 'nowrap' }}>Estimate Cube Earning: </div>
                {cubeProfit !== undefined && cubeProfit !== -100 ? (
                  <>
                    {' '}
                    <strong>
                      {totalEarning} {base?.symbol}
                    </strong>
                    <div> ≈ ${totalEarningInUSD}</div>
                    {!isTrader && cubeletsArray && cubeletsArray.length > 0 && (
                      <div style={{ marginTop: '10px' }}>
                        <div style={{ whiteSpace: 'nowrap' }}>Your Estimate Earning: </div>
                        <strong>
                          {yourEarning} {base?.symbol}
                        </strong>
                        <div> ≈ ${yourEarningInUSD}</div>
                      </div>
                    )}
                    {isTrader && (
                      <>
                        <div>Trader Estimate Earning: </div>
                        <strong>
                          {traderEarning} {base?.symbol}
                        </strong>
                        <div> ≈ ${traderEarningInUSD}</div>
                      </>
                    )}
                  </>
                ) : (
                  <LoadingRows style={{ minWidth: '200px', maxHeight: '30px' }}>
                    <span />
                    <span />
                    <span />
                  </LoadingRows>
                )}
              </EstimateEarning>
            </div>
          </MobileHide>
        )}

        {cubeState !== 4 && (
          <DesktopHide style={{ position: 'relative', margin: 'auto', marginLeft: '-70px' }}>
            <PieChartCenterText>
              Current Profit: <br />
              {!cubeInfo?.assetsToEth ? (
                <LoadingRows style={{ maxWidth: '50px' }}>
                  <span />
                </LoadingRows>
              ) : (
                <span className="numberClass">{profitToDisplay}</span>
              )}
            </PieChartCenterText>
            <Chart
              width={'500px'}
              // height={'490px'}
              chartType="PieChart"
              loader={
                <LoadingRows>
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </LoadingRows>
              }
              data={[['Currency', 'Balance'], ...pieChartData]}
              options={{
                pieHole: 0.87,
                pieSliceText: 'none',
                tooltip: { showColorCode: true, text: 'none', trigger: 'selection' },
                chartArea: { top: 10, left: 30 },
                enableInteractivity: true,
                legend: {
                  //maxLines: 5,
                  position: 'right',
                  alignment: 'center',
                  textStyle: {
                    fontSize: 13,
                    color: '#424242',
                    fontWeight: 600,
                    fontFamily: 'Open Sans',
                  },
                },
                pieSliceTextStyle: {
                  color: 'black',
                },
              }}
              rootProps={{ 'data-testid': '3' }}
            />
            {cubeProfit !== undefined && cubeProfit !== -100 && (
              <EstimateEarning>
                <div>Estimate Cube Earning: </div>
                <strong>
                  {totalEarning} {base?.symbol}
                </strong>
                <div> ≈ ${totalEarningInUSD}</div>
                {!isTrader && cubeletsArray && cubeletsArray.length > 0 && (
                  <>
                    <div>Your Estimate Earning: </div>
                    <strong>
                      {yourEarning} {base?.symbol}
                    </strong>
                    <div> ≈ ${yourEarningInUSD}</div>
                  </>
                )}
                {isTrader && (
                  <>
                    <div>Trader Estimate Earning: </div>
                    <strong>
                      {traderEarning} {base?.symbol}
                    </strong>
                    <div> ≈ ${traderEarningInUSD}</div>
                  </>
                )}
              </EstimateEarning>
            )}
          </DesktopHide>
        )}
        {cubeState === 4 && (
          <FinalProfit>
            Final Profit: <strong>{profitToDisplay}</strong>
          </FinalProfit>
        )}

        {pools.map((item, i) => {
          return <PositionListMap key={`${item}${i}`} pool={item} index={i} />
        })}
        {cubeInfo?.currencyList.map((item: any, i: number) => {
          return (
            <TokenListMap
              key={`${i}${item?.name}${i}`}
              token={item as Currency}
              amount={
                tryParseAmount(balancesWithPool[i][1].toString(), item as Currency)
                  ? tryParseAmount(balancesWithPool[i][1].toString(), item as Currency)
                  : tryParseAmount(balancesWithPool[i][1].toPrecision(12), item as Currency)
              }
              index={i}
            />
          )
        })}
      </CubeInfoDesktopWrapper>

      {showEarlyButton &&
        isTrader &&
        cubeState !== 4 &&
        (hasOpenPosition ? (
          <StyledButton onClick={onUnstake}>Unstake</StyledButton>
        ) : needToApprove ? (
          <StyledButton onClick={onApprove} disabled={approvePending}>
            {approvePending ? <Dots>Approving</Dots> : 'Approve'}
          </StyledButton>
        ) : (
          <StyledButton onClick={onLiquidate}>Liquidate</StyledButton>
        ))}

      {isTrader && cubeInfo ? (
        <>
          {(cubeState === 2 && today > endDate) || cubeState === 3 ? (
            <>
              {hasOpenPosition ? (
                <StyledButton onClick={onUnstake}>Unstake</StyledButton>
              ) : needToApprove ? (
                <StyledButton onClick={onApprove} disabled={approvePending}>
                  {approvePending ? <Dots>Approving</Dots> : 'Approve'}
                </StyledButton>
              ) : (
                <StyledButton onClick={onLiquidate}>Liquidate</StyledButton>
              )}
            </>
          ) : null}

          {cubeState === 4 && (
            <StyledButton
              disabled={cubeInfo?.traderLiquidatedAmount === '0'}
              style={{ marginTop: '15px' }}
              onClick={onTraderLiquidate}
            >
              Claim
            </StyledButton>
          )}
        </>
      ) : (
        <>
          {(cubeState === 2 && today > endDate) || cubeState === 3 ? (
            today > liquidDate ? (
              hasOpenPosition ? (
                <StyledButton onClick={onUnstake}>Unstake</StyledButton>
              ) : needToApprove ? (
                <StyledButton onClick={onApprove} disabled={approvePending}>
                  {approvePending ? <Dots>Approving</Dots> : 'Approve'}
                </StyledButton>
              ) : (
                <StyledButton onClick={onLiquidate}>Liquidate</StyledButton>
              )
            ) : (
              <WaitingToLiquidateText>
                <MouseoverTooltipContent
                  content={
                    'If the trader does not liquidate 7 days after the trade ends, you will be able to manually liquidate and withdraw your cubelets.'
                  }
                >
                  <Alert />
                  {/* If the trader does not liquidate within 7 days, you can manually perform liquidation */}
                </MouseoverTooltipContent>
                Waiting for trader to liquidate. <br />
              </WaitingToLiquidateText>
            )
          ) : null}

          {cubeState === 4 ? (
            <>
              <StyledButton
                disabled={cubeletsArray && cubeletsArray.length === 0}
                style={{ marginTop: '15px' }}
                onClick={onInvestorLiquidate}
              >
                Withdraw
              </StyledButton>
              {withdrawn && (
                <div style={{ textAlign: 'center', marginTop: '10px', color: '#424242' }}>
                  You have withdrawn from this cube
                </div>
              )}
            </>
          ) : null}
        </>
      )}
    </OutWrapper>
  )
}
