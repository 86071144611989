import { encodeRouteToPath, toHex } from '@uniswap/v3-sdk'
import { Interface } from '@ethersproject/abi'
import { Trade as V3Trade } from '@uniswap/v3-sdk'
import { BigintIsh, Currency, Percent, TradeType } from '@uniswap/sdk-core'
import invariant from 'tiny-invariant'
import LC_PERI_ABI from 'abis/lc-periphery.json'
import { PermitOptions } from '@uniswap/v3-sdk/dist/selfPermit'

//========= LCV0 ===========
const INTERFACE: Interface = new Interface(LC_PERI_ABI)
export interface FeeOptions {
  /**
   * The percent of the output that will be taken as a fee.
   */
  fee: Percent

  /**
   * The recipient of the fee.
   */
  recipient: string
}

export interface SwapOptions {
  /**
   * How much the execution price is allowed to move unfavorably from the trade execution price.
   */
  slippageTolerance: Percent

  /**
   * The account that should receive the output.
   */
  recipient: string

  /**
   * When the transaction expires, in epoch seconds.
   */
  deadline: BigintIsh

  /**
   * The optional permit parameters for spending the input.
   */
  inputTokenPermit?: PermitOptions

  /**
   * The optional price limit for the trade.
   */
  sqrtPriceLimitX96?: BigintIsh

  /**
   * Optional information for taking a fee on output.
   */
  fee?: FeeOptions
}

export function useLCSwap(
  account: string,
  cubeAddr: string,
  trades: V3Trade<Currency, Currency, TradeType> | V3Trade<Currency, Currency, TradeType>[],
  options: SwapOptions
): string[] {
  if (!Array.isArray(trades)) {
    trades = [trades]
  }

  const calldatas: string[] = []

  const sampleTrade = trades[0]
  const tokenIn = sampleTrade.inputAmount.currency.wrapped
  const tokenOut = sampleTrade.outputAmount.currency.wrapped

  // All trades should have the same starting and ending token.
  invariant(
    trades.every((trade) => trade.inputAmount.currency.wrapped.equals(tokenIn)),
    'TOKEN_IN_DIFF'
  )
  invariant(
    trades.every((trade) => trade.outputAmount.currency.wrapped.equals(tokenOut)),
    'TOKEN_OUT_DIFF'
  )

  const deadline = toHex(options.deadline)

  for (const trade of trades) {
    for (const { route, inputAmount, outputAmount } of trade.swaps) {
      const amountIn: string = toHex(trade.maximumAmountIn(options.slippageTolerance, inputAmount).quotient)
      const amountOut: string = toHex(trade.minimumAmountOut(options.slippageTolerance, outputAmount).quotient)
      // flag for whether the trade is single hop or not
      const singleHop = route.pools.length === 1

      invariant(options.sqrtPriceLimitX96 === undefined, 'MULTIHOP_PRICE_LIMIT')

      const path: string = encodeRouteToPath(route, trade.tradeType === TradeType.EXACT_OUTPUT)

      //console.log(`>>>>> path ${path}`)

      if (trade.tradeType === TradeType.EXACT_INPUT) {
        // console.log(`>>>> tokenIn.symbol === 'WETH': ${tokenIn.symbol === 'WETH'}`)
        // console.log(`>>>> tokenOut.symbol === 'WETH': ${tokenOut.symbol === 'WETH'}`)
        // console.log(`>>>> tokenIn: ${tokenIn.address}`)
        // console.log(`>>>> tokenOut: ${tokenOut.address}`)

        const exactInputSingleParams = [
          toHex(cubeAddr),
          toHex(account as string),
          toHex(0), // v3
          singleHop, // single hop check
          tokenIn.symbol === 'WETH',
          tokenOut.symbol === 'WETH',
          [toHex(cubeAddr), amountIn, amountOut, [route.tokenPath[0].address, route.tokenPath[1].address]], // v2
          [
            tokenIn.address,
            tokenOut.address,
            route.pools[0].fee,
            toHex(cubeAddr),
            deadline,
            amountIn,
            amountOut,
            toHex(options.sqrtPriceLimitX96 ?? 0),
          ], // v3 single
          [tokenIn.address, tokenOut.address, path, toHex(cubeAddr), deadline, amountIn, amountOut], // v3 multi
        ]
        //需要修改
        calldatas.push(INTERFACE.encodeFunctionData('swapExactAForB', [exactInputSingleParams]))
      } else {
        // console.log(`>>>> tokenIn.symbol === 'WETH': ${tokenIn.symbol === 'WETH'}`)
        // console.log(`>>>> tokenOut.symbol === 'WETH': ${tokenOut.symbol === 'WETH'}`)

        // console.log(`>>>> tokenIn: ${tokenIn.address}`)
        // console.log(`>>>> tokenOut: ${tokenOut.address}`)

        const exactOutputSingleParams = [
          toHex(cubeAddr),
          toHex(account as string),
          toHex(0), // v3
          singleHop,
          tokenIn.symbol === 'WETH',
          tokenOut.symbol === 'WETH',
          [
            tokenIn.address,
            tokenOut.address,
            route.pools[0].fee,
            toHex(cubeAddr),
            deadline,
            amountOut,
            amountIn,
            toHex(options.sqrtPriceLimitX96 ?? 0),
          ], // v3 single
          [tokenIn.address, tokenOut.address, path, toHex(cubeAddr), deadline, amountOut, amountIn], // v3 multi
        ]
        calldatas.push(INTERFACE.encodeFunctionData('swapAForExactB', [exactOutputSingleParams]))
      }
    }
    // LC mvp only do single hop with exact input
  }

  return calldatas
}
