// import react from 'react'
import styled from 'styled-components/macro'
// import Row, { AutoRow, RowBetween, RowFixed, RowFlat } from 'components/Row'
// import youtubeLogo from 'assets/images/youtube@2x.png'
// import twitterLogo from 'assets/images/twitter43(1)@2x.png'
// import mediumLogo from 'assets/images/medium-square-fill@2x.png'
// import discordLogo from 'assets/images/discord@2x.png'
import telegramLogo from 'assets/images/telegram-icon.png'
//import cubeLogo from 'assets/images/logo@2x.png'
import cubeLogo from '../../assets/svg/logo-final.svg'
// import { useHistory, useLocation } from 'react-router'
import logo from '../../assets/images/logo_trustlook_with_name.png'

const FooterFrame = styled.div`
  width: 100%;
  //padding: 50px 10%;
  //max-width: 1200px;
  background: transparent;
  justify-content: center;
  padding-bottom: 15px;
  @media only screen and (max-width: 500px) {
    //padding: 15px;
    width: 100%;
  }
`

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //max-width: 1200px;
`
const FooterUpper = styled.div`
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  max-width: 1200px;
  margin: auto;
  height: auto;
  //justify-content: space-between;
  align-items: flex-start;
  @media only screen and (max-width: 500px) {
    //justify-content: center;
    //padding: 20px 10px;
  }
`

const LeftWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  @media only screen and (max-width: 500px) {
    width: auto;
    flex-direction: row;
  }
`
const FooterLogo = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;

  img {
    /* width: 40px; */
  }
  @media only screen and (max-width: 500px) {
    img {
      /* width: 30px; */
    }
  }
`

const LogoText = styled.div`
  //width: 214px;
  font-size: 20px;
  color: #424242;
  font-weight: bold;
  text-align: left;
  padding: 0 10px;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
`
const SocialMedia = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  @media only screen and (max-width: 500px) {
    padding-left: 40px;
  }
`

const SocialMediaText = styled.div`
  //width: 214px;
  font-size: 16px;
  color: #424242;
  font-weight: 700;
  text-align: left;
  margin: 10px 0;
  @media (max-width: 1024px) {
    font-size: 13px;
    font-weight: 600;
    text-align: left;
  }
`

const MediaIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  img {
    width: 30px;
    height: auto;
    cursor: pointer;
  }
  @media only screen and (max-width: 500px) {
    img {
      width: 25px;
    }
  }
`

const FooterInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: auto;
  //margin-left: 30px;
  margin-top: 10px;
  @media only screen and (max-width: 500px) {
    padding: 0 0;
  }
`

const InfoText = styled.div`
  font-size: 16px;
  color: #424242;
  font-weight: 700;
  text-align: left;
  margin-bottom: 10px;
  cursor: pointer;
  @media (max-width: 1024px) {
    font-size: 13px;
    font-weight: 600;
  }
`

const FooterLower = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 14px;
  margin-top: 40px;
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    row-gap: 4px;
    align-items: start;
  }
`

const Copyright = styled.div``

const Trustlook = styled.div`
  display: flex;
  align-items: center;
`

const LogoWrapper = styled.a``

const Logo = styled.img`
  width: 100px;
  height: 100%;
  transform: translateY(1.5px);
`

export default function Footer() {
  // const location = useLocation()

  return (
    <FooterFrame>
      <hr />
      <FooterContainer>
        <FooterUpper>
          <LeftWrapper>
            <FooterLogo>
              <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <img src={cubeLogo} alt="logo" />
                <LogoText>LiquidCube</LogoText>
              </span>
              <div style={{ textAlign: 'left', fontSize: '13px', marginTop: '15px' }}>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open('https://liquidcube.gitbook.io/welcome-to-liquidcube/release-note/frontend')
                  }
                >
                  Frontend Version: 1.2.0.9
                </div>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open('https://liquidcube.gitbook.io/welcome-to-liquidcube/release-note/smart-contracts')
                  }
                >
                  Contract Version: 1.2
                </div>
              </div>
              <FooterLower>
                <Copyright>© 2023 LiquidCube</Copyright>
                <Trustlook>
                  <small style={{ marginRight: '6px' }}>powered by</small>
                  <LogoWrapper href="https://www.trustlook.com/" target="_blank" rel="noreferrer">
                    <Logo src={logo} alt="Trustlook's Logo" />
                  </LogoWrapper>
                </Trustlook>
              </FooterLower>
            </FooterLogo>

            <SocialMedia>
              <FooterInfo>
                {/* <InfoText onClick={() => window.open('https://liquidcube.gitbook.io/doc/')}>About Us</InfoText> */}
                <InfoText onClick={() => window.open('https://liquidcube.gitbook.io/doc/')}>Documentation</InfoText>
                {/* {/* <InfoText onClick={() => window.open('https://liquidcube.gitbook.io/doc/')}>Terms of Service</InfoText> */}
                {/* <InfoText onClick={() => window.open('https://liquidcube.gitbook.io/welcome-to-liquidcube/about')}>
                  About (Release Notes)
                </InfoText> */}
              </FooterInfo>
              <SocialMediaText>Join Our Community</SocialMediaText>
              <MediaIcons>
                <img src={telegramLogo} alt="mediumLogo" onClick={() => window.open('https://t.me/LiquidCubeEN')} />
                {/* <img src={twitterLogo} alt="twitterLogo" />
                <img src={youtubeLogo} alt="youtubeLogo" />
                <img src={discordLogo} alt="discordLogo" /> */}
              </MediaIcons>
            </SocialMedia>
          </LeftWrapper>
        </FooterUpper>
      </FooterContainer>
    </FooterFrame>
  )
}
