import { Trans } from '@lingui/macro'
// import { onFocusLost } from '@reduxjs/toolkit/dist/query/core/setupListeners'
import { ButtonPrimary, ButtonPrimary2 } from 'components/Button'
import { AutoColumn } from 'components/Column'
// import { FlyoutAlignment, NewMenu } from 'components/Menu'
// import { SwapPoolTabs } from 'components/NavigationTabs'
import { RowBetween, RowFixed } from 'components/Row'
// import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
// import { L2_CHAIN_IDS } from 'constants/chains'
// import { useV3Positions } from 'hooks/useV3Positions'
import { useActiveWeb3React } from 'hooks/web3'
import { useContext, useEffect, useState } from 'react'
import { Inbox } from 'react-feather'
import { Link } from 'react-router-dom'
import { useWalletModalToggle } from 'state/application/hooks'
import styled, { ThemeContext } from 'styled-components/macro'
import { TYPE } from 'theme'
import { LoadingRows } from './styleds'
// ============= LC ================
import { MEDIA_WIDTHS } from 'theme'
import { useTraderSudoCubeInfo } from 'hooks/useSudoCubeInfo'
import SudoCubeListItem from 'components/SudoCubeListItem'
//import { useCubeInfo } from 'hooks/useCubeInfo'
import CubeListItem from 'components/CubeListItem'
import LotsCubes from 'assets/images/lots_cubes_trader.png'
import { useTraderCubeInfo } from 'hooks/useCubeInfo'
import { SudoCubeDetails } from 'types/sudoCube'
import { CubeDetails } from 'types/cube'

const PageWrapper = styled(AutoColumn)`
  max-width: 1085px;
  width: 100%;
  padding-bottom: 100px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 0;
  `};
`
const TitleRow = styled(RowBetween)`
  color: ${({ theme }) => theme.text2};
  padding-bottom: 20px;
  //border-bottom: 1px solid ${({ theme }) => theme.bg2};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    padding-bottom: 0;
  `};
`
const ButtonRow = styled(RowFixed)`
  & > *:not(:last-child) {
    margin-left: 8px;
  }
  display: flex;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    //width: 100px;
    flex-direction: row;
    justify-content: space-between;
    flex-direction: row-reverse;
  `};
`

const LotsCube = styled.img`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin: auto;
  height: auto;
  display: block;
  width: 283.52px;
  margin-top: -70px;
  margin-bottom: -45px;
  `};
`
// const Menu = styled(NewMenu)`
//   margin-left: 0;
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     flex: 1 1 auto;
//     width: 49%;
//     right: 0px;
//   `};
//   a {
//     width: 100%;
//   }
// `
// const MenuItem = styled.div`
//   align-items: center;
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
//   font-weight: 500;
// `
// const MoreOptionsButton = styled(ButtonGray)`
//   border-radius: 12px;
//   flex: 1 1 auto;
//   padding: 6px 8px;
//   width: 100%;
//   background-color: ${({ theme }) => theme.bg0};
//   margin-right: 8px;
// `
const NoLiquidity = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 25vh;
  padding-top: 100px;
`

const EmptyWarning = styled.div`
  font-size: 18px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 16px;
  `};
`
const ResponsiveButtonPrimary = styled(ButtonPrimary2)`
  border-radius: 20px;
  padding: 8px 10px;
  font-size: 18px;
  width: 190px;
  height: 43px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: -40px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const MobileButton = styled(ButtonPrimary2)`
  border-radius: 100px;
  padding: 0;
  font-size: 14px;
  width: 97px;
  height: 32px;
  font-weight: 600;
  display: none;
  line-height: 32px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    margin-top: 8px;
    margin-left: auto;
`};
`

const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.bg0};
  padding: 24px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
`

// const ShowInactiveToggle = styled.div`
//   display: flex;
//   align-items: center;
//   justify-items: end;
//   grid-column-gap: 4px;
//   padding: 0 8px;
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     margin-bottom: 12px;
//   `};
// `

// const ResponsiveRow = styled(RowFixed)`
//   justify-content: space-between;
//   width: 100%;
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     flex-direction: column-reverse;
//   `};
// `

const DesktopHeader = styled.div`
  display: none;
  font-size: 18px;
  font-weight: 500;
  //padding-left: 20px;
  border-bottom: ${({ theme }) => '1px solid ' + theme.bg3};

  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    align-items: center;
    display: flex;

    display: grid;
    grid-template-columns: 1fr 1fr;
    & > div:last-child {
      text-align: right;
      margin-right: 12px;
    }
  }
`

const MobileHeader = styled.div`
  font-weight: medium;
  font-size: 16px;
  padding: 8px;
  padding-bottom: 0;
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    display: none;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: flex;
  flex-direction: row;
  padding-left: 0;
  align-items: left;
  text-align: left;
  color: #0D6CE8;
  letter-spacing: 0;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 15px;
`};
`
const ToggleWrapper = styled.button<{ width?: string }>`
  display: flex;
  align-items: center;
  width: 100%;

  background: ${({ theme }) => theme.white};
  border: none;
  //border-bottom: ${({ theme }) => '1px solid ' + theme.bg3};
  cursor: pointer;
  outline: none;
`
const GridContainer = styled.div`
  margin-top: 51px;
  display: grid;
  margin-left: 4%;
  //grid-template-columns: 2.7fr 0.9fr 1fr 0.8fr;
  grid-template-columns: 1.8fr 1fr 0.5fr 0.6fr 0.4fr;
  //grid-template-columns: 2.7fr 1.1fr 0.8fr 1fr 0.8fr;
  margin-bottom: 25px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};
`
const ListTitle = styled.div`
  font-size: 16px;
  color: #424242;
  font-weight: 600;
  align-items: center;
  text-align: center;
  .profitsplit {
    text-align: center;
    //padding-right: 30px;
  }
`

const ToggleOption = styled.div<{ active?: boolean }>`
  width: 33%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin-bottom: -0.5%;

  color: ${({ theme, active }) => (active ? theme.primary1 : theme.bg4)};
  user-select: none;
  border-bottom: ${({ theme, active }) => (active ? '2px solid ' + theme.primary1 : 'none')};

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

export default function TraderPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()

  const theme = useContext(ThemeContext)
  // Tab=> 1-> trading, 2-> waiting, 3-> completed
  const [switchTab, setSwitchTab] = useState<number>(1)
  const { cubes: sudoCubes, loading: sudoCubesLoading } = useTraderSudoCubeInfo(account as string)
  //const { cubes: cubes, loading: cubesLoading } = useCubeInfo(account ?? undefined, isTrader)
  const { cubes: cubes, loading: cubesLoading } = useTraderCubeInfo(account as string)
  // const notTradingCubes = sudoCubes?.filter((cube) => cube.state === 0 || cube.state === 1) //waiting

  // const filteredCubes = cubes?.filter((cube) => cube.startingTime !== '0') //remove deployed but not started cube

  const combinedAllCubesLoading = sudoCubesLoading || cubesLoading
  const combinedAllCubes =
    sudoCubes && cubes
      ? [...sudoCubes, ...cubes].sort((a, b) => Number(b.startingTime.toString()) - Number(a.startingTime.toString()))
      : []

  const showConnectAWallet = Boolean(!account)

  function TraderCubeList() {
    return (
      <>
        <GridContainer>
          <ListTitle style={{ textAlign: 'left', marginLeft: '22px' }}>Cube Name</ListTitle>
          <ListTitle>Fund Cap</ListTitle>
          <ListTitle>Cube Version</ListTitle>
          {/* <ListTitle style={{ marginTop: '-10px' }}>
            Profit Split <div className="profitsplit"> (investor)</div>
          </ListTitle> */}
          <ListTitle>Platform</ListTitle>
          <ListTitle>Profit</ListTitle>
        </GridContainer>

        {combinedAllCubes.map((cube) => {
          if (cube.state < 2) {
            return <SudoCubeListItem key={cube.startingTime.toString()} cubeDetails={cube as SudoCubeDetails} />
          } else {
            return <CubeListItem key={cube.startingTime.toString()} cubeDetails={cube as CubeDetails} />
          }
        })}

        {/* {sudoCubes &&
          sudoCubes
            // .sort((a, b) => Number(b.startingTime.toString()) - Number(a.createdTime.toString()))
            .map((sudoCube) => {
              return <SudoCubeListItem key={sudoCube.startingTime.toString()} cubeDetails={sudoCube} />
            })}
        {cubes &&
          cubes
            // .sort((a, b) => Number(b.startingTime.toString()) - Number(a.startingTime.toString()))
            .map((cube) => {
              return <CubeListItem key={cube.startingTime} cubeDetails={cube} />
            })} */}
      </>
    )
  }
  return (
    <>
      <PageWrapper>
        {/* <SwapPoolTabs active={'pool'} /> */}
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <MainContentWrapper>
              <LotsCube src={LotsCubes} alt="cubes" />
              <TitleRow style={{ marginTop: '0.5rem', marginBottom: '1rem' }} padding={'0'}>
                <div></div>
                <ButtonRow>
                  <ResponsiveButtonPrimary id="join-pool-button" as={Link} to="/create-cube">
                    Create a new cube
                  </ResponsiveButtonPrimary>
                </ButtonRow>
              </TitleRow>

              <DesktopHeader>
                <div>
                  <ToggleWrapper>
                    <ToggleOption
                      onClick={
                        () => setSwitchTab(1) //no actual functionality
                      }
                      active={switchTab === 1}
                      style={{ fontSize: '19px' }}
                    >
                      <Trans>Cube List</Trans>
                    </ToggleOption>
                  </ToggleWrapper>
                </div>
              </DesktopHeader>
              <MobileHeader>
                <span style={{ borderBottom: '2px solid #0D6CE8' }}>Cube List</span>
                <MobileButton id="join-pool-button" as={Link} to="/create-cube">
                  New Cube
                </MobileButton>
              </MobileHeader>
              {combinedAllCubesLoading ? (
                <LoadingRows>
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </LoadingRows>
              ) : (cubes && cubes.length > 0) || (sudoCubes && sudoCubes.length > 0) ? (
                <TraderCubeList />
              ) : (
                <NoLiquidity>
                  <TYPE.body color={theme.text3} textAlign="center">
                    <Inbox size={48} strokeWidth={1} style={{ marginBottom: '.5rem' }} />
                    <EmptyWarning>
                      <Trans>Create a new cube.</Trans>
                    </EmptyWarning>
                  </TYPE.body>
                  {showConnectAWallet && (
                    <ButtonPrimary style={{ marginTop: '2em', padding: '8px 16px' }} onClick={toggleWalletModal}>
                      <Trans>Connect a wallet</Trans>
                    </ButtonPrimary>
                  )}
                </NoLiquidity>
              )}
            </MainContentWrapper>
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
      {/* <SwitchLocaleLink /> */}
    </>
  )
}
