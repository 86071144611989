import React, { useCallback, useContext, useEffect, useState } from 'react'
import { darken } from 'polished'
import styled from 'styled-components/macro'
import { utils } from 'ethers'

import { Currency } from '@uniswap/sdk-core'
import { AutoColumn } from 'components/Column'

import { useActiveWeb3React } from 'hooks/web3'
import Row, { AutoRow, RowBetween, RowtoColumn } from 'components/Row'
import { Info } from 'react-feather'

import { currencyId } from 'utils/currencyId'
import { ButtonPrimary } from 'components/Button'
// ============== LC ===============
import { Interface } from '@ethersproject/abi'
import LC_PERI_ABI from 'abis/lc-periphery.json'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import ReactGA from 'react-ga'
import TransactionConfirmationModal, { TransactionErrorContent } from 'components/TransactionConfirmationModal'
import { BigNumber } from '@ethersproject/bignumber'
import { RouteComponentProps } from 'react-router-dom'
import { useAllTokens, useCurrency } from 'hooks/Tokens'
import { MouseoverTooltipContent } from 'components/Tooltip'
//Icons
import CubeBlue from '../../assets/svg/cube_blue.svg'
import CubeGrey from '../../assets/images/cube_gray.png'
import CubeYellow from '../../assets/images/cube_yellow.png'
import ArrowDown from '../../assets/images/arrowdown.png'

import { toHex } from '@uniswap/v3-sdk'
import { ButtonConfirmed, ButtonPrimary as ButtonP } from 'components/Button'
import {
  LC_FACTORY_ADDRESS,
  LC_FACTORY_ADDRESS_V1_1,
  LC_FACTORY_ADDRESS_V1_2,
  LC_PERIPHERY_ADDRESS,
  LC_PERIPHERY_ADDRESS_V1_1,
  LC_PERIPHERY_ADDRESS_V1_2,
} from 'constants/addresses'
import { tryParseAmount } from 'state/swap/hooks'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useCurrencyBalance } from 'state/wallet/hooks'
import LeftArrow from '../../components/NavigationTabs/left-arrow.png'
import { ZERO_ADDRESS } from 'constants/misc'
import { AlertCircle } from 'react-feather'
import { DesktopHide, MobileHide } from 'theme'
import CubeInfoDetail from 'components/CubeInfoDetail'
import { useSudoCubeInfoByIds } from 'hooks/useSudoCubeInfo'
import { useCubeAddressByCubeId } from 'hooks/useCubeInfo'
import CountdownTimer from 'components/CountdownTimer'
import { VersionContext } from 'pages/App'
import { useWalletModalToggle } from 'state/application/hooks'
import CopyHelper from 'components/AccountDetails/Copy'
import numeral from 'numeral'

//Swap Widget
//import { SwapWidget, Theme } from '@uniswap/widgets'
//import { COINGECKO_LIST, COMPOUND_LIST } from 'constants/lists'
import Modal from 'components/Modal'
import SwapPopup from 'components/SwapPopup'
//import { NETWORK_URLS } from 'connectors'
//import '@uniswap/widgets/fonts.css'

// ========= LC ==============
const LC_PERI_INTERFACE: Interface = new Interface(LC_PERI_ABI)

const OutWrapper = styled.div`
  padding: 50px 50px 150px 50px;
  max-width: 1057px;
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 25px;
  `};
`
const InputWrapper = styled.div`
  font-size: 16px;
  color: #424242;
  letter-spacing: 0;
  font-weight: 700;
  text-align: left;
  align-items: flex-start;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    color: #000000;
  `};
`

const InputWrapperToRow = styled.div`
  font-size: 16px;
  color: #424242;
  letter-spacing: 0;
  font-weight: 700;
  text-align: left;
  align-items: flex-start;
  margin-top: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    margin-top: 0;
    color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `};
`

const StyledAutoColumn = styled(AutoColumn)`
  gap: 18px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  gap: 15px;
  margin-bottom: 15px
  `};
`
const FirstSectionContainer = styled(AutoColumn)`
  gap: 18px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  gap: 15px;
  margin-top: 0;
  margin-bottom: 0;
`};
`

const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: 3rem;
  justify-content: space-evenly;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const MobileNav = styled.img`
  position: absolute;
  height: 25px;
  width: 25px;
  display: none;
  margin-top: 3px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: inline;
`};
`

const InfoTextTitle = styled.div`
  font-size: 16px;
  color: #424242;
  font-weight: 400;
  text-align: left;
  width: 200.06px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: auto;
    font-size: 14px;
    color: #424242;
    letter-spacing: 0;
    font-weight: 600;
  `};
`

const TextTitle = styled(InfoTextTitle)`
  width: auto;
`

const CubeName = styled.div`
  font-size: 23px;
  color: #1d5398;
  font-weight: 700;
  .versionTag {
    font-size: 17px;
    //line-height: 20px;
    margin: 7px 0 0 5px;
    //padding-bottom: 15px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    flex-direction: row;
    font-size: 22px;
    padding-left: 24px;
    margin: auto;
    max-width: 80%;
    .versionTag {
      font-size: 15px;
      margin: 0;
    }
  `};
`

const OpenUntil = styled.div`
  font-size: 14px;
  color: #424242;
  letter-spacing: 0;
  font-weight: 600;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    color: #868686;
    flex-direction: column;
    font-size: 14px;
    margin: auto;
    margin-top: 15px;
  `};
`

const RangeLineItem = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: left;
  font-size: 14px;
  color: #868686;
  //margin-top: -20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    margin-top: 10px;
    border-radius: 12px;
    align-items: center;
    margin: auto;
    //padding: 8px 0;
`};
`

const InfoTitle = styled.div`
  font-size: 19px;
  font-weight: 700;
  color: #424242;
  //margin-top: 10px;
  margin-bottom: 3px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    flex-direction: row;
    font-size: 16px;
    color: #1D5398;
    gap: 16%;
    margin-bottom: 3px;
  `};
`

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 2px;
  progress[value] {
    width: 250px;
    appearance: none;
    padding-top: 5px;
    margin-right: 30px;

    ::-webkit-progress-bar {
      height: 13px;
      border-radius: 20px;
      background: #eeeeee;
    }

    ::-webkit-progress-value {
      height: 13px;
      border-radius: 20px;
      background-color: #045de9;
      background-image: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
    }
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 5px;
    progress[value] {
      width: 100%;
    }
  `};
`

const SameRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 5px;
    flex-direction: column;
  `};
`

const CubeletContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  //justify-content: center;
  max-width: 73%;
  margin-top: -5px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: flex-start;
    margin-top: 10px;
    max-width: none;
  `};
`

const OwnedCubeletContainer = styled(CubeletContainer)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
  float: right;
  width: 52%;
  margin-top: -20px;
  align-items: left;
`};
`
const InvestedContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  max-width: 73%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    float: right;
    width: 52%;
    margin-top: 15px;
    align-items: left;
`};
`

const CubeInfoWrapper = styled.div`
  padding: 0 42px;
  display: flex;
  width: 100%;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    //display: none;
    padding-left: 10px;
    padding-right: 10px;
  `};
`

const NavButton = styled.span`
  padding-bottom: 20px;
  text-decoration: none;
  color: #989898;
  font-weight: 600;
  font-size: 17px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
`};
`
const TicketWrapper = styled.div`
  padding: 0 42px;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
`};
`

const BorderLine = styled.div`
  padding: 10px 60px;
  width: 95%;
  margin: auto auto 40px auto;
  border-bottom: 1px solid #979797;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 10px;
    width: 100%;
    padding-top: 0;
`};
`

const BlueLabel = styled.div`
  font-size: 14px;
  color: #0d6ce8;
  font-weight: 700;
  border: 2px solid #0d6ce8;
  margin-left: 20px;
  margin-top: -5px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 11px;
  width: 150px;
  height: 34px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    border: 1px solid #0D6CE8;
    width: 59px;
    height: 20px;
    font-size: 11px;
    margin: 0;
    margin-top: 3px;
    line-height: 8px;
    border-radius: 7px;
`};
`

const YellowLabel = styled(BlueLabel)`
  color: #efa116;
  border: 2px solid #efa116;
  align-items: flex-end;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 107px;
    line-height: 10px;
    border: 1px solid #EFA116;
`};
`

const BlackLabel = styled(BlueLabel)`
  color: #000000;
  border: 2px solid #000000;
  align-items: flex-end;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  border: 1px solid #000000;
  width: 95px;
  line-height: 8px;
`};
`

const AvailableCubelet = styled.div`
  width: 34px;
  height: 34px;

  background-color: white;
  font-size: 17px;
  border: 2px solid #0d6ce8;
  border-radius: 4px;
  margin: 3px 5px;

  color: black;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  line-height: 34px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  :hover {
    cursor: pointer;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin: 2px 2.5px;
`};
`

const SelectedCubelet = styled(AvailableCubelet)`
  cursor: default;
  margin: 3px 5px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  //border: 1px solid #0d6ce8;
  line-height: 22px;
  font-size: 14px;
  border-radius: 4px;
  width: 25px;
  height: 25px;
  margin: 2px 2.5px;
`};
`

const SelectedCubeletSample = styled(AvailableCubelet)`
  width: 18px;
  height: 18px;
  margin: 1px 9px 0 0;
  border: 1px solid #0d6ce8;
  border-radius: 0;
  cursor: default;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 15px;
    height: 15px;
`};
`

const AvailableCubeletSample = styled(AvailableCubelet)`
  width: 18px;
  height: 18px;
  margin: 1px 9px 0 0;
  border: none;
  border-radius: 0;
  background-color: #71a9f2;
  cursor: default;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 15px;
    height: 15px;
`};
`

const InvestedCubelet = styled(AvailableCubelet)`
  background-color: #989898;
  border: 1px solid white;
  color: white;
  position: relative;

  :hover {
    cursor: not-allowed;
  }
`
const CrossLine = styled.div`
  border: 1px solid white;
  position: absolute;
  width: 141.5%;
  top: 0;
  right: 0;

  //background: linear-gradient(135deg, transparent calc(50% - 1px), red 50%, transparent calc(50% + 1px));
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
`

const OwnedCubelet = styled(AvailableCubelet)`
  background-color: #8ad76d;
  border: 1px solid white;
  color: white;
  position: relative;
  user-select: none;

  :hover {
    cursor: not-allowed;
  }
`

const SecondSectionContainer = styled.div`
  display: grid;
  text-align: left;
  grid-template-columns: 1fr 3.7fr;
  padding: 0 42px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 10px;
`};
`

const HideTickets = styled.div`
  cursor: pointer;
  font-size: 17px;
  color: #424242;
  letter-spacing: 0;
  font-weight: 600;
  .img {
    width: 16px;
  }
  &:hover {
    color: ${({ theme }) => darken(0.05, theme.bg4)};
  }
  white-space: nowrap;
  margin: 0 0 auto auto;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  ${({ theme }) => theme.mediaWidth.upToSmall`
    img{
      width: 14px;
    }
    font-size: 14px;
    margin: 6px auto 0 0;
`};
`

const FlexDiv = styled.div`
  display: flex;
  white-space: nowrap;
  font-size: 14px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    color: #000000;
    letter-spacing: 0;
    font-weight: 400;
`};
`
const Input = styled.input`
  width: 135px;
  height: 40px;
  border: 1px solid #979797;
  border-radius: 7px;
  font-size: 19px;
  font-weight: 600;
  text-align: center;
  color: black;
  margin-right: 35px;
  :focus,
  :hover {
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  //margin-left: 20px;
    margin-top: 5px;
    font-size: 15px;
    font-weight: 600;
    width: 88px;
    height: 32px;
    margin-right: 15px;
`};
`

const BottomSection = styled.div`
  margin: 40px auto auto auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-top: 10px;
`};
`

const RowToColumn = styled(Row)`
  justify-content: start;
  margin-top: 15px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: left;
`};
`

const StyledButton = styled(ButtonP)`
  font-weight: 700;
  font-size: 20px;
  margin: 20px auto auto auto;
  width: 19%;
  height: 52px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 35%;
    white-space: nowrap;
    height: 40px;
    font-size: 14px;
`};
`
const StyledConfirmButton = styled(ButtonConfirmed)`
  height: 55px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 90%;
    height: 40px;
    font-size: 14px;
  `};
`

const CubeIcon = styled.img`
  height: 40px;
  margin-top: 5px;
  margin-right: 5px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  height: 40px;
  display: none;
`};
`

const InvestmentAmount = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #424242;
  margin: 82px auto -30px auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 33px;
    margin-bottom: -5px;
    font-weight: 400;
    font-size: 15px;
`};
`

const Warning = styled.div`
  color: #c25742;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: auto auto 10px auto;
  padding: 0 10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 10px;
    font-size: 13px;
`};
`

const MobileOverInvestedWarning = styled.div`
  color: #c25742;
  font-size: 16px;
  margin: 10px auto auto auto;
  text-align: left;
  display: none;
  // flex-direction: row;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 13px;
    display: inline;
    margin-left: 10px;
    //display: none;
`};
`
const OverInvestedWarning = styled(MobileOverInvestedWarning)`
  display: block;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
`};
`

const BalanceText = styled.div`
  margin: 15px auto auto auto;
  text-align: center;
  font-weight: 600;
  font-size: 17px;
  color: #424242;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 14px;
  font-weight: 600;
  //margin-bottom: 40px;
`};
`

const Alert = styled(AlertCircle)`
  width: 80%;
  color: #c25742;
  margin-bottom: -6px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 70%;
`};
`

const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`

const ConnectWalletButton = styled(ButtonPrimary)`
  margin-top: 2em;
  padding: 8px 16px;
  margin: auto;
  margin-top: 10px;
  height: 45px;
  width: 20%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 180px;
  height: 40px;
`};
`

const StyledInfo = styled(Info)`
  opacity: 0.4;
  color: black;
  height: 18px;
  width: 18px;
  :hover {
    opacity: 0.8;
  }
  margin-bottom: -3px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  height: 16px;
  width: 16px;
`};
`

const SwapButton = styled.div`
  cursor: pointer;
  font-size: 17px;
  text-decoration: underline;
  font-weight: 700;
  margin: 10px auto 0 auto;
  text-align: center;
`

// const widgetTheme: Theme = {
//   primary: '#000',
//   secondary: '#666',
//   interactive: 'white',
//   container: 'white',
//   module: '#FFF',
//   accent: '#0068de',
//   outline: '#000',
//   dialog: '#FFF',
//   fontFamily: 'Open Sans',
//   borderRadius: 0.7,
// }

export default function SudoCubeDetail({
  match: {
    params: { version: versionFromURL, cubeAddress },
  },
  history,
}: RouteComponentProps<{
  version: string
  cubeAddress: string
}>) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { account, chainId, library } = useActiveWeb3React()
  const { currentVersion, switchVersion } = useContext(VersionContext)
  const addTransaction = useTransactionAdder()

  useEffect(() => {
    switchVersion(versionFromURL ? versionFromURL?.replace('v', '').replace('-', '.') : '1.2')
  }, [switchVersion, versionFromURL])

  // useEffect(() => {
  //   //setVersion(version ? version === 'v1' : isVersionOne)
  //   if (isVersionOne !== (version === 'v1')) {
  //     switchVersion()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [version])

  // console.log('isVersionOneFromURL', isVersionOneFromURL)
  // console.log('isVersionOne', isVersionOne)

  const toggleWalletModal = useWalletModalToggle()
  const showConnectAWallet = Boolean(!account)

  const { cubes: cubeInfoArray } = useSudoCubeInfoByIds([Number(cubeAddress)], undefined, undefined, versionFromURL)
  const cubeInfo = cubeInfoArray ? cubeInfoArray[0] : undefined

  const cubeletsInfo = cubeInfo?.cubeletsInfo

  const [showSwap, setShowSwap] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()

  const [inputNumber, setInputNumber] = useState<number>(0)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm
  const [, setShowConfirm] = useState<boolean>(false)
  const [hideCubelets, setHideCubelets] = useState<boolean>(true)
  const [selectedCubelets, setSelectedCubelets] = useState<number[]>([])

  //===============Error===================
  const [showError, setShowError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>()

  const base = useCurrency(cubeInfo?.baseCurrency)
  const numberShares = Number(cubeInfo?.numberOfCubelets)
  const cubeletsRemaining = Number(cubeInfo?.cubeletsRemaining)
  const pricePerCubelet = base
    ? base.decimals === 18
      ? utils.formatEther(cubeInfo?.pricePerCubelet as BigNumber)
      : Number(cubeInfo?.pricePerCubelet.toString()) / 10 ** 6
    : undefined
  const totalFund = parseFloat((numberShares * Number(pricePerCubelet)).toFixed(12))
  const formattedTotalFund = numeral(totalFund).format('0,0')

  const availableCubelets = numberShares - cubeletsRemaining

  const { cubeAddress: cubeId } = useCubeAddressByCubeId(cubeAddress, versionFromURL)
  const isDeployed = cubeId && cubeId !== ZERO_ADDRESS

  const isTrader = account?.toLowerCase() === cubeInfo?.traderAddr.toLowerCase()
  const isTraderPage = history.location.pathname.includes('trader')
  const isFullyInvested = cubeInfo?.cubeletsRemaining.toString() === '0'
  const investedPercent = (availableCubelets / Number(cubeInfo?.numberOfCubelets.toString())) * 100

  // ========= Token Approval ========
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)
  const [showApproveFlow, setShowApproveFlow] = useState<boolean>(true)

  const parsedAmount = tryParseAmount('1', base as Currency)
  const [approval, approveCallback] = useApproveCallback(
    parsedAmount,
    chainId
      ? currentVersion === '1.0'
        ? LC_FACTORY_ADDRESS[chainId]
        : currentVersion === '1.1'
        ? LC_FACTORY_ADDRESS_V1_1[chainId]
        : LC_FACTORY_ADDRESS_V1_2[chainId]
      : undefined
  )
  const balance = useCurrencyBalance(account ?? undefined, base ?? undefined)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval])

  useEffect(() => {
    setShowApproveFlow(
      approval === ApprovalState.NOT_APPROVED ||
        approval === ApprovalState.PENDING ||
        (approvalSubmitted && approval === ApprovalState.APPROVED)
    )
  }, [approval, approvalSubmitted])

  const today = new Date()
  const startDate = new Date(Number(cubeInfo?.startDeadline) * 1000)
  const endDate = new Date(Number(cubeInfo?.startDeadline) * 1000 + Number(cubeInfo?.tradingPeriodLength) * 1000)
  const timerComponents = CountdownTimer(startDate)

  const tokens = useAllTokens()
  const currencyList =
    cubeInfo && cubeInfo.tokenWhitelist !== [] ? cubeInfo.tokenWhitelist.map((item: string) => tokens[item]) : []

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setShowError(false)
    setHash(undefined)
    setShowSwap(false)
  }, [])

  // ======= tickets picker component ===========
  function handlePickCubelet(cubelet: number) {
    if (selectedCubelets.includes(cubelet)) {
      setSelectedCubelets(selectedCubelets.filter((item) => item !== cubelet))
    } else {
      setSelectedCubelets([...selectedCubelets, cubelet])
    }
  }

  const cubeletArray = []
  for (let i = 1; i <= numberShares; i++) {
    cubeletArray.push(i)
  }

  const cubeletsArray = []
  if (cubeletsInfo) {
    for (let i = 0; i < numberShares; i++) {
      cubeletsArray.push(
        cubeletsInfo?.filter((cubelet) => Number(cubelet.cubeletId) === i)[0]?.ownerAddr
          ? cubeletsInfo?.filter((cubelet) => Number(cubelet.cubeletId) === i)[0]?.ownerAddr
          : ZERO_ADDRESS
      )
    }
  }

  const adding: number[] = []
  cubeletsArray.map((item, i) => {
    if (item === ZERO_ADDRESS && !selectedCubelets.includes(i + 1)) {
      adding.push(i + 1)
    }
  })

  function handleNumberInput(input: number) {
    if (input && input >= cubeletsRemaining) {
      setInputNumber(cubeletsRemaining)
      setSelectedCubelets((old) => [...old, ...adding])
    } else {
      if (input > inputNumber) {
        setSelectedCubelets((old) => [...old, ...adding.slice(0, input - inputNumber)])
      } else {
        setSelectedCubelets((old) => [...old.slice(0, input)])
      }
      setInputNumber(input)
    }
  }

  useEffect(() => {
    setInputNumber(selectedCubelets.length)
  }, [selectedCubelets])

  const ownedCubeletsNumber = cubeletsArray.filter((item) => item?.toLowerCase() === account?.toLowerCase()).length

  async function onInvest() {
    if (!chainId || !library || !account) return

    const formattedSelectedCubelets = selectedCubelets.map((item) => toHex((item - 1).toString()))

    const txn: { to: string; data: string; value: string } = { to: '', data: '', value: '' }
    if (formattedSelectedCubelets && cubeInfo) {
      //const calldata: string = LC_PERI_INTERFACE.encodeFunctionData('depositETH')
      const calldata: string = LC_PERI_INTERFACE.encodeFunctionData('buyCubeletRelay', [
        [toHex(account), cubeInfo.sudoCubeId, formattedSelectedCubelets],
      ])
      //console.log(`>>>>> calldata buy share: ${calldata}`)

      setAttemptingTxn(true)

      if (currencyId(base as Currency) === 'ETH') {
        txn.to =
          currentVersion === '1.0'
            ? LC_PERIPHERY_ADDRESS[chainId]
            : currentVersion === '1.1'
            ? LC_PERIPHERY_ADDRESS_V1_1[chainId]
            : LC_PERIPHERY_ADDRESS_V1_2[chainId]
        txn.data = calldata
        txn.value = toHex((inputNumber * Number(utils.parseEther(pricePerCubelet as string).toString())).toString())
      } else {
        txn.to =
          currentVersion === '1.0'
            ? LC_PERIPHERY_ADDRESS[chainId]
            : currentVersion === '1.1'
            ? LC_PERIPHERY_ADDRESS_V1_1[chainId]
            : LC_PERIPHERY_ADDRESS_V1_2[chainId]
        txn.data = calldata
        txn.value = '0x0'
      }
    }

    //console.log(`>>>>>>>>>> tx: ${JSON.stringify(txn, null, ' ')}`)

    library
      .getSigner()
      .estimateGas(txn)
      .then((estimate) => {
        const newTxn = {
          ...txn,
          gasLimit: calculateGasMargin(chainId, estimate),
        }

        return library
          .getSigner()
          .sendTransaction(newTxn)
          .then((response: TransactionResponse) => {
            // debug
            console.log(`response: ${response}`)
            setAttemptingTxn(false)
            addTransaction(response, {
              summary: 'Invested ' + inputNumber + ' shares in Cube ' + '"' + cubeInfo?.cubeName + '"',
            })
            setHash(response.hash)
            history.push('/investor')
            ReactGA.event({
              category: 'Investor',
              action: 'Invest',
              label: cubeInfo?.cubeName ?? '',
            })
          })
      })
      .catch((error) => {
        console.error('Failed to send transaction', error)
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
          setShowError(true)
          if (error.message.includes('LCF:WrongState')) {
            setErrorMsg('The cube is fully invested. Waiting for the trader to start.')
          } else if (error.message.includes('STF')) {
            setErrorMsg(
              'Safe transaction failed. Please make sure you have enough balance and the currency is approved.'
            )
          } else if (error.message.includes('LCF:cubeletTaken')) {
            setErrorMsg('Some of your selected cubelets are taken.')
          } else {
            setErrorMsg(error.message)
          }
        }
      })
  }

  async function onReturnCubelets() {
    if (!chainId || !library || !account) return
    cubeletsInfo?.filter((tokenId) => tokenId.ownerAddr?.toString())

    const txn: { to: string; data: string; value: string } = { to: '', data: '', value: '' }
    if (cubeInfo && cubeletsInfo) {
      const returningTokenIds = cubeletsInfo
        .filter((tokenId) => tokenId.ownerAddr?.toString() === account)
        .map((tokenId) => toHex(tokenId.tokenId))
      //cubeletsInfo.filter((tokenId) => tokenId.ownerAddress?.toString())
      //const calldata: string = LC_PERI_INTERFACE.encodeFunctionData('depositETH')
      const calldata: string = LC_PERI_INTERFACE.encodeFunctionData('returnCubeletRelay', [
        [toHex(account), cubeInfo.sudoCubeId, returningTokenIds],
      ])
      //console.log(`>>>>> calldata return share: ${calldata}`)

      setAttemptingTxn(true)

      // if (currencyId(base as Currency) === 'ETH') {
      //   txn.to = LC_PERIPHERY_ADDRESS[chainId]
      //   txn.data = calldata
      //   txn.value = toHex((Number(numSharesToBuy) * Number(pricePerShare.toString())).toString())
      // } else {
      txn.to =
        currentVersion === '1.0'
          ? LC_PERIPHERY_ADDRESS[chainId]
          : currentVersion === '1.1'
          ? LC_PERIPHERY_ADDRESS_V1_1[chainId]
          : LC_PERIPHERY_ADDRESS_V1_2[chainId]
      txn.data = calldata
      txn.value = '0x0'
      //}

      //console.log(`>>>>>>>>>> tx: ${JSON.stringify(txn, null, ' ')}`)

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              // debug
              console.log(`response: ${response}`)
              setAttemptingTxn(false)
              addTransaction(response, {
                summary: 'Returned all invested cubelets from Cube ' + cubeInfo?.cubeName,
              })
              setHash(response.hash)
              history.push('/investor')
              ReactGA.event({
                category: 'Investor',
                action: 'Return',
                label: cubeInfo?.cubeName ?? '',
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          setAttemptingTxn(false)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
            setShowError(true)
            setErrorMsg(error.message)
          }
        })
    } else {
      console.log('Return cubelets failed.')
      return
    }
  }

  async function onDeployCube() {
    if (!chainId || !library || !account) return

    const txn: { to: string; data: string; value: string } = { to: '', data: '', value: '' }
    if (cubeInfo) {
      //const calldata: string = LC_PERI_INTERFACE.encodeFunctionData('depositETH')
      const calldata: string = LC_PERI_INTERFACE.encodeFunctionData('createCubeRelay', [cubeInfo.sudoCubeId])
      //console.log(`>>>>> calldata create cube from sudo cube: ${calldata}`)
      setAttemptingTxn(true)

      txn.to =
        currentVersion === '1.0'
          ? LC_PERIPHERY_ADDRESS[chainId]
          : currentVersion === '1.1'
          ? LC_PERIPHERY_ADDRESS_V1_1[chainId]
          : LC_PERIPHERY_ADDRESS_V1_2[chainId]
      txn.data = calldata
      txn.value = '0x0'
    }

    //console.log(`>>>>>>>>>> tx: ${JSON.stringify(txn, null, ' ')}`)

    library
      .getSigner()
      .estimateGas(txn)
      .then((estimate) => {
        const newTxn = {
          ...txn,
          gasLimit: calculateGasMargin(chainId, estimate),
        }

        return library
          .getSigner()
          .sendTransaction(newTxn)
          .then((response: TransactionResponse) => {
            // debug
            console.log(`response: ${response}`)
            setAttemptingTxn(false)
            addTransaction(response, {
              summary: 'Deployed the cube ' + cubeInfo?.cubeName + '.',
            })
            setHash(response.hash)
            //history.push('/trader')
            ReactGA.event({
              category: 'Trader',
              action: 'Create cube',
              label: cubeInfo?.cubeName ?? '',
            })
          })
      })
      .catch((error) => {
        console.error('Failed to send transaction', error)
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
          setShowError(true)
          setErrorMsg(error.message)
        }
      })
  }

  async function onStartTrading() {
    if (!chainId || !library || !account) return

    const txn: { to: string; data: string; value: string } = { to: '', data: '', value: '' }
    if (cubeInfo) {
      //const calldata: string = LC_PERI_INTERFACE.encodeFunctionData('depositETH')
      const calldata: string = LC_PERI_INTERFACE.encodeFunctionData('initCubeRelay', [cubeInfo.sudoCubeId])
      //console.log(`>>>>> calldata create cube from sudo cube: ${calldata}`)
      setAttemptingTxn(true)

      txn.to =
        currentVersion === '1.0'
          ? LC_PERIPHERY_ADDRESS[chainId]
          : currentVersion === '1.1'
          ? LC_PERIPHERY_ADDRESS_V1_1[chainId]
          : LC_PERIPHERY_ADDRESS_V1_2[chainId]
      txn.data = calldata
      txn.value = '0x0'
    }

    //console.log(`>>>>>>>>>> tx: ${JSON.stringify(txn, null, ' ')}`)

    library
      .getSigner()
      .estimateGas(txn)
      .then((estimate) => {
        const newTxn = {
          ...txn,
          gasLimit: calculateGasMargin(chainId, estimate),
        }

        return library
          .getSigner()
          .sendTransaction(newTxn)
          .then((response: TransactionResponse) => {
            // debug
            console.log(`response: ${response}`)
            setAttemptingTxn(false)
            addTransaction(response, {
              summary: 'The cube ' + cubeInfo?.cubeName + ' begins trading',
            })
            setHash(response.hash)
            history.push('/trader')
            ReactGA.event({
              category: 'Trader',
              action: 'Create cube',
              label: cubeInfo?.cubeName ?? '',
            })
          })
      })
      .catch((error) => {
        console.error('Failed to send transaction', error)
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
          setShowError(true)
          if (error.message.includes('LCF:WrongState')) {
            setErrorMsg('Wrong state! The trading has started for this cube.')
          } else {
            setErrorMsg(error.message)
          }
        }
      })
  }

  return (
    <>
      <OutWrapper>
        <Tabs>
          {/* Desktop only */}
          <Row style={{ padding: '0' }}>
            <NavButton
              onClick={() => history.push(isTraderPage ? '/trader' : '/investor')}
              style={{
                marginRight: '8px',
                cursor: 'pointer',
              }}
            >
              Cube List
            </NavButton>
            <NavButton>
              {' '}
              {'>'} {cubeInfo?.cubeName}
            </NavButton>
          </Row>
        </Tabs>
        <TransactionConfirmationModal
          isOpen={showError}
          onDismiss={handleDismissConfirmation}
          attemptingTxn={attemptingTxn}
          hash={hash}
          content={() => (
            <TransactionErrorContent
              onDismiss={() => {
                setShowError(false)
                handleDismissConfirmation()
              }}
              message={errorMsg}
            />
          )}
          pendingText={undefined}
        />

        <MobileNav
          src={LeftArrow}
          alt="left-arrow"
          onClick={() => history.push(isTraderPage ? '/trader' : '/investor')}
        />
        <Row>
          <Row>
            <CubeIcon
              src={today > startDate ? CubeGrey : investedPercent.toFixed(2) === '100.00' ? CubeYellow : CubeBlue}
              alt="blueCube"
            />
            <CubeName>
              <div>
                {cubeInfo?.cubeName} <span className="versionTag">v{cubeInfo?.version.replace('-', '.')}</span>
                {'  '}
              </div>
              <DesktopHide style={{ margin: 'auto' }}>
                <CopyHelper toCopy={window.location.href.replace('trader', 'investor')} style={1} />
              </DesktopHide>
            </CubeName>
            <MobileHide>
              <CopyHelper toCopy={window.location.href.replace('trader', 'investor')} style={1} />
            </MobileHide>
          </Row>
          <RangeLineItem style={{ marginLeft: 'auto' }}>
            <span>Funding Ends:</span>
            <span style={{ fontSize: '15px', color: 'black', marginTop: '7px' }}>
              {cubeInfo && (today < startDate ? timerComponents : <strong> Funding Ended.</strong>)}{' '}
            </span>
          </RangeLineItem>
        </Row>
        <CubeInfoWrapper>
          <FirstSectionContainer>
            <SameRow>
              <OpenUntil>
                <span>Open for Investment Until </span>
                <DesktopHide style={{ color: 'black', margin: 'auto' }}>
                  {startDate.toLocaleString('en-US')},
                  {startDate.toLocaleDateString('en-US', { timeZoneName: 'short' }).split(',')[1]}
                </DesktopHide>
                <MobileHide>
                  {startDate.toLocaleString('en-US')},
                  {startDate.toLocaleDateString('en-US', { timeZoneName: 'short' }).split(',')[1]}
                </MobileHide>
              </OpenUntil>
            </SameRow>

            <div style={{ margin: 'auto' }}>
              <DesktopHide style={{ margin: '0 auto 5px auto', display: 'flex', flexDirection: 'column' }}>
                {cubeInfo &&
                  (today > startDate ? (
                    <DesktopHide>
                      <strong> Funding Ended.</strong>
                    </DesktopHide>
                  ) : (
                    <>
                      <DesktopHide>Funding Ends In:</DesktopHide>
                      <DesktopHide style={{ fontSize: '13px', color: 'black', marginTop: '7px' }}>
                        {cubeInfo && (today < startDate ? timerComponents : <strong> Funding Ended.</strong>)}{' '}
                      </DesktopHide>
                    </>
                  ))}
              </DesktopHide>
            </div>

            <InfoTitle>
              <span>Cube Info</span>
              <DesktopHide>
                {today > startDate ? (
                  <BlackLabel>Funding Failed</BlackLabel>
                ) : investedPercent.toFixed(2) === '100.00' ? (
                  <YellowLabel>Waiting to Trade</YellowLabel>
                ) : (
                  <BlueLabel>Funding</BlueLabel>
                )}
              </DesktopHide>
            </InfoTitle>

            <CubeInfoDetail
              currencyList={currencyList}
              base={base as Currency}
              totalFund={formattedTotalFund}
              traderCut={cubeInfo?.traderCut.toString()}
              traderAddr={cubeInfo?.traderAddr}
              date={startDate}
              endDate={endDate}
              tradingPeriodLength={cubeInfo?.tradingPeriodLength}
            />
          </FirstSectionContainer>

          <BorderLine />
        </CubeInfoWrapper>
        <>
          <SecondSectionContainer style={{ marginBottom: '10px' }}>
            <DesktopHide>
              <InfoTitle>Invest Cubelet</InfoTitle>
            </DesktopHide>

            <MobileHide>
              {' '}
              <InputWrapper style={{ fontSize: '19px', fontWeight: 700, color: '#424242' }}>
                Invest Cubelet
              </InputWrapper>
            </MobileHide>
            <ProgressBarContainer>
              <InputWrapperToRow style={{ marginTop: '0' }}>
                <TextTitle style={{ marginRight: '30px' }}>Total Shares: {cubeInfo?.numberOfCubelets}</TextTitle>
                <DesktopHide>
                  <TextTitle>{investedPercent.toFixed(2)}% Raised</TextTitle>
                </DesktopHide>
              </InputWrapperToRow>
              <progress
                value={availableCubelets ? availableCubelets : 0}
                max={cubeInfo ? Number(cubeInfo?.numberOfCubelets.toString()) : 0}
              />
              <MobileHide>
                <TextTitle style={{ fontWeight: 600 }}>{investedPercent.toFixed(2)}% Raised</TextTitle>
              </MobileHide>
            </ProgressBarContainer>
          </SecondSectionContainer>

          <SecondSectionContainer>
            <MobileHide>
              <TextTitle style={{ marginTop: '10px' }}>Cubelet Price:</TextTitle>
            </MobileHide>
            <InputWrapperToRow style={{ marginTop: '10px', marginBottom: '10px' }}>
              <DesktopHide>
                <TextTitle style={{ marginRight: '10px' }}>Cubelet Price:</TextTitle>
              </DesktopHide>
              1 Cubelet = {Number(pricePerCubelet)} {base?.symbol}
            </InputWrapperToRow>
          </SecondSectionContainer>

          {!isFullyInvested && today < startDate && !isTraderPage ? (
            <SecondSectionContainer>
              <MobileHide>
                <TextTitle style={{ marginTop: '15px' }}>Cubelet Amount:</TextTitle>
              </MobileHide>

              <InputWrapperToRow>
                <DesktopHide>
                  <TextTitle style={{ marginRight: '5px', marginTop: '12px' }}>Cubelet Amount:</TextTitle>
                </DesktopHide>
                <span>
                  <Input
                    className="number-input"
                    value={inputNumber}
                    type="text"
                    pattern="[0-9]*"
                    onChange={(val) => {
                      handleNumberInput(Number(val.target.value))
                    }}
                    placeholder={'0'}
                  />
                  <span>Cubelets</span>
                </span>
                {inputNumber > 20 && (
                  <OverInvestedWarning>
                    {/* <Alert /> */}
                    <MouseoverTooltipContent
                      content={
                        <span>
                          Due to ETH block gas limit, a single transaction should contain no more than 20 cubelets
                        </span>
                      }
                    >
                      <StyledInfo /> Please invest no more than 20 cubelets at a time
                    </MouseoverTooltipContent>
                  </OverInvestedWarning>
                )}
              </InputWrapperToRow>
            </SecondSectionContainer>
          ) : null}

          {inputNumber > 20 && (
            <MobileOverInvestedWarning>
              {/* <Alert /> */}{' '}
              <MouseoverTooltipContent
                content={
                  <span> Due to ETH block gas limit, a single transaction should contain no more than 20 cubelets</span>
                }
              >
                <StyledInfo /> Please invest no more than 20 cubelets at a time
              </MouseoverTooltipContent>
            </MobileOverInvestedWarning>
          )}

          <SecondSectionContainer>
            {!isFullyInvested && today < startDate && !isTraderPage ? (
              <MobileHide>
                <TextTitle style={{ lineHeight: '35px', marginTop: '10px', marginBottom: '10px' }}>
                  Selected Cubelets:
                </TextTitle>
              </MobileHide>
            ) : null}

            <StyledAutoColumn>
              {!isFullyInvested && today < startDate && !isTraderPage ? (
                <TextTitle>
                  <RowToColumn style={{ maxWidth: '600px' }}>
                    {/* Desktop selected Cubelets */}
                    <CubeletContainer style={{ marginRight: 'auto' }}>
                      {selectedCubelets.length !== 0 && (
                        <DesktopHide>
                          <TextTitle style={{ marginRight: '10px' }}>Selected Cubelets:</TextTitle>
                        </DesktopHide>
                      )}
                      {selectedCubelets &&
                        selectedCubelets
                          .sort((a, b) => a - b)
                          .map((item, i) => <SelectedCubelet key={i}>{item}</SelectedCubelet>)}
                    </CubeletContainer>
                    <HideTickets onClick={() => setHideCubelets(!hideCubelets)}>
                      Review Cubelets Number
                      <img
                        src={ArrowDown}
                        className="hide-ticket"
                        alt="arrow-down"
                        style={{ marginLeft: '5px' }}
                        width="18px"
                      />
                    </HideTickets>
                  </RowToColumn>
                </TextTitle>
              ) : null}
            </StyledAutoColumn>
          </SecondSectionContainer>
        </>

        <TicketWrapper>
          {!hideCubelets && (
            <>
              <MobileHide>
                <BorderLine />
              </MobileHide>
              <DesktopHide>
                <BorderLine style={{ margin: '-20px 0 10px 0' }} />
              </DesktopHide>
              <MobileHide>
                <RowBetween margin="auto" maxWidth="60%">
                  <FlexDiv>
                    <SelectedCubeletSample />
                    selected
                  </FlexDiv>
                  <FlexDiv>
                    <AvailableCubeletSample />
                    Available
                  </FlexDiv>
                  <FlexDiv>
                    <AvailableCubeletSample style={{ backgroundColor: '#989898' }} />
                    Invested Cubelets
                  </FlexDiv>
                  <FlexDiv>
                    <AvailableCubeletSample style={{ backgroundColor: '#8ad76d' }} />
                    My Invested Cubelets
                  </FlexDiv>
                </RowBetween>
              </MobileHide>
              <DesktopHide>
                <Row margin="0 auto 11px auto" padding="0 10px">
                  <FlexDiv style={{ marginRight: '70px' }}>
                    <SelectedCubeletSample />
                    selected
                  </FlexDiv>
                  <FlexDiv>
                    <AvailableCubeletSample />
                    Available
                  </FlexDiv>
                </Row>
                <Row margin="auto" padding="0 10px">
                  <FlexDiv style={{ marginRight: '17px' }}>
                    <AvailableCubeletSample style={{ backgroundColor: '#989898' }} />
                    Invested Cubelets
                  </FlexDiv>
                  <FlexDiv>
                    <AvailableCubeletSample style={{ backgroundColor: '#8ad76d' }} />
                    My Invested Cubelets
                  </FlexDiv>
                </Row>
              </DesktopHide>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CubeletContainer style={{ margin: '26px auto 10px auto' }}>
                  {cubeletsArray.map((item, i) =>
                    item?.toLowerCase() === account?.toLowerCase() ? (
                      <OwnedCubelet key={i}>{i > 99 ? ('0' + (i + 1)).slice(-2) : i + 1}</OwnedCubelet>
                    ) : item === ZERO_ADDRESS ? (
                      <AvailableCubelet
                        key={i}
                        style={{
                          backgroundColor: selectedCubelets.includes(i + 1) ? '#FFFFFF' : '#71a9f2',
                          border: selectedCubelets.includes(i + 1) ? '2px solid #0d6ce8' : '1px solid white',
                          color: selectedCubelets.includes(i + 1) ? 'black' : 'white',
                        }}
                        onClick={() => handlePickCubelet(i + 1)}
                      >
                        {i > 99 ? ('0' + (i + 1)).slice(-2) : i + 1}
                      </AvailableCubelet>
                    ) : (
                      <InvestedCubelet key={i}>
                        <CrossLine />
                        {i > 99 ? ('0' + (i + 1)).slice(-2) : i + 1}
                      </InvestedCubelet>
                    )
                  )}
                </CubeletContainer>
              </div>
            </>
          )}
          {cubeletsArray.filter((item) => item?.toLowerCase() == account?.toLowerCase()).length !== 0 && !isTraderPage && (
            <DesktopHide>
              <AutoRow padding="5px 10px 0 10px" justify="space-between">
                <TextTitle>You Invested:</TextTitle>
                <InputWrapper style={{ whiteSpace: 'nowrap' }}>
                  <span style={{ color: 'black' }}>
                    {' '}
                    {cubeletsArray.filter((item) => item?.toLowerCase() == account?.toLowerCase()).length} Cubelets (
                    {cubeletsArray &&
                      parseFloat(
                        (
                          cubeletsArray.filter((item) => item?.toLowerCase() == account?.toLowerCase()).length *
                          Number(pricePerCubelet)
                        ).toFixed(12)
                      )}{' '}
                    {base?.symbol})
                  </span>
                </InputWrapper>
              </AutoRow>
              <InvestedContainer>
                {cubeletsArray.map(
                  (item, i) =>
                    item?.toLowerCase() == account?.toLowerCase() && (
                      <SelectedCubelet
                        style={{ backgroundColor: '#8AD76D', border: 'none', color: 'white', lineHeight: '25px' }}
                        key={`${item}${i}`}
                      >
                        {i > 99 ? ('0' + (i + 1)).slice(-2) : i + 1}
                      </SelectedCubelet>
                    )
                )}
              </InvestedContainer>
            </DesktopHide>
          )}

          <MobileHide>
            {ownedCubeletsNumber > 0 && !isTraderPage && (
              <Row justify={'start'} marginTop="10px">
                <TextTitle style={{ marginRight: '20px' }}>My Invested Cubelets:</TextTitle>
                <OwnedCubeletContainer style={{ marginTop: '-5px' }}>
                  {cubeletsArray.map(
                    (item, i) =>
                      item?.toLowerCase() == account?.toLowerCase() && (
                        <OwnedCubelet key={i} style={{ margin: '0 5px' }}>
                          {i > 99 ? ('0' + (i + 1)).slice(-2) : i + 1}
                        </OwnedCubelet>
                      )
                  )}
                </OwnedCubeletContainer>
              </Row>
            )}
          </MobileHide>

          {!showConnectAWallet && today < startDate && (inputNumber || !isFullyInvested) && !isTraderPage ? (
            <InvestmentAmount>
              Investment Amount: {}
              <strong>
                {parseFloat((Number(pricePerCubelet) * inputNumber).toFixed(12))} {base?.symbol}
              </strong>
            </InvestmentAmount>
          ) : null}
        </TicketWrapper>

        <BottomSection>
          {today > startDate && (
            <>
              <Warning>
                <MouseoverTooltipContent
                  content={
                    <span>
                      {isTraderPage
                        ? 'You can still start trading when the cube is fully invested.'
                        : 'You can withdraw at any time. The trader can still start trading when the cube is fully invested even if the funding period has ended.'}
                    </span>
                  }
                >
                  <Alert />
                </MouseoverTooltipContent>
                Cube Funding Expired.
              </Warning>
            </>
          )}
          {showConnectAWallet ? (
            <ConnectWalletButton onClick={toggleWalletModal}>Connect a wallet</ConnectWalletButton>
          ) : isTraderPage ? (
            isFullyInvested ? (
              isTrader ? (
                <>
                  {!isDeployed ? (
                    <>
                      <Warning style={{ textAlign: 'center', marginBottom: '10px' }}>
                        Please first deploy cube then start trading.
                      </Warning>
                      <StyledButton onClick={onDeployCube}>{attemptingTxn ? 'Deploying' : 'Deploy Cube'}</StyledButton>
                    </>
                  ) : (
                    <>
                      {/* <Warning style={{ textAlign: 'center', marginBottom: '10px' }}>
                      Please first deploy cube then start trading.
                    </Warning> */}
                      <StyledButton onClick={onStartTrading}>
                        {attemptingTxn ? 'Starting' : 'Start Trading'}
                      </StyledButton>
                    </>
                  )}
                </>
              ) : null
            ) : null // investor page
          ) : today < startDate ? (
            isFullyInvested ? (
              <Warning>
                <MouseoverTooltipContent
                  content={
                    'If the trader does not start trading by ' +
                    startDate.toLocaleString('en-US') +
                    ', you can withdraw all the invested cubelets'
                  }
                >
                  <Alert />
                </MouseoverTooltipContent>
                Waiting for trader to start
              </Warning>
            ) : showApproveFlow && selectedCubelets.length !== 0 ? (
              <>
                <RowtoColumn style={{ margin: 'auto', maxWidth: '50%' }}>
                  <StyledConfirmButton
                    onClick={approveCallback}
                    disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                    width="46%"
                    altDisabledStyle={false} // show solid button while waiting
                    confirmed={approval === ApprovalState.APPROVED}
                  >
                    {approval === ApprovalState.PENDING ? (
                      <Dots>
                        Approving {base?.symbol}
                        {/* <Loader stroke="white" /> */}
                      </Dots>
                    ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                      'Approved'
                    ) : (
                      'Approve ' + base?.symbol
                    )}
                  </StyledConfirmButton>
                  <StyledConfirmButton
                    onClick={onInvest}
                    width="46%"
                    id="transfer-button"
                    disabled={
                      inputNumber > 20 ||
                      approval !== ApprovalState.APPROVED ||
                      Number(balance?.toExact()) < parseFloat((Number(pricePerCubelet) * inputNumber).toFixed(12))
                    }
                  >
                    Confirm Invest
                  </StyledConfirmButton>
                </RowtoColumn>

                <BalanceText>
                  {Number(balance?.toExact()) < parseFloat((Number(pricePerCubelet) * inputNumber).toFixed(12)) && (
                    <Warning>Insufficient balance!</Warning>
                  )}
                  Your Balance: {balance?.toSignificant(4)} {base?.symbol}
                </BalanceText>
                <SwapButton onClick={() => setShowSwap(true)}>Swap</SwapButton>
              </>
            ) : (
              <>
                <StyledButton
                  disabled={
                    inputNumber > 20 ||
                    selectedCubelets.length === 0 ||
                    Number(balance?.toExact()) < parseFloat((Number(pricePerCubelet) * inputNumber).toFixed(12))
                  }
                  onClick={onInvest}
                >
                  {attemptingTxn
                    ? // <RowBetween>
                      //   Investing
                      //   {/* <Loader stroke="white" /> */}
                      // </RowBetween>
                      'Investing'
                    : 'Invest'}
                </StyledButton>
                {Number(balance?.toExact()) < parseFloat((Number(pricePerCubelet) * inputNumber).toFixed(12)) && (
                  <Warning>Insufficient balance!</Warning>
                )}

                <BalanceText>
                  Your Balance: {balance?.toSignificant(4)} {base?.symbol}
                  {account && <SwapButton onClick={() => setShowSwap(true)}>Swap</SwapButton>}
                </BalanceText>
              </>
            )
          ) : (
            <StyledButton onClick={onReturnCubelets} disabled={ownedCubeletsNumber === 0}>
              {attemptingTxn
                ? // <RowBetween>
                  //   {/* <Loader stroke="white" />  */}
                  //   withdraw
                  // </RowBetween>
                  'Withdrawing'
                : 'Withdraw'}
            </StyledButton>
          )}
        </BottomSection>

        <Modal isOpen={showSwap} onDismiss={handleDismissConfirmation}>
          {/* <SwapWidget
              provider={library}
              jsonRpcEndpoint={chainId === 1 ? NETWORK_URLS[1] : undefined}
              tokenList={chainId === 1 ? COINGECKO_LIST : COMPOUND_LIST}
              theme={widgetTheme}
              defaultOutputTokenAddress={base?.isToken ? base.address : undefined}
              defaultOutputAmount={base?.isToken ? parseFloat((Number(pricePerCubelet) * inputNumber).toFixed(12)) : 0}
              //defaultOutputAmount={100}
            /> */}
          <SwapPopup
            // defaultOutputToken={base?.isToken ? base.address : undefined}
            defaultOutputToken={base}
            defaultOutputAmount={parseFloat((Number(pricePerCubelet) * inputNumber).toFixed(12))}
          />
        </Modal>
      </OutWrapper>
    </>
  )
}
