import { Percent } from '@uniswap/sdk-core'
import { Position } from '@uniswap/v3-sdk'
import { toHex } from '@uniswap/v3-sdk'
import { Interface } from '@ethersproject/abi'
import { BigNumber } from 'ethers'
import { ZERO_ADDRESS } from 'constants/misc'

export function useLCRemoveLiquidity(
  account: string,
  cubeAddress: string,
  tokenId: BigNumber,
  position: Position,
  INTERFACE: Interface,
  liquidityPercentage: Percent,
  // fee0Value: CurrencyAmount<Currency>,
  // fee1Value: CurrencyAmount<Currency>
  fee0Value: string,
  fee1Value: string
) {
  //const { account } = useActiveWeb3React()
  const calldatas: string[] = []
  const decreasePercent = liquidityPercentage.numerator.toString()

  console.log(`decrease percent: ${decreasePercent}`)
  console.log(`type decrease percent: ${typeof decreasePercent}`)

  const decreasePercentByte = toHex(decreasePercent)
  console.log(`tokenId: ${tokenId}`)
  console.log(`decreasePercentByte: ${decreasePercentByte}`)
  console.log(position)
  //console.log('amount0Min: ', fee0Value.quotient.toString(), 'amount1Min: ', fee1Value.quotient.toString())
  // remove liquidity
  if (account) {
    calldatas.push(
      INTERFACE.encodeFunctionData('decreaseLiquidity', [
        {
          cubeAddr: toHex(cubeAddress),
          traderAddr: toHex(account),
          liquidity: toHex(position.liquidity.toString()), //newly added
          selectedPlatform: toHex(0),
          decreaseParams: {
            recipient: toHex(cubeAddress),
            tokenId: toHex(tokenId.toString()),
            v2PositionId: ZERO_ADDRESS,
            decreasePercent: toHex(decreasePercent),
            // amount0Min: toHex(fee0Value.quotient.toString()),
            // amount1Min: toHex(fee1Value.quotient.toString()),
            amount0Min: toHex(fee0Value),
            amount1Min: toHex(fee1Value),
          },
        },
      ])
    )
  }

  // collect
  calldatas.push(
    INTERFACE.encodeFunctionData('collectAllFees', [
      position.pool.token0.address,
      position.pool.token1.address,
      // cubeAddr: toHex(cubeAddress),
      // traderAddr: toHex(account),
      // selectedPlatform: toHex(0),
      {
        cubeAddr: toHex(cubeAddress),
        traderAddr: toHex(account),
        selectedPlatform: toHex(0),
        collectParams: {
          tokenId: toHex(tokenId.toString()),
          recipient: toHex(cubeAddress),
        },
        // tokenId: toHex(tokenId.toString()),
        // recipient: toHex(cubeAddress),
      },
    ])
  )

  const calldataRe: string[] = []
  calldataRe.push(INTERFACE.encodeFunctionData('multicall', [calldatas]))
  return calldataRe
}
