import { useCurrency } from 'hooks/Tokens'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { MEDIA_WIDTHS } from 'theme'
import { Currency } from '@uniswap/sdk-core'
import { SudoCubeDetails } from 'types/sudoCube'
import CurrencyLogo from 'components/CurrencyLogo'
import { useActiveWeb3React } from 'hooks/web3'
import { utils } from 'ethers'
import CubeBlue from '../../assets/images/cube_blue.png'
//import CubeRed from '../../assets/images/cube_red.png'
import CubeYellow from '../../assets/images/cube_yellow.png'
import CubeGrey from '../../assets/images/cube_gray.png'
import UniswapLogo from '../../assets/images/uniswap.png'
import { CubeStatusLabel } from 'components/CubeStatusLabel'
import numeral from 'numeral'

const InsideWrapper = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 0;
  :hover {
    background-color: ${({ theme }) => theme.bg1};
  }
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    /* flex-direction: row; */
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
  `};
`

const Wrapper = styled.div`
  user-select: none;
  justify-content: space-between;
  border-bottom: ${({ theme }) => '1px solid' + theme.bg2};
  color: ${({ theme }) => theme.text1};
  margin: 8px 0;
  padding-bottom: 12px;
  text-decoration: none;
  font-weight: 500;

  &:last-of-type {
    margin: 8px 0 0 0;
  }
  & > div:not(:first-child) {
    text-align: left;
  }

  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    /* flex-direction: row; */
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 0;
    padding: 5px 0 10px 0;
  `};
`

const SmallCube = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 8px;
  margin-top: -7px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 23px;
    height: 23px;
    margin: 0;
    margin-right: 5px;
  `};
`

const CubeName = styled.div`
  font-size: 19px;
  color: #1d5398;
  margin-top: -5px;
  //display: flex;
  font-weight: 700;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .versionTag {
    //position: absolute;
    font-size: 15px;
    line-height: 20px;
    margin: 0 0 3px 5px;
    padding-bottom: 15px;
    .versionTag {
      font-size: 13px;
    }
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 140px;
    font-size: 16px;
    margin-top: 0;
  `};
`

const GridContainer = styled.div`
  display: grid;
  margin-left: 2%;
  //grid-template-columns: 1.8fr 1.4fr 1.2fr 1fr 0.5fr;
  grid-template-columns: 1.2fr 1fr 1fr 0.5fr 0.5fr 0.3fr;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr 1fr;
    margin: 0;
  `};
`
const SecondRowGrid = styled.div`
  display: grid;
  margin-left: 6.5%;
  grid-template-columns: 1.5fr 5fr;
  margin-bottom: 10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: -8px;
    margin-left: 8%;
    display: block;
    text-align: left;
    margin-bottom: 6px;
  `};
`

const ThirdRowGrid = styled.div`
  display: flex;
  margin-left: 6.5%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    margin-top: -13px;
    margin-left: 8%;
    margin-bottom: 10px;
  `};
`

const ExtentsText = styled.div`
  color: #424242;
  font-size: 15px;
  margin-right: 4px;
  //width: 40%;
  display: flex;
  font-weight: 400;
  flex-direction: row;
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    font-size: 14px;
  `};
  text-align: left;
`

const MobileOnlyText = styled.div`
  color: #424242;
  margin-right: 4px;
  display: none;
  font-weight: 600;
  flex-direction: row;
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    font-size: 13px;
  `};
  text-align: left;
`

const FundRaised = styled.span`
  font-style: italic;
  font-size: 14px;
  color: #70b954;
  white-space: nowrap;
  margin-top: 3px;
  font-weight: 600;
`

const PercentText = styled.div`
  color: #424242;
  font-size: 18px;
  margin-right: 4px;
  display: flex;
  flex-direction: column;
  //padding-bottom: 17px;
  white-space: nowrap;
  max-height: 50px;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
  text-align: left;
`

const TitleText = styled.div`
  color: #424242;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  //padding-bottom: 17px;
  white-space: nowrap;
  width: 40%;
  font-weight: 400;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 1fr 2fr;
  `};
  text-align: left;
`

const DesktopHidedText = styled.div`
  color: #424242;
  display: none;
  flex-direction: row;
  //padding-bottom: 17px;
  white-space: nowrap;
  font-weight: 600;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    font-size: 13px;
    display: grid;
    grid-template-columns: 1fr 2fr;
  `};
  text-align: left;
`

const DataText = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 700;
  font-size: 22px;
  padding-bottom: 10px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
    //justify-content: space-between;
    padding: 0;
  `};
`

const WhiteListDesktop = styled.div`
  display: flex;
  margin-top: 7px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};

  .whitelistIcon {
    margin-left: 61px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 150px;
      margin-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  `};
  }
`

const WhiteListMobile = styled.div`
  display: none;
  margin-left: 8%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: flex;
`};
  .whitelistIcon {
    margin-left: 61px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 145px;
      margin-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  `};
  }
`

const CubeletsContainer = styled.div`
  // display: flex;
  // flex-direction: row;
  display: inline-block;
  min-width: 300px;
  max-width: 600px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -3px;
  // white-space: nowrap;
  // overflow: hidden;
`

const OwnedCubelet = styled.div`
  width: 29px;
  height: 100%;
  display: inline-block;
  background-color: #8ad76d;
  font-size: 18px;
  color: white;
  font-weight: 400;
  min-width: 29px;
  min-height: 29px;
  text-align: center;
  vertical-align: middle;
  line-height: 29px;
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`
interface CubeListItemProps {
  cubeDetails: SudoCubeDetails
}

export function formatFloat(src: number, pos: number) {
  return Math.round(src * Math.pow(10, pos)) / Math.pow(10, pos)
}

export default function SudoCubeListItem({ cubeDetails }: CubeListItemProps) {
  const {
    sudoCubeId,
    cubeName,
    //traderCut,
    startDeadline,
    traderAddr,
    baseCurrency,
    numberOfCubelets,
    pricePerCubelet,
    cubeletsRemaining,
    tokenWhitelist,
    cubeletsInfo,
    version,
  } = cubeDetails
  const history = useHistory()
  const { account } = useActiveWeb3React()

  const defaultCurrency = useCurrency('ETH')
  const base = useCurrency(baseCurrency)
  const numberShares = Number(numberOfCubelets)
  const investedShare = numberShares - Number(cubeletsRemaining)

  const pricePerCublet = base
    ? base.decimals === 18
      ? utils.formatEther(pricePerCubelet)
      : Number(pricePerCubelet.toString()) / 10 ** 6
    : undefined
  const totalFund = parseFloat((numberShares * Number(pricePerCublet)).toFixed(12))

  const formattedTotalFund = numeral(totalFund).format('0,0')

  const investedPercent = ((investedShare / numberShares) * 100).toFixed(2)

  const addressList = tokenWhitelist.map((item) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCurrency(item)?.symbol === 'WETH' ? 'ETH' : item
  )

  const currencyList = addressList.map((item) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCurrency(item)
  )

  const today = new Date()
  const date = new Date(Number(startDeadline) * 1000)

  const ownedTokenIds = cubeletsInfo
    ?.filter((item) => item?.ownerAddr?.toLowerCase() === account?.toLowerCase())
    .sort((item) => Number(item.cubeletId))

  // do not show expired cubes unless the user has invested cubelets in the cube
  return today < date ||
    (today > date &&
      (account?.toLowerCase() === traderAddr.toLowerCase() || (ownedTokenIds && ownedTokenIds?.length > 0))) ? (
    <InsideWrapper
      to={
        history.location.pathname.includes('trader')
          ? '/trader/cube/' + 'v' + version + '/' + sudoCubeId
          : '/investor/cube/' + 'v' + version + '/' + sudoCubeId
      }
    >
      <Wrapper>
        <GridContainer>
          <DataText>
            <SmallCube
              src={today > date ? CubeGrey : investedPercent === '100.00' ? CubeYellow : CubeBlue}
              alt="little cube"
            />
            <CubeName>
              {' ' + cubeName}
              {/* <span className="versionTag">v{version.replace('-', '.')}</span> */}
            </CubeName>
          </DataText>

          {today > date ? (
            <CubeStatusLabel color="black" />
          ) : investedPercent === '100.00' ? (
            <CubeStatusLabel color="yellow" />
          ) : (
            <CubeStatusLabel color="blue" />
          )}

          <PercentText>
            <ExtentsText style={{ fontWeight: 700 }}>
              <CurrencyLogo
                currency={base as Currency}
                size={'25px'}
                style={{ marginTop: '-2px', marginRight: '10px' }}
              />
              {formattedTotalFund} {base?.symbol}
            </ExtentsText>
            <FundRaised>Fund Raised: {investedPercent}%</FundRaised>
          </PercentText>

          <ExtentsText style={{ fontWeight: 700 }}>{version.replace('-', '.')}</ExtentsText>

          {/* <ExtentsText style={{ fontWeight: 700 }}>{formatFloat((100 - Number(traderCut)) * 0.98, 2)} %</ExtentsText> */}
          <ExtentsText>
            <img src={UniswapLogo} alt="uni-v3" style={{ marginTop: '-6px' }} width={'30px'} height={'30px'} />
          </ExtentsText>
          <ExtentsText style={{ fontWeight: 700 }}>N/A</ExtentsText>
        </GridContainer>
        <WhiteListMobile>
          <MobileOnlyText>Trading Token Whitelist: </MobileOnlyText>{' '}
          <MobileOnlyText className="whitelistIcon" style={{ marginTop: '-3px' }}>
            {currencyList.map((item, i) =>
              item?.symbol === 'WETH' ? (
                <span key={`${item?.name}${i}`} style={{ marginRight: '3px', marginLeft: i === 0 ? '0' : '-10px' }}>
                  <CurrencyLogo currency={defaultCurrency as Currency} size={'23px'} />
                </span>
              ) : (
                <span key={`${item?.name}${i}`} style={{ marginRight: '3px', marginLeft: i === 0 ? '0' : '-10px' }}>
                  <CurrencyLogo currency={item as Currency} size={'23px'} />
                </span>
              )
            )}
          </MobileOnlyText>
        </WhiteListMobile>
        <SecondRowGrid>
          <DesktopHidedText>
            <span> Fund Cap: </span>
            <span
              style={{
                color: 'black',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {' '}
              <CurrencyLogo currency={base as Currency} size={'22px'} style={{ margin: '-3px 8px 0 0' }} />
              {formattedTotalFund} {base?.symbol}
            </span>
          </DesktopHidedText>{' '}
          <WhiteListDesktop>
            <ExtentsText>Trading Token Whitelist: </ExtentsText>{' '}
            <ExtentsText className="whitelistIcon" style={{ display: 'block' }}>
              {currencyList.map((item, i) =>
                item?.symbol === 'WETH' ? (
                  <span key={`${item?.name}${i}`} style={{ marginRight: '8px' }}>
                    <CurrencyLogo
                      currency={defaultCurrency as Currency}
                      size={'25px'}
                      style={{ margin: '-5px 11px 0 0' }}
                    />
                  </span>
                ) : (
                  <span key={`${item?.name}${i}`} style={{ marginRight: '8px' }}>
                    <CurrencyLogo currency={item as Currency} size={'25px'} style={{ margin: '-5px 3px 0 0' }} />
                  </span>
                )
              )}
            </ExtentsText>
          </WhiteListDesktop>
        </SecondRowGrid>
        {ownedTokenIds && ownedTokenIds && history.location.pathname.includes('investor') && ownedTokenIds.length > 0 && (
          <ThirdRowGrid>
            <TitleText style={{ width: '40%' }}>
              <span style={{ marginRight: '16px' }}> You Invested: </span>
              <span style={{ color: 'black' }}>
                {' '}
                {ownedTokenIds.length} Cubelets (
                {parseFloat((ownedTokenIds.length * Number(pricePerCublet)).toFixed(12))} {base?.symbol})
              </span>
            </TitleText>{' '}
            <ExtentsText style={{ width: '40%' }}>
              <span style={{ marginRight: '16px' }}> Your Cubelets:</span>
              <CubeletsContainer>
                {ownedTokenIds
                  ?.sort((itemA, itemB) => itemA.cubeletId - itemB.cubeletId)
                  .map((item, i) => (
                    <OwnedCubelet key={i} style={{ margin: '0 5px', marginTop: '0' }}>
                      {Number(item.cubeletId) > 99
                        ? ('0' + (Number(item.cubeletId) + 1)).slice(-2)
                        : Number(item.cubeletId) + 1}
                    </OwnedCubelet>
                  ))}
              </CubeletsContainer>
            </ExtentsText>{' '}
          </ThirdRowGrid>
        )}
      </Wrapper>
    </InsideWrapper>
  ) : null
}
