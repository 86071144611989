import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components/macro'
import investorIcon from './icons/investor_cut.png'
import traderIcon from './icons/trader_cut.png'
import cubes from './icons/Cubes.png'
import cubeDemo from './icons/CubesDemo1.png'
import swapDemo from './icons/SwapDemo.png'
import sushiSwapIcon from './icons/SushiSwapIcon.png'
import uniSwapIcon from './icons/UniSwapIcon.png'
import dydxIcon from './icons/DydxIcon.png'
import AaveIcon from './icons/AaveIcon.png'
import daoIcon from './icons/curveDaoIcon.png'
import cubeCut from './icons/cube_cut.png'
import EmptyCube from './icons/EmptyCube.png'
import CertikIcon from './icons/certik@2x.png'
import Checked from './icons/checked@2x.png'
import OKCoin from './icons/okx.jpeg'
//import cubeLogo from '../../assets/svg/logo-final.svg'
import PaperFly from './icons/paperfly.png'
import RightArrow from './icons/right-arrow.png'
import CountUp from 'react-countup'

import { Fade } from 'react-awesome-reveal'

import { Timeline } from './Timeline'
import { DesktopHide, MobileHide } from 'theme'
import Footer from 'components/Footer'
import { useTVL, useCubeNumber } from 'hooks/useProfileFromAPI'
import useUSDCPrice from 'hooks/useUSDCPrice'
import { useCurrency } from 'hooks/Tokens'
import { Currency } from '@uniswap/sdk-core'

import {
  BigCube,
  BigCubeMobile,
  LandingFrame,
  CubeDemo,
  CubeDemoMobile,
  CubeImgWrapper,
  SectionWrapper,
  LeftWrapper,
  Monitor,
  Platform,
  PlatformIcon,
  RightTextWrapper,
  SwapDemo,
  SwapDemoMobile,
  TitleText,
  SubTitleText,
  FrontText,
  DescText,
  ListText,
  TitleTextWrapper,
  AuditCompleted,
  SameRow,
} from './styled'

const StyledRightArrow = styled.img`
  width: 20px;
  margin-left: 15px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-left: 3px;
  width: 15px;
   `};
`

const Splitter = styled.div`
  height: 0.5px;
  border-bottom: 1px solid #979797;
  width: 90%;
  max-width: 1120px;
  margin: auto;
`

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 20px;
   `};
`

const ButtonRow = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 10px;
   `};
`

const MobileTelegram = styled.span`
  font-size: 12px;
  color: #0c1e70;
  img {
    width: 20px;
    margin-bottom: -2px;
  }
`

const LeftRoleButton = styled.button`
  width: 214px;
  height: 60px;
  border: none;
  background-color: #facc66;
  border-radius: 100px;
  font-size: 21px;
  color: #0c1e70;
  font-weight: 700;
  text-align: left;
  padding-left: 22px;
  padding-top: 5px;
  cursor: pointer;
  white-space: nowrap;
  img {
    height: 41px;
    vertical-align: middle;
    padding-bottom: 5px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      height: 35px
   `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
      height: 30px;
      display: none;
   `};
  }
  span {
    padding-left: 10px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
    padding-left: 5px;
   `};
  }
  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 181px;
    height: 50px;
    font-size: 18px;
    padding-left: 16px;
   `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 175px;
    height: 50px;
    font-size: 15px;
    padding-left: 10px;
   `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    //padding: 3px 0 0 8px;
    padding: 0;
    font-size: 12px;
    width: 115px;
    height: 35px;
    text-align: center;
   `};
`

const RightRoleButton = styled(LeftRoleButton)`
  margin-left: 37px;
  width: 245px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding-left: 10px;
    width: 200px;
   `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-left: 20px;
    width: 200px;
   `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    //padding-left: 8px;
    padding: 0;
    width: 115px; //134
    margin: 0;
   `};
`
const GetStartButton = styled.button`
  width: 235px;
  height: 60px;
  background-color: #facc66;
  color: #0c1e70;
  font-size: 21px;
  border: none;
  font-weight: 700;
  border-radius: 100px;
  margin-top: 34px;
  cursor: pointer;
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 200px;
    height: 50px;
    font-size: 18px;
    padding-left: 10px;
 `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
  width: 200px;
  height: 50px;
  font-size: 17px;
  padding-left: 5px;
 `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin: auto;
  margin-top: 34px;
  display: block;
  font-size: 14px;
  width: 125px;
  height: 38px;
 `};
`

const RoadMapSection = styled.div`
  display: inline-block;
  text-align: center;
`

const NumberSectionWrapper = styled(SectionWrapper)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: row;
  padding: 8% 3% 8% 3%;
 `};
`

const NumberDisplay = styled.div`
  width: 33%;
  text-align: center;
  .title {
    color: #0c1e70;
    font-weight: 700;
    font-size: 1.3rem;
    padding-bottom: 1rem;
  }
  .number {
    color: #424242;
    font-weight: 700;
    font-size: 1.9rem;
  }
  //border-right: 1px solid #e8e8e8;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  //width: 100%;
  border-right: none;
  .title {
    font-size: 0.6rem;
    padding-bottom: 0.5rem;
  }
  .number {
    font-weight: 700;
    font-size: 1.1rem;
  }
 `};
`
//import Fade from 'react-reveal/Fade'

export default function HomePage({ history }: RouteComponentProps) {
  //const theme = useContext(ThemeContext)
  const { data: tvlData, loading: loading1 } = useTVL(false)
  const { data: cumulativeTvlData, loading: loading2 } = useTVL(true)
  const { data: cubeNumber, loading: loading3 } = useCubeNumber()
  const dataLoading = loading1 || loading2 || loading3

  const ethToUSDC = Number(useUSDCPrice(useCurrency('ETH') as Currency)?.toSignificant(8))
  return (
    <>
      <LandingFrame>
        <SectionWrapper style={{ paddingTop: '5%' }}>
          <TitleRow>
            <div>
              <FrontText>
                AN ON-CHAIN PLATFORM <br /> FOR CRYPTO ADVISORY SERVICES
              </FrontText>
              <AuditCompleted>
                Audit Completed
                <SameRow>
                  <img
                    src={Checked}
                    alt="green_checked"
                    onClick={() => window.open('https://www.certik.com/projects/liquidcube')}
                  />
                  <img
                    src={CertikIcon}
                    style={{ cursor: 'pointer' }}
                    alt="certik_icon"
                    onClick={() => window.open('https://www.certik.com/projects/liquidcube')}
                  />
                  <a href="/OKCoin_Audit_Report.pdf" download>
                    <img
                      src={OKCoin}
                      style={{ cursor: 'pointer', color: 'black', marginLeft: '5px' }}
                      alt="OK_Coin"
                      //onClick={() => window.open('https://www.certik.com/projects/liquidcube')}
                    />
                  </a>
                  {/* <span onClick={() => window.open('https://t.me/LiquidCubeEN')}>
                    <img src={PaperFly} alt="paper" />
                  </span> */}
                </SameRow>
                <DesktopHide>
                  {/* <MobileTelegram onClick={() => window.open('https://t.me/LiquidCubeEN')}>
                    Join Our Telegram <img src={PaperFly} alt="paper" />
                  </MobileTelegram> */}
                </DesktopHide>
              </AuditCompleted>
              <ButtonRow>
                <LeftRoleButton onClick={() => history.push('/trader')}>
                  <img className="traderButtonIcon" src={traderIcon} alt="trader_icon" />
                  <span>I&apos;m a Trader </span>
                </LeftRoleButton>
                <RightRoleButton onClick={() => history.push('/investor')}>
                  <img className="investorButtonIcon" src={investorIcon} alt="investor_icon" />
                  <span>I&apos;m an Investor </span>
                </RightRoleButton>
              </ButtonRow>
            </div>

            <CubeImgWrapper src={cubeCut} alt="3D" />
          </TitleRow>
        </SectionWrapper>

        <Splitter />
        <NumberSectionWrapper>
          <NumberDisplay>
            <div className="title">Total Value Locked</div>
            <CountUp
              end={!dataLoading && tvlData ? tvlData * ethToUSDC : 0}
              duration={2}
              separator=","
              decimals={0}
              prefix={'$'}
              className="number"
            />
          </NumberDisplay>
          <NumberDisplay>
            <div className="title">Cumulative TVL</div>
            <CountUp
              end={!dataLoading && cumulativeTvlData ? cumulativeTvlData * ethToUSDC : 0}
              duration={2}
              separator=","
              decimals={0}
              prefix={'$'}
              className="number"
            />
          </NumberDisplay>
          <NumberDisplay>
            <div className="title">Number of Cubes</div>
            <CountUp
              end={!dataLoading && cubeNumber ? cubeNumber : 0}
              duration={0.4}
              separator=","
              className="number"
            />
          </NumberDisplay>
        </NumberSectionWrapper>

        <Splitter />

        {/* What is LiquidCube? */}
        <SectionWrapper>
          <Monitor src={EmptyCube} alt="homepageMockup" />
          <Fade triggerOnce duration={1500}>
            <LeftWrapper>
              <TitleTextWrapper>
                <TitleText>
                  What is LiquidCube?
                  {/* <UnderLine2 /> */}
                </TitleText>
              </TitleTextWrapper>
              {/* <MobileIcon src={homepageMockup} alt="bank" /> */}
              <DescText>
                <MobileHide>
                  LiquidCube is a DeFi investment <br />
                  opportunity marketplace
                </MobileHide>
                <DesktopHide>LiquidCube is a DeFi investment opportunity marketplace</DesktopHide>
              </DescText>
              <SubTitleText>Make DeFi investment marketplace</SubTitleText>
              <ListText>
                <li>• Trader list</li>
                <li>• Built in performance and profit sharing</li>
              </ListText>
              <SubTitleText>Set role-based Permissions</SubTitleText>
              <ListText>
                <li>• Trader can trade but cannot withdraw</li>
              </ListText>
              <SubTitleText>Define an investment opportunity (cube) and its criteria</SubTitleText>
              <ListText>
                <li>• Platform</li>
                <li>• Tokens</li>
                <li>• Profit sharing</li>
              </ListText>
            </LeftWrapper>
          </Fade>
        </SectionWrapper>

        {/* What is Cube? */}
        <SectionWrapper style={{ paddingTop: '0' }}>
          <Fade triggerOnce duration={1500}>
            <RightTextWrapper>
              <BigCubeMobile src={cubes} alt={'bigOne1'} />
              <TitleTextWrapper>
                <TitleText>
                  What is Cube?
                  {/* <UnderLine /> */}
                </TitleText>
              </TitleTextWrapper>
              <DescText>
                <MobileHide>
                  Each cube is an investment <br />
                  opportunity or a fund
                </MobileHide>
                <DesktopHide>
                  Each cube is an investment opportunity <br />
                  or a fund
                </DesktopHide>
              </DescText>
              <SubTitleText>The Trader Defines</SubTitleText>
              <ListText>
                <li>• Number of shares</li>
                <li>• Price per share</li>
                <li>• Trading period</li>
                <li>
                  <MobileHide>
                    • Other settings such a profit split ratio, <br />
                    initial deposit, trading platform, limit, etc
                  </MobileHide>
                  <DesktopHide>
                    • Other settings such a profit split ratio, initial deposit, trading platform, limit, etc
                  </DesktopHide>
                </li>
              </ListText>
            </RightTextWrapper>
          </Fade>
          <BigCube src={cubes} alt="cubes" />
        </SectionWrapper>

        <Splitter />

        {/* What is Investor? */}
        <SectionWrapper>
          <Fade triggerOnce duration={2000} direction={'right'}>
            <CubeDemo src={cubeDemo} alt="cubeDemo" />
            <LeftWrapper>
              <CubeDemoMobile src={cubeDemo} alt="cubeDemo" />
              <TitleTextWrapper>
                <TitleText style={{ borderBottom: '4px solid #0C1E70' }}>
                  What is Investor?
                  {/* <UnderLine2 style={{ marginBottom: '10px' }} /> */}
                </TitleText>
              </TitleTextWrapper>
              <DescText style={{ marginBottom: '10px' }}>
                <MobileHide>
                  Investors can deposit, withdraw from the <br />
                  cube, and find opportunities to invest but <br />
                  cannot trade
                </MobileHide>
                <DesktopHide>
                  Investors can deposit, withdraw from the cube, and find opportunities to invest but cannot trade
                </DesktopHide>
              </DescText>
              <SubTitleText>The Investor</SubTitleText>
              <ListText>
                <li>• Views the cubes created by the trader</li>
                <li>• Selects the cubelets to invest in</li>
                <li style={{ paddingRight: '20px' }}>• Receives &ldquo;investor NFTs&rdquo; as the receipt</li>
              </ListText>
              <GetStartButton onClick={() => history.push('/investor')}>
                Get Started
                <StyledRightArrow src={RightArrow} alt="right-arrow" />
              </GetStartButton>
            </LeftWrapper>
          </Fade>
        </SectionWrapper>

        {/* What is Trader? */}
        <SectionWrapper>
          <Fade triggerOnce duration={2000} direction={'left'} style={{ display: 'flex' }}>
            <RightTextWrapper>
              {/* <img src={swapDemo} alt="swap" width="420" height="488"></img> */}
              <SwapDemoMobile src={swapDemo} alt="swap" />
              <TitleTextWrapper>
                <TitleText style={{ borderBottom: '4px solid #0C1E70' }}>
                  What is Trader?
                  {/* <UnderLine style={{ marginBottom: '10px' }} /> */}
                </TitleText>
              </TitleTextWrapper>
              <MobileHide>
                <DescText>
                  {' '}
                  Traders can define cubes and trade <br />
                  but cannot withdraw or deposit from
                  <br /> the cubes
                </DescText>
              </MobileHide>
              <DesktopHide>
                <DescText style={{ textAlign: 'center' }}>
                  Traders can define cubes and trade but cannot withdraw or deposit from the cubes
                </DescText>
              </DesktopHide>
              <GetStartButton onClick={() => history.push('/trader')}>
                Get Started <StyledRightArrow src={RightArrow} alt="right-arrow" />
              </GetStartButton>
            </RightTextWrapper>
            <SwapDemo src={swapDemo} alt="swap" />
          </Fade>
        </SectionWrapper>

        <Splitter />
        <SectionWrapper>
          <Platform>
            <TitleText>Platform</TitleText>
            {/* <UnderLine3 /> */}
            <PlatformIcon>
              <img src={uniSwapIcon} className="icon" alt="uniSwap" />
              <img src={AaveIcon} className="icon" alt="Aave" />
              <img src={sushiSwapIcon} className="icon" alt="sushiSwap" />
              <img src={daoIcon} className="icon" alt="dao" />
              <img src={dydxIcon} className="dydxicon" alt="dydx" />
            </PlatformIcon>
          </Platform>
        </SectionWrapper>
        <Splitter />

        <SectionWrapper>
          <RoadMapSection>
            <TitleText style={{ textAlign: 'center', marginBottom: '-20px', paddingBottom: '0' }}>Roadmap</TitleText>
            <Timeline />
          </RoadMapSection>
        </SectionWrapper>
        {/* <BorderLine style={{ width: '80%' }} /> */}

        <Footer />
      </LandingFrame>
      {/* <FooterLogo>
        <img src={cubeLogo} alt="logo" />
        <LogoText>LiquidCube</LogoText>© 2022 LiquidCube
      </FooterLogo> */}
    </>
  )
}
