import { useSingleCallResult, useSingleContractMultipleData } from 'state/multicall/hooks'
import { useMemo } from 'react'
import { SudoCubeDetails } from 'types/sudoCube'
import { CubeletInfo } from 'types/cubelet'
import { useLCFactoryContract, useLCFactoryContractV1_1, useLCFactoryContractV1_2 } from './useContract'
import { utils } from 'ethers'
import { ZERO_ADDRESS } from 'constants/misc'

interface useSudoCubeInfoResults {
  loading: boolean
  cubes: SudoCubeDetails[] | undefined
}

export function useSudoCubeInfoByIds(
  ids: number[],
  ids2?: number[],
  ids3?: number[],
  version?: string
): useSudoCubeInfoResults {
  const factory = useLCFactoryContract()
  const factory2 = useLCFactoryContractV1_1()
  const factory3 = useLCFactoryContractV1_2()

  if (version && version === 'v1-1') {
    ids2 = ids
    ids = []
  }

  if (version && version === 'v1-2') {
    ids3 = ids
    ids = []
  }

  const calls = ids.map((id) => {
    return [id]
  })

  const calls2 = ids2
    ? ids2.map((id) => {
        return [id]
      })
    : []

  const calls3 = ids3
    ? ids3.map((id) => {
        return [id]
      })
    : []

  const cubesResults = useSingleContractMultipleData(factory, 'getSudoCubeById', calls)
  const cubesLoading = useMemo(() => cubesResults.some(({ loading }) => loading), [cubesResults])

  const cubesResults2 = useSingleContractMultipleData(factory2, 'getSudoCubeById', calls2)
  const cubesLoading2 = useMemo(() => cubesResults2.some(({ loading }) => loading), [cubesResults2])

  const cubesResults3 = useSingleContractMultipleData(factory3, 'getSudoCubeById', calls3)
  const cubesLoading3 = useMemo(() => cubesResults3.some(({ loading }) => loading), [cubesResults3])

  const cubes = useMemo(() => {
    if (!cubesLoading) {
      return cubesResults
        .filter(
          (item) =>
            !item.error && item.result && item.result[0] && (item.result[0].state === 0 || item.result[0].state === 1)
        )
        .map((item: any) => {
          const cubeRaw: SudoCubeDetails = item.result[0]
          const cubeletsInfo = item.result[1].map((cubelet: CubeletInfo) => {
            return {
              cubeletId: Number(cubelet.cubeletId.toString()),
              ownerAddr: cubelet.ownerAddr,
              tokenId: Number(cubelet.tokenId.toString()),
            }
          })
          return {
            creator: cubeRaw.creator, // same as traderAddr
            sudoCubeId: cubeRaw.sudoCubeId.toString(),
            cubeName: utils.parseBytes32String(cubeRaw.cubeName),
            traderAddr: cubeRaw.creator,
            traderCut: Number(cubeRaw.traderCut.toString()),
            baseCurrency: cubeRaw.baseCurrency === ZERO_ADDRESS ? 'ETH' : cubeRaw.baseCurrency,
            state: cubeRaw.state,
            tradingPeriodLength: cubeRaw.tradingPeriodLength.toString(),
            numberOfCubelets: Number(cubeRaw.numberOfCubelets.toString()),
            pricePerCubelet: cubeRaw.pricePerCubelet,
            tokenWhitelist: cubeRaw.tokenWhitelist,
            createdTime: Number(cubeRaw.createdTime.toString()),
            startingTime: Number(cubeRaw.createdTime.toString()), // for sorting in the list alone with started cubes
            cubeletsRemaining: Number(cubeRaw.cubeletsRemaining.toString()),
            startDeadline: cubeRaw.startDeadline,
            cubeletsInfo: cubeletsInfo,
            version: '1-0',
          }
        })
        .sort((a, b) => Number(b.startingTime.toString()) - Number(a.startingTime.toString()))
    }
    return undefined
  }, [cubesLoading, cubesResults])

  const cubes2 = useMemo(() => {
    if (!cubesLoading2) {
      return cubesResults2
        .filter(
          (item) =>
            !item.error && item.result && item.result[0] && (item.result[0].state === 0 || item.result[0].state === 1)
        )
        .map((item: any) => {
          const cubeRaw: SudoCubeDetails = item.result[0]
          const cubeletsInfo = item.result[1].map((cubelet: CubeletInfo) => {
            return {
              cubeletId: Number(cubelet.cubeletId.toString()),
              ownerAddr: cubelet.ownerAddr,
              tokenId: Number(cubelet.tokenId.toString()),
            }
          })
          return {
            creator: cubeRaw.creator, // same as traderAddr
            sudoCubeId: cubeRaw.sudoCubeId.toString(),
            cubeName: utils.parseBytes32String(cubeRaw.cubeName),
            traderAddr: cubeRaw.creator,
            traderCut: Number(cubeRaw.traderCut.toString()),
            baseCurrency: cubeRaw.baseCurrency === ZERO_ADDRESS ? 'ETH' : cubeRaw.baseCurrency,
            state: cubeRaw.state,
            tradingPeriodLength: cubeRaw.tradingPeriodLength.toString(),
            numberOfCubelets: Number(cubeRaw.numberOfCubelets.toString()),
            pricePerCubelet: cubeRaw.pricePerCubelet,
            tokenWhitelist: cubeRaw.tokenWhitelist,
            createdTime: Number(cubeRaw.createdTime.toString()),
            startingTime: Number(cubeRaw.createdTime.toString()), // for sorting in the list alone with started cubes
            cubeletsRemaining: Number(cubeRaw.cubeletsRemaining.toString()),
            startDeadline: cubeRaw.startDeadline,
            cubeletsInfo: cubeletsInfo,
            version: '1-1',
          }
        })
        .sort((a, b) => Number(b.startingTime.toString()) - Number(a.startingTime.toString()))
    }
    return undefined
  }, [cubesLoading2, cubesResults2])

  const cubes3 = useMemo(() => {
    if (!cubesLoading3) {
      return cubesResults3
        .filter(
          (item) =>
            !item.error && item.result && item.result[0] && (item.result[0].state === 0 || item.result[0].state === 1)
        )
        .map((item: any) => {
          const cubeRaw: SudoCubeDetails = item.result[0]
          const cubeletsInfo = item.result[1].map((cubelet: CubeletInfo) => {
            return {
              cubeletId: Number(cubelet.cubeletId.toString()),
              ownerAddr: cubelet.ownerAddr,
              tokenId: Number(cubelet.tokenId.toString()),
            }
          })
          return {
            creator: cubeRaw.creator, // same as traderAddr
            sudoCubeId: cubeRaw.sudoCubeId.toString(),
            cubeName: utils.parseBytes32String(cubeRaw.cubeName),
            traderAddr: cubeRaw.creator,
            traderCut: Number(cubeRaw.traderCut.toString()),
            baseCurrency: cubeRaw.baseCurrency === ZERO_ADDRESS ? 'ETH' : cubeRaw.baseCurrency,
            state: cubeRaw.state,
            tradingPeriodLength: cubeRaw.tradingPeriodLength.toString(),
            numberOfCubelets: Number(cubeRaw.numberOfCubelets.toString()),
            pricePerCubelet: cubeRaw.pricePerCubelet,
            tokenWhitelist: cubeRaw.tokenWhitelist,
            createdTime: Number(cubeRaw.createdTime.toString()),
            startingTime: Number(cubeRaw.createdTime.toString()), // for sorting in the list alone with started cubes
            cubeletsRemaining: Number(cubeRaw.cubeletsRemaining.toString()),
            startDeadline: cubeRaw.startDeadline,
            cubeletsInfo: cubeletsInfo,
            version: '1-2',
          }
        })
        .sort((a, b) => Number(b.startingTime.toString()) - Number(a.startingTime.toString()))
    }
    return undefined
  }, [cubesLoading3, cubesResults3])

  return {
    loading: cubesLoading || cubesLoading2,
    cubes:
      cubes && cubes2 && cubes3
        ? cubes.concat(cubes2).concat(cubes3)
        : cubes
        ? cubes
        : cubes2
        ? cubes2
        : cubes3
        ? cubes3
        : undefined,
  }
}

export function useAllSudoCubeInfo(): useSudoCubeInfoResults {
  const factory = useLCFactoryContract()
  const factory2 = useLCFactoryContractV1_1()
  const factory3 = useLCFactoryContractV1_2()

  const sudoCubeLengthResult = useSingleCallResult(factory, 'cubeNonce')
  const sudoCubeLengthResult2 = useSingleCallResult(factory2, 'cubeNonce')
  const sudoCubeLengthResult3 = useSingleCallResult(factory3, 'cubeNonce')

  const ids = useMemo(() => {
    const inputList: number[] = []
    if (!sudoCubeLengthResult.loading && sudoCubeLengthResult.result) {
      for (let i = 0; i < sudoCubeLengthResult.result[0].toNumber(); i++) {
        inputList.push(i)
      }
    }
    return inputList
  }, [sudoCubeLengthResult])

  const ids2 = useMemo(() => {
    const inputList: number[] = []
    if (!sudoCubeLengthResult2.loading && sudoCubeLengthResult2.result) {
      for (let i = 0; i < sudoCubeLengthResult2.result[0].toNumber(); i++) {
        inputList.push(i)
      }
    }
    return inputList
  }, [sudoCubeLengthResult2])

  const ids3 = useMemo(() => {
    const inputList: number[] = []
    if (!sudoCubeLengthResult3.loading && sudoCubeLengthResult3.result) {
      for (let i = 0; i < sudoCubeLengthResult3.result[0].toNumber(); i++) {
        inputList.push(i)
      }
    }
    return inputList
  }, [sudoCubeLengthResult3])

  return useSudoCubeInfoByIds(ids, ids2, ids3)
}

export function useTraderSudoCubeInfo(traderAddr: string): useSudoCubeInfoResults {
  const { loading, cubes } = useAllSudoCubeInfo()
  const filteredCubes = useMemo(() => {
    if (!loading && cubes) {
      return cubes.filter((cube) => cube.creator === traderAddr)
    }
    return undefined
  }, [loading, cubes, traderAddr])
  return {
    loading: loading,
    cubes: filteredCubes,
  }
}
