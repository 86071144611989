import * as React from 'react'
import { SxProps, Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
// import Toolbar from '@mui/material/Toolbar'
// import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
// import Checkbox from '@mui/material/Checkbox'
// import IconButton from '@mui/material/IconButton'
// import Tooltip from '@mui/material/Tooltip'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import Switch from '@mui/material/Switch'
// import DeleteIcon from '@mui/icons-material/Delete'
// import FilterListIcon from '@mui/icons-material/FilterList'
import { visuallyHidden } from '@mui/utils'
import { useAllTokens, useCurrency } from 'hooks/Tokens'
import CurrencyLogo from 'components/CurrencyLogo'
import { ZERO_ADDRESS } from 'constants/misc'
import { Currency } from '@uniswap/sdk-core'
import styled from 'styled-components/macro'
import numeral from 'numeral'
import { useState } from 'react'

const ShowInvestorButton = styled.span`
  color: #1d5398;
  border: 1px solid #1d5398;
  font-size: 14px;
  padding: 0.3rem;
  border-radius: 9px;
  margin-left: 10px;
  cursor: pointer;
  font-weight: 700;
  position: relative;
  margin-top: -0.15rem;
  float: right;
  :hover {
    background-color: #1d5398;
    color: white;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-left: 0;
  font-size: 13px;
  padding: 0.3rem;
  margin-top: -0.15rem;
`};
`

export interface TableData {
  address: string
  cubeName: string
  state: number
  baseToken: string
  amount: number
  earning: number
  version: string
}

export function createData(
  address: string,
  cubeName: string,
  state: number,
  baseToken: string,
  amount: number,
  earning: number,
  version: string
): TableData {
  return {
    address,
    cubeName,
    state,
    baseToken,
    amount,
    earning,
    version,
  }
}

// const rows = [
//   createData('Cube101', 305, 'ETH', 67, 4.3),
//   createData('CubeIsCool', 452, 'ETH', 51, 4.9),
//   createData('Eclair', 262, 'ETH', 24, 6.0),
//   createData('Cube Liquid', 159, 'ETH', 24, 4.0),
//   createData('Gingerbread', 356, 'ETH', 49, 3.9),
//   createData('Honeycomb', 408, 'ETH', 87, 6.5),
//   createData('May Looks', 237, 'ETH', 37, 4.3),
//   createData('Jelly Bean', 375, 'ETH', 94, 0.0),
//   createData('KitKat', 518, 'ETH', 65, 7.0),
//   createData('Lollipop', 392, 'ETH', 98, 0.0),
//   createData('Marshmallow', 318, 'ETH', 81, 2.0),
//   createData('Nougat', 360, 'ETH', 9, 37.0),
//   createData('Oreo', 437, 'ETH', 63, 4.0),
// ]

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string | number[] }, b: { [key in Key]: number | string | number[] }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface HeadCell {
  //disablePadding: boolean
  id: keyof TableData
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'cubeName',
    numeric: false,
    label: 'Cube Name',
  },
  {
    id: 'state',
    numeric: true,
    label: 'State',
  },
  {
    id: 'baseToken',
    numeric: true,
    label: 'Base Token',
  },
  {
    id: 'amount',
    numeric: true,
    label: 'Amount',
  },
  {
    id: 'earning',
    numeric: true,
    label: 'Cube Earning',
  },
]

interface EnhancedTableProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TableData) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = (property: keyof TableData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {
          headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden as SxProps<Theme>}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))
          // .concat(
          //   <TableCell key={'showInvestor'} align={'right'} padding={'normal'} sortDirection={false}>
          //     <TableSortLabel
          //       active={orderBy === 'showInvestor'}
          //       direction={'asc'}
          //       onClick={createSortHandler('showInvestor')}
          //     ></TableSortLabel>
          //   </TableCell>
          // )
        }
      </TableRow>
    </TableHead>
  )
}

interface PropsType {
  account: string
  rows: TableData[]
}

export default function EnhancedTable({ account, rows }: PropsType) {
  //console.log(account)
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof TableData>('state')
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState(0)
  //const [dense, setDense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(15)

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof TableData) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.cubeName)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  // const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
  //   const selectedIndex = selected.indexOf(name)
  //   let newSelected: readonly string[] = []

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name)
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1))
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1))
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
  //   }

  //   setSelected(newSelected)
  // }
  // const handleClick = (address: string, version: string) => {
  //   window.open(``)
  // }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setDense(event.target.checked)
  // }

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0
  const tokens = useAllTokens()
  const ETH = useCurrency('ETH')

  const [showInvestors, setShowInvestors] = useState<boolean>(false)

  function toggleInvestorList() {
    setShowInvestors(!showInvestors)
  }

  return (
    <Box sx={{ width: '100%', padding: '1rem 0 2rem 0' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.cubeName)
                  const labelId = `enhanced-table-checkbox-${index}`
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  //const currency = useCurrency(row.baseToken === ZERO_ADDRESS ? 'ETH' : row.baseToken)
                  const currency = row.baseToken === ZERO_ADDRESS ? ETH : tokens[row.baseToken]

                  return (
                    <TableRow
                      hover
                      //onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.cubeName}
                      selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell> */}
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        onClick={() =>
                          window.open(`/#/investor/${row.version.replace('.', '-')}/${row.address}`, '_blank')
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        <strong>{row.cubeName}</strong>
                      </TableCell>
                      <TableCell align="right">
                        {row.state === 2 ? 'Trading' : row.state < 2 ? 'Funding' : 'Trade Ended'}
                      </TableCell>
                      {/* <TableCell align="right">{row.baseToken}</TableCell> */}
                      <TableCell align="right">
                        <strong>{currency?.symbol} </strong>
                        <CurrencyLogo currency={currency as Currency} size={'24px'} style={{ marginBottom: '-6px' }} />
                      </TableCell>
                      <TableCell align="right">
                        {numeral(row.amount).format('0,0.[00]')} {currency?.symbol}
                      </TableCell>
                      <TableCell align="right">{numeral(row.earning).format('0,0.[00]')} USDC</TableCell>
                      {/* <TableCell align="right" style={{ maxWidth: '300px' }}>
                        {row.cubelets.map((cubelet) => (
                          <Cubelet key={cubelet}>{cubelet}</Cubelet>
                        ))}
                      </TableCell> */}
                      {/* <TableCell align="right">
                        <ShowInvestorButton onClick={toggleInvestorList}>View Investors</ShowInvestorButton>
                      </TableCell> */}
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" /> */}
    </Box>
  )
}
