import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import SettingsTab from '../Settings'
import { Percent } from '@uniswap/sdk-core'

import { RowBetween, RowFixed } from '../Row'
import { TYPE } from 'theme'

const StyledSwapHeader = styled.div<{ isTitle?: boolean }>`
  //padding: 1rem 0 0.5rem 1.25rem;
  padding: ${({ isTitle }) => (isTitle ? '0 0 1.5rem 1.25rem' : '1rem 0 0.5rem 1.25rem')};
  width: 100%;
  color: ${({ theme }) => theme.text2};
`

export default function SwapHeader({ allowedSlippage, title }: { allowedSlippage: Percent; title?: boolean }) {
  return (
    <StyledSwapHeader isTitle={title}>
      <RowBetween>
        <RowFixed />
        {title && (
          <RowFixed>
            <TYPE.body fontSize={'20px'} fontWeight={'600'} margin={'auto'}>
              <Trans>Swap</Trans>
            </TYPE.body>
          </RowFixed>
        )}
        <RowFixed>
          <SettingsTab placeholderSlippage={allowedSlippage} />
        </RowFixed>
      </RowBetween>
    </StyledSwapHeader>
  )
}
