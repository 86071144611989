import { Contract } from '@ethersproject/contracts'
import { abi as GOVERNANCE_ABI } from '@uniswap/governance/build/GovernorAlpha.json'
import { abi as UNI_ABI } from '@uniswap/governance/build/Uni.json'
import { abi as STAKING_REWARDS_ABI } from '@uniswap/liquidity-staker/build/StakingRewards.json'
import { abi as MERKLE_DISTRIBUTOR_ABI } from '@uniswap/merkle-distributor/build/MerkleDistributor.json'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { abi as QuoterABI } from '@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json'
import { abi as V2MigratorABI } from '@uniswap/v3-periphery/artifacts/contracts/V3Migrator.sol/V3Migrator.json'
import { abi as IUniswapV2Router02ABI } from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import { abi as MulticallABI } from '@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json'

import ARGENT_WALLET_DETECTOR_ABI from 'abis/argent-wallet-detector.json'
import ENS_PUBLIC_RESOLVER_ABI from 'abis/ens-public-resolver.json'
import ENS_ABI from 'abis/ens-registrar.json'
import ERC20_ABI from 'abis/erc20.json'
import ERC20_BYTES32_ABI from 'abis/erc20_bytes32.json'
import WETH_ABI from 'abis/weth.json'
import EIP_2612 from 'abis/eip_2612.json'
import MULTICALL_DETACHED_ABI from 'abis/MulticallDetached.json'

import {
  NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  QUOTER_ADDRESSES,
  V3_MIGRATOR_ADDRESSES,
  ARGENT_WALLET_DETECTOR_ADDRESS,
  MERKLE_DISTRIBUTOR_ADDRESS,
  MULTICALL_ADDRESS,
  V2_ROUTER_ADDRESS,
  ENS_REGISTRAR_ADDRESSES,
  GOVERNANCE_ALPHA_V0_ADDRESSES,
  GOVERNANCE_ALPHA_V1_ADDRESSES,
  LC_PERIPHERY_ADDRESS,
  LC_MANAGER_ADDRESS, // LC address
  LC_FACTORY_ADDRESS,
  MULTICALL_DETACHED_ADDRESS,
  LC_MANAGER_ADDRESS_V1_1,
  LC_PERIPHERY_ADDRESS_V1_1,
  LC_FACTORY_ADDRESS_V1_1,
  LC_PERIPHERY_ADDRESS_V1_2,
  LC_FACTORY_ADDRESS_V1_2,
  LC_MANAGER_ADDRESS_V1_2,
} from 'constants/addresses'
import { abi as NFTPositionManagerABI } from '@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json'
import { useContext, useMemo } from 'react'
import { Quoter, NonfungiblePositionManager, UniswapInterfaceMulticall } from 'types/v3'
import { V3Migrator } from 'types/v3/V3Migrator'
import { getContract } from 'utils'
import {
  Erc20,
  ArgentWalletDetector,
  EnsPublicResolver,
  EnsRegistrar,
  Weth,
  LcPeriphery,
  LcCubeManager,
  LcCube,
  LcFactory,
  MulticallDetached,
} from '../abis/types'
import { UNI, WETH9_EXTENDED } from '../constants/tokens'
import { useActiveWeb3React } from './web3'

// ============== LC ===============
import LC_PERI_ABI from 'abis/lc-periphery.json'
import LC_CUBE_ABI from 'abis/lc-cube.json'
import LC_FACTORY_ABI from 'abis/lc-factory.json'
import LC_MANAGER_ABI from 'abis/lc-cube-manager.json'
import { VersionContext } from 'pages/App'

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { library, account, chainId } = useActiveWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, library, chainId, withSignerIfPossible, account]) as T
}

// =============== LC ===============
export function useCubeInfoContract(cubeAddress: string) {
  //const { chainId } = useActiveWeb3React()
  return useContract<LcCube>(cubeAddress ? cubeAddress : undefined, LC_CUBE_ABI)
}

// export function useLCManagerContract() {
//   const { chainId } = useActiveWeb3React()
//   const { isVersionOne } = useContext(VersionContext)
//   return useContract<LcCubeManager>(
//     chainId ? (isVersionOne ? LC_MANAGER_ADDRESS[chainId] : LC_MANAGER_ADDRESS_V1_1[chainId]) : undefined,
//     LC_MANAGER_ABI
//   )
// }

export function useLCManagerContract() {
  const { chainId } = useActiveWeb3React()
  //const { isVersionOne } = useContext(VersionContext)
  return useContract<LcCubeManager>(chainId ? LC_MANAGER_ADDRESS[chainId] : undefined, LC_MANAGER_ABI)
}

export function useLCManagerContractV1_1() {
  const { chainId } = useActiveWeb3React()
  //const { isVersionOne } = useContext(VersionContext)
  return useContract<LcCubeManager>(chainId ? LC_MANAGER_ADDRESS_V1_1[chainId] : undefined, LC_MANAGER_ABI)
}

export function useLCManagerContractV1_2() {
  const { chainId } = useActiveWeb3React()
  //const { isVersionOne } = useContext(VersionContext)
  return useContract<LcCubeManager>(chainId ? LC_MANAGER_ADDRESS_V1_2[chainId] : undefined, LC_MANAGER_ABI)
}

export function useLcPeripheryContract() {
  const { chainId } = useActiveWeb3React()
  const { currentVersion } = useContext(VersionContext)
  return useContract<LcPeriphery>(
    chainId
      ? currentVersion === '1.0'
        ? LC_PERIPHERY_ADDRESS[chainId]
        : currentVersion === '1.1'
        ? LC_PERIPHERY_ADDRESS_V1_1[chainId]
        : currentVersion === '1.2'
        ? LC_PERIPHERY_ADDRESS_V1_2[chainId]
        : undefined
      : undefined,
    LC_PERI_ABI
  )
}

// export function useLCFactoryContract() {
//   const { chainId } = useActiveWeb3React()
//   const { isVersionOne } = useContext(VersionContext)
//   return useContract<LcFactory>(
//     chainId ? (isVersionOne ? LC_FACTORY_ADDRESS[chainId] : LC_FACTORY_ADDRESS_V1_1[chainId]) : undefined,
//     LC_FACTORY_ABI
//   )
// }
export function useLCFactoryContract() {
  const { chainId } = useActiveWeb3React()
  return useContract<LcFactory>(chainId ? LC_FACTORY_ADDRESS[chainId] : undefined, LC_FACTORY_ABI)
}
export function useLCFactoryContractV1_1() {
  const { chainId } = useActiveWeb3React()
  return useContract<LcFactory>(chainId ? LC_FACTORY_ADDRESS_V1_1[chainId] : undefined, LC_FACTORY_ABI)
}

export function useLCFactoryContractV1_2() {
  const { chainId } = useActiveWeb3React()
  return useContract<LcFactory>(chainId ? LC_FACTORY_ADDRESS_V1_2[chainId] : undefined, LC_FACTORY_ABI)
}

export function useV2MigratorContract() {
  return useContract<V3Migrator>(V3_MIGRATOR_ADDRESSES, V2MigratorABI, true)
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean) {
  const { chainId } = useActiveWeb3React()
  return useContract<Weth>(chainId ? WETH9_EXTENDED[chainId]?.address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useArgentWalletDetectorContract() {
  return useContract<ArgentWalletDetector>(ARGENT_WALLET_DETECTOR_ADDRESS, ARGENT_WALLET_DETECTOR_ABI, false)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean) {
  return useContract<EnsRegistrar>(ENS_REGISTRAR_ADDRESSES, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean) {
  return useContract<EnsPublicResolver>(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useEIP2612Contract(tokenAddress?: string): Contract | null {
  return useContract(tokenAddress, EIP_2612, false)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useV2RouterContract(): Contract | null {
  return useContract(V2_ROUTER_ADDRESS, IUniswapV2Router02ABI, true)
}

export function useMulticall2Contract() {
  return useContract<UniswapInterfaceMulticall>(MULTICALL_ADDRESS, MulticallABI, false) as UniswapInterfaceMulticall
}

export function useMerkleDistributorContract() {
  return useContract(MERKLE_DISTRIBUTOR_ADDRESS, MERKLE_DISTRIBUTOR_ABI, true)
}

export function useGovernanceV0Contract(): Contract | null {
  return useContract(GOVERNANCE_ALPHA_V0_ADDRESSES, GOVERNANCE_ABI, true)
}

export function useGovernanceV1Contract(): Contract | null {
  return useContract(GOVERNANCE_ALPHA_V1_ADDRESSES, GOVERNANCE_ABI, true)
}

export const useLatestGovernanceContract = useGovernanceV1Contract

export function useUniContract() {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? UNI[chainId]?.address : undefined, UNI_ABI, true)
}

export function useStakingContract(stakingAddress?: string, withSignerIfPossible?: boolean) {
  return useContract(stakingAddress, STAKING_REWARDS_ABI, withSignerIfPossible)
}

export function useV3NFTPositionManagerContract(withSignerIfPossible?: boolean): NonfungiblePositionManager | null {
  return useContract<NonfungiblePositionManager>(
    NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
    NFTPositionManagerABI,
    withSignerIfPossible
  )
}

export function useV3Quoter() {
  return useContract<Quoter>(QUOTER_ADDRESSES, QuoterABI)
}

export function useMulticallDetachedContract() {
  return useContract<MulticallDetached>(MULTICALL_DETACHED_ADDRESS, MULTICALL_DETACHED_ABI)
}
