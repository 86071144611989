import React, { useState } from 'react'
import styled from 'styled-components/macro'

import { useActiveWeb3React } from 'hooks/web3'
import Row from 'components/Row'

// ============== LC ===============
import { RouteComponentProps } from 'react-router-dom'
// import CopyHelper from 'components/AccountDetails/Copy'
// import numeral from 'numeral'
// import ApexCharts from 'apexcharts'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

import CountUp from 'react-countup'
import { RowBetween } from 'components/Row'
import LotsCubes from 'assets/images/lots_cubes.png'
//import CopyHelper from 'components/AccountDetails/Copy'
import EnhancedTable, { createData, TableData } from './table'
import { useTraderTVL, useFetchTraderProfileData } from 'hooks/useProfileFromAPI'
import useUSDCPrice from 'hooks/useUSDCPrice'
import { useCurrency } from 'hooks/Tokens'
import { Currency } from '@uniswap/sdk-core'
import { LoadingRows } from 'pages/InvestorPage/styleds'
import LinearProgress from '@mui/material/LinearProgress'
import Copy from 'components/AccountDetails/Copy'
import { DesktopHide, ExternalLink, MobileHide } from 'theme/components'
import { ExternalLink as LinkIcon, Inbox } from 'react-feather'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { isAddress, shortenAddress } from 'utils'
import './index.css'
import TVL_icon from './images/padlock1.png'
import cube_icon from './images/hastag.png'
import profit_icon from './images/line-chart.png'
import numeral from 'numeral'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

export default function TraderProfile({
  match: {
    params: { address },
  },
  history,
}: RouteComponentProps<{
  address: string
}>) {
  const { account } = useActiveWeb3React()

  if (!address && account) {
    address = account
  }

  const checksumAddress = isAddress(address)
  const ethToUSDC = Number(useUSDCPrice(useCurrency('ETH') as Currency)?.toSignificant(8))

  const { data: userData, loading: loadingData } = useFetchTraderProfileData(address)
  const { data: tvlData, loading: loadingTVL } = useTraderTVL(address)

  const [isCreated, setCreated] = useState<boolean>(false)
  const handleChange = (event: React.MouseEvent<HTMLElement>, value: boolean) => {
    setCreated(value)
  }
  //console.log(userData)

  const linechartData = tvlData
    ? tvlData
        .map((item) => {
          return [item.block_timestamp * 1000, item.tvl]
        })
        .concat([[new Date().getTime(), tvlData[tvlData.length - 1].tvl]])
    : []

  //console.log(userData?.detail)

  const pieOptions = {
    chart: {
      //width: 380,
      //type: 'pie',
    },
    // labels: ['ETH', 'LOOKS', 'USDC'],
    labels: loadingData ? [] : (userData?.token_value_locked_eth.map((token) => token[0]) as string[]),
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      // eslint-disable-next-line react/display-name
      custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
        //console.log(w.globals)
        return (
          '<div class="arrow_box" >' +
          '<div>' +
          '<span>' +
          w.globals.labels[seriesIndex] +
          ': $' +
          series[seriesIndex] +
          '</span>' +
          '</div>' +
          '<div>' +
          '<span>' +
          Number(w.globals.seriesPercent[seriesIndex]).toFixed(2) +
          '% ' +
          '</span>' +
          '</div>' +
          '</div>'
        )
      },
    },
  }

  //const pieSeries = [44, 55, 13]
  const pieSeries = loadingData
    ? []
    : (userData?.token_value_locked_eth.map((token) => Number((token[1] * ethToUSDC).toFixed(2))) as number[])

  //================ BAR CHART ===================
  const barOptions: ApexOptions = {
    //colors: ['#87CEFA'],
    series: [
      {
        name: 'tvl',
        data: linechartData as [number, number][],
      },
    ],
    chart: {
      id: 'basic-bar',
      width: '100%',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'stepline',
      width: 1,
    },
    yaxis: {
      min: 0,
      forceNiceScale: true,
      decimalsInFloat: 2,
      labels: {
        formatter: (value) => numeral(value).format('0,0.[00]'),
      },
    },
    xaxis: {
      // eslint-disable-next-line prettier/prettier
      type: 'datetime'
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      //colors: ['green'],
      gradient: {
        //shade: 'dark',
        //type: 'horizontal',
        shadeIntensity: 0.1,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 0.5,
        opacityTo: 0.6,
        //stops: [0, 50, 100],
        //colorStops: [],
      },
      // type: 'pattern',
      // pattern: {
      //   style: 'verticalLines',
      //   width: 6,
      //   height: 6,
      //   strokeWidth: 2,
      // },
    },
    // plotOptions: {
    //   bar: {
    //     distributed: true,
    //     rangeBarOverlap: true,
    //   },
    // },
  }

  const tableRows: TableData[] = userData
    ? userData.detail.map((cube) => {
        const cubeInfo = cube[1]
        return createData(
          cube[0],
          cubeInfo.cube_name,
          cubeInfo.state,
          cubeInfo.base_currency,
          cubeInfo.value_locked,
          cubeInfo.address_earning_eth * ethToUSDC,
          cubeInfo.cube_version
        )
      })
    : []

  const fundingCubeCount = !loadingData && userData ? userData.detail.filter((cube) => cube[1].state < 2).length : 0
  const tradingCubeCount = !loadingData && userData ? userData.detail.filter((cube) => cube[1].state === 2).length : 0
  const endedCubeCount = !loadingData && userData ? userData.detail.filter((cube) => cube[1].state > 2).length : 0
  const profitableCubeCount =
    !loadingData && userData
      ? userData.detail.filter((cube) => cube[1].address_earning_eth > 0 && cube[1].state > 2).length
      : 0

  return (
    <>
      <OutWrapper>
        <RowBetween>
          <Announcement>
            Trader Profile{' '}
            <div className="userAddress">
              {' '}
              {/* {address && (
                <IconWrapper>
                  <Identicon />
                </IconWrapper>
              )} */}
              {checksumAddress && shortenAddress(address, 8)}
              {/* <CopyHelper toCopy={address as string} /> */}
            </div>
            <AccountUtil style={{ marginLeft: '-0.5rem' }}>
              {address && (
                <Copy toCopy={address}>
                  <span style={{ marginLeft: '4px' }}>Copy Address</span>
                </Copy>
              )}
              {address && (
                <AddressLink href={getExplorerLink(1, address, ExplorerDataType.ADDRESS)}>
                  <LinkIcon size={'20'} />
                  <span style={{ marginLeft: '4px', whiteSpace: 'nowrap' }}>View on Explorer</span>
                </AddressLink>
              )}
            </AccountUtil>
          </Announcement>
          <LotsCube src={LotsCubes} alt="cubes" />
        </RowBetween>
        <Tabs>
          {/* Desktop only */}
          <Row style={{ padding: '0' }}>
            {/* <NavButton
              onClick={() => history.push('/investor')}
              style={{
                marginRight: '8px',
                cursor: 'pointer',
              }}
            >
              Trader Profile
            </NavButton> */}
            {/* <NavButton>
              {' '}
              {'>'} {address}
            </NavButton> */}
          </Row>
        </Tabs>

        <SectionWrapper>
          <NumberWrapperWithLoading>
            {' '}
            {loadingData && <BorderLinearProgress />}
            <NumberWrapper className="btn">
              <img src={TVL_icon} alt="tvl" style={{ width: '30px', marginBottom: '1rem' }} />
              <div>Cumulative TVL under Trader</div>
              <DisplayNumber>
                <span>
                  {' '}
                  <CountUp end={userData?.total_value_locked_eth || 0} duration={0.4} separator="," decimals={4} /> ETH
                  <div style={{ fontSize: '13px', fontWeight: 500, color: '#303030' }}>
                    {' '}
                    ≈ $
                    {userData?.total_value_locked_eth
                      ? numeral(userData?.total_value_locked_eth * ethToUSDC).format('0,0')
                      : 0}
                  </div>
                </span>
              </DisplayNumber>
            </NumberWrapper>
          </NumberWrapperWithLoading>

          <NumberWrapperWithLoading>
            {loadingData && <BorderLinearProgress />}
            <NumberWrapper>
              <img src={profit_icon} alt="profit" style={{ width: '30px', marginBottom: '1rem' }} />
              <div>Total Profit</div>
              <DisplayNumber>
                <CubeNumberSplit>
                  <span>
                    <CountUp
                      end={(userData?.totalProfitRate as number) * 100 || 0}
                      duration={1}
                      separator=","
                      decimals={2}
                    />
                    %
                  </span>
                </CubeNumberSplit>
                <span style={{ width: '50%', textAlign: 'center', marginLeft: '5px' }}>
                  <CountUp end={userData?.totalProfit || 0} duration={1} separator="," decimals={3} />
                  ETH
                  <ToUSDDiv>
                    {' '}
                    ≈ ${userData?.total_value_locked_eth ? numeral(userData?.totalProfit * ethToUSDC).format('0,0') : 0}
                  </ToUSDDiv>
                </span>
              </DisplayNumber>
            </NumberWrapper>
          </NumberWrapperWithLoading>

          <NumberWrapperWithLoading>
            {loadingData && <BorderLinearProgress />}
            <NumberWrapper>
              <div>
                <img src={cube_icon} alt="cube" style={{ width: '30px', marginBottom: '1rem' }} />{' '}
                <ToggleButtonGroup
                  color="primary"
                  value={isCreated}
                  exclusive
                  onChange={handleChange}
                  size="small"
                  style={{ position: 'absolute', marginLeft: '1rem', marginTop: '-0.2rem' }}
                >
                  <ToggleButton value={true}>All</ToggleButton>
                  <ToggleButton value={false}>Completed</ToggleButton>
                </ToggleButtonGroup>
              </div>

              <div>Number of {isCreated ? 'All' : 'Completed'} Cubes</div>

              {/* <DisplayNumber>
              <CubeNumberSplit>
                <CountUp end={3} duration={0.4} />
              </CubeNumberSplit>
              <CubeNumberSplit>
                <CountUp end={6} duration={0.4} />
              </CubeNumberSplit>
              <CubeNumberSplit style={{ border: 'none' }}>
                <CountUp end={9} duration={0.4} />
              </CubeNumberSplit>
            </DisplayNumber> */}
              {isCreated ? (
                <DisplayNumber
                //style={{ cursor: 'pointer' }}
                //onClick={() => window.open(`/#/investor`, '_blank')}
                >
                  <CubeNumberSplit style={{ color: '#0d6ce8' }}>
                    <CountUp end={fundingCubeCount} duration={0.4} preserveValue={true} />
                    <div className="title">funding</div>
                  </CubeNumberSplit>
                  <CubeNumberSplit style={{ color: '#4fbb26' }}>
                    <CountUp end={tradingCubeCount} duration={0.4} preserveValue={true} />
                    <div className="title">trading</div>
                  </CubeNumberSplit>
                  <span style={{ width: '50%', textAlign: 'center', color: '#d64b4b' }}>
                    <CountUp end={endedCubeCount} duration={0.4} preserveValue={true} />
                    <div className="title">ended</div>
                  </span>
                </DisplayNumber>
              ) : (
                <DisplayNumber
                //style={{ cursor: 'pointer' }}
                //onClick={() => window.open(`/#/investor`, '_blank')}
                >
                  <CubeNumberSplit style={{ color: '#0d6ce8' }}>
                    <CountUp end={endedCubeCount} duration={0.4} preserveValue={true} />
                    <div className="title">ended cubes</div>
                  </CubeNumberSplit>
                  <CubeNumberSplit style={{ color: '#4fbb26', border: 'none' }}>
                    <CountUp end={profitableCubeCount} duration={0.4} preserveValue={true} />
                    <div className="title">profitable</div>
                  </CubeNumberSplit>
                  {/* <span style={{ width: '50%', textAlign: 'center', color: '#d64b4b' }}>
                    <CountUp end={endedCubeCount - profitableCubeCount} duration={0.4} preserveValue={true} />
                    <div className="title">ended</div>
                  </span> */}
                </DisplayNumber>
              )}
            </NumberWrapper>
          </NumberWrapperWithLoading>
        </SectionWrapper>
        {!loadingData && userData ? (
          userData.detail.length === 0 ? (
            <NoData>
              <Inbox size={48} strokeWidth={1} style={{ margin: 'auto', marginBottom: '0' }} />
              <div style={{ margin: 'auto', marginTop: '20px' }}>NO DATA</div>
            </NoData>
          ) : (
            <SameRow>
              <PiechartDiv>
                <div style={{ marginBottom: '20px' }}>Locked Tokens (In USDC)</div>
                <MobileHide>
                  {' '}
                  <div style={{ margin: 'auto', height: '250px' }}>
                    <Chart type="pie" series={pieSeries} options={pieOptions} height="100%" />
                  </div>
                </MobileHide>
                <DesktopHide>
                  <div style={{ margin: 'auto', height: '200px' }}>
                    <Chart type="pie" series={pieSeries} options={pieOptions} height="100%" />
                  </div>
                </DesktopHide>
              </PiechartDiv>
              <LinechartDiv>
                <div style={{ marginBottom: '10px' }}>Cumulative TVL (In ETH)</div>
                <Chart type="area" series={barOptions.series} options={barOptions} height="100%" width="100%" />
              </LinechartDiv>

              {/* <div style={{ width: '50%' }}>
              <div style={{ marginBottom: '10px' }}>Token Portfolio (In USDC)</div>
              <Chart type="pie" series={pieSeries} options={pieOptions} height="100%" />
            </div> */}
            </SameRow>
          )
        ) : (
          <LoadingRows>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </LoadingRows>
        )}

        <TitleDiv>Created Cube</TitleDiv>
        <EnhancedTable account={address} rows={tableRows} />
      </OutWrapper>
    </>
  )
}

const ToUSDDiv = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: #303030;
  text-align: left;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  text-align: center;
  `};
`

const NoData = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 200px;
`

const TitleDiv = styled.div`
  margin-top: 5rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-top: 3.5rem;
  `};
`

const PiechartDiv = styled.div`
  width: 40%; //50
  ${({ theme }) => theme.mediaWidth.upToSmall`
   width: 100%;
  `};
`

const LinechartDiv = styled.div`
  width: 60%; //50
  height: 280px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
   margin-top: 10px;
   width: 100%;
  `};
`

const BorderLinearProgress = styled(LinearProgress)(({}) => ({
  height: 10,
  borderRadius: '0.7rem',
  margin: 'auto',
  marginBottom: '-4.7px',
  width: '96.5%',
}))

const Announcement = styled.div`
  font-size: 30px;
  color: #242424;
  font-weight: 700;
  width: 50%;
  padding-top: 20px;
  padding-bottom: 20px;
  //margin-left: 5px;
  .userAddress {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 24px;
    padding-bottom: 0px;
    //display: none;
    .userAddress {
      font-size: 16px;
      margin-top: 10px;
    }
  `};
`

const LotsCube = styled.img`
  top: 100px;
  margin-left: auto;
  max-width: 340px;
  margin-top: -15px;
  margin-bottom: -10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: auto;
    height: auto;
    display: block;
    width: 283.52px;
    margin-top: -50px;
    margin-bottom: -20px;
    display: none;
  `};
`
const SameRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 250px;
  margin: 20px 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    margin-top: 10px;
    height: 100%;
  `};
`

// const IconWrapper = styled.div<{ size?: number }>`
//   ${({ theme }) => theme.flexColumnNoWrap};
//   align-items: center;
//   justify-content: center;
//   margin-right: 8px;
//   & > img,
//   span {
//     height: ${({ size }) => (size ? size + 'px' : '32px')};
//     width: ${({ size }) => (size ? size + 'px' : '32px')};
//   }
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     align-items: flex-end;
//   `};
// `

const NumberWrapperWithLoading = styled.div`
  width: 30%;
  .progress {
    margin: auto;
    margin-bottom: -4px;
    width: 95%;
    overflow: hidden;
    height: 10;
    border-radius: 5;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  padding: 5px 0;
`};
`

const NumberWrapper = styled.div`
  border: 1px solid hsla(0, 0%, 49%, 0.1);
  border-radius: 0.7rem;
  padding: 1.3rem 1.5rem 1.3rem 1.5rem;
  //width: 30%;
  color: #989898;
  font-size: 0.8rem;
  text-align: left;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  //padding: 0.5rem;
  font-size: 0.7rem;
  padding: 1rem;
`};
`

const DisplayNumber = styled.div`
  color: black;
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  .title {
    font-size: 0.8rem;
    font-weight: 600;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 1.2rem;
`};
`
const CubeNumberSplit = styled.div`
  border-right: 1px solid #e8e8e8;
  width: 50%;
  text-align: center;
`

const SectionWrapper = styled.div`
  padding: 1.5rem 0 1.5rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 0.5rem;
  flex-direction: column;
  `};
`

const OutWrapper = styled.div`
  //padding: 50px 50px 150px 50px;
  padding: 25px 10px 100px 10px;
  max-width: 1057px;
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 25px;
  `};
`

const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: 3rem;
  justify-content: space-evenly;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const AccountUtil = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 0 1rem 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 1rem 0 0.5rem 0;
`};
`

const AddressLink = styled(ExternalLink)`
  font-size: 0.825rem;
  color: ${({ theme }) => theme.text3};
  margin-left: 1rem;
  font-size: 0.825rem;
  display: flex;
  :hover {
    color: ${({ theme }) => theme.text2};
  }
`
