import { Currency } from '@uniswap/sdk-core'
import { useState, useCallback, ReactNode } from 'react'
import styled from 'styled-components/macro'
import { darken } from 'polished'
import CurrencyLogo from '../CurrencyLogo'
import { ButtonGray } from '../Button'
import { RowFixed } from '../Row'
import { TYPE } from '../../theme'
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'
import { Trans } from '@lingui/macro'
import useTheme from '../../hooks/useTheme'
import { Lock } from 'react-feather'
import { AutoColumn } from 'components/Column'
import MultipleCurrencySearchModal from '../SearchModal/MultipleCurrencySearchModal'

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '16px' : '20px')};
  background-color: ${({ theme, hideInput }) => (hideInput ? 'transparent' : theme.bg2)};
  z-index: 1;
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
`

const FixedContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.bg1};
  opacity: 0.95;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

const Container = styled.div<{ hideInput: boolean }>`
  border-radius: ${({ hideInput }) => (hideInput ? '11px' : '20px')};
  border: 1px solid ${({ theme, hideInput }) => (hideInput ? theme.bg4 : theme.bg2)};
  background-color: ${({ theme }) => theme.bg1};
  //width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  max-width: 551px;
  height: 50px;
  padding-right: 1px;
  :focus,
  :hover {
    border: 1px solid ${({ theme, hideInput }) => (hideInput ? ' transparent' : theme.bg3)};
  }
`

const CurrencySelect = styled(ButtonGray)<{ selected: boolean; hideInput?: boolean }>`
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  background-color: ${({ selected, theme }) => (selected ? theme.bg1 : theme.bg0)};
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
  border-radius: 11px;
  //box-shadow: ${({ selected }) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')};
  //box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  height: ${({ hideInput }) => (hideInput ? '2.8rem' : '2.4rem')};
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  padding: 0 8px;
  justify-content: space-between;
  margin-right: ${({ hideInput }) => (hideInput ? '0' : '12px')};
  :focus,
  :hover {
    background-color: ${({ selected, theme }) => (selected ? theme.bg2 : darken(0.05, theme.bg1))};
  }
`

const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: ${({ selected }) => (selected ? ' 1rem 1rem 0.75rem 1rem' : '1rem 1rem 0.75rem 1rem')};
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
`

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.35rem;
  height: 35%;

  path {
    stroke: ${({ theme }) => theme.text1};
    stroke-width: 1.5px;
  }
`

// =========== LC ==============
// add isTrader in Currency Input Panel
// to determine when to use contract address and when to use wallet
interface MultipleCurrencyInputPanelProps {
  isWallet?: boolean
  label?: ReactNode
  onCurrencySelect?: (currency: Currency[]) => void
  currencies?: Currency[] | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  showCurrencyAmount?: boolean
  disableNonToken?: boolean
  locked?: boolean
}

export default function MultipleCurrencyInputPanel({
  isWallet,
  onCurrencySelect,
  currencies,
  otherCurrency,
  id,
  showCommonBases,
  showCurrencyAmount,
  disableNonToken,
  hideInput = false,
  locked = false,
  ...rest
}: MultipleCurrencyInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false)

  const theme = useTheme()

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  return (
    <InputPanel id={id} hideInput={hideInput} {...rest}>
      {locked && (
        <FixedContainer>
          <AutoColumn gap="sm" justify="center">
            <Lock />
            <TYPE.label fontSize="12px" textAlign="center" padding="0 12px">
              <Trans>The market price is outside your specified price range. Single-asset deposit only.</Trans>
            </TYPE.label>
          </AutoColumn>
        </FixedContainer>
      )}
      <Container hideInput={hideInput}>
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={!onCurrencySelect}>
          <CurrencySelect
            selected={false}
            hideInput={hideInput}
            className="open-currency-select-button"
            onClick={() => {
              if (onCurrencySelect) {
                setModalOpen(true)
              }
            }}
          >
            <Aligner>
              <RowFixed>
                {currencies && currencies?.length !== 0 ? (
                  <>
                    {currencies.map((curr, i) => (
                      <CurrencyLogo
                        key={`${curr.symbol}${i}`}
                        style={{ marginRight: '0.5rem' }}
                        currency={curr}
                        size={'28px'}
                      />
                    ))}
                  </>
                ) : (
                  <TYPE.black fontWeight={500} fontSize={17} color={theme.text4} textAlign="center">
                    <Trans>{'Select allowed tokens'}</Trans>
                  </TYPE.black>
                )}
              </RowFixed>
              {onCurrencySelect && <StyledDropDown selected={!!currencies} />}
            </Aligner>
          </CurrencySelect>
        </InputRow>
      </Container>
      {onCurrencySelect && (
        <MultipleCurrencySearchModal
          isWallet={isWallet}
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrencies={currencies}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
          showCurrencyAmount={showCurrencyAmount}
          disableNonToken={disableNonToken}
        />
      )}
    </InputPanel>
  )
}
