import styled from 'styled-components/macro'

export const LandingFrame = styled.main`
  padding: 0 5%;
  width: 100%;
  item-aligns: center;
`

export const AuditCompleted = styled.div`
  color: #0c1e70;
  font-size: 18px;
  font-weight: 400;
  padding-top: 35px;
  margin-bottom: 21px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-top: 0;
    font-size: 12px;
    padding-left: 5px;
    margin-bottom: 15px;
   `};
`

export const SameRow = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 14px;
  img {
    height: 38px;
    width: auto;
    cursor: pointer;
  }
  span {
    white-space: nowrap;
    line-height: 38px;
    font-size: 18px;
    font-weight: 600;
    margin-left: 70px;
    text-align: center;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    img {
      height: 28px;
      margin-top: 3px;
      padding-left: 15px;
    }
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
  span{
    padding-left: 30px;
    font-size: 16px;
  }`};

  ${({ theme }) => theme.mediaWidth.upToSmall`
  gap: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  img {
    height: 18px;
    width: auto;
  }
  span{
    display: none;
  }
   `};
`

export const CubeImgWrapperMobile = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin: 20px auto 0 -15%;
  max-width: 250px;
  //min-width: 150px;
  display: block;
`};
  @media (max-width: 340px) {
    display: none;
  }
`

export const BorderLine = styled.div`
  padding: 15px 60px;
  width: 50%;
  margin: auto;
  border-bottom: 1px solid #979797;
  margin-bottom: 50px;
`

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  item-aligns: center;
  justify-content: center;
  padding-bottom: 80px;
  max-width: 1200px;
  width: 90%;
  margin: auto;
  padding-top: 90px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  //flex-direction: column;
`};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    margin: auto;
    flex-direction: column;
    padding: 10% 5% 7% 3%;
  `};
`

export const TitleText = styled.div`
  font-weight: 700;
  font-size: 38px;
  color: #0c1e70;
  text-align: left;
  margin: 0 auto 5px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  display: inline-block;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 30px;
   `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
   font-size: 25px;
    `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    text-align: center;
    font-size: 18px;
    `};
`

export const SubTitleText = styled.div`
  font-weight: 700;
  font-size: 17px;
  color: #868686;
  text-align: left;
  // text-indent: 8px;
  padding-top: 15px;
  margin-right: 50px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 16px;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 15px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    text-indent: 0;
    padding-top: 15px;
    padding-bottom: 6px;
    padding-left: 8px;
    text-align: left;
    margin: 0 auto 5px;
    margin: auto;
    max-width: 400px;
    font-size: 14px;
    `};
`

export const FrontText = styled.div`
  font-weight: 800;
  font-size: 44px;
  max-width: 566px;
  color: #0c1e70;
  text-align: left;
  padding-top: 20px;
  margin-right: 130px;
  line-height: 50px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 40px;
    margin-right: 100px;
   `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-right: 40px;
   font-size: 38px;
    `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  line-height: 18px;
    margin-right: 0;
    padding: 13px 5%;
    padding-left: 5px;
    font-size: 18px;
    font-weight: 700;
    width: 207px;
    `};
`

export const DescText = styled.div`
  font-weight: 600;
  font-size: 19px;
  color: #868686;
  line-height: 120%;
  text-align: left;
  //padding-top: 15px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
  font-size: 19px;
   `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
   font-size: 18px;
    `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
    padding-left: 8px;
    max-width: 400px;
    font-size: 14px;
    margin: auto;
    text-align: left;
    line-height: 150%;
    `};
`
export const ListText = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 98%;
  color: #868686;
  text-align: left;
  padding: 0;
  padding-left: 13px;
  margin: 10px 0 0 3px;
  border-left: 6px solid #facc66;
  li {
    list-style-type: none;
    line-height: 22px;
  }
  ${({ theme }) => theme.mediaWidth.upToLarge`
  font-size: 16px;
   `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
   font-size: 14px;
    `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-left: 4px solid #facc66;
    padding-left: 10px;
    margin: auto;
    margin-left: 10px;
    max-width: 400px;
    font-size: 14px;
    li {
      line-height: 18px;
    }
    `};
`
export const TitleTextWrapper = styled.div`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`

export const LeftWrapper = styled.div`
  margin-right: -50px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: auto;
  `};
`

export const RightWrapper = styled.main`
  max-width: 460px;
  max-height: 810px;
  display: inline-flex;
`

export const CubeImgWrapper = styled.img`
  height: 400px;
  margin-top: 10px;
  margin-left: -70px;
  z-index: -1;
  ${({ theme }) => theme.mediaWidth.upToLarge`

  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 220px;
    height: 100%;
    margin-left: -80px;
  `};
`

export const Monitor = styled.img`
  padding-top: 30px;
  max-width: 550px;
  width: 100%;
  height: 100%;
  margin-right: 50px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    max-width: 400px;
    margin-left: -20px;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 350px;
    margin-right: 20px;
    //margin: auto;
    //display: none;
 `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    margin: auto;
    margin-top: -30px;
    width: 100vw;
    height: 100%;
 `};
`

export const BigCube = styled.img`
  max-width: 620px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    max-width: 500px;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 40px;
    max-width: 400px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
  //margin: auto;
   `};
`

export const BigCubeMobile = styled.img`
  height: 100%;
  display: none;
  padding-top: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
    display: block;
    margin: 0 auto;
    width: 245px;
  `};
`

export const CubeDemo = styled.img`
  //padding-left: 30px;
  max-width: 600px;
  margin-right: 20px;
  margin-top: 30px;
  width: 100%;
  height: auto;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    max-width: 450px;
    margin-left: -60px;
    margin-right: 10px;
 `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    margin: auto;
 `};
`
export const CubeDemoMobile = styled.img`
  height: 100%;
  display: none;
  width: 84px;
  padding-left: 15px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: block;
  margin: auto;
  margin-bottom: 20px;
  width: 319px;
`};
`

export const SwapDemo = styled.img`
  max-width: 462px;
  height: 100%;
  margin-top: -70px;
  margin-right: 100px;
  margin-left: 50px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    max-width: 350px;
    margin-right: 70px;
 `};
  //   ${({ theme }) => theme.mediaWidth.upToMedium`
//   margin: auto;
//   display: none;
// `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin: auto;
  display: none;
`};
`

export const SwapDemoMobile = styled.img`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: block;
  align-self: center;
  width: 293px;
  height:  100%;
  padding-bottom: 25px;
  padding-right: 8%;
  margin-right: 5px;
  margin: auto;
`};
`

export const RoleSelection = styled.button`
  width: 300px;
  margin: 5px 450px 25px auto;
  height: 72px;
  background-color: #facc66;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-item: center;
  border-radius: 22px;
  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
      0px 24px 32px rgba(0, 0, 0, 0.04);
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 250px;
    height: 70px;
    margin: 10px auto;
  
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
      width: 200px;
      height: 50px;
      margin: 5px auto;
   `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 145px;
    height: 38px;
    margin: 0 auto 10px;
    align-item: stretch;
   `};
  .traderButtonIcon {
    width: 90px;
    padding-left: 20px;
    //padding-top: 10px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
      width: 73px;
      padding-top: 0;
      padding-left: 0;
   `};
    ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 48px;
    padding-top: 0;
    padding-left: 0;
  `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 38px;;
    padding-left: 0;
    padding-top: 0;
   `};
  }
  .investorButtonIcon {
    padding-top: 10px;
    padding-left: 20px;
    width: 80px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
      width: 70px;
      padding-top: 0;
      padding-left: 0;
   `};
    ${({ theme }) => theme.mediaWidth.upToMedium`
      width: 45px;
      padding-top: 0;
      padding-left: 0;
   `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 33px;
    padding-top: 0;
    padding-left: 0;
   `};
  }
`

export const RoleColumn = styled.div`
  display: flex;
  flex-direction: column;
  //flex-wrap: wrap;
  align-items: left;
  justify-content: center;
  border-spacing: 12px;
  padding-top: 3%;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin-right: 60%;
   `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-top: 2%;
 `};
`
export const RightTextWrapper = styled.div`
  //display: inline-block;
  margin-left: 150px;
  margin-right: auto;
  text-align: left;
  min-width: 280px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
  margin-left: 20px;
   `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    margin: auto;
    min-width: auto;
  `};
`
export const Platform = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  padding-left: 70px;
  gap: 200px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  flex-direction: column;
  padding-left: 0;
  gap: 10px;
  `};
`

export const PlatformIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0 auto;
  .icon {
    width: 60px;
    height: 60px;
    margin: 0 19px;
    @media (max-width: 768px) {
      width: 30px;
      height: 30px;
      margin: 0 15px;
    }
  }
  .dydxicon {
    width: 200px;
    height: 111px;
    margin-top: -4%;
    margin-left: -5%;
    @media (max-width: 768px) {
      width: 100px;
      margin-top: -4%;
      height: 55.5px;
    }
  }
  @media (max-width: 768px) {
    justify-content: center;
    align-center: center;
  }
`
