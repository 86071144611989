import { useContext, useEffect, useMemo } from 'react'
import { Position } from '@uniswap/v3-sdk'
import Badge from 'components/Badge'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { usePool } from 'hooks/usePools'
import { useToken } from 'hooks/Tokens'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { MEDIA_WIDTHS, MobileHide } from 'theme'
import { PositionDetails } from 'types/position'
import { Price, Token, Percent } from '@uniswap/sdk-core'
import { formatTickPrice } from 'utils/formatTickPrice'
import Loader from 'components/Loader'
import { unwrappedToken } from 'utils/unwrappedToken'
import RangeBadge from 'components/Badge/RangeBadge'
import HoverInlineText from 'components/HoverInlineText'
import { DAI, USDC, USDT, WBTC, WETH9_EXTENDED } from '../../constants/tokens'
import { Trans } from '@lingui/macro'
import useIsTickAtLimit from 'hooks/useIsTickAtLimit'
import { Bound } from 'state/mint/v3/actions'
import { VersionContext } from 'pages/App'

const LinkRow = styled(Link)`
  align-items: center;
  display: flex;
  cursor: pointer;
  user-select: none;
  flex-direction: row;

  justify-content: space-between;
  color: ${({ theme }) => theme.text1};
  margin: 8px 0;

  padding: 16px 16px 26px 0;
  text-decoration: none;
  font-weight: 500;

  &:last-of-type {
    margin: 8px 0 0 0;
  }
  & > div:not(:first-child) {
    text-align: center;
  }
  :hover {
    background-color: ${({ theme }) => theme.bg1};
  }

  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    /* flex-direction: row; */
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    row-gap: 12px;
    padding: 0 0 6px 0;
    margin-right: 0;
  `};
  border-bottom: ${({ theme }) => '1px solid' + theme.bg2};
`

const BadgeText = styled.div`
  font-weight: 500;
  font-size: 18px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
`

const DataLineItem = styled.div`
  font-size: 14px;
`

const RangeLineItem = styled(DataLineItem)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  margin-top: 4px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  //background-color: ${({ theme }) => theme.bg2};
    border-radius: 12px;
    padding: 0 0;
    margin-top: 0;
`};
`
const RangeText = styled.span`
  /* background-color: ${({ theme }) => theme.text3}; */
  padding: 0.25rem 0.5rem;
  border-radius: 8px;
  text-align: left;
  font-size: 16px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 13px;
  `};
`

const ExtentsText = styled.span`
  color: ${({ theme }) => theme.text3};
  font-size: 16px;
  padding-bottom: 8px;
  margin-right: 4px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    //display: none;
    font-size: 13px;
  `};
  text-align: left;
`

const PrimaryPositionIdData = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding-right: 30px;
  > * {
    margin-right: 8px;
  }
`

const DataText = styled.div`
  font-weight: 600;
  font-size: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
  `};
`

const DesktopHide = styled.span`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: block;
`};
`

interface PositionListItemProps {
  cubeAddr: string
  version: string
  positionDetails: PositionDetails
}

export function getPriceOrderingFromPositionForUI(position?: Position): {
  priceLower?: Price<Token, Token>
  priceUpper?: Price<Token, Token>
  quote?: Token
  base?: Token
} {
  if (!position) {
    return {}
  }

  const token0 = position.amount0.currency
  const token1 = position.amount1.currency

  // if token0 is a dollar-stable asset, set it as the quote token
  const stables = [DAI, USDC, USDT]
  if (stables.some((stable) => stable.equals(token0))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // if token1 is an ETH-/BTC-stable asset, set it as the base token
  const bases = [...Object.values(WETH9_EXTENDED), WBTC]
  if (bases.some((base) => base.equals(token1))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // if both prices are below 1, invert
  if (position.token0PriceUpper.lessThan(1)) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // otherwise, just return the default
  return {
    priceLower: position.token0PriceLower,
    priceUpper: position.token0PriceUpper,
    quote: token1,
    base: token0,
  }
}

export default function PositionListItem({
  cubeAddr,
  positionDetails,
  version: versionFromURL,
}: PositionListItemProps) {
  const {
    token0: token0Address,
    token1: token1Address,
    fee: feeAmount,
    liquidity,
    tickLower,
    tickUpper,
  } = positionDetails

  const { switchVersion } = useContext(VersionContext)

  useEffect(() => {
    switchVersion(versionFromURL ? versionFromURL?.replace('v', '').replace('-', '.') : '1.2')
  }, [switchVersion, versionFromURL])

  const token0 = useToken(token0Address)
  const token1 = useToken(token1Address)

  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined

  // construct Position from details returned
  const [, pool] = usePool(currency0 ?? undefined, currency1 ?? undefined, feeAmount)

  const position = useMemo(() => {
    if (pool) {
      return new Position({ pool, liquidity: liquidity.toString(), tickLower, tickUpper })
    }
    return undefined
  }, [liquidity, pool, tickLower, tickUpper])

  const tickAtLimit = useIsTickAtLimit(feeAmount, tickLower, tickUpper)

  // prices
  const { priceLower, priceUpper, quote, base } = getPriceOrderingFromPositionForUI(position)

  const currencyQuote = quote && unwrappedToken(quote)
  const currencyBase = base && unwrappedToken(base)

  // check if price is within range
  const outOfRange: boolean = pool ? pool.tickCurrent < tickLower || pool.tickCurrent >= tickUpper : false

  const positionSummaryLink = '/trader/' + versionFromURL + '/' + cubeAddr + '/pool/' + positionDetails.tokenId

  const removed = liquidity?.eq(0)

  return (
    <LinkRow to={positionSummaryLink}>
      <PrimaryPositionIdData>
        <MobileHide>
          <DoubleCurrencyLogo currency0={currencyBase} currency1={currencyQuote} size={38} margin />
        </MobileHide>
        <DesktopHide>
          <DoubleCurrencyLogo currency0={currencyBase} currency1={currencyQuote} size={28} margin />
        </DesktopHide>
        <DataText>
          &nbsp;{currencyQuote?.symbol}&nbsp;/&nbsp;{currencyBase?.symbol}
        </DataText>
        &nbsp;
        <Badge>
          <BadgeText>
            <Trans>{new Percent(feeAmount, 1_000_000).toSignificant()}%</Trans>
          </BadgeText>
        </Badge>
        <DesktopHide style={{ width: '100%', whiteSpace: 'nowrap', fontSize: '14px' }}>
          <RangeBadge removed={removed} inRange={!outOfRange} />
        </DesktopHide>
      </PrimaryPositionIdData>

      {priceLower && priceUpper ? (
        <RangeLineItem>
          <RangeText>
            <ExtentsText>
              <Trans>Min: </Trans>
            </ExtentsText>
            <Trans>
              {formatTickPrice(priceLower, tickAtLimit, Bound.LOWER)} <HoverInlineText text={currencyQuote?.symbol} />{' '}
              per <HoverInlineText text={currencyBase?.symbol ?? ''} />
            </Trans>
          </RangeText>
          <RangeText>
            <ExtentsText>
              <Trans>Max:</Trans>
            </ExtentsText>
            <Trans>
              {formatTickPrice(priceUpper, tickAtLimit, Bound.UPPER)} <HoverInlineText text={currencyQuote?.symbol} />{' '}
              per <HoverInlineText maxCharacters={10} text={currencyBase?.symbol} />
            </Trans>
          </RangeText>
        </RangeLineItem>
      ) : (
        <Loader size={'28px'} />
      )}
      <MobileHide style={{ width: '100%' }}>
        <RangeBadge removed={removed} inRange={!outOfRange} />
      </MobileHide>
    </LinkRow>
  )
}
