import { useEffect, useState } from 'react'
import axios from 'axios'

export interface CubeApiType {
  assets: string
  assets_in_eth: any[][]
  cube_address: string
  cube_state: number
  base_currency: string
  principle: string
  profit: string
  token_whitelist: string
}

const useFetchCubeData = () => {
  const [data, setData] = useState<CubeApiType[]>()
  const [loading, setLoading] = useState(false)

  //const ENDPOINT = 'http://64.71.142.215:8006/api/v1/profit'
  const ENDPOINT = 'https://www.liquidcube.io/api/v1/profit'

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(ENDPOINT)
        //console.log(response.data)
        setData(response.data)
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
    }

    fetchData()
  }, [])

  return {
    data,
    loading,
  }
}

export function useCubeWhitelistAPI(cubeAddress: string) {
  const [data, setData] = useState<string[]>()
  const [loading, setLoading] = useState(false)

  //const ENDPOINT = 'http://64.71.142.215:8006/api/v1/profit'
  const ENDPOINT = `https://www.liquidcube.io/api/v1/cube?address=${cubeAddress}`

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const { data: response } = await axios.get(ENDPOINT)
        //console.log(response.data)
        setData(response.data[0].token_whitelist)
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
    }

    if (!cubeAddress) {
      setData(undefined)
    } else {
      fetchData()
    }
  }, [cubeAddress])

  return {
    data,
    loading,
  }
}

export default useFetchCubeData
